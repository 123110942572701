import {
    useAuth,
    useConfirm,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQAccountCreateMutation,
    IsAccountNameInUseDocument,
    useAccountCreateMutation,
    GQAccountCreate,
    GQIsAccountNameInUseQuery,
} from "graphql-sdk";
import { defaultAccount, schemaAccount, schemaGiftCardAccount } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { AdminContainer } from "../../..";
import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { CreateForm } from "./create-form";

import { useApolloClient, type FetchResult } from "@apollo/client";
import type { StyleFunction } from "@venuepos/react-common";
type ScreenProps = RootStackScreenProps<"ACCOUNT_CREATE">;

export function AccountCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();
    const [accountCreate] = useAccountCreateMutation();
    const graphqlClient = useApolloClient();
    const confirm = useConfirm();

    // The validation schema is used for both create and edit, but VAT can only be set during create...
    // so vatId is required in this screen. Compare to the Edit screen, where schemaAccount is not extended
    const type = route.params.type;
    const formSchema =
        type === "GIFT_CARD" ? schemaGiftCardAccount : schemaAccount;
    const form = useForm<GQAccountCreate>(formSchema, {
        ...defaultAccount,
        vatId: "",
    });
    const [{ values: formValues }, { reset: resetForm }] = form;

    const myCustomerId =
        route.params.referrer?.route &&
        route.params.referrer.route === "CUSTOMER_VIEW"
            ? route.params.referrer?.id
            : undefined;

    const persistAccount = useCallback(async () => {
        if (!formValues || !formValues.name || formValues.name.trim() === "") {
            return;
        }

        let account: FetchResult<
            GQAccountCreateMutation,
            Record<string, any>,
            Record<string, any>
        >;
        await handleMutationError(
            async () => {
                account = await accountCreate({
                    variables: {
                        account: {
                            name: formValues.name,
                            type: type,
                            status: formValues.status,
                            amount: formValues.amount,
                            customerId: myCustomerId,
                            vatId: formValues.vatId,
                            externalId: formValues.externalId,
                        },
                    },
                });
            },
            t("common.saved", "Saved"),
            () => {
                resetForm();
                if (
                    route.params.referrer?.id &&
                    route.params.referrer?.route &&
                    route.params.referrer?.route === "CUSTOMER_VIEW"
                ) {
                    navigate("CUSTOMER_VIEW", {
                        id: route.params.referrer.id,
                    });
                    return;
                }

                if (!account.data) {
                    return;
                }

                navigate("ACCOUNT_EDIT", {
                    id: account.data.accountCreate.id,
                });
            }
        );
    }, [
        accountCreate,
        formValues,
        handleMutationError,
        myCustomerId,
        navigate,
        resetForm,
        route.params.referrer,
        t,
        type,
    ]);

    const handleSubmit = useCallback(async () => {
        if (!formValues || !formValues.name || formValues.name.trim() === "") {
            return;
        }
        const variables = {
            name: formValues!.name,
            customerId: myCustomerId,
        };
        const isNameInUse =
            await graphqlClient.query<GQIsAccountNameInUseQuery>({
                query: IsAccountNameInUseDocument,
                variables,
                fetchPolicy: "no-cache",
            });

        if (isNameInUse.data.isAccountNameInUse.status) {
            if (
                !(await confirm(
                    t(
                        "backoffice.account.duplicate_name",
                        "Duplicate account name found"
                    ),
                    t(
                        "backoffice.account.duplicate_name_explain",
                        "An account with this name ({{ name }}) already exists.\n\nAre you sure, you want to save?",
                        { name: formValues.name }
                    )
                ))
            ) {
                return;
            }
        }
        persistAccount();
    }, [confirm, formValues, graphqlClient, myCustomerId, persistAccount, t]);

    return (
        <AdminContainer testID="accountCreateScreen">
            <View style={styles.container}>
                <CreateForm
                    form={form}
                    onSubmit={handleSubmit}
                    submitButton={["common.create", "Create"]}
                    isGiftcard={type === "GIFT_CARD"}
                />
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: "100%",
        backgroundColor: theme.colors.white,
        alignSelf: "flex-start",
    },
});
