import React, { useCallback } from "react";
import type { OrderConditions } from "lib/src/entity-condition";
import { produce } from "immer";
import type { GQDiscountFormQuery } from "graphql-sdk";
import { StyleSheet, View } from "react-native";
import type { Conditions } from "lib";
import { NumberInput, Picker, Text } from "@venuepos/react-common";
import { useTranslation } from "locales";

export function ProductConditionInput({
    condition,
    conditions,
    onConditionChange,
    formData,
}: {
    condition: OrderConditions["hasProductQuantity"];
    conditions: Conditions;
    onConditionChange: (
        conditionArgs: OrderConditions["hasProductQuantity"]
    ) => void;
    formData: GQDiscountFormQuery;
}) {
    const [t] = useTranslation();
    const onQuantityChange = useCallback(
        (quantity: number | null) => {
            onConditionChange(
                produce(condition, draft => {
                    draft.requiredQuantity = quantity || 0;
                })
            );
        },
        [condition, onConditionChange]
    );

    const onPickerValueChange = useCallback(
        (productId: string) => {
            onConditionChange(
                produce(condition, draft => {
                    draft.productId = productId;
                })
            );
        },
        [condition, onConditionChange]
    );

    return (
        <View style={styles.row}>
            <NumberInput
                defaultValue={condition.requiredQuantity}
                onChange={onQuantityChange}
                style={styles.quantityInput}
                min={1}
                decimals={0}
            />
            <Text style={styles.times}>x</Text>
            <Picker
                selectedValue={condition.productId}
                onValueChange={onPickerValueChange}
            >
                {formData.products.data
                    .filter(productItr => {
                        // If the product is the same as the current condition, then it's allowed
                        if (productItr.id === condition.productId) {
                            return true;
                        }

                        // Filter out products used by other conditions
                        return (
                            conditions.find(
                                itr =>
                                    itr.condition === "hasProductQuantity" &&
                                    (
                                        itr.args as OrderConditions["hasProductQuantity"]
                                    ).productId === productItr.id
                            ) === undefined
                        );
                    })
                    .map(product => {
                        return (
                            <Picker.Item
                                key={product.id}
                                value={product.id}
                                label={
                                    product.name +
                                    (product.active
                                        ? ""
                                        : ` (${t(
                                              "common.inactive",
                                              "Inactive"
                                          )})`)
                                }
                            />
                        );
                    })}
            </Picker>
        </View>
    );
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        flex: 1,
    },
    quantityInput: {
        width: 55,
        textAlign: "center",
        marginRight: 10,
    },
    times: {
        lineHeight: 34,
        marginRight: 10,
    },
});
