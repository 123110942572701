import {
    RouteProp,
    useFocusEffect,
    useNavigation,
    useRoute,
} from "@react-navigation/native";
import {
    Alert,
    Button,
    CheckBox,
    ConfirmModal,
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQTagsQuery, useTagDeleteMutation, useTagsQuery } from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { useMultiSelect } from "../../hooks/use-multi-select";
import { RootStackParamList } from "../../navigation";
import { ImportTagsModal } from "./import-modal";
import { TagItem } from "./tag-item";
import { SearchDefinition } from "lib";
import { useSearchDefinition } from "../../hooks";

export function TagsTab() {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const { navigate } = useNavigation();
    const route = useRoute<RouteProp<RootStackParamList, "TAGS">>();
    const theme = useTheme();
    const [t] = useTranslation();
    const { createSearchDefinition } = useSearchDefinition();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);

    const defaultTagSearch: SearchDefinition = {
        identifier: {
            name: t("searching.identifier", "Identifier"),
            type: "string",
            value: "",
            enabled: false,
        },
        name: createSearchDefinition("name"),
        type: {
            name: t("searching.type", "Type"),
            type: "type",
            values: [
                {
                    name: t("searching.nfc_wristband", "NFC wristband"),
                    value: "NFC_WRISTBAND",
                },
                {
                    name: t("searching.nfc_card", "NFC Card"),
                    value: "NFC_CARD",
                },
                { name: t("searching.barcode", "Barcode"), value: "BARCODE" },
            ],
            value: "",
            enabled: false,
        },
        group: {
            name: t("searching.group", "Group"),
            type: "string",
            value: "",
            enabled: false,
        },
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultTagSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { render } = useModal();
    const { handleMutationError } = useHandleMutationError();

    const {
        selectedItems: selectedTags,
        setSelectedItems: setSelectedTags,
        handleMultiSelect,
        allItemsSelected: allRowsSelected,
        setAllItemsSelected: setAllRowsSelected,
    } = useMultiSelect<GQTagsQuery["tags"]["data"][0]["id"]>();

    // GraphQL
    const { data, loading, error, refetch } = useTagsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
    });
    const [deleteTag] = useTagDeleteMutation();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("TAG_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => {
                    await deleteTag({ variables: { id } });
                },
                t("common.deleted", "Deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deleteTag, handleMutationError, refetch, t]
    );

    const deleteSelectedTags = useCallback(async () => {
        const tagIds = [...selectedTags];
        if (
            !(await render(onClose => (
                <ConfirmModal
                    headerText={t(
                        "backoffice.tags.delete_tags",
                        "Delete tags?"
                    )}
                    bodyText={t(
                        "backoffice.layout.delete_tags_explain",
                        "Are you sure you want delete the selected tag(s)?"
                    )}
                    onClose={onClose}
                />
            )))
        ) {
            return;
        }

        await Promise.all([tagIds.map(tagId => handleDelete(tagId))]);
        setSelectedTags([]);
        setAllRowsSelected(false);
    }, [
        handleDelete,
        render,
        selectedTags,
        setAllRowsSelected,
        setSelectedTags,
        t,
    ]);

    const handleImportPress = useCallback(async () => {
        await render(onClose => <ImportTagsModal onClose={onClose} />);
    }, [render]);

    return (
        <View testID="tagListScreen">
            <View style={[theme.styles.row, styles.container]}>
                <Button
                    variant="invert"
                    disabled={!selectedTags.length}
                    onPress={deleteSelectedTags}
                    testID="delete"
                >
                    {t("common.delete", "Delete")}
                </Button>
                <Spacer space={1} />
                <Button
                    variant="invert"
                    onPress={handleImportPress}
                    testID="import"
                >
                    {t("common.import", "Import")}
                </Button>
                <Spacer space={1} />
                <Button
                    type="secondary"
                    onPress={() => {
                        navigate("TAG_CREATE");
                    }}
                    testID="create"
                >
                    {t("common.create", "Create")}
                </Button>
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable testID="tag:list">
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds = data!.tags.data.map(
                                        p => p.id
                                    );
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                                testID="check:selectAll"
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "tagType" && sortDirection
                            }
                            onPress={() => onSortChange("tagType")}
                        >
                            {t("common.type", "Type")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "group.name" && sortDirection
                            }
                            onPress={() => onSortChange("group.name")}
                        >
                            {t("common.group", "Group")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "account.name" && sortDirection
                            }
                            onPress={() => onSortChange("account.name")}
                        >
                            {t("common.account", "Account")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "customer.name" && sortDirection
                            }
                            onPress={() => onSortChange("customer.name")}
                        >
                            {t("common.customer", "Customer")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.sortIconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data &&
                        data?.tags.data.map(
                            (item: GQTagsQuery["tags"]["data"][0]) => (
                                <TagItem
                                    key={item.id}
                                    item={item}
                                    onSelect={() => handleEdit(item.id)}
                                    selectedTag={selectedTags.includes(item.id)}
                                    onMultiSelectChange={handleMultiSelect}
                                    onDelete={() => handleDelete(item.id)}
                                />
                            )
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.tags.pagination.pages}
                        itemCount={data?.tags.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = () => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
    sortIconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 100 },
});
