import React from "react";
import { View } from "react-native";
import {
    Form,
    Button,
    Text,
    StyleFunction,
    useThemedStyle,
    Headline,
} from "@venuepos/react-common";
import { PasswordInput } from "../user/password/password-input";
import { IUserPassword } from "lib";
import { useTranslation } from "locales";

export function PasswordResetForm({
    form,
    onSubmit,
}: {
    form: Form<IUserPassword>;
    onSubmit: () => void;
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = form;
    const styles = useThemedStyle(styleFunc);

    return (
        <View>
            <View>
                <Headline size="h1">
                    {t(
                        "backoffice.password_form.change_your_password",
                        "Reset password"
                    )}
                </Headline>
                <Text style={styles.text}>
                    {t(
                        "backoffice.password_form.enter_new_password",
                        "Enter your desired password.\nAfterwards you need to login again using your new password."
                    )}
                </Text>
            </View>
            <PasswordInput
                errors={errors}
                setValue={setValue}
                t={t}
                values={values}
            />
            <Button onPress={handleSubmit(onSubmit)}>
                <Text>
                    {t(
                        "backoffice.password_form.submit_button",
                        "Save password"
                    )}
                </Text>
            </Button>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    text: {
        marginBottom: theme.spacingScale * 2,
    },
});
