import { add, set, sub } from "date-fns";

export function today(date = new Date()) {
    return set(date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

export function previousDay(date = new Date()) {
    return set(sub(date, { days: 1 }), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

export function nextDay(date = new Date()) {
    return set(add(date, { days: 1 }), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}
