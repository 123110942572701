import { StackScreenProps } from "@react-navigation/stack";
import {
    Loading,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    usePaymentMethodCreateMutation,
    usePaymentMethodFormQuery,
} from "graphql-sdk";
import { PaymentMethodInputForm, schemaPaymentMethod } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackParamList } from "../../navigation";
import { defaultPaymentMethod } from "./forms";
import { PaymentMethodForm } from "./payment-method-form";
import { PaymentMethodScreen } from "./payment-method-screen";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "PAYMENT_METHOD_CREATE"
>;

export function PaymentMethodCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.payment_method.write");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [paymentMethodCreate] = usePaymentMethodCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const formData = usePaymentMethodFormQuery();
    const form = useForm<PaymentMethodInputForm>(
        schemaPaymentMethod,
        defaultPaymentMethod
    );
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await paymentMethodCreate({
                    variables: {
                        paymentMethod: {
                            name: values.name,
                            paymentType: values.paymentType as string,
                            enabled: values.enabled,
                            account: values.account,
                            diffAccount: values.diffAccount,
                            currencyId: values.currencyId,
                        },
                    },
                }),
            t("backoffice.payment_method.created", "Payment method created"),
            () => {
                reset();
                navigate("PAYMENT_METHODS", { refetch: true });
            }
        );
    }, [values, handleMutationError, t, paymentMethodCreate, reset, navigate]);

    if (!formData.data) {
        return <Loading />;
    }

    return (
        <PaymentMethodScreen>
            <Surface>
                <View style={styles.box}>
                    <View
                        style={styles.container}
                        testID="payment:method:createForm"
                    >
                        <PaymentMethodForm
                            form={form}
                            formData={formData.data}
                            onSubmit={create}
                            submitButton={["common.create", "Create"]}
                        />
                    </View>
                </View>
            </Surface>
        </PaymentMethodScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
