import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import { useCustomerAccountsQuery } from "graphql-sdk";
import type { GQAccountsQuery } from "graphql-sdk";
import { AccountItem } from "./account-item";
import {
    useThemedStyle,
    Icon,
    DataTable,
    usePagination,
    useAuth,
    Loading,
    Button,
    Surface,
    useMerchantConfig,
    Headline,
    Alert,
    Spacer,
} from "@venuepos/react-common";
import type { StyleFunction } from "@venuepos/react-common";
import type { AvailableLocale } from "locales";
import { useAdminSession } from "../../../../session";
import { RootStackParamList } from "../../../../navigation";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";

export function CustomerAccountsTab({ customerId }: { customerId: string }) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { navigate } = useNavigation();
    const route = useRoute<RouteProp<RootStackParamList, "ACCOUNT_VIEW">>();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    // GraphQL
    const { data, error, loading, refetch } = useCustomerAccountsQuery({
        variables: {
            customerId: customerId,
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleView = useCallback(
        (id: string) => {
            navigate("ACCOUNT_VIEW", {
                id,
            });
        },
        [navigate]
    );

    const handleCreate = useCallback(() => {
        navigate("ACCOUNT_CREATE", {
            type: "CUSTOMER",
            referrer: {
                id: customerId,
                route: "CUSTOMER_VIEW",
            },
        });
    }, [customerId, navigate]);

    return (
        <Surface>
            <View style={styles.container}>
                <Headline size="h5">
                    {t("common.accounts", "Accounts")}
                </Headline>

                <Button
                    onPress={handleCreate}
                    type="secondary"
                    size="small"
                    testID="customerAccount:createAccount"
                >
                    {t("common.create", "Create")}
                </Button>
            </View>
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title
                        sortDirection={sortBy === "name" && sortDirection}
                        onPress={() => onSortChange("name")}
                    >
                        {t("common.name", "Name")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={sortBy === "status" && sortDirection}
                        onPress={() => onSortChange("status")}
                    >
                        {t("common.status", "Status")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={sortBy === "created_at" && sortDirection}
                        onPress={() => onSortChange("created_at")}
                    >
                        {t("common.created_at", "Created at")}
                    </DataTable.Title>
                    <DataTable.Title
                        numeric
                        sortDirection={sortBy === "amount" && sortDirection}
                        onPress={() => onSortChange("amount")}
                    >
                        {t("common.balance", "Balance")}
                    </DataTable.Title>
                    <DataTable.Title numeric style={styles.iconColumn}>
                        <Icon name="sort" color={styles.iconColumn.color} />
                    </DataTable.Title>
                </DataTable.Header>
                {error ? (
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: error.message,
                            }
                        )}
                    </Alert>
                ) : loading ? (
                    <>
                        <Spacer />
                        <Loading />
                        <Spacer />
                    </>
                ) : (
                    data?.customerAccounts.data.map(
                        (item: GQAccountsQuery["accounts"]["data"][0]) => (
                            <AccountItem
                                key={item.id}
                                item={item}
                                onSelect={handleView}
                                locale={locale as AvailableLocale}
                                currency={currency}
                            />
                        )
                    )
                )}
                <DataTable.Pagination
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    onSizeChange={onPageSizeChange}
                    page={page}
                    numberOfPages={data?.customerAccounts.pagination.pages}
                    itemCount={data?.customerAccounts.pagination.resultCount}
                />
            </DataTable>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        flexDirection: "row",
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
});
