import type { ApolloError } from "apollo-server-express";
import type { ValidationErrorData } from "..";

export type ParsedError<T> =
    | {
          type: "VALIDATION";
          data: Partial<{ [key in keyof T]: ValidationErrorData }>;
      }
    | {
          type: "IN_USE";
      }
    | {
          type: "NOT_FOUND";
      }
    | {
          type: "NOT_UNIQUE";
          data: (keyof T)[];
      };

export function parseError<T>(
    error: Pick<ApolloError, "graphQLErrors">
): ParsedError<T> {
    return {
        type: error.graphQLErrors[0].extensions!.type,
        data: error.graphQLErrors[0].extensions!.data,
    };
}
