import { endOfYear, set as setDate, startOfYear } from "date-fns";
import { ValidationSchema } from "lib";

type SAFTExportForm = {
    from: Date;
    to: Date;
};

const now = new Date();

// Initial values for the SAF-T form is from start of current year to end of current year.
export const defaultSAFTExportForm: SAFTExportForm = {
    from: setDate(startOfYear(now), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    }),
    to: setDate(endOfYear(now), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    }),
};

export const schemaSAFTExportForm: ValidationSchema<SAFTExportForm> = {
    from: { required: true },
    to: { required: true },
};
