import {
    Alert,
    DataTable,
    InputControl,
    Loading,
    PageTitle,
    Picker,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQSoftpayAcquirer,
    GQSoftpayMiaMerchantsQuery,
    useSoftpayMiaMerchantsQuery,
} from "graphql-sdk";
import React, { useCallback, useState } from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"SOFTPAY_MIA_MERCHANT_LIST">;

export function SoftpayMiaMerchantListScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "DESC",
    });

    const [acquirer, setAcquirer] = useState<GQSoftpayAcquirer>(
        GQSoftpayAcquirer.Elavon
    );

    const { data, loading, error } = useSoftpayMiaMerchantsQuery({
        variables: {
            acquirer: acquirer,
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    const handleClick = useCallback(
        (id: string) => {
            navigate("SOFTPAY_MIA_MERCHANT", {
                merchantId: id,
                acquirer,
            });
        },
        [acquirer, navigate]
    );

    const renderSoftpayMiaMerchantRow = useCallback(
        (
            item: GQSoftpayMiaMerchantsQuery["softpayMiaMerchants"]["data"][0],
            rowIndex
        ) => (
            <DataTable.Row
                style={rowIndex % 2 === 0 ? styles.oddRow : undefined}
                onPress={() => handleClick(item.merchantId)}
                key={`merchantId_${item.merchantId}`}
            >
                <DataTable.Cell>{item.merchantName}</DataTable.Cell>
                <DataTable.Cell>{item.merchantId}</DataTable.Cell>
                <DataTable.Cell>{item.merchantReference}</DataTable.Cell>
                <DataTable.Cell>{item.businessRegNo}</DataTable.Cell>
                <DataTable.Cell>{item.primaryContactEmail}</DataTable.Cell>
                <DataTable.Cell>{item.primaryContactPhone}</DataTable.Cell>
                <DataTable.Cell>{item.state}</DataTable.Cell>
            </DataTable.Row>
        ),
        [handleClick, styles.oddRow]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.softpay_mia_merchant_list.softpay_mia_merchants",
                    "Softpay MIA merchants"
                )}
            </PageTitle>
            <Surface>
                <InputControl>
                    <Picker
                        label={t(
                            "backoffice.softpay_mia_merchant_list.acquirer",
                            "Acquirer"
                        )}
                        onValueChange={value => {
                            setAcquirer(value as GQSoftpayAcquirer);
                        }}
                        selectedValue={acquirer}
                        required={true}
                    >
                        <Picker.Item label="Elavon" value="ELAVON" />
                        <Picker.Item label="Nets" value="NETS" />
                    </Picker>
                </InputControl>

                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title>
                            {t("backoffice.common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_merchant.merchant_id",
                                "Merchant ID"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_merchant.merchant_reference",
                                "Merchant reference"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_merchant.business_reg_no",
                                "Business Register Number"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_merchant.primary_contact_email",
                                "Primary contact email"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_merchant.primary_contact_phone",
                                "Primary contact phone"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_merchant.state",
                                "State"
                            )}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.softpayMiaMerchants.data.map(
                            renderSoftpayMiaMerchantRow
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={
                            data?.softpayMiaMerchants.pagination.pages
                        }
                        itemCount={
                            data?.softpayMiaMerchants.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
