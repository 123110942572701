import {
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCardTransactionsQuery, useCardTransactionsQuery } from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { CardTransactionRow } from "./card-transaction-row";
import { SearchDefinition, today } from "lib";
import { View } from "react-native";
import { useSearchDefinition } from "../../hooks";

type ScreenProps = RootStackScreenProps<"CARD_TRANSACTIONS">;

export function CardTransactionsScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.invoice");

    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "transaction_at",
        initialSortDirection: "DESC",
    });

    const defaultCardTransactionSearch: SearchDefinition = {
        amount: createSearchDefinition("amount"),
        transactionAt: {
            name: t("searching.transaction_between", "Transaction between"),
            type: "date",
            values: { from: today(new Date()), to: today(new Date()) },
            enabled: false,
        },
        status: {
            name: t("searching.type", "Type"),
            type: "type",
            values: [
                {
                    name: t("searching.success", "Success"),
                    value: "SUCCESSFUL",
                },
                { name: t("searching.failed", "Failed"), value: "FAILED" },
            ],
            value: "",
            enabled: false,
        },
        cashRegisterName: {
            name: t("searching.cash_register_name", "Cash register name"),
            type: "string",
            value: "",
            enabled: false,
        },
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultCardTransactionSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    // GraphQL
    const { data, loading, refetch } = useCardTransactionsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const renderTransactionRow = useCallback(
        (
            item: GQCardTransactionsQuery["cardTransactions"]["data"][0],
            rowIndex
        ) => (
            <CardTransactionRow
                key={item.id}
                item={item}
                currency={currency}
                locale={locale}
                onSelect={() =>
                    !!item.receipt &&
                    navigate("CARD_TRANSACTION_VIEW", { id: item.id })
                }
                style={rowIndex % 2 === 1 ? styles.oddRow : undefined}
            />
        ),
        [currency, locale, navigate, styles.oddRow]
    );

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cash_register" && sortDirection
                            }
                            onPress={() => onSortChange("cash_register")}
                        >
                            {t(
                                "backoffice.card_transaction.cash_register.header",
                                "Cash register"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "status" && sortDirection}
                            onPress={() => onSortChange("status")}
                        >
                            {t(
                                "backoffice.card_transaction.status.header",
                                "Status"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={sortBy === "amount" && sortDirection}
                            onPress={() => onSortChange("amount")}
                        >
                            {t(
                                "backoffice.card_transaction.amount.header",
                                "Amount"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "transaction_at" && sortDirection
                            }
                            onPress={() => onSortChange("transaction_at")}
                        >
                            {t(
                                "backoffice.card_transaction.transaction_at.header",
                                "Transaction at"
                            )}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={styles.iconColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.cardTransactions.data.map(renderTransactionRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.cardTransactions.pagination.pages}
                        itemCount={
                            data?.cardTransactions.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 6,
        color: theme.colors.black,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
