import {
    Accordion,
    Button,
    Loading,
    Spacer,
    useTheme,
    useToast,
} from "@venuepos/react-common";
import {
    useEntityConfigSaveMutation,
    useEntityConfigsQuery,
} from "graphql-sdk";
import { produce } from "immer";
import {
    camelToSnake,
    IEntityConfig,
    Schema,
    SchemaProperty,
    validateField,
    ValidationError,
} from "lib";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "locales";

import { EntityFormInputRow } from "./row";
import { useSchemaValuesResolvers } from "../use-schema-values-resolvers";
import { RootStackParamList } from "../../../navigation";
import { Divider } from "react-native-paper";

export type EntityConfigInputs = {
    [key: string]: {
        value: any;
        setError: (message: ValidationError | undefined) => void;
    };
};

/**
 * The route argument tells the Entity Config Row component to create a link to that route.
 */
export type EntityConfigRowOption = {
    route: keyof ReactNavigation.RootParamList;
};

export type EntityConfigRowOptions<T> = {
    [key in keyof T]: EntityConfigRowOption;
};

/**
 * Static global map to hold the values of the form for each entity
 *  entityId: {
 *      value: any;
 *      setError: () => {};
 *  }
 */
export let entityConfigInputs: {
    [key: string]: EntityConfigInputs;
} = {};

// Allowed types of entity configs defined with camelCase
export type EntityConfigFormEntityType =
    | "cashRegister"
    | "department"
    | "merchant"
    | "default";

/**
 * The referrer argument is used to determine how to get back to this entity:
 * @var referrer What adminRoute
 */
export type EntityConfigFormEntities = {
    id: string;
    type: EntityConfigFormEntityType;
    referrer?: keyof RootStackParamList;
}[];

// entities:    The entities needed for a device config in hierarchical order from highest to lowest
//              The lowest/last entity is the entity that will be showed in the form
export function EntityConfigForm<EntityType extends { [key: string]: any }>({
    entities,
    schema,
    defaultValues,
    onSave,
}: {
    entities: EntityConfigFormEntities;
    schema: Schema<EntityType>;
    defaultValues: EntityType;
    onSave?: () => Promise<void>;
}) {
    const theme = useTheme();
    const [t] = useTranslation();
    const toast = useToast();

    // Add the needed values resolver for given schema
    const schemaResolved = useSchemaValuesResolvers<EntityType>(schema);
    const [schemaValuesResolved, setSchemaValuesResolved] = useState<
        Schema<EntityType> | undefined | null
    >(undefined);

    // Prepare some helpful constant
    const thisEntityKey = entities.length - 1;
    const thisEntityId = entities[thisEntityKey].id;

    // Setup GraphQL hooks
    const entityConfigsResult = useEntityConfigsQuery({
        variables: { entityIds: entities.map(entity => entity.id) },
        fetchPolicy: "no-cache",
    });
    const [entityConfigSave, entityConfigSaveQuery] =
        useEntityConfigSaveMutation();

    // On mount prepare singleton object for values, and unmount those clear inputs
    useEffect(() => {
        entityConfigInputs[thisEntityId] = {};

        return () => {
            delete entityConfigInputs[thisEntityId];
        };

        // Ignore dependencies as we should only listen to mount and dismount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resolveValues = useCallback(async () => {
        const resolvedValues: Partial<
            Record<keyof EntityType, SchemaProperty<EntityType>["values"]>
        > = {};
        for (const i in schemaResolved) {
            const schemaValue = schemaResolved[i];
            if (schemaValue.valuesResolve !== undefined) {
                resolvedValues[i] = await schemaValue.valuesResolve(
                    thisEntityId
                );
            }
        }

        setSchemaValuesResolved(
            produce(schemaResolved, draft => {
                for (const i in draft) {
                    const j = i as unknown as keyof EntityType;
                    if (resolvedValues[j]) {
                        (draft as any)[j].values = resolvedValues[j];
                    }
                }
            })
        );
    }, [schemaResolved, thisEntityId]);

    // Memo to hold the entity configs with the parsed JSON
    const entityConfigs = useMemo(() => {
        if (
            !entityConfigsResult.data ||
            !entityConfigsResult.data.entityConfigs
        ) {
            return [];
        }
        const gqlEntityConfigs = entityConfigsResult.data.entityConfigs;

        // Parse JSON for all entities
        const collectEntities: (IEntityConfig | null)[] = [];
        for (let i = 0; i < gqlEntityConfigs.length; i++) {
            const entity = gqlEntityConfigs[i];
            if (entity) {
                collectEntities.push({
                    entityId: entity.entityId,
                    data: JSON.parse(entity.data),
                });
            } else {
                collectEntities.push(null);
            }
        }

        if (schemaValuesResolved === undefined) {
            setSchemaValuesResolved(null);
            resolveValues();
        }

        return collectEntities;
    }, [entityConfigsResult.data, resolveValues, schemaValuesResolved]);

    // Callback that takes care of form validation and collect the changed fields
    // ... to only send the actually updated fields to the backend
    const save = useCallback(async () => {
        let values: Partial<{ [key in keyof EntityType]: any }> = {};
        const disabledProperties: string[] = [];
        let validated = true;

        // Loop over every field
        for (const i in entityConfigInputs[thisEntityId]) {
            const value = entityConfigInputs[thisEntityId][i].value;

            // Check if field for this entity was enabled, by checking for it not being undefined
            // Else if field is undefined, and the GraphQL data for the field is not undefined,
            // ... we shall mark this field as disabled
            if (typeof value !== "undefined") {
                // Check if value has changed by comparing with the data received from the server
                if (_.isEqual(value, entityConfigs[thisEntityKey]?.data[i])) {
                    continue; // Value of this field has not changed, so we should do nothing with it
                }

                // Validate field
                const validationRules =
                    schema[i as keyof EntityType].validation;
                if (validationRules !== null) {
                    const v = validateField<EntityType>(
                        value,
                        null,
                        validationRules,
                        t
                    );
                    if (v !== null) {
                        validated = false;
                        entityConfigInputs[thisEntityId][i].setError(v);
                        continue; // Validation failed for this field
                    }
                }

                // Field has changed and be validated. Add it to `values`
                values[i as keyof EntityType] = value;
            } else if (
                typeof entityConfigs[thisEntityKey]?.data[i] !== "undefined"
            ) {
                disabledProperties.push(i); // Mark field as disabled
            }
        }

        // If validation failed, show a toast
        if (!validated) {
            toast.error(t("entity_config.validation_failed"));

            return;
        }

        // Validation was successful and we can now send the updated fields to the backend
        await entityConfigSave({
            variables: {
                entityId: entities[thisEntityKey].id,
                partialData: JSON.stringify(values),
                disabledData: disabledProperties as string[],
            },
        });

        if (onSave) {
            await onSave();
        }

        // Show toast with a successful message
        toast.success(t("entity_config.saved", "Configuration saved"));

        // Refetch and rerender form, as we need the updated the entity to be up-to-date,
        // so we can keep making comparison with the new data to the entity.
        // This also makes sure we are up-to-date with any changes from other users
        await entityConfigsResult.refetch();
        entityConfigInputs[thisEntityId] = {};
    }, [
        entities,
        entityConfigSave,
        entityConfigs,
        entityConfigsResult,
        onSave,
        schema,
        t,
        thisEntityId,
        thisEntityKey,
        toast,
    ]);

    // Split the schema up into sections
    const sectionsData = useMemo(() => {
        let sections: { [key: string]: { [key: string]: any } } = {};
        for (const i in schema) {
            const s = schema[i].section;
            if (!sections[s]) {
                sections[s] = {};
            }
            sections[s][i] = schema[i];
        }
        return sections;
    }, [schema]);

    // Wait for GraphQL response and the entity JSON data to be parsed
    if (entityConfigs.length === 0 || !schemaValuesResolved) {
        return <Loading />;
    }

    // Render form
    return (
        <>
            {Object.keys(sectionsData)
                .sort()
                .map(sectionKey => (
                    <>
                        <Accordion
                            key={sectionKey}
                            showContents={sectionKey === "common"}
                            title={t(
                                `entity_config.section.${camelToSnake(
                                    sectionKey
                                )}`
                            )}
                            textStyle={theme.styles.medium}
                        >
                            {Object.keys(sectionsData[sectionKey]).map(key => (
                                <EntityFormInputRow
                                    key={key}
                                    entityId={thisEntityId}
                                    entities={entities}
                                    entityConfigs={entityConfigs}
                                    property={key}
                                    schema={
                                        schemaValuesResolved[
                                            key as keyof EntityType
                                        ]
                                    }
                                    value={
                                        entityConfigs[thisEntityKey]?.data[key]
                                    }
                                    defaultValue={
                                        defaultValues[key as keyof EntityType]
                                    }
                                />
                            ))}
                        </Accordion>
                        <Divider />
                        <Spacer space={2} />
                    </>
                ))}
            <Button
                onPress={save}
                loading={entityConfigSaveQuery.loading}
                disabled={entityConfigSaveQuery.loading}
                testID="entity:save"
            >
                {t("common.save", "Save")}
            </Button>
        </>
    );
}
