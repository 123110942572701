// Types and Helpers to generate the following:
// - Menu Links
// - Route Names (React Navigation)
// - Route Links (React Navigation / Deep linking)
// - Screen Names (with translations)

// TODO: Nested links
import { useMemo } from "react";
import { useTranslation } from "locales";

import "locales";
import { AuthActions, useAuth } from "../../hooks";
import type { IMenuItem } from "../menubar";

export type Route = {
    translationKey: string;
    skipMenu?: boolean;
    link: string | (() => void); // route link (used for deep linking), use to determine parents for menu
    component: any;
    iconName?: string;
    isAuthorized?: (auth: AuthActions) => boolean;
    testID?: string;
};

type ReactNavigationStackProps<
    RouteKey extends keyof ReactNavigation.RootParamList
> = {
    name: RouteKey;
    component: any;
    options: {
        title?: string;
    };
};

// The min. width of the screen to show a permanent drawer
export const PERMANENT_DRAWER_WIDTH = 1280;

export function routeConfigToRoutes<RouteKey extends string>(
    rc: Record<RouteKey, Route>
) {
    const out: Partial<Record<RouteKey, RouteKey>> = {};
    for (const k in rc) {
        out[k] = k;
    }

    return out as Record<RouteKey, RouteKey>;
}

export function routeConfigToLinks<RouteKey extends string>(
    rc: Record<RouteKey, Route>
) {
    const out: Partial<Record<RouteKey, string>> = {};
    for (const k in rc) {
        if (!rc[k] || typeof rc[k].link !== "string") {
            continue; // Skip any links that aren't linkable
        }
        out[k] = rc[k].link as string;
    }

    return out as Record<RouteKey, string>;
}

export function useMenuFromRouteConfig<
    RouteKey extends keyof ReactNavigation.RootParamList
>(routeConfig: Record<RouteKey, Route>, currentRouteName?: RouteKey) {
    const [t] = useTranslation();
    const auth = useAuth();

    return useMemo(() => {
        let filtered: Record<RouteKey, Route> = {} as Record<RouteKey, Route>; // TODO: Move to state

        // Find all the routes, that could form part of the menu
        for (const k in routeConfig) {
            const route = routeConfig[k];

            // If the current route should be skipped, we keep it in the array. There is more filtering later on.
            if (route.skipMenu && k !== currentRouteName) {
                continue;
            }
            filtered[k] = routeConfig[k];
        }

        let tree: Record<string, IMenuItem> = {};
        // Find all routes for the root level menu items
        for (const k in filtered) {
            const route = filtered[k];

            // Skip any root routes that we are not permitted to see.
            if (route.isAuthorized && !route.isAuthorized!(auth)) {
                continue;
            }

            const [parent, lvl] = parentFromLink(route.link);
            const linkStr =
                (route &&
                    typeof route.link === "string" &&
                    (route.link as string)) ||
                "";
            if (lvl === 1 && !route.skipMenu) {
                tree[parent] = {
                    name: t(route.translationKey),
                    link: k,
                    iconName: route.iconName,
                    path: linkStr,
                    isActive: k === currentRouteName,
                    isExpanded: k === currentRouteName,
                    testID: route.testID,
                };
            }
        }

        // Find all descendants
        for (const k in filtered) {
            const route = filtered[k];
            const [parent, lvl] = parentFromLink(route.link);

            if (lvl === 1) {
                continue; // Already added
            }

            if (route.skipMenu && k !== currentRouteName) {
                continue;
            }

            // Skip any child routes that we are not permitted to see
            if (route.isAuthorized && !route.isAuthorized!(auth)) {
                continue;
            }

            if (tree[parent]) {
                if (!route.skipMenu) {
                    // If this is the first child for this parent, then prepare an array for the children
                    if (!tree[parent].items) {
                        tree[parent].items = [];
                    }

                    tree[parent].items!.push({
                        name: t(route.translationKey),
                        link: k,
                        iconName: route.iconName,
                        path: (route.link &&
                            typeof route.link === "string" &&
                            route.link) as string,
                        isActive: k === currentRouteName,
                        testID: route.testID,
                    });
                }

                if (k === currentRouteName) {
                    tree[parent].isExpanded = true;

                    // If the current route is not in the menu, then mark its parent as active.
                    if (route.skipMenu) {
                        if (tree[parent].items === undefined) {
                            tree[parent].isActive = true;
                        } else {
                            tree[parent].items?.map(childItem => {
                                if (
                                    typeof route.link === "string" &&
                                    typeof childItem.path === "string" &&
                                    route.link
                                        .toString()
                                        .startsWith(childItem.path)
                                ) {
                                    childItem.isActive = true;
                                }
                            });
                        }
                    }
                }

                continue;
            }

            // If for some reason, the parent is not visible (typically permissions)
            // Then we pop the child to menu root
            const linkStr =
                (route &&
                    typeof route.link === "string" &&
                    (route.link as string)) ||
                "";

            tree[k] = {
                name: t(route.translationKey),
                link: k,
                iconName: route.iconName,
                path: linkStr,
                isActive: k === currentRouteName,
                isExpanded: k === currentRouteName,
                testID: route.testID,
            };
        }

        // Set isExpanded to false for active menu items with no children. It does not make sense that they are expanded.
        for (const k in tree) {
            if (tree[k].items === undefined) {
                tree[k].isExpanded = false;
            }
        }

        // Convert to out
        let out: IMenuItem[] = [];
        for (const k in tree) {
            out.push({ ...tree[k] });
        }

        return out;
    }, [routeConfig, t, auth, currentRouteName]);
}

// Converts link to string, extracts the first part of link and returns it
// e.g. "/account/create" has parent "account". "/account" or "/account" would have parent ""
function parentFromLink(link: Route["link"]): [string, number] {
    if (typeof link !== "string") {
        return ["", 1]; // Custom menu items do not have parents
    }

    const res = Array.from(link.matchAll(/[a-zW-Z0-9-_]+/g));
    return [res[0][0], res.length];
}

// Returns a map of routes for Stack.Screen components
export function useRoutePropsFromRouteConfig<
    RouteKey extends keyof ReactNavigation.RootParamList
>(rc: Record<RouteKey, Route>) {
    const [t] = useTranslation();

    return useMemo(() => {
        let out: ReactNavigationStackProps<RouteKey>[] = [];
        for (const k in rc) {
            out.push({
                name: k,
                component: rc[k].component,
                options: {
                    title: t(rc[k].translationKey),
                },
            });
        }

        return out;
    }, [rc, t]);
}
