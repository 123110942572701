import type {
    EconomicSettingsInput,
    IIntegrationInput,
    ValidationSchema,
} from "lib";

export const defaultIntegration: IIntegrationInput = {
    merchantId: "",
    name: "",
    apiKey: "",
    type: undefined,
    active: true,
    merchantChartOfAccounts: [],
};

export const schemaEconomicSettings: ValidationSchema<EconomicSettingsInput> = {
    journalId: {
        required: true,
        wholeNumber: true,
        minValue: { value: 0 },
    },
};
