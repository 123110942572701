import type { ReportDefinition, ReportRequest } from "lib";
import type { TFunction } from "i18next";
import { useTranslation } from "locales";
import {
    Headline,
    InputControl,
    Picker,
    useThemedStyle,
} from "@venuepos/react-common";
import { produce } from "immer";
import React from "react";
import { reportStyleFunc } from "./styles";
import { View } from "react-native";

function resolveOrderingValues(
    ordering: ReportDefinition["ordering"],
    t: TFunction
) {
    let returnValue = [];
    for (const [groupByName, groupBy] of Object.entries(ordering)) {
        if (groupBy.internalUse) {
            continue;
        }
        returnValue.push({
            label: t(groupBy.translationKey, groupBy.translationKey),
            value: groupByName,
        });
    }

    return returnValue;
}

export function Ordering(props: {
    ordering: ReportDefinition["ordering"];
    selectedOrdering: ReportRequest<ReportDefinition>["ordering"];
    onOrderingChange: (
        values: ReportRequest<ReportDefinition>["ordering"]
    ) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(reportStyleFunc);

    if (!Object.keys(props.ordering).length) {
        return null;
    }

    const orderingValues = resolveOrderingValues(props.ordering, t);
    const selectedOrderingValues = Object.keys(props.selectedOrdering);

    const selectedOrderingValue =
        selectedOrderingValues.length !== 0 ? selectedOrderingValues[0] : "";
    const selectedOrderingDirection =
        selectedOrderingValues.length !== 0
            ? props.selectedOrdering[selectedOrderingValues[0]]!.direction
            : "ASC";

    return (
        <View>
            <Headline size="h5">{t("report.ordering", "Ordering")}</Headline>
            <View style={styles.row}>
                <InputControl
                    style={styles.inputCell}
                    description={t(
                        "report.description.ordering",
                        "The field to order the report by"
                    )}
                >
                    <Picker
                        label={t("report.field", "Field")}
                        selectedValue={selectedOrderingValue}
                        onValueChange={value => {
                            const returnValue = produce(
                                props.selectedOrdering,
                                draft => {
                                    // erase the previously set value
                                    delete draft[selectedOrderingValue];

                                    // value is none. Back off
                                    if (!value) {
                                        return;
                                    }
                                    draft[value] = {
                                        direction: selectedOrderingDirection,
                                    };
                                }
                            );

                            props.onOrderingChange(returnValue);
                        }}
                    >
                        <Picker.Item
                            label={t("report.none", "None")}
                            value=""
                            key="none"
                        />
                        {orderingValues.map(orderBy => (
                            <Picker.Item
                                label={t(orderBy.label, orderBy.label)}
                                value={orderBy.value}
                                key={orderBy.value}
                            />
                        ))}
                    </Picker>
                </InputControl>
                <InputControl
                    style={styles.inputCell}
                    description={t(
                        "report.description.ordering_direction",
                        "Select of the order is 0-9 / A-Z (ascending) or the reverse (descending)"
                    )}
                >
                    <Picker
                        label={t("report.ordering_direction", "Sort order")}
                        disabled={!selectedOrderingValue}
                        selectedValue={selectedOrderingDirection}
                        onValueChange={value => {
                            if (
                                !selectedOrderingValue ||
                                (value !== "ASC" && value !== "DESC")
                            ) {
                                return;
                            }

                            const returnValue = produce(
                                props.selectedOrdering,
                                draft => {
                                    draft[selectedOrderingValue] = {
                                        direction: value,
                                    };
                                }
                            );

                            props.onOrderingChange(returnValue);
                        }}
                    >
                        <Picker.Item
                            value="ASC"
                            label={t(
                                "report.ordering_ascending",
                                "0-9 / A-Z (Ascending)"
                            )}
                            key="order_ascending"
                        />
                        <Picker.Item
                            value="DESC"
                            label={t(
                                "report.ordering_descending",
                                "9-0 / Z-A (descending)"
                            )}
                            key="order_descending"
                        />
                    </Picker>
                </InputControl>
            </View>
        </View>
    );
}
