import React, { useMemo } from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";

import {
    LogoDark,
    LogoWhite,
    LogoWithNameDark,
    LogoWithNameWhite,
} from "./svg";

export function Logo({
    type = "DARK",
    size = "NORMAL",
    variant = "LOGO_WITH_NAME",
    dimensions,
}: {
    type?: "DARK" | "LIGHT";
    size?: "TINY" | "XSMALL" | "SMALL" | "NORMAL" | "LARGE" | "XLARGE" | "HUGE";
    variant?: "LOGO_ONLY" | "LOGO_WITH_NAME";
    style?: StyleProp<ViewStyle>;
    dimensions?:
        | { width: number; height: number }
        | { width?: number; height: number }
        | { width: number; height?: number };
}) {
    const styles = useThemedStyle(styleFunc);

    // We need the aspect ratios of the logos to be able to render them appropriately when they are used in various flexboxes
    const logoAspectRatio = useMemo(
        () =>
            variant === "LOGO_WITH_NAME"
                ? { aspectRatio: 3.2 }
                : { aspectRatio: 1.135 },
        [variant]
    );

    const logoType = useMemo(() => {
        const component =
            variant === "LOGO_WITH_NAME" ? (
                type === "DARK" ? (
                    <LogoWithNameDark />
                ) : (
                    <LogoWithNameWhite />
                )
            ) : variant === "LOGO_ONLY" ? (
                type === "DARK" ? (
                    <LogoDark />
                ) : (
                    <LogoWhite />
                )
            ) : (
                <LogoDark />
            );

        return component;
    }, [type, variant]);

    const logoStyle = useMemo(() => {
        switch (size) {
            case "TINY":
                return styles.tinyLogo;
            case "XSMALL":
                return styles.xSmallLogo;
            case "SMALL":
                return styles.smallLogo;
            case "NORMAL":
                return styles.logo;
            case "LARGE":
                return styles.largeLogo;
            case "XLARGE":
                return styles.xLargeLogo;
            case "HUGE":
                return styles.hugeLogo;
            default:
                return styles.logo;
        }
    }, [
        size,
        styles.hugeLogo,
        styles.largeLogo,
        styles.logo,
        styles.smallLogo,
        styles.tinyLogo,
        styles.xLargeLogo,
        styles.xSmallLogo,
    ]);

    const logoSize = useMemo(() => {
        if (!dimensions) {
            return logoStyle;
        }

        return dimensions;
    }, [dimensions, logoStyle]);

    return (
        <View
            style={StyleSheet.flatten([logoAspectRatio, logoStyle, logoSize])}
        >
            {logoType}
        </View>
    );
}

const styleFunc: StyleFunction = () => ({
    tinyLogo: {
        height: 16,
    },
    xSmallLogo: {
        height: 24,
    },
    smallLogo: {
        height: 32,
    },
    logo: {
        height: 64,
    },
    largeLogo: {
        height: 128,
    },
    xLargeLogo: {
        height: 192,
    },
    hugeLogo: {
        height: 256,
    },
});
