import React from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import {
    Button,
    DataTable,
    InputControl,
    useThemedStyle,
    CheckBox,
    Text,
    TextInput,
    RequiredText,
} from "@venuepos/react-common";
import type { Form, StyleFunction } from "@venuepos/react-common";
import { IMiscButtonsInput, defaultMiscButtons } from "lib";
import type { MiscButtonsForm } from "./forms";
import { produce } from "immer";

export function MiscButtonForm(props: {
    form: Form<IMiscButtonsInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <>
            <View style={styles.nameContainer}>
                <InputControl error={errors.name}>
                    <TextInput
                        label={t("common.name", "Name")}
                        placeholder={t(
                            "backoffice.common.enter_name",
                            "Enter name"
                        )}
                        defaultValue={values.name}
                        onChangeText={text => setValue("name", text)}
                        required={true}
                    />
                </InputControl>
            </View>
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>
                        {t(
                            "backoffice.misc_buttons_form_add_function_key",
                            "Show"
                        )}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t("common.name", "Name")}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t(
                            "backoffice.misc_buttons_form.description",
                            "Description"
                        )}
                    </DataTable.Title>
                </DataTable.Header>
                {Object.keys(defaultMiscButtons).map(buttonKey => (
                    <DataTable.Row key={buttonKey}>
                        <DataTable.Cell style={styles.checkboxCell}>
                            <CheckBox
                                value={
                                    values.buttons[
                                        buttonKey as keyof MiscButtonsForm["buttons"]
                                    ]
                                }
                                onValueChange={value => {
                                    setValue(
                                        "buttons",
                                        produce(values.buttons, draft => {
                                            draft[
                                                buttonKey as keyof MiscButtonsForm["buttons"]
                                            ] = value;
                                        })
                                    );
                                }}
                            />
                        </DataTable.Cell>
                        <DataTable.Cell>
                            <Text>{t(`misc_buttons.${buttonKey}`)}</Text>
                        </DataTable.Cell>
                        <DataTable.Cell>
                            <Text key={buttonKey}>
                                {t(
                                    `misc_buttons.descriptions.${buttonKey}`,
                                    `misc_buttons.descriptions.${buttonKey}`
                                )}
                            </Text>
                        </DataTable.Cell>
                    </DataTable.Row>
                ))}
            </DataTable>

            <Button
                style={styles.saveButton}
                onPress={handleSubmit(props.onSubmit)}
            >
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    nameContainer: {
        paddingLeft: theme.spacingScale * 4,
        maxWidth: theme.dimensions.maxFormWidth,
    },
    saveButton: {
        marginTop: theme.spacingScale * 2,
    },
    checkboxCell: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 180,
        paddingLeft: 50,
    },
});
