import {
    Alert,
    Headline,
    Loading,
    Surface,
    useThemedStyle,
} from "@venuepos/react-common";
import { theme } from "@venuepos/react-common/src/theme/themes/venue-default";
import { useDashboardCashRegisterStatsQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React from "react";
import {
    VictoryBar,
    VictoryChart,
    VictoryContainer,
    VictoryLabel,
    VictoryTheme,
} from "victory";
import { dashboardStyleFunc } from "../styles";

export function CashRegisterStats() {
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    const { data, loading, error } = useDashboardCashRegisterStatsQuery();

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    const total =
        data?.dashboardCashRegisterStats.reduce(
            (acc, item) => acc + item.count,
            0
        ) || 0;

    return (
        <Surface style={sharedStyles.widget}>
            <Headline testID="headline:cashRegisterDistribution">
                {t(
                    "dashboard.cash_register_distribution",
                    "Distribution of cash register versions (last month)"
                )}
            </Headline>
            <VictoryChart
                theme={VictoryTheme.material}
                height={300}
                width={600}
                domainPadding={{ x: 15 }}
                containerComponent={<VictoryContainer />}
            >
                <VictoryBar
                    data={data?.dashboardCashRegisterStats}
                    y="count"
                    x="label"
                    theme={VictoryTheme.material}
                    labelComponent={
                        <VictoryLabel
                            text={({ datum }) =>
                                `${datum.count} stk\n${(
                                    (datum.count / total) *
                                    100
                                ).toFixed(0)}%`
                            }
                        />
                    }
                    style={{
                        data: {
                            width: 25,
                            fill: theme.colors.secondary,
                        },
                    }}
                />
            </VictoryChart>
        </Surface>
    );
}
