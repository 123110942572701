import React, { ReactElement, useEffect, useState } from "react";
import { Linking } from "react-native";
import { RequestToken } from "./password-reset/request-token";
import { PasswordReset } from "./password-reset/password-reset";

export function LoginServiceProvider({ children }: { children: ReactElement }) {
    const [showRequestToken, setShowRequestToken] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false);

    useEffect(() => {
        const checkURL = async () => {
            const url = await Linking.getInitialURL();
            if (url && url.indexOf("request-token") > -1) {
                setShowRequestToken(true);
            }

            if (url && url.indexOf("password-reset") > -1) {
                setShowPasswordReset(true);
            }
        };

        checkURL();
    }, []);

    if (showRequestToken) {
        return <RequestToken />;
    }

    if (showPasswordReset) {
        return <PasswordReset />;
    }

    return children;
}
