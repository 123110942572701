import { ApolloClient } from "@apollo/client";
import {
    MeType,
    AuthActions,
    useThemedStyle,
    Text,
    StyleFunction,
    Icon,
    useTheme,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import React, { useState, useCallback, useMemo } from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Button as PaperButton, Divider, Menu } from "react-native-paper";

type RightHeaderProps = {
    loggedInUser: MeType;
    client: ApolloClient<object>;
    auth: AuthActions;
};

export function RightHeader({ loggedInUser, client, auth }: RightHeaderProps) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const [visible, setVisible] = useState(false);
    const [t] = useTranslation();
    const { navigate } = useNavigation();

    const closeMenu = () => {
        setVisible(false);
    };

    const openMenu = () => {
        setVisible(true);
    };

    const signOut = useCallback(async () => {
        client.stop();
        await client.clearStore();
        auth.signOut();

        // We need to reset the URL, or else the next signed user might
        // end up on a page that they don't have access to.
        window.open("/", "_self");
    }, [client, auth]);

    const userIcon = useCallback(
        () => <Icon name="user" color={theme.colors.black} />,
        [theme.colors.black]
    );

    const signOutIcon = useCallback(
        () => <Icon name="signOut" color={theme.colors.black} />,
        [theme.colors.black]
    );

    const menuAnchor = useMemo(
        () => (
            <PaperButton onPress={openMenu} testID="menu:user">
                <Icon name="user" color={theme.colors.black} />
                <Icon name="dropdown" color={theme.colors.black} />
            </PaperButton>
        ),
        [theme.colors.black]
    );

    const handleUserEdit = useCallback(() => {
        if (!loggedInUser.user) {
            return;
        }

        navigate("USER_EDIT", {
            id: loggedInUser.user.id,
        });
    }, [loggedInUser.user, navigate]);

    return (
        <View style={styles.rightHeader}>
            <Text style={styles.merchantName}>
                {loggedInUser.merchant?.name}
            </Text>

            <Menu visible={visible} onDismiss={closeMenu} anchor={menuAnchor}>
                <Menu.Item
                    onPress={handleUserEdit}
                    leadingIcon={userIcon}
                    title={`${loggedInUser.user?.username} / ${loggedInUser.role?.name}`}
                />
                <Divider />
                <Menu.Item
                    onPress={signOut}
                    leadingIcon={signOutIcon}
                    title={t("menu.sign_out", "Sign out")}
                    testID="menu:signOut"
                />
            </Menu>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    rightHeader: {
        marginRight: theme.spacingScale * 2,
        flexDirection: "row",
        alignItems: "baseline",
    },
    merchantName: {
        color: theme.colors.textDark,
    },
});
