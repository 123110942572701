// Inspiration: https://github.com/callstack/react-native-paper/blob/master/src/components/IconButton.tsx

import React from "react";
import {
    TouchableOpacity,
    StyleProp,
    ViewStyle,
    StyleSheet,
    TextStyle,
} from "react-native";
import { useTheme } from "../../theme";
import { Icon, IconSizes } from "../icon";

export function IconButton({
    name,
    size,
    onPress,
    color,
    style,
    disabled,
    testID,
}: {
    name: string;
    color?: TextStyle["color"];
    size?: IconSizes;
    disabled?: boolean;
    style?: StyleProp<ViewStyle | TextStyle>;
    onPress: () => void;
    testID?: string;
}) {
    const { colors } = useTheme();

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[styles.container, style]}
            disabled={disabled}
            testID={testID}
        >
            <Icon
                name={name}
                size={size}
                color={
                    StyleSheet.flatten(style as TextStyle)?.color ||
                    (disabled ? colors.grey500 : color)
                }
            />
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 6,
    },
});
