import React from "react";
import {
    useTheme,
    useThemedStyle,
    StyleFunction,
    StyleData,
} from "../../theme";
import { contrastToBlack } from "../../utils";
import type { IToast } from ".";
import { View, Animated } from "react-native";
import { Icon } from "../icon";
import { Text } from "../text";
import { IconButton } from "../icon-button";

const TOAST_WIDTH = 500;

export function ToastItem({
    item,
    onRemove, // TODO: Add a close button
}: {
    item: IToast;
    onRemove: (itemId: string) => void;
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const bgColor = useToastColor(item.type);
    const textColor = contrastToBlack(bgColor)
        ? theme.colors.textLight
        : theme.colors.textDark;

    const windowTransition = item.animated.interpolate({
        inputRange: [0, 1],
        outputRange: [+TOAST_WIDTH, 0],
    });

    return (
        <Animated.View // insert custom renderer here
            pointerEvents="auto"
            style={[
                styles.toastItem,
                {
                    backgroundColor: bgColor,
                    transform: [
                        {
                            translateX: windowTransition,
                        },
                    ],
                },
            ]}
        >
            <Icon name={item.iconName} color={item.iconColor} />
            <View style={styles.toastMsg} testID={"toast:" + item.message}>
                {item.title && (
                    <Text style={[styles.textItem, { color: textColor }]}>
                        {item.title}
                    </Text>
                )}
                <Text
                    onPress={() => {
                        onRemove(item.id);
                    }}
                    style={{ color: textColor }}
                >
                    {item.message}
                </Text>
            </View>
            {item.showClose && (
                <IconButton
                    size="small"
                    style={styles.closeButton}
                    name="close"
                    onPress={() => {
                        onRemove(item.id);
                    }}
                />
            )}
        </Animated.View>
    );
}

function useToastColor(type: IToast["type"]) {
    const theme = useTheme();
    switch (type) {
        case "error":
            return theme.colors.error;
        case "warning":
            return theme.colors.warning;
        case "success":
            return theme.colors.success;
        default:
            return theme.colors.toastDefault;
    }
}

const styleFunc: StyleFunction = (theme, dimensions) => {
    let styling: StyleData = {
        toastItem: {
            flex: 1,
            borderRadius: theme.borderRadiusTiny,
            paddingHorizontal: 8,
            paddingVertical: 9,
            shadowColor: theme.colors.black,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
            opacity: 0.95,
            flexDirection: "row",
            borderColor: theme.colors.textLight,
        },
        textItem: {
            fontWeight: "600",
        },
        closeButton: {
            paddingTop: 0,
            paddingRight: 0,
        },

        toastMsg: {
            marginLeft: theme.spacingScale,
            maxWidth: 400,
        },
    };

    if (dimensions.width <= theme.breakpoint.portraitWidth) {
        styling = {
            ...styling,
            toastMsg: {
                ...styling.toastMsg,
                maxWidth: dimensions.width - 150,
            },
        };
    }

    return styling;
};
