import { useFocusEffect } from "@react-navigation/native";
import {
    StyleFunction,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQTagInput, useTagCreateMutation, useTagFormQuery } from "graphql-sdk";
import { defaultTag, schemaTag } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { Form } from "../form";
import { TagScreen } from "../tag-screen";

type ScreenProps = RootStackScreenProps<"TAG_CREATE">;

export function TagCreateScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const form = useForm<GQTagInput>(schemaTag, defaultTag);
    const [{ values }, { reset }] = form;

    const [tagCreate] = useTagCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const { data, refetch } = useTagFormQuery();

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await tagCreate({
                    variables: {
                        tag: {
                            identifier: values.identifier,
                            name: values.name,
                            tagType: values.tagType,
                            groupId: values.groupId || null,
                        },
                    },
                }),
            t("common.created", "Created"),
            () => {
                reset();
                navigate("TAGS", { refetch: true });
            }
        );
    }, [values, handleMutationError, t, tagCreate, reset, navigate]);

    return (
        <TagScreen>
            <View style={styles.container}>
                <Form
                    form={form}
                    formData={data}
                    onSubmit={create}
                    submitButton={["common.create", "Create"]}
                />
            </View>
        </TagScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        maxWidth: theme.dimensions.maxFormWidth,
    },
});
