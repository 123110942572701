import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { StyleSheet, View } from "react-native";
import {
    useAccountSaveMutation,
    useAccountLazyQuery,
    GQAccountInput,
} from "graphql-sdk";
import { LoadingScreen, useForm, useAuth } from "@venuepos/react-common";
import { schemaAccount } from "lib";
import { AdminContainer } from "../../../index";
import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { AccountForm } from "../account-form";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../../../../navigation";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "BOOKKEEPING_ACCOUNT_EDIT"
>;

export function BookkeepingAccountEdit({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [accountEdit] = useAccountSaveMutation();
    const [getAccount, account] = useAccountLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<GQAccountInput>(schemaAccount, null);
    const [{ values }, { setDefaultValues }] = form;
    const accountId = route.params.id;

    useEffect(() => {
        if (!accountId) {
            navigate("ACCOUNTS");
        } else {
            getAccount({
                variables: {
                    id: accountId,
                    tagPagination: {
                        sort: "createdAt",
                        sortDirection: "DESC",
                        pageSize: 99999,
                        page: 0,
                    },
                },
            });
        }
    }, [getAccount, navigate, accountId]);

    useEffect(() => {
        if (account.data && account.data.account) {
            setDefaultValues(account.data.account as GQAccountInput);
        }
    }, [account.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!account.data || !account.data.account || !values) {
            return;
        }
        await handleMutationError(
            async () =>
                await accountEdit({
                    variables: {
                        id: account.data!.account.id,
                        account: {
                            name: values.name,
                            type: values.type,
                            status: values.status,
                            amount: values.amount,
                            externalId: values.externalId,
                        },
                    },
                }),
            t("common.saved", "Saved"),
            () => {
                navigate("BOOKKEEPING_ACCOUNTS", {
                    refetch: true,
                });
            }
        );
    }, [account.data, values, handleMutationError, t, accountEdit, navigate]);

    if (!account.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <AdminContainer>
            <View style={styles.container}>
                <AccountForm
                    id={account.data.account.id}
                    form={form}
                    onSubmit={edit}
                    submitButton={["common.save", "Save"]}
                />
            </View>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        backgroundColor: "white",
        alignSelf: "flex-start",
    },
});
