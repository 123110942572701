import {
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { useMerchantCreateMutation } from "graphql-sdk";
import { IMerchantCreate, schemaMerchantCreate } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { useUsernameAvailability } from "../../user/use-username-availability";
import { defaultMerchantCreate } from "../forms";
import { MerchantScreen } from "../merchant-screen";
import { CreateForm } from "./create-form";

type ScreenProps = RootStackScreenProps<"MERCHANT_EDIT">;

export function Create({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("admin.merchant.write");

    const [t] = useTranslation();
    const [merchantCreate] = useMerchantCreateMutation();
    const { handleMutationError } = useHandleMutationError();
    const [checkUsernameAvailability, usernameAvailable] =
        useUsernameAvailability();

    const styles = useThemedStyle(styleFunc);
    const form = useForm<IMerchantCreate>(
        schemaMerchantCreate,
        defaultMerchantCreate
    );
    const [{ values }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        // On submit we should check if username is already taken
        if (!(await checkUsernameAvailability(values.email))) {
            return;
        }

        await handleMutationError(
            async () => {
                await merchantCreate({
                    variables: {
                        merchant: {
                            name: values.name,
                            email: values.email,
                            currency: values.currency,
                            vat: values.vat,
                            giftcardVatBookkeepingMethod:
                                values.giftcardVatBookkeepingMethod,
                        },
                    },
                });
            },
            t("common.created", "Created"),
            () => {
                navigate("MERCHANTS");
            }
        );
    }, [
        values,
        checkUsernameAvailability,
        handleMutationError,
        t,
        merchantCreate,
        navigate,
    ]);

    return (
        <MerchantScreen>
            <Surface>
                <View style={styles.container}>
                    <CreateForm
                        form={form}
                        onSubmit={create}
                        submitButton={["common.create", "Create"]}
                        usernameAvailable={usernameAvailable}
                    />
                </View>
            </Surface>
        </MerchantScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
