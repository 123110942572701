import React, { useEffect, useState } from "react";
import {
    camelToSnake,
    SchemaProperty,
    SchemaValuesPrimitiveType,
    SchemaValuesType,
} from "lib";
import { useTranslation } from "locales";
import type { TFunction } from "i18next";
import { Picker } from "@venuepos/react-common";

const nullItem = (
    <Picker.Item key={"__null__"} value={"__null__"} label={"---"} />
);

// Handles types: text_enum, number_enum and select
export function EntityFormPickerInput<T>({
    property,
    higherOrder,
    value,
    onValueChange,
    disabled,
    schema,
    testID,
}: {
    property: string;
    higherOrder: any;
    value: any;
    onValueChange: (v: any) => void;
    disabled: boolean;
    schema: SchemaProperty<T>;
    testID?: string;
}) {
    const [t, i18n] = useTranslation();
    const [values, setValues] = useState<SchemaValuesType[]>([]);

    // Use a state to keep track of seelcted value in test enum/select
    const [localValue, setLocalValue] = useState<any>(
        String(value === null ? "__null__" : value)
    );

    // Prepare available values for the picker
    useEffect(() => {
        // If values is already resolved, we should do nothing
        if (values.length > 0) {
            return;
        }

        // If `values` is given, we should use them. Else we will resolve the values with the given `valuesResolve` function
        if (schema.values) {
            setValues(convertToLabelValue(schema.values, property, t));
        }
    }, [property, schema, t, values.length]);

    // If values is still empty, meaning we are waiting for the values to be resolve, we will just show an empty disabled picker
    if (values.length === 0) {
        return (
            <Picker key={"empty"} disabled={true}>
                {nullItem}
            </Picker>
        );
    }

    if (!disabled) {
        // NON-DISABLED
        const v = String(value === null ? "__null__" : value);

        if (
            schema.type === "text_enum" ||
            (schema.type === "select" && typeof values[0].value === "string")
        ) {
            // TEXT ENUM / TEXT SELECT
            return (
                <Picker
                    selectedValue={localValue}
                    onValueChange={val => {
                        onValueChange(val === "__null__" ? null : val);
                        setLocalValue(val === "__null__" ? null : val);
                    }}
                    testID={testID}
                >
                    {nullItem}
                    {values.map(val => (
                        <Picker.Item
                            key={val.value}
                            value={String(val.value)}
                            label={val.label}
                            testID={testID + ":" + val.label}
                        />
                    ))}
                </Picker>
            );
        } else if (
            schema.type === "number_enum" ||
            (schema.type === "select" && typeof values[0].value === "number")
        ) {
            // NUMBER ENUM / NUMBER SELECT
            return (
                <Picker
                    selectedValue={v}
                    onValueChange={val =>
                        onValueChange(val === "__null__" ? null : Number(val))
                    }
                    testID={testID}
                >
                    {nullItem}
                    {values.map(val => (
                        <Picker.Item
                            key={val.value}
                            value={String(val.value)}
                            label={
                                i18n.exists(
                                    `entity_config.value.${camelToSnake(
                                        property
                                    )}.${val.label}`
                                )
                                    ? t(
                                          `entity_config.value.${camelToSnake(
                                              property
                                          )}.${val.label}`
                                      )
                                    : val.label
                            }
                            testID={testID + ":" + val.label}
                        />
                    ))}
                </Picker>
            );
        }
    } else {
        const v = String(higherOrder === null ? "__null__" : higherOrder);
        return (
            <Picker
                key={"disabled"}
                selectedValue={v}
                disabled={true}
                testID={testID}
            >
                {nullItem}
                {values.map(val => (
                    <Picker.Item
                        key={val.value}
                        value={String(val.value)}
                        label={val.label}
                        testID={testID + ":" + val.label}
                    />
                ))}
            </Picker>
        );
    }

    return null;
}

// Convert an array of strings or numbers to pairs of value and labels for the picker
// If an array of pairs is given, it will just return that back
function convertToLabelValue(
    values: (SchemaValuesPrimitiveType | SchemaValuesType)[],
    property: string,
    t: TFunction
): SchemaValuesType[] {
    let newValues = [];
    if (typeof values[0] === "object") {
        return values as SchemaValuesType[];
    } else {
        for (const i in values) {
            newValues.push({
                label:
                    typeof values[i] === "string"
                        ? t(
                              `entity_config.value.${camelToSnake(property)}.${
                                  values[i]
                              }`
                          )
                        : String(values[i]),
                value: values[i] as SchemaValuesPrimitiveType,
            });
        }
    }
    return newValues;
}
