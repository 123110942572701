import React from "react";
import { useTranslation } from "locales";
import { AdminContainer } from "../container";
import { Tabs } from "@venuepos/react-common";
import { useAuth } from "@venuepos/react-common";
import { TagsTab } from "./tags-tab";
import { TagGroupsTab } from "./groups/groups-tab";

export function Tags() {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();

    return (
        <AdminContainer>
            <Tabs
                labels={[
                    t("common.tags", "Tags"),
                    t("common.tag_groups", "Tag groups"),
                ]}
            >
                <TagsTab />
                <TagGroupsTab />
            </Tabs>
        </AdminContainer>
    );
}
