import {
    Alert,
    Button,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    usePaymentMethodDeleteMutation,
    usePaymentMethodsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"PAYMENT_METHODS">;

export function PaymentMethodListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        ["merchant.payment_method.write", "merchant.payment_method.delete"],
        "OR"
    );

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const { render } = useModal();
    const [paymentMethodDelete] = usePaymentMethodDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();

    const { data, loading, error, refetch } = usePaymentMethodsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.payment_methods.delete",
                            "Delete Payment method?"
                        )}
                        bodyText={t(
                            "backoffice.payment_methods.delete_explain",
                            "If you choose to delete this payment method, the cash registers that use this setup, will stop working. Make sure that no cash register is using this before you continue.\n\nAre you sure, you want to delete this?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            await handleMutationError(
                async () =>
                    await paymentMethodDelete({
                        variables: {
                            id: id,
                        },
                    }),
                t(
                    "backoffice.payment_method.deleted",
                    "Payment method deleted"
                ),
                async () => {
                    await refetch();
                }
            );
        },
        [handleMutationError, paymentMethodDelete, refetch, render, t]
    );

    function handleEdit(id: string) {
        navigate("PAYMENT_METHOD_EDIT", {
            id,
        });
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer testID="paymentMethodListScreen">
            <View style={[theme.styles.row, styles.container]}>
                <Button
                    onPress={() => {
                        navigate("PAYMENT_METHOD_CREATE");
                    }}
                    testID="paymentMethodCreate"
                >
                    {t("backoffice.payment_methods.create", "Create new")}
                </Button>
            </View>
            <Spacer space={2} />
            <Surface>
                <DataTable testID="paymentMethods:list">
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "paymentType" && sortDirection
                            }
                            onPress={() => onSortChange("paymentType")}
                        >
                            {t(
                                "backoffice.payment_method.payment_type",
                                "Payment type"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "currency" && sortDirection
                            }
                            onPress={() => onSortChange("currency")}
                        >
                            {t(
                                "backoffice.payment_method.currency",
                                "Currency"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "enabled" && sortDirection
                            }
                            onPress={() => onSortChange("enabled")}
                            style={styles.enabledColumn}
                        >
                            {t("backoffice.payment_method.enabled", "Enabled")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading || !data ? (
                        <Loading />
                    ) : (
                        data.paymentMethods.data.map((item, index) => (
                            <DataTable.Row
                                onPress={() => handleEdit(item.id)}
                                key={`payment_method_${index}`}
                                testID={`paymentMethod:${item.name}`}
                            >
                                <DataTable.Cell testID="name">
                                    {item.name}
                                </DataTable.Cell>
                                <DataTable.Cell testID="paymentType">
                                    {t(
                                        `backoffice.payment_method_form.types.${item.paymentType}`,
                                        item.paymentType
                                    )}
                                </DataTable.Cell>
                                <DataTable.Cell testID="currency">
                                    {item.currency.code}
                                </DataTable.Cell>
                                <DataTable.Cell
                                    style={styles.enabledColumn}
                                    testID="enabled"
                                >
                                    {item.enabled
                                        ? t("common.yes", "Yes")
                                        : t("common.no", "No")}
                                </DataTable.Cell>
                                <DataTable.Cell
                                    numeric
                                    style={styles.iconColumn}
                                >
                                    <IconButton
                                        color={styles.icon.color}
                                        name="edit"
                                        onPress={() => handleEdit(item.id)}
                                        testID="button:edit"
                                    />
                                    <IconButton
                                        color={styles.icon.color}
                                        name="delete"
                                        onPress={() => handleDelete(item.id)}
                                        testID="button:delete"
                                    />
                                </DataTable.Cell>
                            </DataTable.Row>
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.paymentMethods.pagination.pages}
                        itemCount={data?.paymentMethods.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    icon: {
        color: theme.colors.secondary,
    },
    iconColumn: { flexBasis: 120, flexGrow: 0, flexShrink: 0 },
    enabledColumn: { flexBasis: 80, flexGrow: 0, flexShrink: 0 },
});
