import { useThemedStyle, StyleFunction, Text } from "@venuepos/react-common";
import { ConflictError } from "lib";
import { useTranslation } from "locales";
import React, { ScrollView, View } from "react-native";

export function ConflictErrorsList({ errors }: { errors: ConflictError[] }) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    if (errors.length === 0) {
        return null;
    }

    return (
        <ScrollView style={styles.validationErrors}>
            {errors.map((rowError, idx) => (
                <View
                    key={rowError.rowIndex}
                    style={[styles.row, idx % 2 ? styles.oddRow : undefined]}
                >
                    <Text style={styles.validationRowErrorText}>{`${t(
                        "import.duplicate_value",
                        {
                            rowIndex: rowError.rowIndex,
                            value: rowError.value,
                            field: rowError.field,
                        }
                    )}`}</Text>
                </View>
            ))}
        </ScrollView>
    );
}
const styleFunc: StyleFunction = theme => ({
    validationErrors: {
        marginTop: theme.spacingScale,
        maxHeight: 350,
        backgroundColor: theme.colors.grey50,
        borderColor: theme.colors.grey100,
        borderWidth: 1,
    },
    validationRowErrorText: {
        ...theme.styles.text,
        fontSize: 16,
    },
    row: {
        paddingVertical: theme.spacingScale / 2,
        paddingHorizontal: theme.spacingScale,
    },
    oddRow: {
        backgroundColor: theme.colors.grey100,
    },
});
