import { StackScreenProps } from "@react-navigation/stack";
import {
    LoadingScreen,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    usePaymentConfigurationFormQuery,
    usePaymentConfigurationLazyQuery,
    usePaymentConfigurationSaveMutation,
} from "graphql-sdk";
import { IPaymentConfigurationInput, schemaPaymentConfiguration } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackParamList } from "../../navigation";
import { PaymentConfigurationForm } from "./payment-configuration-form";
import { PaymentConfigurationScreen } from "./payment-configuration-screen";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "PAYMENT_CONFIGURATION_EDIT"
>;

export function PaymentConfigurationEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.payment_configuration.write");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [paymentConfigurationEdit] = usePaymentConfigurationSaveMutation();
    const { handleMutationError } = useHandleMutationError();
    const formData = usePaymentConfigurationFormQuery();
    const paymentConfigId = route.params.id;

    const [loadPaymentConfiguration, paymentConfiguration] =
        usePaymentConfigurationLazyQuery({
            variables: { id: paymentConfigId },
            fetchPolicy: "network-only",
        });
    const form = useForm<IPaymentConfigurationInput>(
        schemaPaymentConfiguration,
        null
    );
    const [{ values }, { setDefaultValues }] = form;

    useEffect(() => {
        if (!formData.data || paymentConfiguration.called) {
            return;
        }

        loadPaymentConfiguration();
    }, [formData.data, loadPaymentConfiguration, paymentConfiguration.called]);

    useEffect(() => {
        if (
            !paymentConfiguration.data ||
            !paymentConfiguration.data.paymentConfiguration
        ) {
            return;
        }

        const pc = paymentConfiguration.data.paymentConfiguration;
        setDefaultValues({
            name: pc.name,
            paymentMethods:
                paymentConfiguration.data.paymentConfiguration.paymentMethods.map(
                    method => ({
                        methodId: method.paymentMethod.id,
                        showAsQuickButton: method.showAsQuickButton,
                        showInPaymentWindow: method.showInPaymentWindow,
                    })
                ),
        });
    }, [paymentConfiguration, setDefaultValues]);

    const redirect = useCallback(() => {
        if (
            !paymentConfiguration.data ||
            !route.params ||
            !route.params.referrer ||
            !route.params.referrer.route
        ) {
            return;
        }

        // Figure out where to redirect to...
        if (route.params.referrer.route === "MERCHANT_SETTINGS") {
            navigate("MERCHANT_SETTINGS");
        }
    }, [navigate, paymentConfiguration.data, route.params]);

    const edit = useCallback(
        async (paymentMethodOrder: string[]) => {
            if (
                !paymentConfiguration ||
                !paymentConfiguration.data ||
                !paymentConfiguration.data.paymentConfiguration ||
                !values
            ) {
                return;
            }

            const sortedPaymentMethods = [...values.paymentMethods].sort(
                (a, b) =>
                    paymentMethodOrder.indexOf(a.methodId) -
                    paymentMethodOrder.indexOf(b.methodId)
            );

            await handleMutationError(
                async () =>
                    await paymentConfigurationEdit({
                        variables: {
                            id: paymentConfiguration!.data!.paymentConfiguration
                                .id,
                            paymentConfiguration: {
                                name: values.name,
                                paymentMethods: sortedPaymentMethods,
                            },
                        },
                    }),

                t(
                    "backoffice.payment_configuration.saved",
                    "Payment configuration saved"
                ),

                redirect
            );
        },
        [
            handleMutationError,
            paymentConfiguration,
            paymentConfigurationEdit,
            redirect,
            t,
            values,
        ]
    );

    if (
        !values ||
        !formData.data ||
        !paymentConfiguration.data ||
        paymentConfiguration.loading
    ) {
        return <LoadingScreen style="light" />;
    }

    /**
     * Merge the list of possible values (formData.data.paymentMethods) with
     * the list of saved values for this config (values.paymentMethods) to
     * produce a list with all the options (in which the previously selected/saved values
     * are on the top of the list
     *
     * (Long story short: Take the ordered list of saved values and add the difference between the two lists after that)
     */
    const initialSorting = [
        ...new Set([
            ...values.paymentMethods.map(pm => pm.methodId),
            ...formData.data.paymentMethods.data.map(pm => pm.id),
        ]),
    ];

    return (
        <PaymentConfigurationScreen>
            <Surface>
                <View style={styles.box}>
                    <View style={styles.container}>
                        <PaymentConfigurationForm
                            form={form}
                            configurationId={paymentConfigId}
                            initialSorting={initialSorting}
                            paymentMethodOptions={formData.data}
                            onSubmit={edit}
                            submitButton={["common.save", "Save"]}
                        />
                    </View>
                </View>
            </Surface>
        </PaymentConfigurationScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    box: {
        width: "100%",
        backgroundColor: theme.colors.white,
    },
    container: {
        width: "100%",
        maxWidth: theme.dimensions.maxFormWidth,
        alignSelf: "flex-start",
    },
});
