import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Button,
    InputControl,
    Loading,
    NumberInput,
    StyleFunction,
    Surface,
    Table,
    Text,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { AdminContainer } from "../container";
import { useTranslation } from "locales";
import { useMerchantConfig } from "@venuepos/react-common";
import { Currency, supportedCurrencies } from "lib";
import { IconButton } from "@venuepos/react-common";
import { useAddCurrencyModal } from "./add-modal";
import { produce } from "immer";
import {
    GQCurrenciesQuery,
    useCurrenciesLazyQuery,
    useCurrencyCreateMutation,
    useCurrencyDeleteMutation,
    useCurrencySaveMutation,
} from "graphql-sdk";
import { useToast } from "@venuepos/react-common";
import { View } from "react-native";

type CurrencyItem = {
    currency: Currency;
    rate: number;
    loading: boolean;
    createdAtUnix: number;
    cannotDelete?: boolean;
};

export function CurrencyScreen() {
    const auth = useAuth();
    auth.enforce("merchant.currency");

    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const toast = useToast();
    const addCurrencyModal = useAddCurrencyModal();
    const [saveCurrency] = useCurrencySaveMutation();
    const [createCurrency] = useCurrencyCreateMutation();
    const [deleteCurrency] = useCurrencyDeleteMutation();
    const [loadCurrencies, currencies] = useCurrenciesLazyQuery({
        variables: {
            pagination: {
                pageSize: 999999, // Get all merchant currencies
            },
        },
        fetchPolicy: "no-cache",
    });
    const [saving, setSaving] = useState<boolean>(false);
    const [currencyItems, setCurrencyItems] = useState<CurrencyItem[]>([]);

    const notYetAddedCurrencies = useMemo(
        () =>
            supportedCurrencies.filter(
                currency =>
                    !currencyItems.find(itr => itr.currency === currency)
            ),
        [currencyItems]
    );

    const addCurrency = useCallback(async () => {
        const currency = await addCurrencyModal({
            headline: t("backoffice.currency.add_currency", "Add currency"),
            currencies: notYetAddedCurrencies,
        });

        if (currency) {
            setCurrencyItems(
                produce(currencyItems, draft => {
                    draft.push({
                        currency,
                        rate: 1,
                        loading: false,
                        createdAtUnix: new Date().getTime(),
                    });
                })
            );
        }
    }, [addCurrencyModal, currencyItems, notYetAddedCurrencies, t]);

    const removeCurrency = useCallback(
        (currency: Currency) => {
            setCurrencyItems(
                produce(currencyItems, draft => {
                    const key = draft.findIndex(
                        itr => itr.currency === currency
                    );
                    if (key >= 0) {
                        draft.splice(key, 1);
                    }
                })
            );
        },
        [currencyItems]
    );

    const save = useCallback(async () => {
        if (!currencies.data) {
            return;
        }

        setSaving(true);
        const changedCurrencies: {
            id?: string;
            key: number;
        }[] = [];
        const deletedCurrencies: string[] = [];

        // Get changed and deleted currencies
        currencies.data.currencies.data.forEach(currency => {
            const currencyItemKey = currencyItems.findIndex(
                itr => itr.currency === currency.code
            );
            if (
                currencyItemKey >= 0 &&
                currencyItems[currencyItemKey].rate !== currency.rate
            ) {
                changedCurrencies.push({
                    key: currencyItemKey,
                    id: currency.id,
                });
            } else if (currencyItemKey < 0) {
                deletedCurrencies.push(currency.id);
            }
        });

        // Get new currencies
        currencyItems.forEach((currencyItem, currencyItemKey) => {
            const find = currencies.data!.currencies.data.find(
                itr => itr.code === currencyItem.currency
            );
            if (!find) {
                changedCurrencies.push({
                    key: currencyItemKey,
                });
            }
        });

        // Mark changed currencies as loading
        setCurrencyItems(
            produce(currencyItems, draft => {
                for (let i = 0; i < changedCurrencies.length; i++) {
                    draft[changedCurrencies[i].key].loading = true;
                }
            })
        );

        // Create or update changed currencies
        for (let i = 0; i < changedCurrencies.length; i++) {
            const item = currencyItems[changedCurrencies[i].key];
            const id = changedCurrencies[i].id;
            if (id) {
                // Update
                await saveCurrency({
                    variables: {
                        id,
                        currency: {
                            code: item.currency,
                            rate: item.rate,
                        },
                    },
                });
            } else {
                // Create
                await createCurrency({
                    variables: {
                        currency: {
                            code: item.currency,
                            rate: item.rate,
                        },
                    },
                });
            }
        }

        // Delete removed currency items
        const cannotDelete: string[] = [];
        for (let i = 0; i < deletedCurrencies.length; i++) {
            const response = await deleteCurrency({
                variables: {
                    id: deletedCurrencies[i],
                },
            });
            if (
                response.data &&
                response.data.currencyDelete.status === "IN_USE"
            ) {
                cannotDelete.push(deletedCurrencies[i]);
            }
        }

        // Fetch currencies and update currency items
        setCurrencyItems(
            mutateGQDataToCurrencyItems(
                (await currencies.refetch()).data,
                merchantConfig.currency,
                cannotDelete
            )
        );
        toast.success(
            t("backoffice.currency.successful_saved", "Currencies was saved")
        );
        setSaving(false);
    }, [
        createCurrency,
        currencies,
        currencyItems,
        deleteCurrency,
        merchantConfig.currency,
        saveCurrency,
        t,
        toast,
    ]);

    const changeRate = useCallback(
        (currency: Currency, rate: number) => {
            setCurrencyItems(
                produce(currencyItems, draft => {
                    const item = draft.find(itr => itr.currency === currency);
                    if (item) {
                        item.rate = rate;
                    }
                })
            );
        },
        [currencyItems]
    );

    useEffect(() => {
        if (!currencies.called) {
            loadCurrencies();
        }

        if (currencyItems.length > 0 || !currencies?.data) {
            return;
        }

        setCurrencyItems(
            mutateGQDataToCurrencyItems(
                currencies.data,
                merchantConfig.currency
            )
        );
    }, [
        currencyItems.length,
        currencies,
        merchantConfig.currency,
        loadCurrencies,
        currencyItems,
    ]);

    return (
        <AdminContainer>
            <Surface style={styles.tableContainer}>
                <Table style={styles.table} testID="currency:list">
                    <Table.Header>
                        <Table.Title>
                            {t(
                                "backoffice.currency.iso_4217_code",
                                "ISO 4217 code"
                            )}
                        </Table.Title>
                        <Table.Title>
                            {t("backoffice.currency.rate", "Rate")}
                        </Table.Title>
                    </Table.Header>
                    {currencyItems.map(currencyItem => (
                        <Table.Row
                            key={currencyItem.currency}
                            testID={`currency:item:${currencyItem.currency}`}
                        >
                            <Table.Cell testID="currency:name">
                                {currencyItem.currency}
                            </Table.Cell>
                            <Table.Cell style={styles.rateCell}>
                                <CurrencyRate
                                    currencyItem={currencyItem}
                                    isMerchantDefault={
                                        currencyItem.currency ===
                                        merchantConfig.currency
                                    }
                                    onChange={rate => {
                                        changeRate(currencyItem.currency, rate);
                                    }}
                                    removeCurrency={removeCurrency}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table>

                <Button
                    onPress={async () => {
                        await addCurrency();
                    }}
                    variant="text"
                    size="small"
                    iconName="plus"
                    style={styles.addCurrencyButton}
                    disabled={saving || notYetAddedCurrencies.length < 1}
                    testID="currency:button:addCurrency"
                >
                    {t("backoffice.currency.add_currency", "Add currency")}
                </Button>
            </Surface>
            <Button
                onPress={async () => {
                    await save();
                }}
                style={styles.saveButton}
                disabled={saving}
                testID="currency:button:saveList"
            >
                {t("common.save", "Save")}
            </Button>
        </AdminContainer>
    );
}

function CurrencyRate({
    currencyItem,
    isMerchantDefault,
    onChange,
    removeCurrency,
}: {
    currencyItem: CurrencyItem;
    isMerchantDefault: boolean;
    onChange: (rate: number) => void;
    removeCurrency: (currency: Currency) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    if (currencyItem.loading) {
        return <Loading />;
    }

    if (isMerchantDefault) {
        return <Text testID="currency:rate">{currencyItem.rate}</Text>;
    }

    return (
        <InputControl
            error={
                currencyItem.cannotDelete
                    ? t(
                          "backoffice.currency.currency_in_use",
                          "Already in use. Cannot delete."
                      )
                    : undefined
            }
        >
            <View style={styles.controls}>
                <NumberInput
                    defaultValue={currencyItem.rate}
                    style={styles.rateInput}
                    onChange={value => {
                        onChange(value || 1);
                    }}
                    decimals={2}
                    testID="currency:rateInput"
                />
                {!currencyItem.cannotDelete && (
                    <IconButton
                        name="times"
                        color={styles.iconButton.color}
                        onPress={() => {
                            removeCurrency(currencyItem.currency);
                        }}
                        style={styles.removeButton}
                        testID="currency:button:delete"
                    />
                )}
            </View>
        </InputControl>
    );
}

function mutateGQDataToCurrencyItems(
    data: GQCurrenciesQuery,
    currentCurrency: Currency,
    cannotDelete?: string[]
) {
    return data.currencies.data
        .reduce(
            (items, currency) =>
                items.concat([
                    {
                        currency: currency.code as Currency,
                        rate: currency.rate,
                        loading: false,
                        createdAtUnix: new Date(currency.createdAt).getTime(),
                        cannotDelete:
                            cannotDelete && cannotDelete.includes(currency.id),
                    },
                ]),
            [] as CurrencyItem[]
        )
        .sort((a, b) => {
            if (a.currency === currentCurrency) {
                return -1;
            }

            return a.createdAtUnix - b.createdAtUnix;
        });
}

const styleFunc: StyleFunction = theme => ({
    tableContainer: { alignItems: "flex-start" },
    table: {
        maxWidth: theme.dimensions.maxFormWidth,
    },
    controls: {
        flexDirection: "row",
        alignItems: "center",
    },
    removeButton: {
        marginLeft: theme.spacingScale * 2,
    },
    addCurrencyButton: {
        marginTop: theme.spacingScale * 2,
    },
    saveButton: {
        maxWidth: 200,
        marginTop: theme.spacingScale * 2,
    },
    rateInput: {
        marginTop: theme.spacingScale,
        borderColor: theme.colors.grey500,
    },
    iconButton: {
        color: theme.colors.textDark,
    },
    rateCell: {
        minHeight: theme.spacingScale * 6,
    },
    cannotDeleteMessage: {
        color: theme.colors.error,
        marginLeft: theme.spacingScale * 3,
    },
});
