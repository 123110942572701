import {
    Button,
    Cell,
    PageTitle,
    Row,
    Surface,
    Table,
} from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"GRAPH_AND_STATS">;

export function GraphAndStatsScreen({ navigation: { navigate } }: ScreenProps) {
    const [t] = useTranslation();

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.graphs_and_stats.graphs_and_stats",
                    "Graphs and stats"
                )}
            </PageTitle>
            <Surface>
                <Table>
                    <Row>
                        <Cell>
                            <Button
                                onPress={() => {
                                    navigate("INVOICE_COUNT_VIEW");
                                }}
                            >
                                {t(
                                    "backoffice.invoice_count.invoice_count",
                                    "Invoice count"
                                )}
                            </Button>
                        </Cell>
                    </Row>
                </Table>
            </Surface>
        </AdminContainer>
    );
}
