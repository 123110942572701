import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const LogoWithNameWhite = (props: SvgProps): JSX.Element => (
    <Svg height="100%" width="100%" viewBox="0 0 274.3 85.6" {...props}>
        <Path
            fill="#fff"
            d="m97.212 20.723-11.53 63.25a1.24 1.24 0 0 1-1.19 1l-22.67.52a1.24 1.24 0 0 1-1.11-1.84l35.4-63.36a.62.62 0 0 1 1.1.43zM.012 27.643l12.37 56.34a1.23 1.23 0 0 0 1.18 1l22.8.57a1.23 1.23 0 0 0 1.07-1.9l-36.3-56.48a.62.62 0 0 0-1.12.47zM18.762 5.013l28 67.76a2.47 2.47 0 0 0 4.62-.13l24.76-71a1.23 1.23 0 0 0-1.24-1.64l-55.08 3.3a1.23 1.23 0 0 0-1.06 1.71zM133.512 69.233v-48.33a.7.7 0 0 1 .7-.7h22q8.31 0 12.83 4.19t4.51 11.67q0 7.47-4.51 11.7-4.51 4.23-12.83 4.23h-11.07a.7.7 0 0 0-.7.7v16.54a.71.71 0 0 1-.71.71h-9.52a.71.71 0 0 1-.7-.71zm10.93-26.4a.7.7 0 0 0 .7.7h9.24q8.67 0 8.67-7.47t-8.67-7.33h-9.24a.7.7 0 0 0-.7.7zM203.162 70.673a25.19 25.19 0 0 1-12.87-3.21 22.2 22.2 0 0 1-8.6-9 28.31 28.31 0 0 1-3.01-13.45 28.31 28.31 0 0 1 3-13.5 22.12 22.12 0 0 1 8.57-8.92 27.91 27.91 0 0 1 25.84 0 22.12 22.12 0 0 1 8.6 8.92 28.17 28.17 0 0 1 3.06 13.43 28.56 28.56 0 0 1-3.07 13.54 22.22 22.22 0 0 1-8.6 9 25.35 25.35 0 0 1-12.92 3.19zm0-9.3a11.75 11.75 0 0 0 9.69-4.34q3.49-4.33 3.49-12t-3.45-12q-3.46-4.26-9.73-4.26a11.75 11.75 0 0 0-9.62 4.26q-3.5 4.26-3.49 12 .01 7.74 3.49 12a11.66 11.66 0 0 0 9.62 4.34zM253.992 70.673a40 40 0 0 1-11-1.48 27.55 27.55 0 0 1-8.19-3.7.71.71 0 0 1-.26-.82l2.68-7.26a.71.71 0 0 1 1.05-.34 27.72 27.72 0 0 0 6.84 3.17 29.69 29.69 0 0 0 8.92 1.34q5.07 0 7.29-1.59a4.74 4.74 0 0 0 2.22-4 3.85 3.85 0 0 0-1.55-3.24 14.18 14.18 0 0 0-5.43-2.05l-8-1.69q-13.32-2.78-13.33-13.78a13.9 13.9 0 0 1 2.54-8.28 16.62 16.62 0 0 1 7.09-5.54 26.35 26.35 0 0 1 10.54-2 30.62 30.62 0 0 1 9.65 1.48 20.76 20.76 0 0 1 7.1 3.83.72.72 0 0 1 .2.79l-2.67 6.72a.71.71 0 0 1-1.07.32 22.06 22.06 0 0 0-13.3-4 11.77 11.77 0 0 0-6.86 1.68 5.28 5.28 0 0 0-2.43 4.48 4.14 4.14 0 0 0 1.48 3.31 11.93 11.93 0 0 0 5.15 2l8 1.69q13.68 3 13.68 13.39a13.58 13.58 0 0 1-2.5 8.14 16.25 16.25 0 0 1-7.05 5.43 27.34 27.34 0 0 1-10.79 2z"
        />
    </Svg>
);
