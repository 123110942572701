import { StyleFunction } from "@venuepos/react-common";

export const styleFunc: StyleFunction = theme => ({
    headline: {
        color: theme.colors.textDark,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    dataText: {
        fontSize: 14,
    },

    titleCell: { flexBasis: 100, flex: 1 },
    dataCell: { flexBasis: 200, flex: 3 },
    titleText: {
        ...theme.fonts.medium,
        color: theme.colors.textDark,
        fontSize: 16,
    },
});
