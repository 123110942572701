import { Link } from "@react-navigation/native";
import {
    Alert,
    Button,
    Headline,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useForm,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { endOfDay, startOfDay } from "date-fns";
import {
    useInvoicesInPeriodQuery,
    useShiftsInPeriodQuery,
    useVatsQuery,
} from "graphql-sdk";
import { MerchantConfig, RelativePeriods } from "lib";
import { useTranslation } from "locales";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useFetch } from "../../hooks";
import { AdminContainer } from "../container";
import { DateForm } from "./date-form";
import { defaultSAFTExportForm, schemaSAFTExportForm } from "./validation";

export function ExportScreen() {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const merchantConfig = useMerchantConfig();
    const vats = useVatsQuery();

    const authorizedFetch = useFetch();

    const [{ values }, { handleSubmit, setValues }] = useForm(
        schemaSAFTExportForm,
        defaultSAFTExportForm
    );

    const [showError, setShowError] = useState<boolean>(false);
    const [fetchingReport, setFetchingReport] = useState<boolean>(false);

    const requiredDataFields: (keyof MerchantConfig)[] = [
        "cvr",
        "address",
        "city",
        "zipcode",
        "country",
        "currency",
    ];

    const dataFieldsAreValid = requiredDataFields.every(
        element => !!merchantConfig[element]
    );

    let invalidMerchantData;
    if (!dataFieldsAreValid) {
        invalidMerchantData = requiredDataFields.filter(
            element => !merchantConfig[element]
        );
    }

    // The only valid VAT percentages in Denmark is 25 and 0.
    const vatOptionsAreValid = vats.data?.vats.data.every(
        vatItem => vatItem.percentage === 25 || vatItem.percentage === 0
    );
    const currencyIsValid =
        merchantConfig.currency === "DKK" || merchantConfig.currency === "EUR";

    const { data: invoiceData, loading: invoicesLoading } =
        useInvoicesInPeriodQuery({
            variables: {
                from: startOfDay(values?.from!),
                to: endOfDay(values?.to!),
                pagination: { pageSize: 1 }, // We only need the resultCount, so the pageSize is reduced to 1 to limit the response size.
            },
        });

    const { data: shiftData, loading: shiftsLoading } = useShiftsInPeriodQuery({
        variables: {
            from: startOfDay(values?.from!),
            to: endOfDay(values?.to!),
            pagination: { pageSize: 1 }, // We only need the resultCount, so the pageSize is reduced to 1 to limit the response size.
        },
    });

    const handleExportTaxReport = useCallback(async () => {
        if (!values || !values.from || !values.to) {
            return;
        }

        let exportResponse;

        try {
            const requestBody = {
                from: startOfDay(values.from),
                to: endOfDay(values.to),
            };

            setShowError(false);
            setFetchingReport(true);

            exportResponse = await authorizedFetch("/api/export/saft", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            setFetchingReport(false);
        } catch (err) {
            setShowError(true);
            setFetchingReport(false);
            return;
        }

        if (!exportResponse || !exportResponse.ok) {
            setShowError(true);
            setFetchingReport(false);
            return;
        }

        const fileName = exportResponse.headers
            .get("content-disposition")
            ?.split("filename=")[1];

        if (!fileName) {
            setShowError(true);
            return;
        }

        const fileBody = await exportResponse.blob();

        if (!fileBody) {
            setShowError(true);
            return;
        }

        // NOTE Is this a better approach than the "report download"?
        // The report download: Upen a new window/tab and return the file from that.
        // The SAF-T report download: Create an anchor in the page with a binary blob attached and download from that.
        // Which is actually better / more useful?
        const href = window.URL.createObjectURL(fileBody);
        const link = document.createElement("a");
        link.setAttribute("href", href);
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [authorizedFetch, values]);

    const handleDateChange = useCallback(
        (date: Date, dateField: string) => {
            setShowError(false);
            setFetchingReport(false);

            let valueObj:
                | {
                      period: RelativePeriods;
                      from?: Date;
                  }
                | {
                      period: RelativePeriods;
                      to?: Date;
                  } = {
                period: RelativePeriods.USER_DEFINED,
            };

            switch (dateField) {
                case "from": {
                    valueObj = {
                        from: date,
                        period: RelativePeriods.USER_DEFINED,
                    };
                    break;
                }
                case "to": {
                    valueObj = {
                        to: date,
                        period: RelativePeriods.USER_DEFINED,
                    };
                    break;
                }
                default: {
                    // do nothing if false values are used.
                }
            }

            setValues(valueObj);
        },
        [setValues]
    );

    return (
        <AdminContainer>
            <Surface>
                <Headline>
                    {t(
                        "backoffice.export.saft-report.header",
                        "Fetch the SAF-T report"
                    )}
                </Headline>
                {showError ? (
                    <>
                        <Alert type="warning">
                            {t(
                                "backoffice.export.download-failed",
                                "An error occurred during download. Please try again later or contact our support."
                            )}
                        </Alert>
                        <Spacer space={2} />
                    </>
                ) : null}

                {dataFieldsAreValid && vatOptionsAreValid && currencyIsValid ? (
                    <>
                        {!values ? (
                            <Loading />
                        ) : (
                            <DateForm
                                formValues={values}
                                onDateChange={handleDateChange}
                            />
                        )}
                        {invoicesLoading || shiftsLoading ? (
                            <Loading
                                message={t(
                                    "backoffice.export.counting",
                                    "Counting shifts and invoices for the selected period."
                                )}
                            />
                        ) : invoiceData && shiftData ? (
                            <>
                                <View>
                                    <Text>
                                        {t(
                                            "backoffice.export.intro",
                                            "There are {{ invoiceCount }} invoices in {{ shiftCount }} shifts in the selected period.",
                                            {
                                                invoiceCount:
                                                    invoiceData.invoicesInPeriod
                                                        .pagination.resultCount,
                                                shiftCount:
                                                    shiftData.shiftsInPeriod
                                                        .pagination.resultCount,
                                            }
                                        )}
                                    </Text>
                                </View>
                                <Spacer />
                            </>
                        ) : null}

                        <Button
                            onPress={handleSubmit(handleExportTaxReport)}
                            disabled={fetchingReport}
                            loading={fetchingReport}
                        >
                            {t(
                                "backoffice.export.saft-report.button",
                                "Hent SAF-T-rapport (XML)"
                            )}
                        </Button>
                    </>
                ) : (
                    <>
                        <Alert type="warning">
                            <>
                                {(!dataFieldsAreValid ? (
                                    <View style={styles.column}>
                                        <Text>
                                            {`${t(
                                                "backoffice.export.saft-report.missing-merchant-data",
                                                "There are data missing from the Merchant"
                                            )}: ${
                                                invalidMerchantData
                                                    ? invalidMerchantData.join(
                                                          ", "
                                                      )
                                                    : ""
                                            }`}
                                        </Text>
                                        <Link
                                            to={{
                                                screen: "MERCHANT_SETTINGS",
                                            }}
                                            style={styles.textLink}
                                        >
                                            {t(
                                                "backoffice.export.saft-report.link-merchant-settings",
                                                "Enter the missing values in Settings"
                                            )}
                                        </Link>
                                    </View>
                                ) : null) ||
                                    (!vatOptionsAreValid ? (
                                        <View style={styles.column}>
                                            <Text>
                                                {t(
                                                    "backoffice.export.saft-report.faulty-vat-settings",
                                                    "The required VAT for generating SAF-T report is 0% and/or 25%. This requirement is not fulfilled for this merchant."
                                                )}
                                            </Text>
                                        </View>
                                    ) : null) ||
                                    (!currencyIsValid ? (
                                        <View style={styles.column}>
                                            <Text>
                                                {t(
                                                    "backoffice.export.saft-report.faulty-currency",
                                                    "The required currency for generating SAF-T report is either DKK or EUR. This requirement is not fulfilled for this merchant."
                                                )}
                                            </Text>
                                        </View>
                                    ) : null)}
                            </>
                        </Alert>
                        <Spacer space={2} />

                        <Button onPress={() => undefined} disabled={true}>
                            {t(
                                "backoffice.export.saft-report.button",
                                "Hent SAF-T-rapport (XML)"
                            )}
                        </Button>
                    </>
                )}
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    textLink: {
        ...theme.styles.link,
    },
    column: { ...theme.styles.column, alignItems: "flex-start" },
});
