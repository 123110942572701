import React, { ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Text } from "../text";

export function InputLabel(props: {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
}) {
    return (
        <View style={[styles.container, props.style]}>
            <Text style={styles.label} numberOfLines={1} ellipsizeMode="tail">
                {props.children}
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginBottom: 10,
    },
    label: {
        fontFamily: "montserrat-bold",
        fontSize: 12,
        lineHeight: 16,
        textTransform: "uppercase",
    },
});
