import React from "react";
import {
    DataTable,
    Headline,
    InputControl,
    Spacer,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";

import { View, TextInput } from "react-native";
import { useTranslation } from "locales";
import type { IMerchantChartOfAccountInput } from "lib";
import { produce } from "immer";

export function ChartOfAccounts(props: {
    chartOfAccounts: IMerchantChartOfAccountInput[];
    setValue: any;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { chartOfAccounts, setValue } = props;

    const changeMerchantAccountNo = (
        merchantAccountNo: string,
        existingMerchantAccountNo: string
    ) => {
        setValue(
            "merchantChartOfAccounts",
            produce(chartOfAccounts, draft => {
                const item = draft.find(
                    itr => itr.merchantAccountNo === existingMerchantAccountNo
                );

                if (item) {
                    item.merchantAccountNo = merchantAccountNo;
                }
            })
        );
    };

    return (
        <View style={styles.container}>
            <Headline size="h4">
                {t(
                    "backoffice.integration.settings.economic.account_chart",
                    "E-conomic account mapping"
                )}
            </Headline>
            {chartOfAccounts && chartOfAccounts.length > 0 ? (
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title>
                            {t("common.chart_of_accounts_name", "Account name")}
                        </DataTable.Title>
                        <DataTable.Title numeric>
                            {t(
                                "common.merchant_account_no",
                                "Merchant's account no."
                            )}
                        </DataTable.Title>
                    </DataTable.Header>
                    {chartOfAccounts.map((item, index) => (
                        <DataTable.Row key={index.toString()}>
                            <DataTable.Cell>{item.name}</DataTable.Cell>
                            <DataTable.Cell>
                                <InputControl style={styles.inputContainer}>
                                    <TextInput
                                        defaultValue={item.merchantAccountNo}
                                        onChangeText={text =>
                                            changeMerchantAccountNo(
                                                text,
                                                item.merchantAccountNo
                                            )
                                        }
                                        style={styles.input}
                                    />
                                </InputControl>
                            </DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            ) : (
                <>
                    <View>
                        <Text>
                            {t(
                                "backoffice.integration.settings.economics.no_account_chart",
                                "No custom account chart. Using defaults."
                            )}
                        </Text>
                    </View>
                    <Spacer space={2} />
                </>
            )}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: "100%",
        maxWidth: theme.dimensions.maxFormWidth,
        alignSelf: "flex-start",
    },
    inputContainer: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: theme.spacingScale,
        marginTop: theme.spacingScale,
    },
    input: {
        // For some odd reason, the TextInput element does not look like it's supposed to in the DataTable.Cell context.
        // These styles make sure they look the same (as long as they match the general TextInput style ...)
        // TODO Find the cause and fix the problem.
        ...theme.styles.input,
        borderRadius: theme.borderRadiusSmall,
        borderColor: theme.colors.secondary,
        borderWidth: theme.borderWidth,
        padding: theme.spacingScale,
        textAlign: "right",
    },
});
