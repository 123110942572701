import {
    Alert,
    DataTable,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQSoftpayMerchantsQuery, useSoftpayMerchantsQuery } from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"SOFTPAY_MERCHANT_LIST">;

export function SoftpayMerchantListScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "DESC",
    });

    const { data, loading, refetch, error } = useSoftpayMerchantsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const renderSoftpayMerchantRow = useCallback(
        (
            item: GQSoftpayMerchantsQuery["softpayMerchants"]["data"][0],
            rowIndex
        ) => (
            <DataTable.Row
                style={rowIndex % 2 === 0 ? styles.oddRow : undefined}
                key={`merchant_${rowIndex}`}
            >
                <DataTable.Cell>{item.name}</DataTable.Cell>
                <DataTable.Cell>
                    {item.businessRegistrationNumber}
                </DataTable.Cell>
                <DataTable.Cell>{item.merchantReference}</DataTable.Cell>
            </DataTable.Row>
        ),
        [styles.oddRow]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.softpay_merchant_list.softpay_cloud_merchants",
                    "Softpay cloud merchants"
                )}
            </PageTitle>

            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("backoffice.common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_merchant_list.business_registration_number",
                                "Business registration number"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_merchant_list.merchant_reference",
                                "Merchant reference"
                            )}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.softpayMerchants.data.map(
                            renderSoftpayMerchantRow
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.softpayMerchants.pagination.pages}
                        itemCount={
                            data?.softpayMerchants.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
