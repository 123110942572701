import {
    Alert,
    DateTimePickerInput,
    Headline,
    Loading,
    StyleFunction,
    Surface,
    useThemedStyle,
} from "@venuepos/react-common";
import { theme } from "@venuepos/react-common/src/theme/themes/venue-default";
import { useDashboardInvoiceAmountStatsQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React, { useCallback, useState } from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from "victory";
import { dashboardStyleFunc } from "../styles";
import { add, format, set } from "date-fns";
import { dateToDateTime } from "lib";
import { View } from "react-native";

export function InvoiceStats() {
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const styles = useThemedStyle(styleFunc);

    const [fromDate, setFromDate] = useState<Date>(today());
    const [toDate, setToDate] = useState<Date>(nextDay(fromDate));

    const { data, loading, error } = useDashboardInvoiceAmountStatsQuery({
        variables: {
            from: fromDate,
            to: toDate,
            interval: "hour",
        },
    });

    const handleDateChange = useCallback(dateObj => {
        if (dateObj === undefined) {
            return;
        }

        const from = today(dateObj);
        const to = nextDay(dateObj);

        setFromDate(from);
        setToDate(to);
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    // To get the x-axis working we need the element to be Date
    const amountData = data?.dashboardInvoiceAmountStats?.map(element => {
        return {
            datetime: new Date(element?.datetime),
            sum: element?.sum || 0,
        };
    });

    // If we dont have data for the whole day we only get ticks for the date and not the whole day
    // Build list of ticks for 24 hours.
    const ticks = [];

    for (var i = 0; i < 24; i++) {
        ticks.push(fromDate.setHours(i));
    }

    return (
        <Surface style={sharedStyles.widget}>
            <View style={styles.headlineWithDate}>
                <Headline testID="headline:invoiceAmountStats">
                    {t("dashboard.invoice_amount_stat", "Invoice amount")}
                </Headline>
                <DateTimePickerInput
                    dateTimeType="date"
                    onChangeDate={handleDateChange}
                    value={dateToDateTime(fromDate)}
                    style={sharedStyles.dateTimePicker}
                />
            </View>

            <VictoryChart
                height={300}
                width={1200}
                theme={VictoryTheme.material}
            >
                <VictoryAxis dependentAxis crossAxis={false} />
                <VictoryAxis
                    tickFormat={tick => {
                        const date = new Date(tick);
                        return `${format(date, "H:mm")}`;
                    }}
                    tickValues={ticks}
                    label={t("dashboard.invoice_amount_stat_time", "Time")}
                    style={{
                        tickLabels: {
                            angle: 45,
                            padding: 12,
                        },
                        axisLabel: {
                            padding: 50,
                        },
                    }}
                />

                <VictoryBar
                    y={d => d.sum / 100}
                    data={amountData}
                    x="datetime"
                    style={{
                        data: {
                            width: 1,
                            fill: ({ datum }) =>
                                datum.sum > 0
                                    ? theme.colors.secondary
                                    : theme.colors.error,
                        },
                    }}
                />
            </VictoryChart>
        </Surface>
    );
}

const styleFunc: StyleFunction = () => ({
    headlineWithDate: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch",
    },
});

function today(date = new Date()) {
    return set(date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

function nextDay(date = new Date()) {
    return set(add(date, { days: 1 }), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}
