import { IIntegrationInput, IntegrationType, integrationTypes } from "lib";
import {
    Form,
    Button,
    InputControl,
    TextInput,
    Picker,
    CheckBox,
    RequiredText,
    Loading,
} from "@venuepos/react-common";
import { ChartOfAccounts } from "./chart-of-accounts";
import { useIntegrationsQuery } from "graphql-sdk";
import React from "react-native";
import { useTranslation } from "locales";

export function GeneralForm(props: {
    form: Form<IIntegrationInput>;
    onSubmit: () => void;
    submitButton: [string, string];
    pickerDisabled: boolean;
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    const { data, loading } = useIntegrationsQuery({
        variables: {},
        fetchPolicy: "no-cache",
    });

    if (!values) {
        return <Loading />;
    }

    // This is all the created types. There should only be one of each.
    // (Apparently that's a limit we set for our users at some point...)
    const createdTypes: IntegrationType[] = data?.integrations.data
        ? data?.integrations.data.map(itr => itr.type as IntegrationType)
        : [];

    // The set of created types - this should at most limit to the number of available types.
    const uniqueCreatedTypes = Array.from(new Set(createdTypes));

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.type}>
                <Picker
                    label={t("backoffice.integrations.type", "Type")}
                    selectedValue={values.type}
                    onValueChange={value => {
                        setValue("type", value as IntegrationType);
                    }}
                    disabled={
                        props.pickerDisabled ||
                        uniqueCreatedTypes.length >= integrationTypes.length
                    }
                    required={true}
                >
                    <Picker.Item
                        label={t(
                            "backoffice.integrations.choose_type",
                            "Choose type"
                        )}
                        value={undefined}
                    />
                    {!uniqueCreatedTypes?.includes("E-CONOMIC") ? (
                        <Picker.Item
                            label={t(
                                "backoffice.integrations.types.economic",
                                "E-conomic"
                            )}
                            value="E-CONOMIC"
                        />
                    ) : null}
                    {!uniqueCreatedTypes?.includes("PARTNER-API") ? (
                        <Picker.Item
                            label={t(
                                "backoffice.integrations.types.partner-api",
                                "PartnerAPI"
                            )}
                            value="PARTNER-API"
                        />
                    ) : null}
                    {!uniqueCreatedTypes?.includes("VENUE-ACCESS") ? (
                        <Picker.Item
                            label={t(
                                "backoffice.integrations.types.venue-access",
                                "Venue Access"
                            )}
                            value="VENUE-ACCESS"
                        />
                    ) : null}
                </Picker>
            </InputControl>

            <InputControl error={errors.active}>
                <CheckBox
                    label={t("backoffice.integrations.active", "Active")}
                    value={values.active}
                    onValueChange={value => setValue("active", value)}
                    disabled={values.type === "PARTNER-API"}
                />
            </InputControl>

            <InputControl error={errors.apiKey}>
                <TextInput
                    label={t("backoffice.integrations.api_key", "API key")}
                    placeholder={t(
                        "backoffice.integrations.enter_api_key",
                        "Enter API key"
                    )}
                    defaultValue={values.apiKey}
                    onChangeText={text => setValue("apiKey", text)}
                    required={true}
                />
            </InputControl>

            {values.type === "E-CONOMIC" ? (
                <ChartOfAccounts
                    chartOfAccounts={values.merchantChartOfAccounts}
                    setValue={setValue}
                />
            ) : null}

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </>
    );
}
