import { format } from "date-fns";
import { da, enUS } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";
import type { AvailableLocale } from "locales";

export * from "./period";

export type DateType = {
    year: number; // YYYY
    month: number; // 1-12, January = 1
    day: number; // 1-31
};

export type WeekType = {
    week: number; // 1-53
};

export type WeekDayType = {
    weekDay: number; // 0-6, Sunday = 0
};

export type TimeType = {
    hour: number; // 0-24
    minute: number; // 0-59
};

export type DateTimeType = DateType & WeekType & WeekDayType & TimeType;

export type DateTimeTypes = {
    dateTime: {
        from?: DateType & TimeType;
        to?: DateType & TimeType;
    };
    date: {
        from?: DateType;
        to?: DateType;
    };
    time: {
        from?: TimeType;
        to?: TimeType;
    };
    week: {
        from?: WeekType;
        to?: WeekType;
    };
    weekDay: {
        from?: WeekDayType;
        to?: WeekDayType;
    };
};

// Copy paste of https://stackoverflow.com/a/31810991/1286130
// Modified to work with typescript
function getWeek(date: Date) {
    const firstJanuary = new Date(date.getFullYear(), 0, 1).valueOf();
    const today = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    ).valueOf();
    const dayOfYear = (today - firstJanuary + 86400000) / 86400000;
    return Math.ceil(dayOfYear / 7);
}

export function dateToDateTime(date: Date): DateTimeType {
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        weekDay: date.getDay(),
        week: getWeek(date),
    };
}

export function dateTimeToDate(value: Partial<DateTimeType>): Date {
    const date =
        value.year !== undefined &&
        value.month !== undefined &&
        value.day !== undefined;
    const time = value.hour !== undefined && value.minute !== undefined;

    const day = `${value.day}`.padStart(2, "0");
    const month = `${value.month}`.padStart(2, "0");
    const hour = `${value.hour}`.padStart(2, "0");
    const minute = `${value.minute}`.padStart(2, "0");
    if (date && time) {
        return new Date(`${value.year}-${month}-${day}T${hour}:${minute}:00`);
    }

    if (date) {
        return new Date(`${value.year}-${month}-${day}`);
    }

    if (time) {
        return new Date(`1970-01-01T${hour}:${minute}:00`);
    }

    throw Error("Given DateTime value cannot be converted to a Date object");
}

export function getCurrentDateTime(): DateTimeType {
    return dateToDateTime(new Date());
}

export function inRangeDate(
    dateTime: DateTimeType,
    from?: DateType,
    to?: DateType
): boolean {
    const matchFrom =
        !from ||
        dateTime.year > from.year ||
        (dateTime.year === from.year &&
            (dateTime.month > from.month ||
                (dateTime.month === from.month && dateTime.day >= from.day)));
    const matchTo =
        !to ||
        dateTime.year < to.year ||
        (dateTime.year === to.year &&
            (dateTime.month < to.month ||
                (dateTime.month === to.month && dateTime.day <= to.day)));

    // Month and days are interval, and therefore, if the year given in `from` is less than `dateTime`,
    // then we only need to check that `matchTo` is true
    if (!!from && from.year < dateTime.year) {
        return matchTo;
    }

    // Month and days are interval, and therefore, if the year given in `to` is less than `dateTime`,
    // then we only need to check that `matchFrom` is true
    if (!!to && to.year > dateTime.year) {
        return matchFrom;
    }

    // If both the year of `from` and `to` are the same as the year of `dateTime`, both `matchFrom`
    // and `matchTo` needs to be equal
    return matchFrom && matchTo;
}

export function inRangeTime(
    dateTime: DateTimeType,
    from?: TimeType,
    to?: TimeType,
    ignoreDayOverlap?: boolean
): boolean {
    const matchFrom =
        !from ||
        dateTime.hour * 60 + dateTime.minute >= from.hour * 60 + from.minute;
    const matchTo =
        !to || dateTime.hour * 60 + dateTime.minute <= to.hour * 60 + to.minute;

    // Because hours and minutes together are an interval, we need to allow for `from` to be less than `to`
    if (
        !ignoreDayOverlap &&
        !!from &&
        !!to &&
        from.hour * 60 + from.minute > to.hour * 60 + to.minute
    ) {
        return matchFrom || matchTo;
    }

    return matchFrom && matchTo;
}

export function inRangeDateTime(
    dateTime: DateTimeType,
    from?: DateType & TimeType,
    to?: DateType & TimeType
): boolean {
    if (from && to === undefined) {
        // If only `from` is given
        return inRangeDate(dateTime, from) && inRangeTime(dateTime, from);
    } else if (from === undefined && to) {
        // If only `to` is given
        return (
            inRangeDate(dateTime, undefined, to) &&
            inRangeTime(dateTime, undefined, to)
        );
    }

    // Else if `from` and `to` both are given
    if (
        !!from &&
        !!to &&
        from.year === to.year &&
        from.month === to.month &&
        from.day === to.day
    ) {
        // If the dates of `from` and `to` do match, we only need to compare time for `from` and `to`
        // but we also need to ignore day overlapping, as we now two dates is the same, and the time
        // has to be between the start and the end of the day
        return inRangeTime(dateTime, from, to, true);
    } else {
        // If the dates of `from` and `to` doesn't match, we need to compare both date and time
        return (
            inRangeDate(dateTime, from, to) && inRangeTime(dateTime, from, to)
        );
    }
}

export function inRangeWeek(
    dateTime: DateTimeType,
    from?: WeekType,
    to?: WeekType
): boolean {
    const matchFrom = !from || dateTime.week >= from.week;
    const matchTo = !to || dateTime.week <= to.week;

    // Because `week` is an interval, we need to allow for `from` to be less than `to`
    if (!!from && !!to && from.week > to.week) {
        return matchFrom || matchTo;
    }

    return matchFrom && matchTo;
}

export function inRangeWeekDay(
    dateTime: DateTimeType,
    from?: WeekDayType,
    to?: WeekDayType
): boolean {
    const matchFrom = !from || dateTime.weekDay >= from.weekDay;
    const matchTo = !to || dateTime.weekDay <= to.weekDay;

    // Because `weekDay` is an interval, we need to allow for `from` to be less than `to`
    if (!!from && !!to && from.weekDay > to.weekDay) {
        return matchFrom || matchTo;
    }

    return matchFrom && matchTo;
}

export const undefinedDateTime: DateTimeType = {
    year: 0,
    month: 0,
    day: 0,
    hour: 0,
    minute: 0,
    week: 0,
    weekDay: 0,
};

export function dateToUnixtime(d: Date): number {
    return Math.floor(d.getTime() / 1000);
}

export function dateOffsetTimezone(
    d: Date,
    timezoneOffset: number | undefined
): Date {
    if (timezoneOffset !== undefined) {
        // Multiply by -1 as we need to "remove" the offset
        timezoneOffset = timezoneOffset * -1;
        d.setTime(d.getTime() + timezoneOffset * 60 * 1000);
    }
    return d;
}

export function formatDateTime(
    date: Date | string,
    localeString: AvailableLocale,
    formatString: string = "P pp" // Format as localized date and time: "04/11/2022 13:34:56". See https://date-fns.org/v2.28.0/docs/format
): string {
    if (typeof date === "string") {
        date = new Date(date);
    }

    let locale = handleLocale(localeString);

    return format(date, formatString, { locale });
}

export function formatDateTimeInTimezone(
    date: Date | string,
    localeString: AvailableLocale,
    timezone: string
): string {
    if (typeof date === "string") {
        date = new Date(date);
    }

    // Format as localized date and time: "04/11/2022 13:34:56". See https://date-fns.org/v2.28.0/docs/format
    const formatString = "P pp";

    let locale = handleLocale(localeString);

    return formatInTimeZone(date, timezone, formatString, { locale });
}

export function formatDate(
    date: Date | string,
    localeString: AvailableLocale
): string {
    if (typeof date === "string") {
        date = new Date(date);
    }

    let locale = handleLocale(localeString);

    // Format as localized date: "04/11/2022". See https://date-fns.org/v2.28.0/docs/format
    return format(date, "P", { locale });
}

export function formatLongDate(
    date: Date | string,
    localeString: AvailableLocale
): string {
    if (typeof date === "string") {
        date = new Date(date);
    }

    let locale = handleLocale(localeString);

    // Format as long localized date "Friday 2th, february 2021". See https://date-fns.org/v2.28.0/docs/format
    return format(date, "PPPP", { locale });
}

export function formatTime(
    dateObj: Date,
    localeString: AvailableLocale,
    excludeSeconds: boolean = false
): string {
    let locale = handleLocale(localeString);

    // format as localized time: "13:34:56". See https://date-fns.org/v2.28.0/docs/format
    let timeFormat = excludeSeconds ? "p" : "pp";
    return format(dateObj, timeFormat, { locale });
}

function handleLocale(localeString: AvailableLocale): Locale {
    let locale;

    switch (localeString) {
        case "en":
            locale = enUS;
            break;
        case "da":
        default:
            locale = da;
            break;
    }
    return locale;
}
