import React from "react";
import {
    useTheme,
    useMerchantConfig,
    Loading,
    Surface,
    TextInput,
    Text,
    Picker,
    InputControl,
    NumberInput,
    Alert,
    Button,
    RequiredText,
    CheckBox,
} from "@venuepos/react-common";
import type { Form } from "@venuepos/react-common";
import { useTranslation } from "locales";
import {
    GQDiscountType,
    GQDiscountValueType,
    useDiscountFormQuery,
} from "graphql-sdk";
import { StyleSheet, View } from "react-native";
import { ProductConditionsForm } from "./product-conditions-form";
import { DateTimeConstraintsForm } from "./date-time-constraint-form";
import type { DiscountFormInputs } from "./forms";
import { TagConditionsForm } from "./tag-conditions-form";
import { CustomerConditionsForm } from "./customer-conditions-form";
import { GroupConditionsForm } from "./group-conditions-form";

export function DiscountForm(props: {
    form: Form<DiscountFormInputs>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const theme = useTheme();
    const [t] = useTranslation();
    const merchantConfig = useMerchantConfig();
    const [{ values, errors }, { setValue, setValues, handleSubmit }] =
        props.form;
    const formData = useDiscountFormQuery({
        fetchPolicy: "no-cache",
    });

    if (values === null || !formData.data) {
        return <Loading />;
    }

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <Surface>
                    <InputControl error={errors.name}>
                        <TextInput
                            label={t("common.name", "Name")}
                            placeholder={t(
                                "backoffice.common.enter_name",
                                "Enter name"
                            )}
                            defaultValue={values.name}
                            onChangeText={text => setValue("name", text)}
                            required={true}
                        />
                    </InputControl>

                    <InputControl error={errors.lineDescription}>
                        <TextInput
                            label={t(
                                "backoffice.discount_form.line_description",
                                "Receipt line description"
                            )}
                            placeholder={t(
                                "backoffice.discount_form.enter_line_description",
                                "Enter line description"
                            )}
                            defaultValue={values.lineDescription}
                            onChangeText={text =>
                                setValue("lineDescription", text)
                            }
                            required={true}
                        />
                    </InputControl>

                    <InputControl error={errors.active}>
                        <CheckBox
                            label={t("common.active", "Active")}
                            value={values.active}
                            onValueChange={checked =>
                                setValue("active", checked)
                            }
                            testID="active"
                        />
                    </InputControl>

                    <InputControl
                        error={errors.type}
                        description={t(
                            "backoffice.discount_form.type.description",
                            "Determines whether the discount should be applied to a product or the total of an order."
                        )}
                    >
                        <Picker
                            label={t(
                                "backoffice.discount_form.type.label",
                                "Type"
                            )}
                            onValueChange={value => {
                                setValue("type", value as GQDiscountType);
                            }}
                            selectedValue={values.type}
                        >
                            <Picker.Item
                                label={t(
                                    "backoffice.discount_form.type.order",
                                    "Order"
                                )}
                                value="ORDER"
                            />
                            <Picker.Item
                                label={t(
                                    "backoffice.discount_form.type.product",
                                    "Product"
                                )}
                                value="PRODUCT"
                            />
                            <Picker.Item
                                label={t(
                                    "backoffice.discount_form.type.group",
                                    "Group"
                                )}
                                value="GROUP"
                            />
                        </Picker>
                    </InputControl>

                    {values.type === "PRODUCT" && (
                        <InputControl
                            error={errors.maxPerOrder}
                            description={t(
                                "backoffice.discount_form.max_per_order.description",
                                "Max number of times a discount can be applied to a single order. Leave the field blank for the discount to apply an unlimited number of times."
                            )}
                        >
                            <NumberInput
                                label={t(
                                    "backoffice.discount_form.max_per_order.label",
                                    "Max per order"
                                )}
                                defaultValue={values.maxPerOrder}
                                onChange={value =>
                                    setValue("maxPerOrder", value)
                                }
                                min={1}
                                decimals={0}
                            />
                        </InputControl>
                    )}

                    <InputControl
                        error={errors.valueType}
                        description={
                            <>
                                <Text style={theme.styles.inputDescription}>
                                    {`${t(
                                        "backoffice.discount_form.value_type_description.description",
                                        "The calculation method determines how the discount is applied to the order or items. An order with a price of 400 would get following savings."
                                    )}\n\n`}
                                </Text>
                                <Text style={theme.styles.inputDescription}>
                                    <Text
                                        style={[
                                            theme.styles.inputDescription,
                                            styles.boldUppercase,
                                        ]}
                                    >
                                        {`${t(
                                            "backoffice.discount_form.value_type.percentage",
                                            "Percentage"
                                        )}: `}
                                    </Text>
                                    {t(
                                        "backoffice.discount_form.value_type_description.percentage",
                                        "If set to 25%, the new order price would become 300, since 25% of 400 is 100, so 400 - 100 = 300."
                                    )}
                                </Text>
                                <Text style={theme.styles.inputDescription}>
                                    <Text
                                        style={[
                                            theme.styles.inputDescription,
                                            styles.boldUppercase,
                                        ]}
                                    >
                                        {`${t(
                                            "backoffice.discount_form.value_type.relative",
                                            "Relative"
                                        )}: `}
                                    </Text>
                                    {t(
                                        "backoffice.discount_form.value_type_description.relative",
                                        "If set to 100, the new order price would become 300, since 400 - 100 = 300."
                                    )}
                                </Text>
                                <Text style={theme.styles.inputDescription}>
                                    <Text
                                        style={[
                                            theme.styles.inputDescription,
                                            styles.boldUppercase,
                                        ]}
                                    >
                                        {`${t(
                                            "backoffice.discount_form.value_type.absolute",
                                            "Absolute"
                                        )}: `}
                                    </Text>
                                    {t(
                                        "backoffice.discount_form.value_type_description.absolute",
                                        "If set to 100, the new order price would become 100"
                                    )}
                                </Text>
                            </>
                        }
                    >
                        <Picker
                            label={t(
                                "backoffice.discount_form.value_type.label",
                                "Calculation method"
                            )}
                            onValueChange={value => {
                                setValues({
                                    valueType: value as GQDiscountValueType,
                                    value: value === "PERCENTAGE" ? 0 : 0,
                                });
                            }}
                            selectedValue={values.valueType}
                        >
                            <Picker.Item
                                label={t(
                                    "backoffice.discount_form.value_type.percentage",
                                    "Percentage"
                                )}
                                value="PERCENTAGE"
                            />
                            <Picker.Item
                                label={t(
                                    "backoffice.discount_form.value_type.relative",
                                    "Relative"
                                )}
                                value="RELATIVE"
                            />
                            <Picker.Item
                                label={t(
                                    "backoffice.discount_form.value_type.absolute",
                                    "Absolute"
                                )}
                                value="ABSOLUTE"
                            />
                        </Picker>
                    </InputControl>

                    {values.valueType === "PERCENTAGE" ? (
                        <InputControl error={errors.value} key="percentage">
                            <NumberInput
                                label={t(
                                    "backoffice.discount_form.value",
                                    "Value"
                                )}
                                placeholder={t(
                                    "backoffice.discount_form.enter_value",
                                    "Enter value"
                                )}
                                defaultValue={values.value}
                                onChange={value =>
                                    setValue(
                                        "value",
                                        value === null ? 0 : value
                                    )
                                }
                                unit="%"
                                min={0} // rate, equal to 0%
                                max={1} // rate, equal to 100%
                                percentageToRate={true}
                                decimals={2}
                                required={true}
                            />
                        </InputControl>
                    ) : (
                        <InputControl error={errors.value} key="amount">
                            <NumberInput
                                label={t(
                                    "backoffice.discount_form.amount",
                                    "Amount"
                                )}
                                placeholder={t(
                                    "backoffice.discount_form.enter_amount",
                                    "Enter amount"
                                )}
                                defaultValue={values.value / 100}
                                onChange={value =>
                                    setValue(
                                        "value",
                                        value === null ? 1 : value * 100
                                    )
                                }
                                unit={merchantConfig.currency}
                                min={0}
                                decimals={2}
                            />
                        </InputControl>
                    )}

                    {values.type === "ORDER" &&
                        values.dateTimeConstraints.length === 0 &&
                        values.tagConditions.length === 0 &&
                        values.customerConditions.length === 0 && (
                            <Alert style={styles.alert} type="warning">
                                {t(
                                    "backoffice.discount_form.warning_for_type_order_without_datetime_constraints",
                                    "This order discount has no constraints, and will therefore be applied to every new order. You may add a date and time constraint to limit the discount to a specific timeslot."
                                )}
                            </Alert>
                        )}
                    {values.type === "PRODUCT" &&
                        values.productConditions.length === 0 && (
                            <Alert style={styles.alert} type="warning">
                                {t(
                                    "backoffice.discount_form.warning_for_type_product_without_product_conditions",
                                    'This product discount has no product conditions, and will therefore not be applied to any products. You should add a new product condition by clicking the "+ADD CONDITION" button.'
                                )}
                            </Alert>
                        )}

                    <Button onPress={handleSubmit(props.onSubmit)}>
                        {t(props.submitButton[0], props.submitButton[1])}
                    </Button>
                    <RequiredText />
                </Surface>
            </View>
            <View style={styles.rightColumn}>
                <DateTimeConstraintsForm
                    constraints={values.dateTimeConstraints}
                    onConstraintsChange={newConstraints =>
                        setValue("dateTimeConstraints", newConstraints)
                    }
                />
                <TagConditionsForm
                    conditionsLogicOperator={values.tagConditionsLogicOperator}
                    onConditionsLogicOperatorChange={newLogicOperator => {
                        setValue(
                            "tagConditionsLogicOperator",
                            newLogicOperator
                        );
                    }}
                    conditions={values.tagConditions}
                    onConditionsChange={newConditions =>
                        setValue("tagConditions", newConditions)
                    }
                    formData={formData.data}
                />
                <CustomerConditionsForm
                    conditionsLogicOperator={
                        values.customerConditionsLogicOperator
                    }
                    onConditionsLogicOperatorChange={newLogicOperator => {
                        setValue(
                            "customerConditionsLogicOperator",
                            newLogicOperator
                        );
                    }}
                    conditions={values.customerConditions}
                    onConditionsChange={newConditions =>
                        setValue("customerConditions", newConditions)
                    }
                    formData={formData.data}
                />
                {/* Disabled as versions before 1.35 cannot handle department discount created in admin */}
                {/* <DepartmentConditionsForm
                    conditionsLogicOperator={
                        values.departmentConditionsLogicOperator
                    }
                    onConditionsLogicOperatorChange={newLogicOperator => {
                        setValue(
                            "departmentConditionsLogicOperator",
                            newLogicOperator
                        );
                    }}
                    conditions={values.departmentConditions}
                    onConditionsChange={newConditions =>
                        setValue("departmentConditions", newConditions)
                    }
                    formData={formData.data}
                /> */}
                {values.type === "PRODUCT" && (
                    <ProductConditionsForm
                        conditionsLogicOperator={
                            values.productConditionsLogicOperator
                        }
                        onConditionsLogicOperatorChange={newLogicOperator => {
                            setValue(
                                "productConditionsLogicOperator",
                                newLogicOperator
                            );
                        }}
                        conditions={values.productConditions}
                        onConditionsChange={newConditions =>
                            setValue("productConditions", newConditions)
                        }
                        formData={formData.data}
                    />
                )}
                {values.type === "GROUP" && (
                    <GroupConditionsForm
                        conditions={values.groupConditions}
                        onConditionsChange={newConditions => {
                            setValue("groupConditions", newConditions);
                        }}
                        formData={formData.data}
                    />
                )}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
    },
    form: {
        flex: 1,
        paddingRight: 20,
    },
    alert: {
        marginBottom: 20,
    },
    rightColumn: {
        flex: 1,
        paddingLeft: 20,
    },
    bottomSpacing: {
        marginBottom: 40,
    },
    boldUppercase: {
        fontWeight: "bold",
        textTransform: "uppercase",
    },
});
