import {
    LoadingScreen,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQPrinterSave,
    usePrinterLazyQuery,
    usePrinterSaveMutation,
} from "graphql-sdk";
import { schemaPrinter } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { AdminContainer } from "../..";
import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { Form } from "../form";

import type { StyleFunction } from "@venuepos/react-common";
type ScreenProps = RootStackScreenProps<"PRINTER_EDIT">;

export function PrinterEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.printer");

    const [t] = useTranslation();
    const [printerSave] = usePrinterSaveMutation();
    const [getPrinter, printer] = usePrinterLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<GQPrinterSave>(schemaPrinter, null);
    const [{ values }, { setDefaultValues }] = form;
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();
    const printerId = route.params.id;

    useEffect(() => {
        if (!printerId) {
            navigate("TAGS");
            return;
        }

        getPrinter({ variables: { id: printerId } });
    }, [getPrinter, navigate, printerId]);

    useEffect(() => {
        if (printer.data && printer.data.printer) {
            const printerData = printer.data.printer;
            setDefaultValues({
                name: printerData.name || "",
            });
        }
    }, [printer.data, setDefaultValues]);

    const handleEdit = useCallback(async () => {
        if (!printer.data || !printer.data.printer || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await printerSave({
                    variables: {
                        id: printer.data!.printer.id,
                        printer: {
                            name: values.name,
                        },
                    },
                }),
            t("common.saved", "Saved"),
            () => {
                navigate("PRINTERS", { refetch: true });
            }
        );
    }, [printerSave, handleMutationError, navigate, printer.data, t, values]);

    if (!printer.data || !printer.data.printer) {
        return <LoadingScreen style="light" />;
    }

    return (
        <AdminContainer>
            <View style={styles.formContainer}>
                <View style={styles.formBox}>
                    <Form
                        form={form}
                        onSubmit={handleEdit}
                        submitButton={["common.save", "Save"]}
                    />
                </View>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    formContainer: {
        width: "100%",
        backgroundColor: theme.colors.white,
    },
    formBox: {
        maxWidth: theme.dimensions.maxFormWidth,
        backgroundColor: theme.colors.white,
    },
});
