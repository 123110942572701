import {
    Alert,
    Button,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    InputControl,
    Loading,
    StyleFunction,
    Surface,
    TextInput,
    useModal,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useInventoryProductNoteTemplateCreateMutation,
    useInventoryProductNoteTemplateDeleteMutation,
    useInventoryProductNoteTemplateSaveMutation,
    useInventoryProductNoteTemplatesQuery,
} from "graphql-sdk";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import React, { View } from "react-native";
import { sharedStyleFunc } from "./product-list-screen";
import { useCallback, useState } from "react";
import { useHandleMutationError } from "../../hooks";
import { produce } from "immer";

type ScreenProps = RootStackScreenProps<"INVENTORY_PRODUCT_NOTE_TEMPLATES">;

export function InventoryProductNoteTemplateScreen({ route }: ScreenProps) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(sharedStyleFunc);
    const theme = useTheme();
    const { render } = useModal();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "note",
    });

    const { data, loading, error, refetch } =
        useInventoryProductNoteTemplatesQuery({
            variables: {
                pagination: {
                    page,
                    pageSize,
                    sort: sortBy,
                    sortDirection: sortDirection,
                },
            },
            fetchPolicy: route.params?.refetch ? "no-cache" : "cache-first",
        });
    const [deleteNoteTemplate] =
        useInventoryProductNoteTemplateDeleteMutation();
    const [createNoteTemplate] =
        useInventoryProductNoteTemplateCreateMutation();
    const [saveNoteTemplate] = useInventoryProductNoteTemplateSaveMutation();
    const { handleMutationError } = useHandleMutationError();

    const [note, setNote] = useState<{ id?: string; note: string } | undefined>(
        undefined
    );
    const [state, setState] = useState<"create" | "edit" | undefined>(
        undefined
    );

    const handleEdit = useCallback(
        (id: string) => {
            const noteTemplate = data?.inventoryProductNoteTemplates.data.find(
                itr => itr.id === id
            );
            setNote(noteTemplate);
            setState("edit");
        },
        [data?.inventoryProductNoteTemplates.data]
    );

    const handleCreate = useCallback(() => {
        if (note) {
            setNote(undefined);
        }
        setState("edit");
    }, [note]);

    const handleDelete = useCallback(
        async (id: string) => {
            const deleteResponse = await render(onClose => (
                <ConfirmModal
                    headerText={t(
                        "backoffice.products.delete",
                        "Delete products?"
                    )}
                    bodyText={t(
                        "backoffice.products.delete_explain",
                        "If you choose to delete these products, any buttons that are added to any of your layouts, which use these products, will be removed.\nThis cannot be undone.\n\nAre you sure, you want to delete these products?"
                    )}
                    onClose={onClose}
                />
            ));

            if (!deleteResponse) {
                return;
            }

            await handleMutationError(
                async () => await deleteNoteTemplate({ variables: { id } }),
                t("backoffice.inventory_product.note_deleted", "Note deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deleteNoteTemplate, handleMutationError, refetch, render, t]
    );

    const handleSubmit = useCallback(async () => {
        if (note !== undefined && note.note.trim() !== "") {
            if (note.id === undefined) {
                await handleMutationError(
                    async () =>
                        await createNoteTemplate({
                            variables: {
                                inventoryProductNoteTemplate: note,
                            },
                        }),
                    t("backoffice.inventory_product.note_saved", "Note saved"),
                    async () => {
                        await refetch();
                    }
                );
                setNote(undefined);
                setState(undefined);
            } else {
                await handleMutationError(
                    async () =>
                        await saveNoteTemplate({
                            variables: {
                                id: note.id!,
                                inventoryProductNoteTemplate: {
                                    id: note.id!,
                                    note: note.note,
                                },
                            },
                        }),
                    t("backoffice.inventory_product.note_saved", "Note saved"),
                    async () => {
                        await refetch();
                    }
                );
                setNote(undefined);
                setState(undefined);
            }
        }
    }, [
        createNoteTemplate,
        handleMutationError,
        note,
        refetch,
        saveNoteTemplate,
        t,
    ]);

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <Surface style={styles.surface}>
                <View style={styles.actionButtonContainer}>
                    <Button onPress={handleCreate}>
                        {t("common.create", "Create")}
                    </Button>
                </View>
            </Surface>
            <Surface style={styles.surface}>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "note" && sortDirection}
                            onPress={() => onSortChange("note")}
                        >
                            {t("common.note", "Note")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            style={sharedStyles.iconColumn}
                        >
                            <Icon name="sort" color={styles.icon.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data!.inventoryProductNoteTemplates.data.map(
                            (item, index) => (
                                <DataTable.Row
                                    key={
                                        "inventoryProductNoteTemplate_" + index
                                    }
                                    style={
                                        index % 2 === 1
                                            ? styles.oddRow
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell>{item.note}</DataTable.Cell>
                                    <DataTable.Cell
                                        numeric
                                        style={sharedStyles.iconColumn}
                                    >
                                        <IconButton
                                            color={theme.colors.secondary}
                                            name="edit"
                                            onPress={() => handleEdit(item.id)}
                                        />
                                        <IconButton
                                            color={theme.colors.secondary}
                                            name="delete"
                                            onPress={() =>
                                                handleDelete(item.id)
                                            }
                                        />
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={
                            data?.inventoryProductNoteTemplates.pagination.pages
                        }
                        itemCount={
                            data?.inventoryProductNoteTemplates.pagination
                                .resultCount
                        }
                    />
                </DataTable>
            </Surface>
            {state === "create" ||
                (state === "edit" && (
                    <Surface style={styles.surface}>
                        <InputControl>
                            <TextInput
                                label={t("common.note", "Note")}
                                placeholder={t(
                                    "backoffice.common.enter_note",
                                    "Enter note"
                                )}
                                value={note ? note.note : ""}
                                onChangeText={text => {
                                    if (note === undefined) {
                                        setNote({ note: text });
                                    } else {
                                        setNote(
                                            produce(note, draft => {
                                                draft.note = text;
                                            })
                                        );
                                    }
                                }}
                            />
                        </InputControl>

                        <Button
                            onPress={handleSubmit}
                            disabled={
                                note === undefined || note.note.trim() === ""
                            }
                        >
                            {note === undefined || note.id === undefined
                                ? t("common.create", "Create")
                                : t("common.save", "Save")}
                        </Button>
                    </Surface>
                ))}
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    icon: {
        color: theme.colors.black,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    surface: {
        marginBottom: theme.spacingScale * 2,
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
});
