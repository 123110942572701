// Note: This only runs in the browser
import { IDeviceProfile, getLanguage } from "./common";
import { v4 as uuidV4 } from "uuid";

const DEVICE_ID_KEY = "vp-device-id";

function getId() {
    // See if we have on in local storage, otherwise create a new one
    let id = window.localStorage.getItem(DEVICE_ID_KEY);
    if (id) {
        return id;
    }
    id = uuidV4();
    window.localStorage.setItem(DEVICE_ID_KEY, id);
    return id;
}

export async function getDeviceProfile(): Promise<IDeviceProfile> {
    return {
        id: getId(),
        osName: "web",
        osVersion: "web",
        appBuild: "web",
        appVersion: "web",
        hardwareProducer: "web",
        hardwareModel: "web",
        language: getLanguage(),
        capabilities: {},
        printer: {},
    };
}
