import { useMerchantConfig } from "@venuepos/react-common";
import { TokenResponse, getSumUpClient } from "lib";
import { useCallback, useState } from "react";

export function useSumUpClient() {
    const merchantConfig = useMerchantConfig();
    const [code, setCode] = useState("");

    const getAccessToken = useCallback(
        async (newCode: string) => {
            if (newCode === code) {
                return;
            }
            setCode(newCode);
            const client = getSumUpClient();

            const response = await client.post<TokenResponse>("/token", {
                grant_type: "authorization_code",
                client_id: merchantConfig.sumupClientId,
                client_secret: merchantConfig.sumupClientSecret,
                code: newCode,
            });

            if (response.status === 200 && response.data?.refresh_token) {
                return response.data.refresh_token;
            }
            return;
        },
        [code, merchantConfig.sumupClientId, merchantConfig.sumupClientSecret]
    );

    return { getAccessToken };
}
