import { StackScreenProps } from "@react-navigation/stack";
import {
    Alert,
    Button,
    DataTable,
    Headline,
    Loading,
    LoadingScreen,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useBookkeepingAccountQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { RootStackParamList } from "../../../../navigation";
import { useAdminSession } from "../../../../session";
import { AdminContainer } from "../../../index";
import { TransactionTableHeader } from "./transaction-table-header";
import { TransactionTableRow } from "./transaction-table-row";
import { SearchDefinition } from "lib";
import { Divider } from "react-native-paper";
import { useSearchDefinition } from "../../../../hooks";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "BOOKKEEPING_ACCOUNT_VIEW"
>;

export function BookkeepingAccountView({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "transactionAt",
        initialSortDirection: "DESC",
    });

    const defaultBookkeepingTransactionSearch: SearchDefinition = {
        amount: createSearchDefinition("amount"),
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultBookkeepingTransactionSearch, {
        buttonSize: "small",
        hideCloseButton: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const accountId = route.params.id;

    const { data, error, loading } = useBookkeepingAccountQuery({
        variables: {
            id: accountId,
            transactionPagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });
    const handleShowInvoice = useCallback(
        (invoiceId: string) => {
            navigate("INVOICE", { id: invoiceId });
        },
        [navigate]
    );

    if (!data) {
        return <LoadingScreen style="light" />;
    }

    const transactions = data.bookkeepingAccount.bookkeepingTransactions;

    return (
        <AdminContainer>
            <Surface>
                <View style={styles.row}>
                    <Headline>{data.bookkeepingAccount.name}</Headline>
                    <View style={styles.buttonContainer}>
                        <Button
                            type="secondary"
                            onPress={() => {
                                navigate("BOOKKEEPING_ACCOUNT_EDIT", {
                                    id: accountId,
                                });
                            }}
                            iconName="edit"
                        >
                            {t(
                                "backoffice.account.edit_account",
                                "Edit account"
                            )}
                        </Button>
                    </View>
                </View>
            </Surface>
            <Spacer space={2} />
            <Surface>
                <View style={[theme.styles.row, styles.container]}>
                    <Headline size="h5">
                        {t(
                            "backoffice.account.transactions.headline",
                            "Transactions"
                        )}
                    </Headline>
                    <Spacer space={1} />
                    {searchIndicator}
                </View>
                <Divider />
                <Spacer space={2} />
                {searchComponent}
                <DataTable>
                    <TransactionTableHeader
                        onSortChange={onSortChange}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                    />
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        transactions?.data.map(item => (
                            <TransactionTableRow
                                key={item.id}
                                item={item}
                                locale={locale as AvailableLocale}
                                currency={currency}
                                onShowInvoice={handleShowInvoice}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={transactions?.pagination.pages}
                        itemCount={transactions?.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
    },
    row: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },

    buttonContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        marginBottom: theme.spacingScale * 2,
    },
});
