import type { IDeviceProfile } from "./common";
import { getDeviceProfile } from "./device";
import { useState, useEffect } from "react";
import { addLoggingTag } from "../../hooks";

export function useDeviceProfile(): IDeviceProfile | undefined {
    const [deviceProfile, setDeviceProfile] = useState<
        IDeviceProfile | undefined
    >();

    // TODO: Store this globally for performance reasons
    // I think we probably need a session context for the app. Then it can use this.
    useEffect(() => {
        let didUnmount = false;
        getDeviceProfile()
            .then(dp => {
                if (!didUnmount) {
                    setDeviceProfile(dp);
                    addLoggingTag("serialNumber", dp.serialNumber);
                }
            })
            .catch(err => console.error(err));
        return () => {
            didUnmount = true;
        };
    }, []);

    return deviceProfile;
}

export * from "./device";
