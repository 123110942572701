import React from "react";
import { useTheme } from "../../theme";
import { Tooltip } from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome6Pro";
import { StyleProp, ViewStyle } from "react-native";

export function TooltipInfoIcon({
    title,
    style,
}: {
    title: string;
    style?: StyleProp<ViewStyle>;
}) {
    const theme = useTheme();
    return (
        <Tooltip title={title} leaveTouchDelay={0}>
            <Icon
                name="info-circle"
                style={[
                    {
                        paddingHorizontal: theme.spacingScale,
                        color: theme.colors.primary,
                    },
                    style,
                ]}
                size={15}
            />
        </Tooltip>
    );
}
