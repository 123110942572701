import React, { useState } from "react";
import { ColorPicker } from "../color-picker";
import { StyleSheet, View, ViewStyle, StyleProp } from "react-native";
import { Button } from "../button";
import { IconButton } from "../icon-button";
import { useTranslation } from "locales";
import { StyleFunction, useThemedStyle } from "../../theme";

// This could use a rewrite with a Portal to position the picker. Take a look at the Menu component in RN Paper.
export function ColorPickerButton({
    children,
    onChange,
    onCancel,
    style,
    buttonStyle,
    defaultColor,
    color,
    disabled = false,
}: {
    children: string;
    onChange?: (value?: string) => void;
    onCancel?: (value?: string) => void;
    style?: StyleProp<ViewStyle>;
    buttonStyle?: StyleProp<ViewStyle>;
    defaultColor?: string;
    color?: string;
    disabled: boolean;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [currentColor, setCurrentColor] = useState<string | undefined>(
        defaultColor
    );
    const [previousColor, setPreviousColor] = useState<string | undefined>(
        defaultColor
    );
    const [showPicker, setShowPicker] = useState<boolean>(false);

    return (
        <View style={style}>
            <Button
                onPress={() => {
                    setPreviousColor(color || currentColor);
                    setShowPicker(true);
                }}
                size="small"
                style={buttonStyle}
                disabled={disabled}
            >
                {children}
            </Button>
            {showPicker ? (
                <View style={styles.overlay}>
                    <View style={styles.firstLevelFlex}>
                        <View style={styles.secondLevelFlex}>
                            <ColorPicker
                                color={color || currentColor}
                                onChange={newColor => {
                                    setCurrentColor(newColor);
                                }}
                            />
                            <Button
                                onPress={() => {
                                    setShowPicker(false);
                                    if (onChange) {
                                        onChange(currentColor);
                                    }
                                }}
                                style={styles.confirmButton}
                            >
                                {t(
                                    "component.color_picker_button.approve_text",
                                    "Select this color"
                                )}
                            </Button>
                        </View>
                        <IconButton
                            name="close"
                            color={styles.cancelButton.color}
                            onPress={() => {
                                setCurrentColor(previousColor);
                                setShowPicker(!showPicker);
                                if (onCancel) {
                                    onCancel(previousColor);
                                }
                            }}
                            style={styles.cancelButton}
                        />
                    </View>
                </View>
            ) : null}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    overlay: {
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: [
            {
                // @ts-ignore
                translateX: "-50%",
            },
            {
                // @ts-ignore
                translateY: "-50%",
            },
        ],
    },
    cancelButton: {
        width: theme.borderRadiusButton,
        height: theme.borderRadiusButton,
        marginLeft: theme.spacingScale,
        borderWidth: StyleSheet.hairlineWidth,
        borderStyle: "solid",
        borderRadius: theme.borderRadiusButton / 2,
        borderColor: theme.colors.black,
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
    },
    confirmButton: {
        marginTop: theme.spacingScale,
    },

    firstLevelFlex: {
        flex: 1,
        flexDirection: "row",
    },
    secondLevelFlex: {
        flex: 1,
    },
});
