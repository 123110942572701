import React, { useCallback } from "react";
import {
    StyleFunction,
    Text,
    useThemedStyle,
    Icon,
} from "@venuepos/react-common";
import { View } from "react-native";
import { useTranslation } from "locales";

export function TagsType({ type }: { type: string }) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    const iconForTagType = useCallback(
        (tagType: string) => {
            switch (tagType) {
                case "NFC_CARD":
                case "NFC_WRISTBAND":
                    return <Icon style={styles.typeIcon} name="nfc" />;
                case "BARCODE":
                    return <Icon style={styles.typeIcon} name="barcode" />;
                default:
                    return null;
            }
        },
        [styles.typeIcon]
    );

    const translationForTagType = useCallback(
        (tagType: string) => {
            switch (tagType) {
                case "NFC_CARD":
                    return t("tag.type.card", "Card");
                case "NFC_WRISTBAND":
                    return t("tag.type.wristband", "Wristband");
                case "BARCODE":
                    return t("tag.type.card", "Card");
                default:
                    return t("common.unknown", "Unknown");
            }
        },
        [t]
    );

    return (
        <View>
            <Text>
                {translationForTagType(type)} ({iconForTagType(type)})
            </Text>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    typeIcon: {
        color: theme.colors.primary,
    },
});
