import {
    Alert,
    LoadingScreen,
    Surface,
    useAuth,
    useForm,
} from "@venuepos/react-common";
import {
    useMiscButtonsLazyQuery,
    useMiscButtonsSaveMutation,
} from "graphql-sdk";
import { schemaMiscButton } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { MiscButtonForm } from "../misc-button-form";
import { MiscButtonScreen } from "../misc-button-screen";

import type { MiscButtonsForm } from "../forms";
type ScreenProps = RootStackScreenProps<"MISC_BUTTON_EDIT">;

export function MiscButtonEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.misc_button.write");

    const miscButtonId = route.params.id;
    const [t] = useTranslation();
    const [miscButtonEdit] = useMiscButtonsSaveMutation();
    const { handleMutationError } = useHandleMutationError();

    const [getMiscButton, miscButton] = useMiscButtonsLazyQuery({
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const form = useForm<MiscButtonsForm>(schemaMiscButton, null);
    const [{ values }, { setDefaultValues }] = form;

    useEffect(() => {
        if (!miscButtonId) {
            navigate("MISC_BUTTONS");
        } else {
            getMiscButton({ variables: { id: miscButtonId } });
        }
    }, [miscButtonId, getMiscButton, navigate]);

    useEffect(() => {
        if (miscButton.data && miscButton.data.miscButtons) {
            const mb = miscButton.data.miscButtons;
            setDefaultValues({
                name: mb.name,
                buttons: JSON.parse(mb.buttons),
            });
        }
    }, [miscButton.data, setDefaultValues]);

    const handleEdit = useCallback(async () => {
        if (!miscButton.data || !miscButton.data.miscButtons || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await miscButtonEdit({
                    variables: {
                        id: miscButton.data!.miscButtons!.id,
                        miscButtons: {
                            name: values.name,
                            buttons: JSON.stringify(values.buttons),
                        },
                    },
                }),
            t(
                "backoffice.misc_button.saved",
                "Function Key Configuration Saved"
            )
        );
    }, [handleMutationError, miscButton.data, miscButtonEdit, t, values]);

    // Handles if error while fetching buttons
    if (miscButton.error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: miscButton.error.message,
                    }
                )}
            </Alert>
        );
    }

    if (!miscButton.data || !miscButton.data.miscButtons || !miscButtonId) {
        return <LoadingScreen style="light" />;
    }

    return (
        <MiscButtonScreen>
            <Surface>
                <MiscButtonForm
                    form={form}
                    onSubmit={handleEdit}
                    submitButton={["common.save", "Save"]}
                />
            </Surface>
        </MiscButtonScreen>
    );
}
