import axios, { AxiosInstance, AxiosResponse } from "axios";
import type { JsonBody, QueryParameters } from "./types";

type ClientParameters = {
    baseUrl?: string;
};

export type ClientResponse<T = any> = AxiosResponse<T>[
    | "statusText"
    | "config"
    | "request"];

export class Client {
    private _axios: AxiosInstance;

    constructor({ baseUrl }: ClientParameters) {
        this._axios = axios.create({
            baseURL: baseUrl,
            timeout: 5000,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    async get<T = any>(
        url: string,
        parameters?: QueryParameters
    ): Promise<ClientResponse<T> | undefined> {
        try {
            return await this._axios.get<T>(url, {
                params: parameters,
            });
        } catch (error: any) {
            console.error(`SumUp "GET ${url}" request failed.`);
            console.error(error);
            return error.response;
        }
    }

    async post<T = any>(
        url: string,
        body?: JsonBody
    ): Promise<AxiosResponse<ClientResponse<T>>["data"]> {
        try {
            return await this._axios.post<T>(url, body);
        } catch (error: any) {
            console.error(`SumUp "POST ${url}" request failed.`);
            console.error(error);
            return error.response?.data;
        }
    }
}

export function getSumUpClient(parameters?: ClientParameters) {
    if (!parameters) {
        parameters = { baseUrl: "https://api.sumup.com/" };
    }
    return new Client(parameters);
}

export function queryParameters(parameters?: {
    [key: string]: undefined | string | boolean | number;
}):
    | { params: { [key: string]: undefined | string | boolean | number } }
    | undefined {
    return parameters ? { params: parameters } : undefined;
}
