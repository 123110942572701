import {
    DateTimePickerInput,
    IconButton,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    Text,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useInvoiceCountLazyQuery } from "graphql-sdk";
import { dateToDateTime, nextDay, previousDay, today } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryTheme,
    VictoryTooltip,
} from "victory";
import { AdminContainer } from "../../container";

export function InvoiceCountScreen({}) {
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const [fromDate, setFromDate] = useState<Date>(today());
    const [allowDateForward, setAllowDateForward] = useState<boolean>(false);

    const BAR_CHART_PADDING = {
        top: 10,
        bottom: 40,
        left: 200,
        right: 10,
    };

    const [getInvoiceCount, { data, loading, refetch }] =
        useInvoiceCountLazyQuery({
            variables: { from: today(fromDate), to: nextDay(fromDate) },
        });

    const handleDateChange = useCallback(
        async (dateObj: Date) => {
            if (!dateObj === undefined) {
                return;
            }

            const from = today(dateObj);
            const to = nextDay(dateObj);

            setFromDate(from);

            if (to < new Date()) {
                setAllowDateForward(true);
            } else {
                setAllowDateForward(false);
            }

            await refetch({
                from: from,
                to: to,
            });
        },
        [refetch]
    );

    const dateBackward = useCallback(async () => {
        return await handleDateChange(previousDay(fromDate));
    }, [fromDate, handleDateChange]);
    const dateForward = useCallback(
        async () => await handleDateChange(nextDay(fromDate)),
        [fromDate, handleDateChange]
    );

    useEffect(() => {
        getInvoiceCount();
    }, [getInvoiceCount]);

    return (
        <AdminContainer>
            <PageTitle>
                {t("backoffice.invoice_count.invoice_count", "Invoice count")}
            </PageTitle>
            <Surface>
                <View style={styles.topRow}>
                    <View style={theme.styles.row}>
                        <DateTimePickerInput
                            dateTimeType="date"
                            label={t("common.date", "Date")}
                            onChangeDate={handleDateChange}
                            value={dateToDateTime(fromDate)}
                            maxDate={today()}
                        />
                        <IconButton
                            name="caret-left"
                            onPress={dateBackward}
                            color={theme.colors.secondary}
                            style={styles.calendarArrow}
                        />
                        <IconButton
                            name="caret-right"
                            onPress={dateForward}
                            color={theme.colors.secondary}
                            disabled={!allowDateForward}
                            style={styles.calendarArrow}
                        />
                    </View>
                </View>
                <View>
                    {loading ? (
                        <Loading />
                    ) : !data || data.invoiceCount.length === 0 ? (
                        <Text>{t("common.no_data", "No data")}</Text>
                    ) : (
                        <VictoryChart
                            horizontal
                            theme={VictoryTheme.material}
                            height={500}
                            width={1000}
                            padding={BAR_CHART_PADDING}
                            domainPadding={{ x: 10 }}
                        >
                            <VictoryAxis
                                dependentAxis
                                crossAxis={false} // Force showing of 0
                                label={t(
                                    "backoffice.invoice_count.invoice_count",
                                    "Invoice count"
                                )}
                                style={{ axisLabel: { padding: 25 } }}
                            />
                            <VictoryAxis />
                            <VictoryBar
                                labelComponent={
                                    <VictoryTooltip
                                        cornerRadius={0}
                                        flyoutStyle={{
                                            fill: theme.colors.white,
                                        }}
                                        constrainToVisibleArea={true}
                                    />
                                }
                                data={data?.invoiceCount || []}
                                labels={({ datum }) =>
                                    t(
                                        "backoffice.invoice_count.count",
                                        "Count: {{count}}",
                                        { count: datum.count }
                                    )
                                }
                                y="count"
                                x="merchant.name"
                                style={{
                                    data: {
                                        fill: theme.colors.primary,
                                    },
                                }}
                                events={[
                                    {
                                        target: "data",
                                        eventHandlers: {
                                            onMouseOver: () => {
                                                return [
                                                    {
                                                        target: "data",
                                                        mutation: () => ({
                                                            style: {
                                                                fill: theme
                                                                    .colors
                                                                    .secondary,
                                                            },
                                                        }),
                                                    },
                                                    {
                                                        target: "labels",
                                                        mutation: () => ({
                                                            active: true,
                                                        }),
                                                    },
                                                ];
                                            },
                                            onMouseOut: () => {
                                                return [
                                                    {
                                                        target: "data",
                                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                        mutation: () => {},
                                                    },
                                                    {
                                                        target: "labels",
                                                        mutation: () => ({
                                                            active: false,
                                                        }),
                                                    },
                                                ];
                                            },
                                        },
                                    },
                                ]}
                            />
                        </VictoryChart>
                    )}
                </View>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = (theme, _dimensions) => ({
    topRow: {
        height: 100,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacingScale * 2,
        paddingLeft: theme.spacingScale * 2,
        paddingRight: theme.spacingScale * 2,
    },
    calendarArrow: {
        marginTop: 28,
    },
});
