import {
    Button,
    DataTable,
    Icon,
    Loading,
    StyleFunction,
    Surface,
    useAuth,
    useConfirm,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQVatsQuery, useVatDeleteMutation, useVatsQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React, { useCallback, useEffect } from "react";
import { View } from "react-native";

import { AdminContainer } from "..";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { VatRow } from "./vat-row";

type ScreenProps = RootStackScreenProps<"VATS">;

export function VatListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.vat");

    const theme = useTheme();
    const [t] = useTranslation();
    const confirm = useConfirm();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);

    // GraphQL
    const { data, loading, refetch } = useVatsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });
    const [vatDelete] = useVatDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await confirm(
                    t("backoffice.vat.delete", "Delete vat?"),
                    t(
                        "backoffice.vat.delete_explain",
                        "Are you sure, you want to delete this?"
                    )
                ))
            ) {
                return;
            }

            await handleMutationError(
                async () =>
                    await vatDelete({
                        variables: {
                            id: id,
                        },
                    }),
                t("backoffice.vat.deleted", "Vat deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [confirm, handleMutationError, refetch, t, vatDelete]
    );

    const renderVatRow = useCallback(
        (item: GQVatsQuery["vats"]["data"][0]) => (
            <VatRow key={item.id} item={item} onDelete={handleDelete} />
        ),
        [handleDelete]
    );

    return (
        <AdminContainer>
            <View style={styles.container}>
                <View style={styles.actionButtonContainer}>
                    <Button
                        style={styles.button}
                        type="secondary"
                        onPress={() => {
                            navigate("VAT_CREATE");
                        }}
                    >
                        {t("common.create", "Create")}
                    </Button>
                </View>
            </View>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("backoffice.vat.name.header", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "percentage" && sortDirection
                            }
                            onPress={() => onSortChange("percentage")}
                        >
                            {t(
                                "backoffice.vat.percentage.header",
                                "Percentage"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("backoffice.vat.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {!loading ? data?.vats.data.map(renderVatRow) : <Loading />}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.vats.pagination.pages}
                        itemCount={data?.vats.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    button: {
        alignSelf: "flex-end",
        marginLeft: theme.spacingScale,
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
});
