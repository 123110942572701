import { DrawerContentComponentProps } from "@react-navigation/drawer";
import { DrawerActions, Link } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import React, { useCallback, useMemo, useState } from "react";
import { View } from "react-native";

import { IMenuItem, Logo, Spacer, StyleFunction, useThemedStyle } from "../../";
import { MenuItem } from "./menu-item";

export * from "./types";

export function MenuBar({
    items = [],
    navigation,
    children,
    logoLink,
}: {
    items: IMenuItem[];
    navigation: DrawerContentComponentProps["navigation"];
    children?: React.ReactNode;
    logoLink?: To;
}) {
    const styles = useThemedStyle(styleFunc);
    const [expandedItem, setExpandedItem] = useState<IMenuItem | undefined>(
        () => items.find(item => (item.isExpanded ? item : undefined))
    );

    const handleItemPress = useCallback(
        (item: IMenuItem) => {
            if (item === expandedItem) {
                // If the item is already expanded, close it.
                setExpandedItem(undefined);
            } else if (item.items && item.items.length) {
                // If this item has sub-items, then expand it.
                setExpandedItem(item);
            }

            navigation.dispatch(DrawerActions.closeDrawer());

            if (!item.link) {
                return;
            }

            if (typeof item.link === "function") {
                item.link();
                return;
            }

            navigation.navigate(item.link);
        },
        [expandedItem, navigation]
    );

    const handleItemExpand = useCallback((item: IMenuItem) => {
        if (!item.items || item.items.length === 0) {
            return;
        }

        setExpandedItem(prev => {
            if (prev === undefined || prev !== item) {
                return item;
            }
            return undefined;
        });
    }, []);

    const homeLink = useMemo(() => {
        if (!logoLink) {
            return <Logo type="LIGHT" />;
        }

        return (
            <Link to={logoLink}>
                <View>
                    <Logo type="LIGHT" />
                </View>
            </Link>
        );
    }, [logoLink]);

    return (
        <View style={styles.barContainer} testID="leftMenu">
            <Spacer space={3} />
            <View style={styles.logoItem}>{homeLink}</View>
            <Spacer space={3} />

            {children}

            {/* Check if one of the top level items are expanded - if that's the case, then the child items will be checked */}
            {items.map(item => (
                <MenuItem
                    onPress={handleItemPress}
                    onExpand={handleItemExpand}
                    key={item.name}
                    item={item}
                    isExpanded={
                        expandedItem?.name === item.name ||
                        item.isExpanded ||
                        false
                    }
                />
            ))}
            <Spacer space={2} />
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    barContainer: {
        minHeight: "100%",
        elevation: 8,
        backgroundColor: theme.colors.secondary,
    },

    logoItem: {
        alignItems: "center",
    },
});
