import { ApolloError } from "@apollo/client";
import {
    Alert,
    Headline,
    Loading,
    PrinterWrapper,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQInvoiceQuery, useInvoiceQuery } from "graphql-sdk";
import React from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import QRCode from "react-qr-code";

import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"INVOICE">;

export function InvoiceScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.invoice");

    const invoiceId = route.params.id;
    const { data, loading, error } = useInvoiceQuery({
        variables: {
            id: invoiceId,
        },
        fetchPolicy: "no-cache",
    });

    return (
        <AdminContainer>
            <Invoice loading={loading} error={error} data={data} />
        </AdminContainer>
    );
}

function Invoice({
    loading,
    error,
    data,
}: {
    loading: boolean;
    error?: ApolloError;
    data?: GQInvoiceQuery;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    if (!data?.invoice?.receipt) {
        return (
            <Alert type="warning">
                {t("backoffice.invoice.missing", "The receipt is missing.")}
            </Alert>
        );
    }

    const paymentReceipt = (receipt: string, key: string) => {
        return (
            <View style={styles.container} key={key}>
                <Surface style={styles.receiptWrapper}>
                    <Text style={styles.receipt}>{receipt}</Text>
                </Surface>
                <Spacer />
            </View>
        );
    };

    const venueAccessReceipt = (receipt: string, key: string) => {
        // Remove the special blocks that should be rendered as QR
        let cleanedReceipt = receipt?.replace(
            /(\{\{barcode:([\w-]+),(\w+),(\d+),(\d+),(\d+),(true|false)\}\})/,
            ""
        );
        cleanedReceipt = cleanedReceipt.replace(/(\{\{(center|h3)\}\})/g, "");

        let qrCode = "";
        const matches = receipt.match(
            /(\{\{barcode:([\w-]+),(\w+),(\d+),(\d+),(\d+),(true|false)\}\})/
        );
        if (matches && matches.length > 0) {
            qrCode = matches[2];
        }

        if (qrCode === "") {
            return null;
        }

        return (
            <View style={styles.container} key={key}>
                <Surface style={styles.receiptWrapper}>
                    <Text style={styles.receipt}>{cleanedReceipt}</Text>
                    <QRCode size={150} value={qrCode} level="Q" />
                </Surface>
                <Spacer />
            </View>
        );
    };

    return (
        <Surface>
            <Headline size="h4" style={styles.headline}>
                {t("common.receipt", "Receipt")}
            </Headline>

            <PrinterWrapper>
                <Surface style={styles.receiptWrapper}>
                    <Text style={styles.receipt}>{data?.invoice.receipt}</Text>
                </Surface>
                <Spacer />
                {data?.invoice?.payments?.map((payment, paymentIndex) => {
                    if (!payment.cardReceipt) {
                        return null;
                    }

                    return paymentReceipt(
                        payment.cardReceipt,
                        `payment-${paymentIndex}`
                    );
                })}

                {data.invoice.items?.map((item, itemIndex) =>
                    item.external?.map((external, externalIndex) => {
                        if (!external.receipt) {
                            return null;
                        }

                        if (external.integration === "venue-access") {
                            return venueAccessReceipt(
                                external.receipt,
                                `venue-access-${itemIndex}-${externalIndex}`
                            );
                        }

                        return (
                            <>
                                <Surface
                                    style={styles.receiptWrapper}
                                    key={`external-${itemIndex}-${externalIndex}`}
                                >
                                    <Text style={styles.receipt}>
                                        {external.receipt}
                                    </Text>
                                </Surface>
                                <Spacer />
                            </>
                        );
                    })
                )}
            </PrinterWrapper>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    headline: {
        marginBottom: 0,
    },
    receipt: {
        ...theme.fonts.monospace,
        fontSize: 14,
        textAlign: "center",
    },
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    receiptWrapper: {
        padding: theme.spacingScale * 2,
        borderWidth: 1,
        borderColor: theme.colors.grey250,
        borderRadius: 0,

        maxWidth: 400,
        elevation: 3,
    },
});
