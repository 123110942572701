import type { ValidationSchema } from "../validation";
import type { GQPasswordResetTokenInput } from "graphql-sdk";
import { IPasswordResetTokenCreate } from "../..";

export const schemaPasswordResetToken: ValidationSchema<GQPasswordResetTokenInput> =
    {
        userId: {
            required: true,
        },
    };

export const defaultPasswordResetToken: IPasswordResetTokenCreate = {
    token: "",
    userId: "",
    expiresAt: new Date(),
};
