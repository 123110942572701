import type { ReportDefinition, ReportRequest } from "lib";
import type { TFunction } from "i18next";
import { useTranslation } from "locales";
import {
    Headline,
    InputControl,
    MultiPicker,
    Picker,
    useThemedStyle,
} from "@venuepos/react-common";
import { produce } from "immer";
import React, { useCallback } from "react";
import { reportStyleFunc } from "./styles";
import { View } from "react-native";

function resolveGroupingValues(
    grouping: ReportDefinition["grouping"],
    t: TFunction
) {
    let unitGroupings = [];
    let temporalGroupings = [];

    let groupingName: keyof ReportDefinition["grouping"];
    for (groupingName in grouping) {
        const groupBy = grouping[groupingName];
        if (
            !groupBy ||
            ((groupBy.type === "LABEL" || groupBy.type === "CUSTOM") &&
                groupBy.internalUse)
        ) {
            continue;
        }

        switch (groupBy.type) {
            case "HOUR":
            case "DAY":
            case "MONTH":
            case "YEAR": {
                temporalGroupings.push({
                    label: t(groupBy.translationKey, groupBy.translationKey),
                    value: groupingName,
                });
                break;
            }

            case "CASH_REGISTER":
            case "CUSTOM":
            case "DEPARTMENT":
            case "USER": {
                unitGroupings.push({
                    label: t(groupBy.translationKey, groupBy.translationKey),
                    value: groupingName,
                });
                break;
            }
        }
    }

    return { temporalGroupings, unitGroupings };
}

export function Grouping(props: {
    grouping: ReportDefinition["grouping"];
    selectedGrouping: ReportRequest<ReportDefinition>["grouping"];
    onGroupingChange: (
        values: ReportRequest<ReportDefinition>["grouping"]
    ) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(reportStyleFunc);
    const { unitGroupings, temporalGroupings } = resolveGroupingValues(
        props.grouping,
        t
    );
    const returnValue: JSX.Element[] = [];

    const handleTemporalGroupingValueChange = useCallback(
        (grouping: (string | number)[], value: string | number) => {
            props.onGroupingChange(
                produce(props.selectedGrouping, draft => {
                    // reset previously selected values
                    grouping.map(item => {
                        delete draft[item];
                    });

                    if (!value) {
                        return;
                    }

                    draft[value] = {};
                })
            );
        },
        [props]
    );

    const handleUnitGroupingValueChange = useCallback(
        (grouping: (string | number)[], values: (string | number)[]) => {
            props.onGroupingChange(
                produce(props.selectedGrouping, draft => {
                    grouping.map(item => {
                        delete draft[item];
                    });

                    values.map(val => {
                        draft[val] = {};
                    });
                })
            );
        },
        [props]
    );

    if (temporalGroupings.length === 0 && unitGroupings.length === 0) {
        return null;
    }

    const availableTemporalGrouping = temporalGroupings.map(item => item.value);

    returnValue.push(
        <InputControl
            style={styles.inputCell}
            key="time_grouping"
            description={t(
                "report.description.period",
                "Groups the report data by the selected period"
            )}
        >
            <Picker
                label={t("report.period", "Period")}
                selectedValue={
                    temporalGroupings.length === 1
                        ? temporalGroupings[0].value
                        : Object.keys(props.selectedGrouping)[0]
                }
                onValueChange={value =>
                    handleTemporalGroupingValueChange(
                        availableTemporalGrouping,
                        value
                    )
                }
                disabled={temporalGroupings.length === 0}
            >
                <Picker.Item
                    label={t("report.none", "None")}
                    value=""
                    key="none"
                />
                {temporalGroupings.map(
                    (timeGroupBy: { label: string; value: string }) => (
                        <Picker.Item
                            label={timeGroupBy.label}
                            value={timeGroupBy.value}
                            key={timeGroupBy.value}
                        />
                    )
                )}
            </Picker>
        </InputControl>
    );

    const availableUnitGroupings = unitGroupings.map(item => item.value);
    const selectedUnitGroupings = Object.keys(props.selectedGrouping).filter(
        item => availableUnitGroupings.includes(item)
    );

    returnValue.push(
        <InputControl
            style={styles.inputCell}
            key="unit_grouping"
            description={t(
                "report.description.units",
                "Groups your report data by the selected units"
            )}
        >
            <MultiPicker
                label={t("report.units", "Units")}
                placeholder={t("report.none", "None")}
                disabled={unitGroupings.length === 0}
                values={unitGroupings}
                selectedValues={selectedUnitGroupings}
                onValueChange={values =>
                    handleUnitGroupingValueChange(
                        availableUnitGroupings,
                        values
                    )
                }
            />
        </InputControl>
    );

    return (
        <View>
            <Headline size="h5">{t("report.grouping", "Grouping")}</Headline>
            <View style={styles.row}>{returnValue}</View>
        </View>
    );
}
