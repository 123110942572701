import { Link } from "@react-navigation/native";
import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import type { GQAccountsQuery } from "graphql-sdk";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import type { AvailableLocale } from "locales";
import React, { useCallback } from "react";
import { useTranslation } from "locales";

export function AccountItem({
    item,
    onSelect,
    locale,
    currency,
}: {
    item: GQAccountsQuery["accounts"]["data"][0];
    onSelect: (itemId: string) => void;
    locale: AvailableLocale;
    currency: SupportedCurrenciesType;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const handleSelect = useCallback(() => {
        onSelect(item.id);
    }, [item.id, onSelect]);

    return (
        <DataTable.Row onPress={handleSelect} testID={"account:" + item.name}>
            <DataTable.Cell>
                <Link
                    to={{ screen: "ACCOUNT_VIEW", params: { id: item.id } }}
                    style={styles.textLink}
                    numberOfLines={1}
                >
                    {item.name}
                </Link>
            </DataTable.Cell>
            <DataTable.Cell>
                {t(
                    "account.status." + item.status,
                    "account.status." + item.status
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    printCurrency: true,
                    locale: locale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={styles.iconColumn.color}
                    name="edit"
                    onPress={handleSelect}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
});
