import React from "react";
import { View } from "react-native";
import {
    Button,
    Headline,
    InputControl,
    Spacer,
    Text,
    TextInput,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import type { Form, StyleFunction } from "@venuepos/react-common";
import type { LoginFormData } from "./index";
import { TouchableOpacity } from "react-native-gesture-handler";

export function LoginForm({
    form,
    handleSubmit,
    authenticated,
}: {
    form: Form<LoginFormData>;
    handleSubmit: () => void;
    authenticated: boolean | undefined;
}) {
    const theme = useTheme();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue }] = form;

    if (!values) {
        return null;
    }

    const handlePress = () => {
        window.location.href = "/request-token";
    };

    return (
        <>
            <Headline size="h2">
                {t("authentication.user_login", "Login")}
            </Headline>
            <Text>
                {t("authentication.description", "Insert login information")}
            </Text>
            <Spacer space={2} />
            <View />
            {authenticated !== false ? null : (
                <Text style={{ color: theme.colors.error }}>
                    {t(
                        "authentication.user_login_failed",
                        "Could not login. Try again."
                    )}
                </Text>
            )}
            <InputControl error={errors.username}>
                <TextInput
                    required={true}
                    testID="username"
                    label={t(
                        "authentication.user_username_placeholder",
                        "Username"
                    )}
                    autoFocus
                    onChangeText={text => setValue("username", text)}
                    value={values.username}
                    onSubmitEditing={handleSubmit}
                />
            </InputControl>
            <InputControl error={errors.password} style={styles.passwordInput}>
                <TextInput
                    required={true}
                    testID="password"
                    label={t(
                        "authentication.user_password_placeholder",
                        "Password"
                    )}
                    secureTextEntry={true}
                    onChangeText={text => setValue("password", text)}
                    value={values.password}
                    onSubmitEditing={handleSubmit}
                />
            </InputControl>
            <TouchableOpacity onPress={handlePress}>
                <Text style={styles.resetPasswordLink}>
                    {t("reset-password", "Forgotten password?")}
                </Text>
            </TouchableOpacity>
            <Button type="primary" onPress={handleSubmit} testID="button:login">
                {t("authentication.button_login", "Login")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    resetPasswordLink: {
        ...theme.styles.link,
        textAlign: "right",
        marginBottom: 20,
        fontSize: 12,
        fontWeight: "bold",
    },
    passwordInput: {
        marginBottom: 0,
    },
});
