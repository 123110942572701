import type { Schema, SchemaValuesPrimitiveType, SchemaValuesType } from "lib";
import { useMemo } from "react";
import { useApolloClient, ApolloClient, gql } from "@apollo/client";
import { produce } from "immer";

// Adds value resolvers to given schema
export function useSchemaValuesResolvers<
    EntityType extends { [key: string]: any }
>(schemaArg: Schema<EntityType>): Schema<EntityType> {
    const client = useApolloClient();

    return useMemo(() => {
        return produce(schemaArg, (schema: Schema<EntityType>) => {
            addPaginationResolver(
                client,
                schema,
                "defaultDepartment",
                "departments"
            );

            addPaginationResolver(
                client,
                schema,
                "buttonLayout",
                "productLayouts",
                "name"
            );

            addPaginationResolver(
                client,
                schema,
                "tableLayout",
                "tableLayouts",
                "name"
            );

            addPaginationResolver(
                client,
                schema,
                "paymentOptions",
                "paymentConfigurations",
                "name"
            );
            addPaginationResolver(
                client,
                schema,
                "miscButtons",
                "allMiscButtons",
                "name"
            );
            addPaginationResolver(
                client,
                schema,
                "receiptPrinter",
                "printersForCashRegister",
                "name",
                "name",
                "id",
                [
                    { name: "class", type: "String", value: "Generic" },
                    { name: "entityId", type: "String" },
                ]
            );
            addPaginationResolver(
                client,
                schema,
                "summaryPrinter",
                "printersForCashRegister",
                "name",
                "name",
                "id",
                [
                    { name: "class", type: "String", value: "Generic" },
                    { name: "entityId", type: "String" },
                ]
            );
            addPaginationResolver(
                client,
                schema,
                "ticketPrinter",
                "printersForCashRegister",
                "name",
                "name",
                "id",
                [
                    { name: "class", type: "String", value: "Ticket" },
                    { name: "entityId", type: "String" },
                ]
            );
            addPaginationResolver(
                client,
                schema,
                "softpayCloudTerminalAppId",
                "softpayCloudTerminals",
                "name",
                "name",
                "appId"
            );
            addPaginationResolver(client, schema, "defaultVat", "vats", "name");

            return schema;
        }) as Schema<EntityType>;
    }, [client, schemaArg]);
}

// GraphQL resolver for entities using the generic pagination API
async function addPaginationResolver<EntityType extends { [key: string]: any }>(
    client: ApolloClient<object>,
    schema: Schema<EntityType>,
    property: string,
    gqlResolver: string,
    sortBy: string = "createdAt",
    entityLabel: string = "name",
    entityValue: string = "id",
    parameters: { name: string; type: string; value?: string }[] = []
) {
    addResolver(schema, property, async (entityId?: string) => {
        let gqlResolverWithParameters = gqlResolver;
        if (parameters !== undefined && parameters.length > 0) {
            const parameterList = [];
            for (let i = 0; i < parameters.length; i++) {
                parameterList.push(
                    `$${parameters[i].name}: ${parameters[i].type}`
                );
            }
            gqlResolverWithParameters += "(" + parameterList.join(",") + ")";
        }
        let gqlParameter = "";
        if (parameters !== undefined) {
            for (let i = 0; i < parameters.length; i++) {
                gqlParameter += `${parameters[i].name}: $${parameters[i].name},`;
            }
        }
        const query = `
            query ${gqlResolverWithParameters} {
                ${gqlResolver}(${gqlParameter} pagination: {
                    pageSize: 999999,
                    sort: "${sortBy}",
                    sortDirection: "ASC"
                }) {
                    data {
                    ${entityLabel} ${entityValue}
                    }
                }
            }`;

        const variables: Record<string, any> = {};
        if (parameters !== undefined && parameters.length > 0) {
            for (let i = 0; i < parameters.length; i++) {
                if (parameters[i].value !== undefined) {
                    variables[parameters[i].name] = parameters[i].value;
                }
            }
        }
        // Always add the entityId if set.
        if (entityId !== undefined) {
            variables.entityId = entityId;
        }

        const gqlQuery = await client.query({
            query: gql(query),
            fetchPolicy: "no-cache",
            variables,
        });
        return (
            gqlQuery.data[gqlResolver].data.map((item: any) => ({
                label: item[entityLabel],
                value: item[entityValue],
            })) || []
        );
    });
}

// Adds given `resolver` for given `property` to the given `schema, only if the `property` exist on that `schema`
function addResolver<EntityType extends { [key: string]: any }>(
    schema: Schema<EntityType>,
    property: string,
    resolver: (
        entityId?: string
    ) => Promise<(SchemaValuesPrimitiveType | SchemaValuesType)[]>
): void {
    if (schema[property as keyof EntityType]) {
        schema[property as keyof EntityType].valuesResolve = resolver;
    }
}
