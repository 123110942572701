import React, { ReactNode } from "react";
import {
    View,
    TextStyle,
    ViewStyle,
    Pressable,
    StyleSheet,
} from "react-native";
import { StyleData, StyleFunction, useThemedStyle } from "../../theme";
import { IconButton } from "../icon-button";
import { Headline } from "../headline";

export function ModalWrapper({
    title,
    titleStyle,
    backgroundColor,
    children,
    onClose,
    showCloseIcon = true,
    darkMode = false,
    testID,
}: {
    children: ReactNode;
    onClose: (val?: any) => any;
    title?: string;
    titleStyle?: TextStyle;
    backgroundColor?: ViewStyle["backgroundColor"];
    showCloseIcon?: boolean;
    darkMode?: boolean;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <Pressable
            style={[
                styles.centerContainer,
                darkMode && styles.lightCenterContainer,
            ]}
            testID={testID}
            onPress={() => {
                // ignore presses inside the modal
            }}
        >
            <View
                style={[
                    styles.container,
                    darkMode ? styles.darkContainer : undefined,
                    !backgroundColor ? undefined : { backgroundColor },
                ]}
            >
                {(title || showCloseIcon) && (
                    <View style={styles.modalHeader}>
                        <Headline
                            style={[
                                styles.modalTitle,
                                darkMode ? styles.lightModalTitle : undefined,
                                titleStyle,
                            ]}
                            size="h4"
                            numberOfLines={2}
                            adjustsFontSizeToFit={true}
                        >
                            {title}
                        </Headline>
                        {showCloseIcon && (
                            <CloseIcon
                                color={
                                    darkMode
                                        ? styles.lightModalTitle.color
                                        : undefined
                                }
                                onClose={onClose}
                            />
                        )}
                    </View>
                )}
                {children}
            </View>
        </Pressable>
    );
}

function CloseIcon({
    onClose,
    color,
}: {
    onClose: () => void;
    color?: TextStyle["color"];
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <IconButton
            size="large"
            color={color || styles.icon.color}
            name="times-circle"
            onPress={onClose}
            style={styles.close}
            testID="closeIcon"
        />
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => {
    let styling: StyleData = {
        modalHeader: {
            flexDirection: "row",
            marginVertical: theme.spacingScale,
            marginHorizontal: theme.spacingScale * 2,
            alignItems: "center",
        },
        modalTitle: {
            flex: 1,
            marginBottom: 0,
            color: theme.colors.textDark,
        },
        lightModalTitle: {
            color: theme.colors.textLight,
        },
        centerContainer: {
            alignSelf: "center",
            margin: theme.spacingScale * 4,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: theme.colors.white,
            borderRadius: theme.borderRadius,
            backgroundColor: theme.colors.white,
        },
        lightCenterContainer: {
            borderColor: theme.colors.grey100,
            borderWidth: StyleSheet.hairlineWidth,
        },
        container: {
            alignSelf: "center",
            backgroundColor: theme.colors.white,
            borderRadius: theme.borderRadius,
        },
        darkContainer: {
            backgroundColor: theme.colors.secondary,
        },
        icon: {
            color: theme.colors.primary,
        },
        close: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            paddingLeft: 6,
        },
    };

    if (dimensions.width <= theme.breakpoint.portraitWidth) {
        styling = {
            ...styling,

            centerContainer: {
                ...styling.centerContainer,
                margin: 0,
                marginTop: theme.spacingScale,
                marginBottom: theme.spacingScale,
                marginHorizontal: 0,
            },
        };
    }

    return styling;
};
