import { useFocusEffect } from "@react-navigation/native";
import {
    Loading,
    Surface,
    Tabs,
    useAuth,
    useForm,
} from "@venuepos/react-common";
import {
    useLayoutQuery,
    useLayoutSaveMutation,
    useTableLayoutFormQuery,
} from "graphql-sdk";
import {
    GridDimensions,
    ILayoutInput,
    schemaEditLayout,
    TableSection,
} from "lib";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { rinseLayoutData } from "../../functions";
import { LayoutForm } from "../../layout-form";
import { LayoutScreen } from "../../layout-screen";
import { LayoutEditor } from "./layout-editor";

type ScreenProps = RootStackScreenProps<"TABLE_LAYOUT_EDIT">;

export function TableLayoutEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.layout.write");

    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [layoutEdit] = useLayoutSaveMutation();
    const layoutId = route.params.id;

    const { data, loading, refetch } = useLayoutQuery({
        variables: {
            id: layoutId,
        },
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const form = useForm<ILayoutInput>(schemaEditLayout, null);
    const [{ values }, { setDefaultValues }] = form;
    const { data: formData } = useTableLayoutFormQuery();

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    useEffect(() => {
        if (data && data.layout) {
            setDefaultValues({
                name: data.layout.name,
                data: JSON.parse(data.layout.data),
            });
        }

        // Reset the state when the screen is unloaded
        return () => {
            setDefaultValues({
                name: "",
                data: [],
            });
        };
    }, [data, setDefaultValues]);

    const redirect = useCallback(() => {
        // If the page was loaded with a referrer, then return to that referrer.
        // The referrer could be e.g. CASH_REGISTER_EDIT with a specific id, but could also be to the general MERCHANT_SETTINGS,
        // so the id could be missing from the referrer argument
        if (!route.params.referrer || !route.params.referrer.route) {
            return;
        }

        if (route.params.referrer.route === "MERCHANT_SETTINGS") {
            navigate("MERCHANT_SETTINGS");
        }
    }, [navigate, route.params.referrer]);

    // Used with the LayoutEditor's save method. When the user clicks "Save Layout", this is triggered.
    const saveGridDetails = useCallback(
        async (layoutData: TableSection[]) => {
            if (
                !layoutData ||
                !data ||
                !data.layout ||
                !values ||
                !values.data
            ) {
                return;
            }

            // remove metaData object from buttons. There is no need to save them.
            const updatedNewLayoutData = layoutData.map(rinseLayoutData);

            await handleMutationError(
                async () => {
                    await layoutEdit({
                        variables: {
                            id: layoutId,
                            layout: {
                                name: values.name,
                                data: JSON.stringify(updatedNewLayoutData),
                            },
                        },
                    });
                },
                t("common.saved", "Saved"),
                redirect
            );
        },
        [data, values, handleMutationError, t, redirect, layoutEdit, layoutId]
    );

    // Used with the regular form (layout name, type, etc.)
    const saveLayoutDetails = useCallback(async () => {
        if (!data || !data.layout || !values) {
            return;
        }

        // remove metaData object from buttons. There is no need to save them.
        const updatedNewLayoutData = values.data.map(rinseLayoutData);

        await handleMutationError(
            async () => {
                await layoutEdit({
                    variables: {
                        id: layoutId,
                        layout: {
                            name: values.name,
                            data: JSON.stringify(updatedNewLayoutData),
                        },
                    },
                });
            },
            t("common.saved", "Saved"),
            redirect
        );
    }, [data, values, handleMutationError, t, redirect, layoutEdit, layoutId]);

    const gridDimensions: GridDimensions = useMemo(() => {
        if (
            !data ||
            !data.layout ||
            !data?.layout?.columns ||
            !data?.layout.rows
        ) {
            return { columns: 0, rows: 0 };
        }

        return { columns: data.layout.columns, rows: data.layout.rows };
    }, [data]);

    return (
        <LayoutScreen>
            <Surface>
                <Tabs
                    labels={[
                        t("backoffice.layouts.layout", "Layout"),
                        t("backoffice.details", "Details"),
                    ]}
                >
                    {loading ||
                    !data ||
                    !data.layout ||
                    !values ||
                    !values.data ? (
                        <Loading />
                    ) : (
                        <LayoutEditor
                            layoutData={values.data}
                            onSubmit={saveGridDetails}
                            dimensions={gridDimensions}
                        />
                    )}
                    {!formData ? (
                        <Loading />
                    ) : (
                        <LayoutForm
                            form={form}
                            data={formData}
                            onSubmit={saveLayoutDetails}
                            submitButton={["common.save", "Save"]}
                        />
                    )}
                </Tabs>
            </Surface>
        </LayoutScreen>
    );
}
