import { View } from "react-native";
import {
    Button,
    InputControl,
    ModalWrapper,
    Picker,
    StyleFunction,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useState } from "react";
import { useTranslation } from "locales";
import type { Currency } from "lib";

type AddCurrencyModalProps = {
    headline: string;
    currencies: Currency[];
    onClose: (type: Currency | undefined) => void;
};

function AddCurrencyModal({
    currencies,
    headline,
    onClose,
}: AddCurrencyModalProps) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [type, setType] = useState<Currency>(currencies[0]);

    return (
        <ModalWrapper title={headline} onClose={() => onClose(undefined)}>
            <View style={styles.container} testID="currency:modal:add">
                <InputControl>
                    <Picker
                        onValueChange={value => {
                            setType(value as Currency);
                        }}
                        selectedValue={type as string}
                        testID="currency:modal:pickCurrency"
                    >
                        {currencies.map(currency => (
                            <Picker.Item
                                key={currency}
                                value={currency}
                                label={currency}
                            />
                        ))}
                    </Picker>
                </InputControl>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => {
                            onClose(type);
                        }}
                        testID="currency:modal:button:add"
                    >
                        {t("common.add", "Add")}
                    </Button>
                </View>
            </View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        minWidth: 300,
        maxWidth: theme.dimensions.maxFormWidth,
        padding: theme.spacingScale * 2,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
});

export function useAddCurrencyModal() {
    const { render } = useModal<Currency | undefined>();
    return (props: Omit<AddCurrencyModalProps, "onClose">) =>
        render(onClose => <AddCurrencyModal {...props} onClose={onClose} />);
}
