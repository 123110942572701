import React from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { IconButton, Table, Text, useToast } from "@venuepos/react-common";
import type { GQCashRegisterFragment } from "graphql-sdk";
import { useTranslation } from "locales";

export function DeviceInfo({
    device,
    cashRegister,
}: {
    device: Exclude<GQCashRegisterFragment["device"], undefined | null>;
    cashRegister: GQCashRegisterFragment;
}) {
    const [t] = useTranslation();
    const toast = useToast();

    return (
        <Table>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.serial_number", "Serial number")}
                </Table.Title>
                <Table.Cell>
                    <>
                        <Text>{device.serialNumber}</Text>
                        {device.serialNumber ? (
                            <IconButton
                                size="small"
                                onPress={() => {
                                    Clipboard.setString(
                                        device?.serialNumber as string
                                    );
                                    toast.info(
                                        t(
                                            "backoffice.info.text_copied",
                                            "Text copied"
                                        )
                                    );
                                }}
                                name="copy"
                                color="black"
                            />
                        ) : null}
                    </>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.common.id", "Cash register id")}
                </Table.Title>
                <Table.Cell>
                    <>
                        <Text numberOfLines={1} ellipsizeMode="middle">
                            {cashRegister.id}
                        </Text>
                        <IconButton
                            size="small"
                            onPress={() => {
                                Clipboard.setString(cashRegister.id);
                                toast.info(
                                    t(
                                        "backoffice.info.text_copied",
                                        "Text copied"
                                    )
                                );
                            }}
                            name="copy"
                            color="black"
                        />
                    </>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.id", "ID")}
                </Table.Title>
                <Table.Cell>
                    <>
                        <Text numberOfLines={1} ellipsizeMode="middle">
                            {device.id}
                        </Text>
                        <IconButton
                            size="small"
                            onPress={() => {
                                Clipboard.setString(device.id);
                                toast.info(
                                    t(
                                        "backoffice.info.text_copied",
                                        "Text copied"
                                    )
                                );
                            }}
                            name="copy"
                            color="black"
                        />
                    </>
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.os_name", "OS name")}
                </Table.Title>
                <Table.Cell>{device.osName}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.os_version", "OS version")}
                </Table.Title>
                <Table.Cell>{device.osVersion}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.app_version", "App version")}
                </Table.Title>
                <Table.Cell>{device.appVersion}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.app_build", "App build")}
                </Table.Title>
                <Table.Cell>{device.appBuild}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t(
                        "backoffice.device_info.hardware_model",
                        "Hardware model"
                    )}
                </Table.Title>
                <Table.Cell>{device.hardwareModel}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t(
                        "backoffice.device_info.hardware_manufacturer",
                        "Hardware manufacturer"
                    )}
                </Table.Title>
                <Table.Cell>{device.hardwareProducer}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.mac_address", "Mac address")}
                </Table.Title>
                <Table.Cell>{device.macAddress}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.language", "Language")}
                </Table.Title>
                <Table.Cell>{device.language}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t(
                        "backoffice.device_info.is_using_auto_time",
                        "Using auto time"
                    )}
                </Table.Title>
                <Table.Cell>
                    {device.isUsingAutoTime === null
                        ? t("common.unknown", "Unknown")
                        : device.isUsingAutoTime
                        ? t("common.yes", "Yes")
                        : t("common.no", "No")}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t(
                        "backoffice.device_info.is_using_auto_time_zone",
                        "Using auto time zone"
                    )}
                </Table.Title>
                <Table.Cell>
                    {device.isUsingAutoTimeZone === null
                        ? t("common.unknown", "Unknown")
                        : device.isUsingAutoTime
                        ? t("common.yes", "Yes")
                        : t("common.no", "No")}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Title>
                    {t("backoffice.device_info.time_zone", "Time zone")}
                </Table.Title>
                <Table.Cell>{device.timeZone}</Table.Cell>
            </Table.Row>
        </Table>
    );
}
