import { useNavigation, useRoute, RouteProp } from "@react-navigation/native";
import { Spacer, StyleFunction, useSearch } from "@venuepos/react-common";
import {
    Alert,
    Button,
    DataTable,
    Icon,
    Loading,
    Surface,
    useAuth,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useCustomerGroupDeleteMutation,
    useCustomerGroupsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackParamList } from "../../../../navigation";
import { Row } from "./row";
import { SearchDefinition } from "lib";
import { useSearchDefinition } from "../../../../hooks";

export function CustomerGroupsTab() {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const { navigate } = useNavigation();
    const route = useRoute<RouteProp<RootStackParamList, "CUSTOMER_GROUPS">>();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [t] = useTranslation();
    const { createSearchDefinition } = useSearchDefinition();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();

    const defaultCustomerGroupSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultCustomerGroupSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { handleMutationError } = useHandleMutationError();

    // GraphQL
    const [deleteCustomerGroup] = useCustomerGroupDeleteMutation();
    const { data, loading, error, refetch } = useCustomerGroupsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("CUSTOMER_GROUP_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => await deleteCustomerGroup({ variables: { id } }),
                t("common.deleted", "Deleted"),
                () => {
                    navigate("ACCOUNT_PAYMENTS", { refetch: true });
                }
            );
        },
        [deleteCustomerGroup, handleMutationError, navigate, t]
    );

    return (
        <>
            <View style={[theme.styles.row, styles.container]}>
                <Button
                    type="secondary"
                    onPress={() => {
                        navigate("CUSTOMER_GROUP_CREATE");
                    }}
                >
                    {t("common.create", "Create")}
                </Button>
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <Loading />
                    ) : (
                        data?.customerGroups.data.map((item, index) => (
                            <Row
                                item={item}
                                onSelect={() => handleEdit(item.id)}
                                onDelete={() => handleDelete(item.id)}
                                key={index.toString()}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.customerGroups.pagination.pages}
                        itemCount={data?.customerGroups.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    button: {
        alignSelf: "flex-end",
        marginLeft: theme.spacingScale * 2,
    },
    iconColumn: {
        flexBasis: 100,
        flexShrink: 0,
        flexGrow: 0,
    },
});
