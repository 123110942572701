import React, { useCallback, useMemo } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import {
    Button,
    Headline,
    InputLabel,
    RemovableRow,
    StyleFunction,
    Surface,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { produce } from "immer";
import {
    DateTimeType,
    DateTimeTypes,
    getCurrentDateTime,
    camelToSnake,
} from "lib";
import { DateTimeConstraintInput } from "./date-time-constraint-input";
import { useAddModal } from "./add-modal";
import { SubFormBoxInactive } from "./sub-form-box";

export type DateTimeConstraint = {
    type: keyof DateTimeTypes;
    from: Partial<DateTimeType>;
    to: Partial<DateTimeType>;
};

export function assignConstraint<T extends keyof DateTimeTypes>(
    type: T,
    from: DateTimeTypes[T]["from"],
    to: DateTimeTypes[T]["to"]
): DateTimeConstraint {
    return {
        type,
        from: from as any,
        to: to as any,
    };
}

export function DateTimeConstraintsForm({
    constraints,
    onConstraintsChange,
}: {
    constraints: DateTimeConstraint[];
    onConstraintsChange: (constraints: DateTimeConstraint[]) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const addConstraintModal = useAddModal<DateTimeTypes>();

    const constraintTypes = useMemo(() => {
        const types = {
            dateTime: t("date_time.types.date_time", "Date and time"),
            date: t("date_time.types.date", "Date"),
            time: t("date_time.types.time", "Time"),
            week: t("date_time.types.week", "Week"),
            weekDay: t("date_time.types.week_day", "Week day"),
        };

        for (const type in types) {
            if (constraintIncludes(constraints, type as keyof DateTimeTypes)) {
                delete (types as any)[type];
            }
        }

        return types;
    }, [constraints, t]);

    const addConstraint = useCallback(async () => {
        const type = await addConstraintModal({
            headline: t(
                "backoffice.discount_form.add_date_time_constraint_modal_headline",
                "Add new constraint"
            ),
            types: constraintTypes,
        });

        if (type === undefined) {
            return;
        }

        const from = getDefaultDateTimeValue(type);
        const to = from;

        onConstraintsChange(
            produce(constraints, draft => {
                draft.push({
                    type,
                    from,
                    to,
                });
            })
        );
    }, [
        addConstraintModal,
        constraintTypes,
        constraints,
        onConstraintsChange,
        t,
    ]);

    const removeConstraint = useCallback(
        (type: keyof DateTimeTypes) => {
            onConstraintsChange(
                produce(constraints, draft => {
                    draft.splice(
                        draft.findIndex(itr => itr.type === type),
                        1
                    );
                })
            );
        },
        [constraints, onConstraintsChange]
    );

    const onConstraintChange = useCallback(
        (constraint: DateTimeConstraint) => {
            onConstraintsChange(
                produce(constraints, draft => {
                    for (const i in draft) {
                        if (draft[i].type === constraint.type) {
                            draft[i] = constraint;
                            break;
                        }
                    }
                })
            );
        },
        [constraints, onConstraintsChange]
    );

    if (constraints.length === 0) {
        return (
            <SubFormBoxInactive
                text={t(
                    "backoffice.discount_form.add_date_and_time_constraint",
                    "Add date / time constraint"
                )}
                onPress={async () => {
                    await addConstraint();
                }}
            />
        );
    }

    return (
        <Surface style={styles.surface}>
            <Headline size="h5" style={styles.headline}>
                {t(
                    "backoffice.discount_form.date_time_constraints",
                    "Date and time constraints"
                )}
            </Headline>

            {constraints.map(constraint => (
                <View key={constraint.type} style={styles.constraintContainer}>
                    <View style={styles.labelContainer}>
                        <InputLabel>
                            {t(
                                `date_time.types.${camelToSnake(
                                    constraint.type
                                )}`
                            )}
                        </InputLabel>
                        <Text style={styles.labelHint}>{` (${t(
                            "backoffice.discount_form.from_to",
                            "from, to"
                        )})`}</Text>
                    </View>
                    <RemovableRow
                        onRemove={() => {
                            removeConstraint(constraint.type);
                        }}
                    >
                        <DateTimeConstraintInput
                            constraint={constraint}
                            onConstraintChange={onConstraintChange}
                        />
                    </RemovableRow>
                </View>
            ))}

            <View style={styles.addButtonContainer}>
                <Button
                    onPress={addConstraint}
                    disabled={Object.keys(constraintTypes).length === 0}
                    variant="text"
                    size="small"
                    iconName="plus"
                >
                    {t(
                        "backoffice.discount_form.add_date_time",
                        "Add constraint"
                    )}
                </Button>
            </View>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    surface: {
        marginBottom: theme.spacingScale * 4,
    },
    headline: {
        marginBottom: 0,
    },
    constraintContainer: {
        marginTop: theme.spacingScale,
    },
    controls: {
        flexDirection: "row",
    },
    removeButton: {
        marginTop: 2,
    },
    labelContainer: {
        flexDirection: "row",
        marginBottom: theme.spacingScale,
    },
    labelHint: {
        ...theme.fonts.thin,
        fontSize: 14,
        lineHeight: 16,
    },
    addButtonContainer: {
        alignItems: "flex-start",
        marginTop: theme.spacingScale,
    },
});

function getDefaultDateTimeValue(
    constraintType: DateTimeConstraint["type"]
): DateTimeConstraint["from"] | DateTimeConstraint["to"] {
    const current = getCurrentDateTime();
    switch (constraintType) {
        case "dateTime":
            return {
                year: current.year,
                month: current.month,
                day: current.day,
                hour: current.hour,
                minute: current.minute,
            };

        case "date":
            return {
                year: current.year,
                month: current.month,
                day: current.day,
            };

        case "time":
            return {
                hour: current.hour,
                minute: current.minute,
            };

        case "week":
            return {
                week: current.week,
            };

        case "weekDay":
            return {
                weekDay: current.weekDay,
            };

        default:
            return current;
    }
}

function constraintIncludes(
    constraints: DateTimeConstraint[],
    constraintType: DateTimeConstraint["type"]
): boolean {
    return constraints.findIndex(itr => itr.type === constraintType) >= 0;
}
