import { startOfYear } from "date-fns";
// TODO Extra options? "LAST_7DAYS", "LAST_14DAYS", "LAST_30DAYS", "LAST_90DAYS"
export enum RelativePeriods {
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    THIS_WEEK = "THIS_WEEK",
    LAST_WEEK = "LAST_WEEK",
    THIS_MONTH = "THIS_MONTH",
    LAST_MONTH = "LAST_MONTH",
    THIS_QUARTER = "THIS_QUARTER",
    LAST_QUARTER = "LAST_QUARTER",
    THIS_YEAR = "THIS_YEAR",
    LAST_YEAR = "LAST_YEAR",
    DAY_ONE = "DAY_ONE",
    USER_DEFINED = "USER_DEFINED",
}

export type RelativePeriodStrings = keyof typeof RelativePeriods;

export function parseRelativePeriod(
    period: RelativePeriodStrings,
    date?: Date
): { fromDate: Date; toDate: Date } {
    const now = date || new Date();
    let fromDate: Date = now,
        toDate: Date = now;

    switch (period) {
        case RelativePeriods.TODAY: {
            fromDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1
            );
            break;
        }
        case RelativePeriods.YESTERDAY: {
            fromDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - 1
            );

            toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            break;
        }
        case RelativePeriods.THIS_WEEK: {
            fromDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - (now.getDay() - 1)
            );

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1
            );
            break;
        }
        case RelativePeriods.LAST_WEEK: {
            fromDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - (now.getDay() + 6)
            );

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - now.getDay() + 1
            );
            break;
        }
        case RelativePeriods.THIS_MONTH: {
            fromDate = new Date(now.getFullYear(), now.getMonth(), 1);

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1
            );
            break;
        }
        case RelativePeriods.LAST_MONTH: {
            fromDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                1 // Get the first day of the month
            );
            break;
        }
        case RelativePeriods.THIS_QUARTER: {
            const quarter = Math.floor(now.getMonth() / 3);
            const beginningMonthOfCurrentQuarter = quarter * 3;
            fromDate = new Date(
                now.getFullYear(),
                beginningMonthOfCurrentQuarter,
                1
            );

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1
            );

            break;
        }
        case RelativePeriods.LAST_QUARTER: {
            const quarter = Math.floor(now.getMonth() / 3);
            const beginningMonthOfCurrentQuarter = quarter * 3;
            fromDate = new Date(
                now.getFullYear(),
                beginningMonthOfCurrentQuarter - 3,
                1
            );

            toDate = new Date(
                now.getFullYear(),
                beginningMonthOfCurrentQuarter,
                1 // Get the first day of the month
            );
            break;
        }
        case RelativePeriods.THIS_YEAR: {
            fromDate = startOfYear(now);

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1
            );
            break;
        }
        case RelativePeriods.LAST_YEAR: {
            fromDate = new Date(now.getFullYear() - 1, 0, 1);

            toDate = new Date(now.getFullYear(), 0, 1);
            break;
        }
        case RelativePeriods.DAY_ONE: {
            // Approx the date of the first release of Venue POS - 2020-12-01
            fromDate = new Date(2020, 11, 1);

            toDate = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1
            );
            break;
        }
        case RelativePeriods.USER_DEFINED:
        default: {
            break;
        }
    }

    return { fromDate, toDate };
}
