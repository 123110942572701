import {
    useThemedStyle,
    useForm,
    StyleFunction,
    Loading,
    Spacer,
    Text,
    Surface,
    Headline,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import React, { useCallback, useEffect, useState } from "react";
import { IUserPassword } from "lib";
import { defaultUserPassword, schemaSaveUserPassword } from "../user/forms";
import { PasswordResetForm } from "./password-reset-form";
import type { FetchResult } from "@apollo/client";
import { Image, View } from "react-native";
import {
    GQPasswordResetTokenUpdatePasswordMutation,
    usePasswordResetTokenRedeemLazyQuery,
    usePasswordResetTokenUpdatePasswordMutation,
} from "graphql-sdk";
import { useHandleMutationError } from "../../hooks";

export function PasswordReset() {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const form = useForm<IUserPassword>(
        schemaSaveUserPassword,
        defaultUserPassword
    );
    const [tokenValid, setTokenValid] = useState(false);
    const [passwordResetTokenRedeem, { loading, data }] =
        usePasswordResetTokenRedeemLazyQuery();
    const searchParams = new URLSearchParams(document.location.search);
    const urlToken = searchParams.get("token");
    const logo = require("../../assets/img/vp-logo.png");
    const [{ values: formValues }, { reset: resetForm }] = form;
    const { handleMutationError } = useHandleMutationError();
    const [passwordUpdate] = usePasswordResetTokenUpdatePasswordMutation();
    const [countdown, setCountdown] = useState(10);
    const [countdownText, setCountdownText] = useState(
        t(
            "backoffice.password_form.redirect_message",
            "You will be redirected to the login page in {{ countdown }} seconds",
            { countdown: countdown }
        )
    );

    useEffect(() => {
        if (urlToken !== null) {
            passwordResetTokenRedeem({
                variables: {
                    token: urlToken!,
                },
            });
        }
    }, [passwordResetTokenRedeem, urlToken]);

    useEffect(() => {
        if (data?.passwordResetTokenRedeem?.status) {
            setTokenValid(true);
        }
    }, [data]);

    useEffect(() => {
        if (tokenValid) return;

        const timer = setTimeout(() => {
            // Decrement the countdown
            setCountdown(prevCountdown => prevCountdown - 1);

            // Redirect when the countdown reaches 0
            if (countdown === 0) {
                window.location.href = "/login";
            } else {
                setCountdownText(
                    t(
                        "backoffice.password_form.redirect_message",
                        "You will be redirected to the login page in {{ countdown }} seconds",
                        {
                            countdown: countdown,
                        }
                    )
                );
            }
        }, 1000);

        // Clean up the timer
        return () => clearTimeout(timer);
    }, [countdown, tokenValid, t]);

    const submitPassword = useCallback(async () => {
        if (!formValues) {
            return;
        }
        let passwordUpdateResult: FetchResult<GQPasswordResetTokenUpdatePasswordMutation>;
        await handleMutationError(
            async () => {
                passwordUpdateResult = await passwordUpdate({
                    variables: {
                        token: urlToken!,
                        password: formValues.password,
                    },
                });
            },
            t("common.saved", "Saved"),
            () => {
                resetForm();

                if (!passwordUpdateResult.data) {
                    return;
                }

                setTimeout(() => {
                    window.location.href = "/";
                }, 500);
            }
        );
    }, [
        formValues,
        handleMutationError,
        t,
        passwordUpdate,
        urlToken,
        resetForm,
    ]);

    return (
        <View style={styles.container}>
            <Image source={logo} style={styles.logo} />
            <Spacer space={4} />
            <Surface style={styles.box}>
                {loading ? (
                    <>
                        <Spacer />
                        <Loading />
                        <Spacer />
                    </>
                ) : tokenValid === true ? (
                    <PasswordResetForm form={form} onSubmit={submitPassword} />
                ) : (
                    <View>
                        <Headline size="h1">
                            {t(
                                "backoffice.password_form.invalid_token",
                                "This token is no longer valid."
                            )}
                        </Headline>
                        <Text>{countdownText}</Text>
                    </View>
                )}
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.colors.grey50,
    },
    logo: {
        width: 252,
        height: 64,
    },
    box: {
        elevation: 4,
        backgroundColor: theme.colors.white,
        minWidth: 400,
        padding: theme.spacingScale * 4,
    },
});
