import {
    LoadingScreen,
    Spacer,
    StyleFunction,
    useAuth,
    useForm,
    useMeLazy,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import {
    GQUserInputRole,
    useUserEditFormLazyQuery,
    useUserSaveMutation,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { schemaSaveUser } from "./forms";
import { useUsernameAvailability } from "./use-username-availability";
import { UserDeleteForm } from "./user-delete-form";
import { UserForm } from "./user-form";
import { UserScreen } from "./user-screen";

import type { IUserSave } from "lib";
type ScreenProps = RootStackScreenProps<"USER_EDIT">;

export function UserEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        [
            "merchant.user.write",
            "merchant.user.delete",
            "admin.user.write",
            "admin.user.delete",
        ],
        "OR"
    );

    const styles = useThemedStyle(styleFunc);
    const [{ load: loadMe }, me] = useMeLazy();
    const [t] = useTranslation();
    const toast = useToast();
    const [userSave] = useUserSaveMutation();
    const [getUser, user] = useUserEditFormLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [checkUsernameAvailability, usernameAvailable] =
        useUsernameAvailability();
    const form = useForm<IUserSave>(schemaSaveUser, null);
    const [{ values }, { setDefaultValues }] = form;
    const userId = route.params.id;

    useEffect(() => {
        if (!userId) {
            navigate("USERS");
        } else {
            getUser({ variables: { userId: userId } });
        }
    }, [getUser, navigate, userId]);

    useEffect(() => {
        if (user.data && user.data.user && me) {
            const u = user.data.user;

            const roles: IUserSave["roles"] = u.roles.map(userRole => ({
                roleId: userRole.role.id,
                type: userRole.role.type,
                merchantId: userRole.merchant?.id,
            }));

            setDefaultValues({
                username: u.username,
                password: "",
                confirmPassword: "",
                firstName: u.firstName,
                lastName: u.lastName,
                email: u.email,
                active: u.active,
                pin: "",
                requireLoginPin: u.requireLoginPin,
                requireLogoutPin: u.requireLogoutPin,
                requirePasswordChange: u.requirePasswordChange,
                roles,
                doNotSendPassword: false,
            });
        }
    }, [me, setDefaultValues, user.data]);

    const save = useCallback(async () => {
        if (!user.data || !user.data.user || !values) {
            return;
        }

        // On submit we should check if username is already taken
        if (
            !(await checkUsernameAvailability(
                values.username,
                user.data.user.username
            ))
        ) {
            return;
        }

        await userSave({
            variables: {
                id: user.data.user.id,
                user: {
                    username: values.username,
                    password:
                        values.password === "" ? undefined : values.password,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    active: values.active,
                    pin: values.pin ?? "",
                    requireLoginPin: values.requireLoginPin,
                    requireLogoutPin: values.requireLogoutPin,
                    requirePasswordChange: values.requirePasswordChange,
                    roles: values.roles as GQUserInputRole[],
                    doNotSendPassword: true,
                },
            },
        });

        // If editing current signed in user, we should update the me context
        if (me && user.data.user.id === me.user?.id) {
            await auth.resignIn();
            await loadMe();
        }

        toast.success(t("backoffice.user_form.saved", "User saved"));

        navigate("USERS");
    }, [
        auth,
        checkUsernameAvailability,
        loadMe,
        me,
        navigate,
        t,
        toast,
        user.data,
        userSave,
        values,
    ]);

    if (!userId || !user.data || !user.data.user) {
        return <LoadingScreen style="light" />;
    }

    return (
        <UserScreen>
            {auth.may(["merchant.user.write", "admin.user.write"], "OR") && (
                <View style={styles.box}>
                    <View style={styles.container}>
                        <UserForm
                            form={form}
                            onSubmit={save}
                            submitButton={t("common.save", "Save")}
                            roles={user.data.roles.data}
                            showPasswordInput={true}
                            pinSet={!!user.data.user.pin}
                            usernameAvailable={usernameAvailable}
                        />
                    </View>
                </View>
            )}
            {auth.may(["merchant.user.write", "admin.user.write"], "OR") &&
                auth.may(
                    ["merchant.user.delete", "admin.user.delete"],
                    "OR"
                ) && <Spacer space={5} />}
            {auth.may(["merchant.user.write", "admin.user.write"], "OR") && (
                <View style={styles.box}>
                    <View style={styles.container}>
                        <UserDeleteForm
                            username={user.data.user.username}
                            userId={user.data.user.id}
                        />
                    </View>
                </View>
            )}
        </UserScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
