import { useFocusEffect } from "@react-navigation/native";
import {
    LoadingScreen,
    StyleFunction,
    useAuth,
    useConfirm,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQAccountInput,
    GQIsAccountNameInUseQuery,
    IsAccountNameInUseDocument,
    useAccountLazyQuery,
    useAccountSaveMutation,
} from "graphql-sdk";
import { schemaAccount } from "lib";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { AdminContainer } from "../../..";
import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { AccountForm } from "../account-form";
import { AccountTags } from "./account-tags";
import { useApolloClient } from "@apollo/client";

type ScreenProps = RootStackScreenProps<"ACCOUNT_EDIT">;

export function AccountEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [accountEdit] = useAccountSaveMutation();
    const [getAccount, account] = useAccountLazyQuery({
        fetchPolicy: "no-cache",
    });

    const form = useForm<GQAccountInput>(schemaAccount, null);
    const [{ values }, { setDefaultValues, reset }] = form;
    const accountId = route.params.id;
    const confirm = useConfirm();
    const graphqlClient = useApolloClient();

    const fetchConfiguration = useMemo(
        () => ({
            variables: {
                id: accountId,
                tagPagination: {
                    sort: "name",
                    sortDirection: "DESC",
                    pageSize: 99999,
                    page: 0,
                },
            },
        }),
        [accountId]
    );

    const edit = useCallback(async () => {
        if (!account.data || !account.data.account || !values) {
            return;
        }
        await handleMutationError(
            async () =>
                await accountEdit({
                    variables: {
                        id: account.data!.account.id,
                        account: {
                            name: values.name,
                            type: values.type,
                            status: values.status,
                            amount: values.amount,
                            externalId: values.externalId,
                            vatId: values.vatId,
                        },
                    },
                }),
            t("common.saved", "Saved"),
            () => {
                reset();
                navigate("ACCOUNT_EDIT", {
                    id: accountId,
                });
            }
        );
    }, [
        account.data,
        values,
        handleMutationError,
        t,
        accountEdit,
        reset,
        navigate,
        accountId,
    ]);

    const handleSubmit = useCallback(async () => {
        if (!values || !values.name || values.name.trim() === "") {
            return;
        }

        const variables = {
            name: values!.name,
            customerId:
                account.data!.account.customer?.id === undefined
                    ? null
                    : account.data!.account.customer?.id ?? null,
            accountId: account.data!.account.id,
        };
        const isNameInUse =
            await graphqlClient.query<GQIsAccountNameInUseQuery>({
                query: IsAccountNameInUseDocument,
                variables,
                fetchPolicy: "no-cache",
            });

        if (isNameInUse.data.isAccountNameInUse.status) {
            if (
                !(await confirm(
                    t(
                        "backoffice.account.duplicate_name",
                        "Duplicate account name found"
                    ),
                    t(
                        "backoffice.account.duplicate_name_explain",
                        "An account with this name ({{ name }}) already exists.\n\nAre you sure, you want to save?",
                        { name: values.name }
                    )
                ))
            ) {
                return;
            }
        }

        edit();
    }, [account.data, confirm, edit, graphqlClient, t, values]);

    useEffect(() => {
        if (!accountId) {
            navigate("ACCOUNTS");
        } else {
            getAccount(fetchConfiguration);
        }
    }, [getAccount, navigate, accountId, fetchConfiguration]);

    useFocusEffect(
        useCallback(() => {
            getAccount(fetchConfiguration);
        }, [fetchConfiguration, getAccount])
    );

    useEffect(() => {
        if (account.data && account.data.account) {
            setDefaultValues(account.data.account as GQAccountInput);
        }
    }, [account.data, setDefaultValues]);

    if (!account.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <AdminContainer testID="accountEditScreen">
            <View style={styles.container}>
                <View style={styles.left}>
                    <AccountForm
                        id={account.data.account.id}
                        vatId={account.data.account.vat?.id}
                        form={form}
                        onSubmit={handleSubmit}
                        submitButton={["common.save", "Save"]}
                        isCreate={false}
                        enabledTags={account.data.account.tags.data.map(
                            itr => itr.id
                        )}
                        isGiftCard={account.data.account.type === "GIFT_CARD"}
                    />
                </View>
                <View style={styles.right}>
                    <AccountTags account={account.data.account.id} />
                </View>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    headline: {
        marginBottom: 0,
        textAlign: "center",
    },
    centerText: {
        textAlign: "center",
    },

    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
        minWidth: 400,
    },
    right: {
        minWidth: 250,
    },

    textLink: {
        ...theme.styles.link,
    },
});
