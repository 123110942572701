import {
    AmountSearch,
    BooleanSearch,
    DateSearch,
    NumberSearch,
    PercentSearch,
    SearchDefinition,
    SearchQuery,
    StringSearch,
    TypeSearch,
} from "../..";

export function buildQueryForSearch(values: SearchDefinition): SearchQuery {
    const query: SearchQuery = {};
    for (const key in values) {
        if (!values[key].enabled) {
            continue;
        }

        const v = values[key];
        switch (v.type) {
            case "amount":
                query[key] = {
                    value: v.value,
                } as AmountSearch;
                break;
            case "string":
                query[key] = {
                    value: v.value,
                } as StringSearch;
                break;
            case "number":
                query[key] = {
                    value: v.value,
                } as NumberSearch;
                break;
            case "date":
                query[key] = {
                    values: v.values,
                } as DateSearch;
                break;
            case "percent":
                query[key] = {
                    value: v.value,
                } as PercentSearch;
                break;
            case "boolean":
                query[key] = {
                    value: v.value,
                } as BooleanSearch;
                break;
            case "type":
                query[key] = {
                    value: v.value,
                } as TypeSearch;
                break;
        }
    }
    return query;
}

export * from "./types";
