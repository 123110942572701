// Please note: We use FontAwesome Solid icons whenever possible.
// There is an ongoing effort to replace all MaterialIcons icons
// Remember to add keys to any icons you use to the "availableIcons" variable.

import React, { useMemo } from "react";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { availableIcons } from "./available-icons";
import { FontAwesome6IconVariants } from "react-native-vector-icons/FontAwesome6";

const DEFAULT_COLOR = "white";

export type IconSizes =
    | "tiny"
    | "small"
    | "normal"
    | "large"
    | "huge"
    | "placeholder";

interface MyIconProps {
    name: string;
    color?: TextStyle["color"];
    size?: IconSizes;
    style?: StyleProp<ViewStyle>;
    variant?: FontAwesome6IconVariants;
    testID?: string;
}

export const Icon = React.memo(
    ({ name, color, size, style, testID }: MyIconProps) => {
        const iconSelection = availableIcons[name];
        let IconComponent = iconSelection && iconSelection.component;
        if (!IconComponent) {
            IconComponent = MaterialIcons;
        }
        let iconName = iconSelection && iconSelection.name;
        if (!iconName) {
            console.warn(
                `Icon "${name}" is not defined in Icon component, please add it to the list`
            );
            iconName = name;
        }

        const styleObj = useMemo(
            () =>
                StyleSheet.flatten([{ lineHeight: sizeToPixels(size) }, style]),
            [size, style]
        );

        const iconVariant: {
            light?: boolean;
            solid?: boolean;
            brand?: boolean;
            sharpSolid?: boolean;
            duotone?: boolean;
            thin?: boolean;
        } = {};

        iconVariant.light = iconSelection.variant === "light" ?? undefined;
        iconVariant.solid = iconSelection.variant === "solid" ?? undefined;
        iconVariant.brand = iconSelection.variant === "brand" ?? undefined;
        iconVariant.sharpSolid =
            iconSelection.variant === "sharpSolid" ?? undefined;
        iconVariant.duotone = iconSelection.variant === "duotone" ?? undefined;
        iconVariant.thin = iconSelection.variant === "thin" ?? undefined;

        return (
            <IconComponent
                name={iconName}
                size={sizeToPixels(size)}
                color={color || DEFAULT_COLOR}
                style={styleObj}
                testID={testID}
                {...iconVariant}
            />
        );
    }
);

function sizeToPixels(size?: IconSizes) {
    switch (size) {
        case "placeholder":
            return 150;
        case "huge":
            return 72;
        case "large":
            return 34;
        case "small":
            return 16;
        case "tiny":
            return 10;
        default:
            return 22;
    }
}
