import React from "react";
import { Form, RequiredText } from "@venuepos/react-common";
import { useTranslation } from "locales";
import type { IUserSave } from "lib";
import { StyleSheet, View } from "react-native";
import produce from "immer";
import type { GQUserFormRolesFieldsFragment } from "graphql-sdk";
import {
    Button,
    CheckBox,
    Headline,
    InputControl,
    Picker,
    Surface,
    Text,
    TextInput,
} from "@venuepos/react-common";
import { PasswordInput } from "./password/password-input";

export function UserForm<FormValues extends IUserSave>(props: {
    form: Form<FormValues>;
    onSubmit: () => void;
    submitButton: string;
    roles: GQUserFormRolesFieldsFragment[];
    showPasswordInput: boolean;
    usernameAvailable: boolean | undefined;
    pinSet: boolean;
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <Surface>
            <InputControl
                error={
                    props.usernameAvailable === false
                        ? t(
                              "backoffice.user_form.username_taken",
                              "Username is already taken. Choose another one and try again."
                          )
                        : errors.username
                }
            >
                <TextInput
                    label={t("backoffice.user_form.username", "Username")}
                    placeholder={t(
                        "backoffice.user_form.enter_username",
                        "Enter username"
                    )}
                    defaultValue={values.username}
                    onChangeText={text => setValue("username", text)}
                    required={true}
                />
            </InputControl>
            <InputControl error={errors.email}>
                <TextInput
                    label={t("backoffice.user_form.email", "E-mail")}
                    placeholder={t(
                        "backoffice.user_form.enter_email",
                        "Enter e-mail"
                    )}
                    defaultValue={values.email}
                    onChangeText={text => setValue("email", text)}
                    required={true}
                />
            </InputControl>
            <InputControl error={errors.firstName}>
                <TextInput
                    label={t("backoffice.user_form.first_name", "First name")}
                    placeholder={t(
                        "backoffice.user_form.enter_first_name",
                        "Enter first name"
                    )}
                    defaultValue={values.firstName}
                    onChangeText={text => setValue("firstName", text)}
                    required={true}
                />
            </InputControl>
            <InputControl error={errors.lastName}>
                <TextInput
                    label={t("backoffice.user_form.last_name", "Last name")}
                    placeholder={t(
                        "backoffice.user_form.enter_last_name",
                        "Enter last name"
                    )}
                    defaultValue={values.lastName}
                    onChangeText={text => setValue("lastName", text)}
                    required={true}
                />
            </InputControl>
            {values.roles.map((roleInput, key) => (
                <InputControl key={roleInput.merchantId + roleInput.roleId}>
                    <Picker
                        label={
                            values.roles.length > 1
                                ? t(
                                      "backoffice.user_form.role_type",
                                      "Role ({{ type }})",
                                      { type: roleInput.type }
                                  )
                                : t("backoffice.user.role", "Role")
                        }
                        selectedValue={values.roles[key].roleId}
                        onValueChange={roleId =>
                            setValue(
                                "roles",
                                produce(values.roles, draft => {
                                    draft[key].roleId = roleId;
                                })
                            )
                        }
                    >
                        {props.roles
                            .filter(
                                itr =>
                                    itr.type === roleInput.type &&
                                    itr.merchant?.id === roleInput.merchantId
                            )
                            .sort((roleA, roleB) =>
                                roleA.name.localeCompare(roleB.name)
                            )
                            .map(role => (
                                <Picker.Item
                                    key={role.id}
                                    label={role.name}
                                    value={role.id}
                                />
                            ))}
                    </Picker>
                </InputControl>
            ))}
            <InputControl
                error={errors.active}
                description={t(
                    "backoffice.user_form.active_description",
                    "If this option is turned on, the user can log in and use the system."
                )}
            >
                <CheckBox
                    label={t("backoffice.user_form.active", "Active")}
                    value={values.active}
                    onValueChange={checked => setValue("active", checked)}
                />
            </InputControl>

            <Headline size="h3">
                {t("backoffice.user_form.password_headline", "Password")}
            </Headline>
            {props.showPasswordInput ? (
                <View>
                    <Text style={styles.passwordInfo}>
                        {t(
                            "backoffice.user_form.password_left_blank",
                            "Leave the password empty, if you don't want to change it."
                        )}
                    </Text>
                    <PasswordInput
                        errors={errors}
                        values={values}
                        setValue={setValue}
                        t={t}
                    />
                    <InputControl>
                        <CheckBox
                            label={t(
                                "backoffice.user_form.require_new_password_after_next_login",
                                "Kræv nyt password ved næste login"
                            )}
                            onValueChange={checked =>
                                setValue("requirePasswordChange", checked)
                            }
                            value={values.requirePasswordChange}
                        />
                    </InputControl>
                </View>
            ) : (
                <View>
                    <Text style={styles.passwordInfo}>
                        {t(
                            "backoffice.user_form.credentials_will_be_sent",
                            "The credentials will be sent to the email entered above. These can be used to login into the backoffice. If the user only needs access to POS-devices, you can opt to have the system not send the email."
                        )}
                    </Text>
                    <InputControl>
                        <CheckBox
                            label={t(
                                "backoffice.user_form.do_not_send_mail",
                                "Do not send an email to the user with credentials to the backoffice."
                            )}
                            onValueChange={checked =>
                                setValue("doNotSendPassword", checked)
                            }
                            value={values.doNotSendPassword}
                        />
                    </InputControl>
                </View>
            )}

            <Headline size="h3">
                {t("backoffice.user_form.pin_headline", "PIN")}
            </Headline>
            <InputControl
                error={errors.requireLoginPin}
                description={t(
                    "backoffice.user_form.require_login_pin_description",
                    "If this option is turned on, the user is required to enter the PIN in order to log in on the POS."
                )}
            >
                <CheckBox
                    label={t(
                        "backoffice.user_form.require_login_pin",
                        "Require PIN for Login?"
                    )}
                    value={values.requireLoginPin}
                    onValueChange={checked =>
                        setValue("requireLoginPin", checked)
                    }
                />
            </InputControl>

            <InputControl
                error={errors.requireLogoutPin}
                description={t(
                    "backoffice.user_form.require_logout_pin_description",
                    "If this option is turned on, the user is required to enter the PIN in order to log out of the POS."
                )}
            >
                <CheckBox
                    label={t(
                        "backoffice.user_form.require_logout_pin",
                        "Require PIN for Logout?"
                    )}
                    value={values.requireLogoutPin}
                    onValueChange={checked =>
                        setValue("requireLogoutPin", checked)
                    }
                />
            </InputControl>

            <InputControl
                error={errors.pin}
                description={t(
                    "backoffice.user_form.pin_description",
                    "The PIN code can be 1-10 digits. If you don't want to set or change the PIN, just leave the fields blank."
                )}
            >
                <TextInput
                    label={t("backoffice.user_form.login_pin", "PIN for login")}
                    placeholder={t(
                        "backoffice.user_form.pin_enter",
                        "Enter PIN"
                    )}
                    maxLength={10}
                    defaultValue={""}
                    onChangeText={text => setValue("pin", text)}
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)}>
                {props.submitButton}
            </Button>

            <RequiredText />
        </Surface>
    );
}

const styles = StyleSheet.create({
    passwordInfo: {
        marginTop: -10,
        marginBottom: 20,
    },
    loginPinInfo: {
        marginTop: -10,
        marginBottom: 20,
        fontWeight: "bold",
    },
});
