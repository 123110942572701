import {
    Alert,
    Cell,
    DataTable,
    Headline,
    Loading,
    PageTitle,
    Row,
    Spacer,
    Surface,
    Table,
    Text,
    Title,
    TooltipInfoIcon,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQSoftpayAcquirer,
    GQSoftpayMiaStoreQuery,
    useSoftpayMiaStoreQuery,
} from "graphql-sdk";
import { formatDateTime } from "lib";
import { AvailableLocale, useTranslation } from "locales";
import React, { useCallback } from "react";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { styleFunc as sharedStyleFunc } from "./styles";

type ScreenProps = RootStackScreenProps<"SOFTPAY_MIA_STORE">;

export function SoftpayMiaStoreScreen({
    route,
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const merchantId = decodeURI(route.params.merchantId);
    const storeId = decodeURI(route.params.storeId);
    const acquirer = decodeURI(route.params.acquirer);

    const sharedStyles = useThemedStyle(sharedStyleFunc);
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);

    const { data, loading, error } = useSoftpayMiaStoreQuery({
        variables: {
            acquirer: acquirer as GQSoftpayAcquirer,
            merchantId: merchantId,
            storeId: storeId,
        },
        fetchPolicy: "no-cache",
    });

    const handleClick = useCallback(
        (terminalId: string) => {
            navigate("SOFTPAY_MIA_TERMINAL", {
                merchantId,
                storeId,
                terminalId,
                acquirer,
            });
        },
        [acquirer, merchantId, navigate, storeId]
    );

    const renderSoftpayMiaMerchantRow = useCallback(
        (
            item: GQSoftpayMiaStoreQuery["softpayMiaStore"]["terminals"][0],
            rowIndex
        ) => {
            if (!item) {
                return;
            }

            return (
                <DataTable.Row
                    onPress={() => handleClick(item.terminalId)}
                    style={rowIndex % 2 === 0 ? sharedStyles.oddRow : undefined}
                    key={`terminal_${rowIndex}`}
                >
                    <DataTable.Cell>{item.appId}</DataTable.Cell>

                    <DataTable.Cell>{item.onlineTerminalId}</DataTable.Cell>
                    <DataTable.Cell>{item.onlineMerchantId}</DataTable.Cell>
                    <DataTable.Cell>
                        {item.installed
                            ? t("common.yes", "Yes")
                            : t("common.no", "No")}
                    </DataTable.Cell>
                    <DataTable.Cell>
                        {item.lastOnline &&
                            formatDateTime(
                                item.lastOnline,
                                locale as AvailableLocale
                            )}
                    </DataTable.Cell>
                    <DataTable.Cell>
                        {item.takenAt &&
                            formatDateTime(
                                item.takenAt,
                                locale as AvailableLocale
                            )}
                    </DataTable.Cell>
                    <DataTable.Cell>
                        {item.releasedAt &&
                            formatDateTime(
                                item.releasedAt,
                                locale as AvailableLocale
                            )}
                    </DataTable.Cell>
                    <DataTable.Cell>{item.state}</DataTable.Cell>
                </DataTable.Row>
            );
        },
        [handleClick, locale, sharedStyles.oddRow, t]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.softpay_mia_merchant_list.softpay_mia_store",
                    "Softpay MIA store"
                )}
            </PageTitle>
            <Surface>
                {loading ? (
                    <Loading />
                ) : data?.softpayMiaStore === undefined ? (
                    t(
                        "backoffice.softpay_mia_terminal.no_data",
                        "No data returned"
                    )
                ) : (
                    <Table>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t("backoffice.common.name", "Name")}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.name}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.address_line_1",
                                    "Address"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.addressLine1}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.address_line_2",
                                    "Address 2"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.addressLine2}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.zip_code",
                                    "Zip code"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.zipCode}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t("backoffice.softpay_mia_store.city", "City")}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.city}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_store.phone",
                                            "Phone"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Store's contact phone in E.164 format. Store phone is not needed for transaction flow but may be useful for operations and support" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.phone}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.business_reg_no",
                                    "Business Register Number"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.businessRegNo}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_store.card_acceptor_country_code",
                                            "Card acceptor country code"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Card acceptor country code in ISO 3166 (Alpha2 Code) format" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.cardAcceptorCountryCode}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.card_acceptor_name_location",
                                    "Card acceptor name location"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.cardAcceptorNameLocation}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.online_store_id",
                                    "Online store ID"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.onlineStoreId}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.storeId",
                                    "Store ID"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.storeId}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.time_zone",
                                    "Time zone"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.timeZone}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_store.state",
                                    "State"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaStore.state}
                            </Cell>
                        </Row>
                        {data?.softpayMiaStore.additionalData &&
                        data.softpayMiaStore.additionalData.length > 0 ? (
                            <Row>
                                <Title style={sharedStyles.titleCell}>
                                    {t(
                                        "backoffice.softpay_mia_store.additional_data",
                                        "Additional data"
                                    )}
                                </Title>
                                <Cell style={sharedStyles.dataCell}>
                                    <Table>
                                        {data.softpayMiaStore.additionalData.map(
                                            (item, idx) => (
                                                <Row
                                                    style={
                                                        idx % 2 === 1
                                                            ? sharedStyles.oddRow
                                                            : undefined
                                                    }
                                                >
                                                    <Title
                                                        style={
                                                            sharedStyles.titleCell
                                                        }
                                                    >{`${item.key}:`}</Title>
                                                    <Cell
                                                        style={
                                                            sharedStyles.dataCell
                                                        }
                                                    >
                                                        <Text
                                                            style={
                                                                sharedStyles.dataText
                                                            }
                                                        >
                                                            {item.value}
                                                        </Text>
                                                    </Cell>
                                                </Row>
                                            )
                                        )}
                                    </Table>
                                </Cell>
                            </Row>
                        ) : null}
                    </Table>
                )}
            </Surface>
            <Spacer space={2} />
            <Surface>
                <Headline size="h2" style={sharedStyles.headline}>
                    {t(
                        "backoffice.softpay_mia_store.terminals",
                        "Terminals for store"
                    )}
                </Headline>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.app_id",
                                "App ID"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.online_terminal_id",
                                "Online terminal ID (TID)"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.online_merchant_id",
                                "Online merchant ID (MID)"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.installed",
                                "Installed"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.last_online",
                                "Last online"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.taken_at",
                                "Taken at"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.released_at",
                                "Released at"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_terminal.state",
                                "State"
                            )}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : data?.softpayMiaStore.terminals.length === 0 ? (
                        <DataTable.Row>
                            <DataTable.Cell>
                                {t(
                                    "backoffice.softpay_mia_terminal.no_terminals",
                                    "No terminals for store."
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                    ) : (
                        data?.softpayMiaStore?.terminals?.map(
                            renderSoftpayMiaMerchantRow
                        )
                    )}
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}
