import {
    Button,
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    Form,
    Headline,
    InputControl,
    InputLabel,
    Loading,
    StyleFunction,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { ButtonSizeForm } from "../../../../button-size-form";

import type { ILayoutProduct, ProductButton } from "lib";
export function ProductButtonForm(props: {
    form: Form<ProductButton>;
    product: ILayoutProduct;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const [activateColorPicker, setActivateColorPicker] = useState<boolean>(
        (values &&
            values.color &&
            props.product.group &&
            props.product.group.color &&
            props.product.group.color !== values.color) ||
            false
    );

    const initialButtonColor =
        values?.color || props.product.group?.color || "#73B22E";

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <Headline size="h2">{props.product.name}</Headline>
            <ButtonSizeForm form={props.form as any} />
            <View>
                <InputControl error={errors.label}>
                    <TextInput
                        label={t(
                            "layout.button.label",
                            "The name on this button"
                        )}
                        placeholder={t(
                            "layout.button.label_placeholder",
                            `If this field is left blank, the button will be labelled "{{ label }}".`,
                            {
                                label:
                                    props.product.buttonText ||
                                    props.product.name,
                            }
                        )}
                        value={values.label}
                        onChangeText={text => {
                            setValue("label", text);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <View style={[styles.rowContainer, styles.onTop]}>
                    <View style={styles.rowContent}>
                        <View style={[styles.rowContainer, styles.colorRow]}>
                            <InputLabel>
                                {`${t(
                                    "layout.button.product_group_color",
                                    "Product group color"
                                )}:`}
                            </InputLabel>
                            <View style={styles.groupColor}>
                                <ColorIndicator
                                    color={props.product.group?.color}
                                    copyOnClick={true}
                                />
                            </View>
                        </View>
                        <CheckBox
                            style={styles.checkboxContent}
                            value={activateColorPicker}
                            onValueChange={checkboxValue => {
                                if (!checkboxValue) {
                                    setValue("color", null);
                                } else {
                                    setValue("color", initialButtonColor);
                                }
                                setActivateColorPicker(checkboxValue);
                            }}
                            label={t(
                                "layout.button.pick_color_question",
                                "Pick a custom color?"
                            )}
                        />
                    </View>
                    {activateColorPicker ? (
                        <View style={styles.rowContent}>
                            <InputControl error={errors.color}>
                                <ColorPickerInput
                                    defaultColor={values.color ?? undefined}
                                    label={t(
                                        "layout.button.color",
                                        "Custom button color"
                                    )}
                                    onChangeColor={newColor => {
                                        if (
                                            newColor !==
                                            props.product.group?.color
                                        ) {
                                            setValue("color", newColor ?? null);
                                        }
                                    }}
                                />
                            </InputControl>
                        </View>
                    ) : null}
                </View>
                <Button
                    type="primary"
                    onPress={handleSubmit(props.onSubmit)}
                    testID="layout:editButton:save"
                >
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
            </View>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        width: "50%",
        visibility: "visible",
    },
    rowContainer: {
        flexDirection: "row",
    },
    onTop: { zIndex: 2 },
    checkboxContent: {
        marginBottom: theme.spacingScale * 5,
    },
    colorRow: { alignItems: "center", marginBottom: 12 },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },
});
