import {
    ConfirmModal,
    StyleFunction,
    useAuth,
    useForm,
    useMerchantConfig,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    usePrintersQuery,
    useProductLazyQuery,
    useProductSaveMutation,
    useSetInventoryProductQuantityMutation,
    useVatsQuery,
} from "graphql-sdk";
import {
    IInventoryProductInput,
    IProductInput,
    schemaInventoryProduct,
    schemaProduct,
} from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { useAdminSession } from "../../../session";
import { InventoryQuantity } from "../inventory-quantity";
import { ProductChildren } from "../product-children";
import { ProductForm } from "../product-form";
import { ProductScreen } from "../product-screen";

import type { AvailableLocale } from "locales";
type ScreenProps = RootStackScreenProps<"PRODUCT_EDIT">;

export function ProductEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.product");

    const [t] = useTranslation();

    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const [productEdit] = useProductSaveMutation();
    const { handleMutationError } = useHandleMutationError();

    const { data: vatsData } = useVatsQuery();
    const { data: printersData } = usePrintersQuery({
        variables: {
            pagination: {
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
    });

    const [getProduct, product] = useProductLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IProductInput>(schemaProduct, null);
    const inventoryForm = useForm<IInventoryProductInput>(
        schemaInventoryProduct,
        null
    );
    const [{ values }, { setDefaultValues }] = form;
    const [
        { values: inventoryValues },
        { setDefaultValues: setDefaultInventoryValues },
    ] = inventoryForm;
    const styles = useThemedStyle(styleFunc);
    const productId = route.params.id;
    const { render } = useModal();

    // Inventory related props
    const [setInventoryProductQuantity, { loading: quantitySavingLoading }] =
        useSetInventoryProductQuantityMutation();

    useEffect(() => {
        if (!productId) {
            navigate("PRODUCTS");
        } else {
            getProduct({ variables: { id: productId } });
        }
    }, [getProduct, navigate, productId]);

    useEffect(() => {
        if (!product.data || !product.data.product) {
            return;
        }

        const p = product.data.product;
        setDefaultValues({
            parentId: p.parent ? p.parent.id : null,
            groupId: p.group.id,
            externalId: p.externalId || null,
            name: p.name,
            amount: p.amount,
            minimumAmount: p.amount >= 0 ? p.minimumAmount : 0,
            costAmount: p.costAmount,
            vat: p.vat,
            ownVatId: p.ownVat?.id || null,
            onlineRequired: p.onlineRequired,
            active: p.active,
            isExternal: p.isExternal,
            ownExcludeFromDiscounts: p.ownExcludeFromDiscounts,
            barcode: p.barcode || null,
            barcodes: p.barcodes || [],
            buttonText: p.buttonText || "",
            printerId: p.printer?.id || null,
            inheritPrinter: p.inheritPrinter,
        });

        setDefaultInventoryValues({
            quantity: p.inventoryProduct?.quantity || 0,
            note: undefined,
            type: "quantity",
        });
    }, [product.data, setDefaultInventoryValues, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!product || !product.data || !product.data.product || !values) {
            return;
        }

        if (
            product.data.product.active !== values.active &&
            values.active === false
        ) {
            const deleteResponse = await render(onClose => (
                <ConfirmModal
                    headerText={t(
                        "backoffice.products.set_inactive",
                        "Set product to inactive?"
                    )}
                    bodyText={t(
                        "backoffice.products.inactive_explain",
                        "If you choose to make this product inactive, any buttons that are added to any of your layouts, which use this product, will be removed.\nThis cannot be undone.\n\nAre you sure, you want to set this product to inactive?"
                    )}
                    onClose={onClose}
                />
            ));

            if (!deleteResponse) {
                return;
            }
        }

        await handleMutationError(
            async () =>
                await productEdit({
                    variables: {
                        id: product.data!.product!.id,
                        product: {
                            parentId: values.parentId,
                            groupId: values.groupId,
                            externalId: values.externalId,
                            name: values.name,
                            amount: values.amount,
                            minimumAmount:
                                values.amount >= 0 ? values.minimumAmount : 0,
                            costAmount: values.costAmount,
                            ownVatId: values.ownVatId || null,
                            onlineRequired: values.onlineRequired,
                            active: values.active,
                            isExternal: values.isExternal,
                            ownExcludeFromDiscounts:
                                values.ownExcludeFromDiscounts,
                            barcode: values.barcode,
                            barcodes:
                                values.barcodes
                                    .filter(itm => itm.barcode.trim() !== "")
                                    .map(item => {
                                        return {
                                            id: item.id,
                                            barcode: item.barcode,
                                        };
                                    }) || [],
                            buttonText: values.buttonText,
                            printerId: values.inheritPrinter
                                ? null
                                : values.printerId || null,
                            inheritPrinter: values.inheritPrinter,
                        },
                    },
                }),
            t("backoffice.product.saved", "Product saved"),
            () => {
                navigate("PRODUCTS", { refetch: true });
            }
        );
    }, [
        handleMutationError,
        navigate,
        product,
        productEdit,
        render,
        t,
        values,
    ]);

    const handleInventoryQuantityChange = useCallback(async () => {
        if (!inventoryValues || !product.data || !product.data.product) {
            return;
        }

        const p = product.data.product;

        const invProductId = p.inventoryProduct?.id || null;

        const quantityValue = inventoryValues.quantity || 0;
        const originalValue = p.inventoryProduct?.quantity || 0;

        let quantityDelta = 0;
        if (inventoryValues.type === "quantity") {
            quantityDelta = p.inventoryProduct
                ? quantityValue - originalValue
                : quantityValue;
        } else {
            quantityDelta = quantityValue;
        }

        await handleMutationError(async () => {
            const invProduct = await setInventoryProductQuantity({
                variables: {
                    id: invProductId,
                    productId: productId,
                    quantity: quantityDelta as number,
                    note:
                        inventoryValues.note === ""
                            ? undefined
                            : inventoryValues.note,
                },
            });
            if (invProduct && invProduct.data) {
                // We have to update the local inventory product, otherwise more than 1 change to quantity (without reload) would use the the same initial quantity
                if (p.inventoryProduct) {
                    p.inventoryProduct.quantity += quantityDelta;
                } else {
                    p.inventoryProduct =
                        invProduct.data.setInventoryProductQuantity;
                }
            }
        }, t("backoffice.product.inventory_quantity_saved", "Inventory: Quantity is updated"));
    }, [
        handleMutationError,
        inventoryValues,
        product.data,
        productId,
        setInventoryProductQuantity,
        t,
    ]);

    return (
        <ProductScreen>
            <View style={styles.container}>
                <View style={styles.left}>
                    <ProductForm
                        productId={productId}
                        form={form}
                        onSubmit={edit}
                        currency={currency}
                        submitButton={["common.save", "Save"]}
                        printers={printersData}
                        vats={vatsData}
                    />
                </View>
                <View style={styles.right}>
                    {product?.data?.product?.children?.data &&
                    product?.data?.product?.children?.data.length > 0 ? (
                        <ProductChildren
                            childProducts={product.data.product.children.data}
                            currency={currency}
                            locale={locale as AvailableLocale}
                        />
                    ) : null}
                    <InventoryQuantity
                        form={inventoryForm}
                        onSubmit={handleInventoryQuantityChange}
                        unit={t(
                            "backoffice.product.inventory_unit_pcs",
                            "Pcs."
                        )}
                        loading={quantitySavingLoading}
                    />
                </View>
            </View>
        </ProductScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
    },
    right: {
        minWidth: 250,
    },
});
