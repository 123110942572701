import React from "react";
import { Link } from "@react-navigation/native";
import {
    DataTable,
    Headline,
    Loading,
    Text,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQDashboard } from "graphql-sdk";
import { formatAmount } from "lib";
import { AvailableLocale } from "locales";
import { useTranslation } from "locales";
import { useAdminSession } from "../../../session";
import { dashboardStyleFunc } from "../styles";
import { ScrollView } from "react-native-gesture-handler";

export function ProductSalesTop10({ data }: { data?: GQDashboard }) {
    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const [{ locale }] = useAdminSession(["locale"]);

    return (
        <>
            <Headline size="h5">
                {`${t(
                    "dashboard.widgets.product_sales_title",
                    "Product Sales - Top 10"
                )} *`}
            </Headline>
            {data ? (
                <ScrollView>
                    <DataTable>
                        <DataTable.Header>
                            <DataTable.Title style={sharedStyles.widerColumn}>
                                {t("common.product", "Product")}
                            </DataTable.Title>
                            <DataTable.Title numeric>
                                {t("dashboard.quantity", "Quantity")}
                            </DataTable.Title>
                            <DataTable.Title numeric>
                                {t("dashboard.turnover", "Turnover")}
                            </DataTable.Title>
                        </DataTable.Header>
                        {data.products.length === 0 ? (
                            <DataTable.Row style={sharedStyles.darkRow}>
                                <DataTable.Cell>
                                    {t(
                                        "dashboard.no_data",
                                        "No data for the selected date."
                                    )}
                                </DataTable.Cell>
                            </DataTable.Row>
                        ) : (
                            data.products.map((row, index) => (
                                <DataTable.Row
                                    key={`product-${row.name}`}
                                    style={
                                        index % 2 === 0
                                            ? sharedStyles.darkRow
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell
                                        style={sharedStyles.widerColumn}
                                    >
                                        {!row.id ? (
                                            row.name
                                        ) : (
                                            <Link
                                                to={{
                                                    screen: "PRODUCT_EDIT",
                                                    params: { id: row.id },
                                                }}
                                                style={sharedStyles.linkText}
                                                numberOfLines={1}
                                            >
                                                {row.name}
                                            </Link>
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {row.quantity}
                                    </DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {formatAmount(
                                            row.turnoverAmount,
                                            merchantConfig.currency,
                                            {
                                                locale: locale as AvailableLocale,
                                            }
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            ))
                        )}
                    </DataTable>
                </ScrollView>
            ) : (
                <Loading message={t("common.loading", "Loading")} />
            )}
            <Text style={sharedStyles.noteText}>
                {`* ${t(
                    "dashboard.discount_disclaimer",
                    "Prices are shown excl. discount"
                )}`}
            </Text>
        </>
    );
}
