import React from "react";
import { View } from "react-native";
import {
    Form,
    Button,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useThemedStyle,
    Headline,
    Logo,
} from "@venuepos/react-common";
import { PasswordInput } from "./password-input";
import { IUserPassword } from "lib";
import { useTranslation } from "locales";

export function RequiredPasswordChangeForm({
    form,
    onSubmit,
}: {
    form: Form<IUserPassword>;
    onSubmit: () => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    return (
        <View>
            <Logo />
            <Spacer space={2} />
            <Surface style={styles.box}>
                <View>
                    <Headline size="h1">
                        {t(
                            "backoffice.password_form.change_your_password",
                            "Reset password"
                        )}
                    </Headline>
                    <Text>
                        {t(
                            "backoffice.password_form.required_change_text",
                            "Before you can login you must change your password.\nAfterwards you need to login again using your new password."
                        )}
                    </Text>
                </View>
                <Spacer space={2} />
                <PasswordInput
                    errors={errors}
                    setValue={setValue}
                    t={t}
                    values={values}
                />
                <Button onPress={handleSubmit(onSubmit)}>
                    {t(
                        "backoffice.password_form.submit_button",
                        "Save password"
                    )}
                </Button>
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = () => ({
    box: {
        elevation: 5,
        minWidth: 400,
    },
});
