import React, { useState } from "react";
import { View } from "react-native";
import {
    useThemedStyle,
    useTheme,
    Headline,
    useMerchantConfig,
    IconButton,
    Text,
    NumberInput,
    InputControl,
    Button,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import type { StyleFunction } from "@venuepos/react-common";
import type { GQAccountQuery } from "graphql-sdk";
import { parseAmount } from "lib";

export function UpdateAmountModal({
    onClose,
    onSave,
    item,
    minAmount,
    maxAmount,
}: {
    onClose: (val: boolean) => void;
    onSave: (val: GQAccountQuery["account"] | null) => void;
    item: GQAccountQuery["account"];
    minAmount?: number;
    maxAmount?: number;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const merchantConfig = useMerchantConfig();
    const theme = useTheme();
    const [value, setValue] = useState<number>(item.amount);

    return (
        <View style={styles.container}>
            <View style={styles.headerContainer}>
                <Headline size="h2" style={styles.title}>
                    {t(
                        "backoffice.account.update_amount.header",
                        "Update balance"
                    )}
                </Headline>
                <IconButton
                    size="large"
                    color={theme.colors.primary}
                    name="times-circle"
                    onPress={() => onClose(false)}
                />
            </View>

            <View>
                <Text>
                    {t(
                        "backoffice.account.update_amount.description",
                        "Update the balance of the account to the specified amount.\nThe specified amount will be the new total balance for the account."
                    )}
                </Text>
                <View style={styles.inputContainer}>
                    <InputControl
                        description={
                            minAmount !== undefined &&
                            t(
                                "backoffice.account.account_amount_description",
                                "The balance should be at least {{minAmount}}.",
                                { replace: { minAmount: minAmount } }
                            )
                        }
                    >
                        <NumberInput
                            placeholder={t(
                                "backoffice.account.enter_amount",
                                "Enter amount"
                            )}
                            defaultValue={item.amount / 100}
                            onChange={val => setValue(parseAmount(val || 0))}
                            decimals={2}
                            unit={merchantConfig.currency}
                            min={minAmount}
                            max={maxAmount}
                        />
                    </InputControl>
                </View>

                <View style={styles.buttonContainer}>
                    <Button
                        style={styles.button}
                        onPress={() => {
                            item.amount = value;
                            onSave(item);
                            onClose(true);
                        }}
                    >
                        {t("common.save", "Save")}
                    </Button>
                    <Button
                        style={styles.button}
                        onPress={() => {
                            onClose(false);
                        }}
                    >
                        {t("common.cancel", "Cancel")}
                    </Button>
                </View>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        backgroundColor: theme.colors.white,
        padding: theme.spacingScale * 3,
        alignSelf: "center",
        width: "100%",
        maxWidth: 500,
    },
    button: {
        marginLeft: theme.spacingScale,
    },
    inputContainer: { flex: 1, paddingTop: theme.spacingScale * 2 },
    headerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    title: { color: theme.colors.primary },
    buttonContainer: {
        flexDirection: "row-reverse",
    },
});
