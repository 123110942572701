import { useEffect } from "react";
import { Platform } from "react-native";
import { useTranslation } from "locales";

export function useBarcodeScannerWeb(props: {
    onDone: (barcode: string) => void;
}) {
    const [t] = useTranslation();
    if (Platform.OS !== "web") {
        throw new Error(
            t(
                "backoffice.platform_not_supported",
                "Platform not supported. Web is required."
            )
        );
    }

    let inputTimer: NodeJS.Timeout | null = null;
    let barcode = "";

    const handleKeyDown = (event: KeyboardEvent) => {
        if (inputTimer === null) {
            inputTimer = setTimeout(() => {
                inputTimer = null;
                barcode = "";
            }, 300);
        }

        if (event.key === "Enter" && barcode !== "") {
            clearTimeout(inputTimer);
            inputTimer = null;

            props.onDone(barcode);
            barcode = "";
        } else {
            barcode += event.key;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    });

    return;
}
