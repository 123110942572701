import { Tabs, useAuth } from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { AdminContainer } from "../container";
import { CustomersTab } from "./customers/customers-tab";
import { CustomerGroupsTab } from "./customers/groups/groups-tab";

export * from "./accounts";
export * from "./bookkeeping";
export * from "./customers";

export function AccountPaymentScreen() {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const [t] = useTranslation();

    return (
        <AdminContainer testID="accountPaymentScreen">
            <View>
                <Tabs
                    labels={[
                        t("backoffice.customer.headline", "Customers"),
                        t(
                            "backoffice.customer_group.headline",
                            "Customer groups"
                        ),
                    ]}
                >
                    <CustomersTab />
                    <CustomerGroupsTab />
                </Tabs>
            </View>
        </AdminContainer>
    );
}
