import React, { createRef, ReactElement } from "react";
import { useTranslation } from "locales";
import { ErrorBoundary } from "./error-boundary";
import RNRestart from "react-native-restart";
import { Platform } from "react-native";

const appErrorBoundaryRef = createRef<ErrorBoundary>();

const handleRestart = () => {
    if (Platform.OS === "web") {
        // Node has implemented the globalThis object,
        // which (in a browser environment) is just a reference to the Window object
        globalThis.location.reload();
        return;
    }

    RNRestart.Restart();
};

/**
 * A global function to abort the app to the error screen
 *
 * @param error - An Error object describing the abort is required as an argument to abort the application
 * @throws Always throw to avoid running any further code
 */
export function abort(error: Error) {
    if (appErrorBoundaryRef.current) {
        appErrorBoundaryRef.current.abort(error);
    } else {
        console.error(
            "Trying to abort the app before the AppErrorBoundary has been mounted to the providers stack.",
            error
        );
    }

    // We should throw the error, to make sure that further code is run on the current stack.
    throw error;
}

export function AppErrorBoundary(props: { children: ReactElement }) {
    const [t] = useTranslation();

    return (
        <ErrorBoundary
            ref={appErrorBoundaryRef}
            buttonText={t("app_errors.button_text", "Restart application")}
            title={t("app_errors.title", "Oops!")}
            description={t(
                "app_errors.description",
                "An error occurred. A message has been sent to our technical team."
            )}
            onPress={handleRestart}
        >
            {props.children}
        </ErrorBoundary>
    );
}
