import {
    LoadingScreen,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { useMerchantLazyQuery, useMerchantSaveMutation } from "graphql-sdk";
import { IMerchantInput, schemaMerchantInput } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { MerchantScreen } from "../merchant-screen";
import { EditForm } from "./edit-form";

type ScreenProps = RootStackScreenProps<"MERCHANT_EDIT">;

export function Edit({ navigation: { navigate }, route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("admin.merchant.write");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [merchantEdit] = useMerchantSaveMutation();
    const { handleMutationError } = useHandleMutationError();
    const [getMerchant, merchant] = useMerchantLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IMerchantInput>(schemaMerchantInput, null);
    const [{ values }, { setDefaultValues }] = form;
    const merchantId = route.params.id;

    useEffect(() => {
        if (!merchantId) {
            navigate("MERCHANTS");
        } else {
            getMerchant({ variables: { id: merchantId } });
        }
    }, [getMerchant, navigate, merchantId]);

    useEffect(() => {
        if (merchant.data && merchant.data.merchant) {
            const p = merchant.data.merchant;
            setDefaultValues({
                name: p.name,
            });
        }
    }, [merchant.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!merchant.data || !merchant.data.merchant || !values) {
            return;
        }

        await handleMutationError(
            async () => {
                await merchantEdit({
                    variables: {
                        id: merchant.data!.merchant!.id,
                        merchant: {
                            name: values.name,
                        },
                    },
                });
            },
            t("common.saved", "Saved"),
            () => {
                navigate("MERCHANTS", { refetch: true });
            }
        );
    }, [merchant.data, values, handleMutationError, t, merchantEdit, navigate]);

    if (!merchantId || !merchant.data || !merchant.data.merchant) {
        return <LoadingScreen style="light" />;
    }

    return (
        <MerchantScreen>
            <Surface>
                <View style={styles.container}>
                    <EditForm
                        form={form}
                        onSubmit={edit}
                        submitButton={["common.save", "Save"]}
                    />
                </View>
            </Surface>
        </MerchantScreen>
    );
}
const styleFunc: StyleFunction = theme => {
    return {
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
