import { InputControl, TextInput, Form, Button } from "@venuepos/react-common";
import { useTranslation } from "locales";
import React from "react";
import { View } from "react-native";
import { ResetPasswordData } from "./request-token";

export function RequestTokenForm({
    form,
    handleSubmit,
}: {
    form: Form<ResetPasswordData>;
    handleSubmit: () => void;
}) {
    const [t] = useTranslation();
    const [{ values }, { setValue }] = form;

    return (
        <View>
            <InputControl>
                <TextInput
                    testID="email"
                    label={t("backoffice.password_form.email", "Email")}
                    placeholder={t(
                        "backoffice.password_form.enter_email_placeholder",
                        "Enter email"
                    )}
                    onChangeText={text => setValue("email", text)}
                    required={true}
                    value={values?.email}
                />
            </InputControl>
            <Button
                type="primary"
                onPress={handleSubmit}
                testID="button:resetPassword"
            >
                {t("backoffice.password_form.button_forgot_password", "Send")}
            </Button>
        </View>
    );
}
