import { useForm } from "@venuepos/react-common";
import { GQTagInput, useTagCreateMutation } from "graphql-sdk";
import { schemaTag, defaultTag, ITag } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import type { AssignTagsModalOutput } from "./assign-tags-modal";
import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { ModalFriendlyForm } from "../modal-friendly-form";

export function TagsCreateTab({
    onClose,
    enabledTags,
}: {
    onClose: (tags?: AssignTagsModalOutput) => void;
    enabledTags: ITag["id"][];
}) {
    const [t] = useTranslation();
    const [tagCreate] = useTagCreateMutation();
    const form = useForm<GQTagInput>(schemaTag, defaultTag);
    const [{ values }, { reset }] = form;
    const { handleMutationError } = useHandleMutationError();

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () => {
                const result = await tagCreate({
                    variables: {
                        tag: {
                            identifier: values.identifier,
                            name: values.name,
                            tagType: values.tagType,
                            groupId: values.groupId || null,
                            accountId: values.accountId || null,
                        },
                    },
                });
                if (result.data?.tagCreate.id) {
                    onClose([result.data.tagCreate.id].concat(enabledTags));
                }
            },
            t("common.created", "Created"),
            () => {
                reset();
            }
        );
    }, [
        values,
        handleMutationError,
        t,
        tagCreate,
        onClose,
        enabledTags,
        reset,
    ]);

    return (
        <ModalFriendlyForm
            form={form}
            onSubmit={create}
            submitButton={["common.create", "Create"]}
        />
    );
}
