import React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export const LogoDark = (props: SvgProps): JSX.Element => (
    <Svg height="100%" width="100%" viewBox="0 0 97.2 85.6" {...props}>
        <Path
            fill="#201c35"
            d="m97.212 20.723-11.53 63.25a1.24 1.24 0 0 1-1.19 1l-22.67.52a1.24 1.24 0 0 1-1.11-1.84l35.4-63.36a.62.62 0 0 1 1.1.43zm-97.2 6.92 12.37 56.34a1.23 1.23 0 0 0 1.18 1l22.8.57a1.23 1.23 0 0 0 1.07-1.9l-36.3-56.48a.62.62 0 0 0-1.12.47zm18.75-22.63 28 67.76a2.47 2.47 0 0 0 4.62-.13l24.76-71a1.23 1.23 0 0 0-1.24-1.64l-55.08 3.3a1.23 1.23 0 0 0-1.06 1.71z"
        />
    </Svg>
);
