import {
    Button,
    Headline,
    InputControl,
    Spacer,
    Surface,
    Text,
    TextInput,
    useTheme,
    useToast,
} from "@venuepos/react-common";
import React, { useCallback, useState } from "react";
import { useTranslation } from "locales";
import { GQRoleEditFormQuery, useRoleDeleteMutation } from "graphql-sdk";
import { useNavigation } from "@react-navigation/native";

export function RoleDeleteForm({
    name,
    role,
}: {
    name: string;
    role: Exclude<GQRoleEditFormQuery["role"], null | undefined>;
}) {
    const { navigate } = useNavigation();
    const [deleteRoleQuery] = useRoleDeleteMutation();
    const theme = useTheme();
    const toast = useToast();
    const [t] = useTranslation();
    const [enteredName, setEnteredName] = useState<string>("");
    const [error, setError] = useState<string>();

    const deleteRole = useCallback(async () => {
        if (enteredName !== name) {
            setError(
                t(
                    "backoffice.role_form.wrong_name",
                    "You have not entered the correct name."
                )
            );
            return;
        } else {
            setError(undefined);
        }

        await deleteRoleQuery({ variables: { id: role.id } });

        toast.success(t("backoffice.role_form.deleted", "Role deleted"));

        navigate("ROLES");
    }, [deleteRoleQuery, enteredName, name, navigate, role.id, t, toast]);

    return (
        <Surface>
            <Headline size="h3">
                {t("backoffice.role_form.delete_headline", "Delete role")}
            </Headline>
            {role.inUse ? (
                <>
                    <Text>
                        {t(
                            "backoffice.role_form.cannot_delete_role_in_use",
                            "This role is in use. To delete this role, you'll need to make sure, that no users is assigned to this role."
                        )}
                    </Text>
                </>
            ) : (
                <>
                    <Text>
                        {t(
                            "backoffice.role_form.delete_info",
                            "To delete this role, you'll have to enter the name of the role into the field below, and click the delete button."
                        )}
                    </Text>
                    <Spacer space={2} />
                    <InputControl error={error}>
                        <TextInput
                            onChangeText={text => setEnteredName(text)}
                        />
                    </InputControl>
                    <Button
                        style={{ backgroundColor: theme.colors.error }}
                        onPress={deleteRole}
                    >
                        {t("backoffice.role_form.delete_button", "Delete role")}
                    </Button>
                </>
            )}
        </Surface>
    );
}
