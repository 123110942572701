import React from "react-native";
import {
    Loading,
    InputControl,
    NumberInput,
    Form,
    RequiredText,
    Button,
    useForm,
} from "@venuepos/react-common";
import { EconomicSettings, EconomicSettingsInput } from "lib";
import { useTranslation } from "locales";
import {
    GQIntegrationQuery,
    useEconomicSettingsSaveMutation,
    useIntegrationSettingsLazyQuery,
} from "graphql-sdk";
import { useCallback, useEffect } from "react";
import { useHandleMutationError } from "../../hooks";
import { schemaEconomicSettings } from "./forms";

export function EconomicSettingsTab({
    integration,
}: {
    integration: GQIntegrationQuery["integration"];
}) {
    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [economicSettingsSave] = useEconomicSettingsSaveMutation();
    const form = useForm<EconomicSettingsInput>(schemaEconomicSettings, null);

    const [{ values }, { setDefaultValues }] = form;

    const edit = useCallback(async () => {
        if (
            !integration ||
            !integration.type ||
            integration.type !== "E-CONOMIC" ||
            !values
        ) {
            return;
        }

        await handleMutationError(async () => {
            const settingsInput: EconomicSettingsInput = {
                journalId: values.journalId,
            };

            await economicSettingsSave({
                variables: {
                    integrationId: integration.id,
                    settings: settingsInput,
                },
            });
        }, t("common.saved", "Saved"));
    }, [integration, values, handleMutationError, t, economicSettingsSave]);

    const [getIntegrationSettings, integrationSettings] =
        useIntegrationSettingsLazyQuery({
            fetchPolicy: "no-cache",
        });

    useEffect(() => {
        if (!integrationSettings?.data) {
            return;
        }

        const settings = integrationSettings.data?.integrationSettings;

        const defaultValues = {
            journalId: (settings as EconomicSettings).journalId ?? 1,
        };

        setDefaultValues(defaultValues);
    }, [integrationSettings, setDefaultValues]);

    useEffect(() => {
        if (!integration?.id) {
            return;
        }

        getIntegrationSettings({
            variables: { integrationId: integration.id },
        });
    }, [getIntegrationSettings, integration?.id]);

    if (
        !integration ||
        !integrationSettings.data ||
        integrationSettings.loading
    ) {
        return <Loading />;
    }

    return (
        <EconomicSettingsForm
            form={form}
            onSubmit={edit}
            submitButton={["common.edit", "Edit"]}
        />
    );
}

function EconomicSettingsForm({
    form,
    onSubmit,
    submitButton,
}: {
    form: Form<EconomicSettingsInput>;
    onSubmit: () => Promise<void>;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <InputControl
                error={errors.journalId}
                description={t(
                    "backoffice.integrations.economic.journal_id_description",
                    "The id of the journal with e-conomic. Find it by going to the Visma e-conomic site and locate the id i.e. via the URL"
                )}
            >
                <NumberInput
                    label={t(
                        "backoffice.integrations.economic.journal_id",
                        "Journal id"
                    )}
                    placeholder={t(
                        "backoffice.integrations.economic.journal_id_enter",
                        "Enter journal ID from e-conomic"
                    )}
                    defaultValue={(values as EconomicSettingsInput).journalId}
                    onChange={value => setValue("journalId", value!)}
                    required={true}
                    min={0}
                />
            </InputControl>
            <Button onPress={handleSubmit(onSubmit)} testID="save">
                {t(submitButton[0], submitButton[1])}
            </Button>
            <RequiredText />
        </>
    );
}
