import React, { useEffect, useState } from "react";
import { View, ViewStyle, StyleProp } from "react-native";
import { StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { contrastToBlack } from "../../utils";
import { Icon } from "../icon";
import { Text } from "../text";

export type AlertType = "success" | "warning" | "error" | "info";

export function Alert({
    children,
    type,
    style,
    delay,
    testID,
}: {
    type: AlertType;
    children: JSX.Element | string;
    style?: StyleProp<ViewStyle>;
    delay?: number; // Given in milliseconds
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const bgColor = useToastColor(type);
    const textColor = contrastToBlack(bgColor)
        ? theme.colors.textLight
        : theme.colors.textDark;

    const [hidden, setHidden] = useState<boolean>(delay !== undefined);
    useEffect(() => {
        if (!delay) {
            return;
        }

        const timeout = setTimeout(() => {
            setHidden(false);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    if (hidden) {
        return null;
    }

    return (
        <View
            style={[
                styles.alert,
                {
                    borderColor: theme.colors.textLight,
                    backgroundColor: bgColor,
                },
                style,
            ]}
        >
            <Icon name={type} color={textColor} />
            <Text
                style={[styles.message, { color: textColor }]}
                testID={testID}
            >
                {children}
            </Text>
        </View>
    );
}

function useToastColor(type: AlertType) {
    const theme = useTheme();
    switch (type) {
        case "error":
            return theme.colors.error;
        case "warning":
            return theme.colors.warning;
        case "info":
            return theme.colors.info;
        case "success":
            return theme.colors.success;
        default:
            return theme.colors.toastDefault;
    }
}

const styleFunc: StyleFunction = theme => ({
    alert: {
        borderRadius: theme.borderRadiusTiny,
        paddingHorizontal: theme.spacingScale * 1.6,
        paddingVertical: theme.spacingScale,
        shadowColor: theme.colors.black,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        opacity: 0.95,
        flexDirection: "row",
        alignItems: "center",
    },
    message: {
        ...theme.fonts.regular,
        fontWeight: "600",
        marginHorizontal: theme.spacingScale,
        fontSize: 14,
    },
});
