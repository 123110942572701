import { LoadingScreen, useAuth, useForm } from "@venuepos/react-common";
import {
    useProductGroupLazyQuery,
    useProductGroupSaveMutation,
} from "graphql-sdk";
import { IProductGroupInput, schemaProductGroup } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { AdminContainer } from "../../../container";
import { ProductGroupForm } from "../product-group-form";

type ScreenProps = RootStackScreenProps<"PRODUCT_GROUP_EDIT">;

export function ProductGroupEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.product");

    const [t] = useTranslation();
    const [productGroupEdit] = useProductGroupSaveMutation();
    const { handleMutationError } = useHandleMutationError();
    const [getProductGroup, productGroup] = useProductGroupLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IProductGroupInput>(schemaProductGroup, null);
    const [{ values }, { setDefaultValues }] = form;
    const groupId = route.params.id;

    useEffect(() => {
        if (!groupId) {
            navigate("PRODUCT_GROUPS");
            return;
        }

        getProductGroup({ variables: { id: groupId } });
    }, [getProductGroup, navigate, groupId]);

    useEffect(() => {
        if (productGroup.data && productGroup.data.productGroup) {
            const pg = productGroup.data.productGroup;
            setDefaultValues({
                externalId: pg.externalId || null,
                name: pg.name,
                vatId: pg.vat.id,
                color: pg.color,
                excludeFromDiscounts: pg.excludeFromDiscounts,
                printerId: pg.printer?.id || null,
                isExternal: pg.isExternal,
            });
        }
    }, [productGroup.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!productGroup.data || !productGroup.data.productGroup || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await productGroupEdit({
                    variables: {
                        id: productGroup.data!.productGroup!.id,
                        productGroup: {
                            externalId: values.externalId,
                            name: values.name,
                            vatId: values.vatId!,
                            color: values.color,
                            excludeFromDiscounts: values.excludeFromDiscounts,
                            printerId: values.printerId || null,
                            isExternal: values.isExternal,
                        },
                    },
                }),
            t("backoffice.product_group.saved", "Product group saved"),
            () => {
                navigate("PRODUCT_GROUPS", { refetch: true });
            }
        );
    }, [
        handleMutationError,
        navigate,
        productGroup.data,
        productGroupEdit,
        t,
        values,
    ]);

    if (!productGroup.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <AdminContainer>
            <ProductGroupForm
                form={form}
                onSubmit={edit}
                submitButton={["common.save", "Save"]}
            />
        </AdminContainer>
    );
}
