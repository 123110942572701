import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import { StyleFunction, useThemedStyle, StyleData } from "../../theme";
import { Text } from "../text";
import { Button } from "../button";
import { ModalWrapper, useModal } from "../modal";

type ConfirmModalProps = {
    headerText: string;
    bodyText: string | ReactElement;
    onClose: (val: boolean) => void;
    timeout?: number;

    /**
     * This arguments determines if the modal should "cancel" or "confirm" at the end of the timeout.
     *
     * Options: true = confirm, false = cancel
     * Default: true = confirm
     */
    confirmOnTimeout?: boolean;
    testID?: string;
};

export function ConfirmModal({
    headerText,
    bodyText,
    onClose,
    timeout = 0,
    confirmOnTimeout = true,
    testID,
}: ConfirmModalProps) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [timeRemaining, setTimeRemaining] = useState<number | undefined>(
        timeout
    );

    const handleCloseModal = useCallback(() => {
        return onClose(confirmOnTimeout);
    }, [confirmOnTimeout, onClose]);

    useEffect(() => {
        if (!timeout || timeRemaining === undefined) {
            return;
        }

        let timer = setInterval(() => {
            if (timeRemaining === 0) {
                clearInterval(timer);
                handleCloseModal();
            } else {
                setTimeRemaining(timeRemaining - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [handleCloseModal, timeRemaining, timeout]);

    return (
        <ModalWrapper
            onClose={() => onClose(false)}
            showCloseIcon={false}
            title={headerText}
            testID={testID}
        >
            <View
                style={[
                    styles.container,
                    headerText ? undefined : styles.topPadding,
                ]}
            >
                {typeof bodyText === "string" ? (
                    <Text>{bodyText}</Text>
                ) : (
                    bodyText
                )}

                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => {
                            onClose(false);
                        }}
                        style={styles.buttonCancel}
                        testID="modal:cancel"
                    >
                        {t("common.cancel", "Cancel")}
                        {!!timeout &&
                            !confirmOnTimeout &&
                            confirmOnTimeout !== undefined &&
                            ` (${timeRemaining})`}
                    </Button>
                    <Button
                        type="primary"
                        onPress={() => {
                            onClose(true);
                        }}
                        style={styles.buttonConfirm}
                        testID="modal:confirm"
                    >
                        {t("backoffice.confirm", "Accept")}
                        {!!timeout &&
                            (confirmOnTimeout === undefined ||
                                confirmOnTimeout) &&
                            ` (${timeRemaining})`}
                    </Button>
                </View>
            </View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => {
    let styling: StyleData = {
        container: {
            padding: theme.spacingScale * 2,
            paddingTop: 0,
            maxWidth: 500,
        },
        buttonContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: theme.spacingScale * 2,
        },
        topPadding: {
            paddingTop: theme.spacingScale * 2,
        },
        buttonCancel: {
            marginRight: theme.spacingScale,
        },
        buttonConfirm: {
            marginLeft: theme.spacingScale,
        },
    };

    if (dimensions.width <= theme.breakpoint.portraitWidth) {
        styling = {
            ...styling,

            container: {
                ...styling.container,
                width: dimensions.width - theme.spacingScale * 2,
                maxWidth: "auto",
            },
        };
    }

    return styling;
};

export function useConfirm() {
    const { render } = useModal<boolean>();

    return useCallback(
        (
            headerText: ConfirmModalProps["headerText"],
            bodyText: ConfirmModalProps["bodyText"],
            timeout?: ConfirmModalProps["timeout"],
            confirmOnTimeout?: ConfirmModalProps["confirmOnTimeout"],
            testID?: string
        ) =>
            render(onClose => (
                <ConfirmModal
                    headerText={headerText}
                    bodyText={bodyText}
                    onClose={onClose}
                    timeout={timeout}
                    confirmOnTimeout={confirmOnTimeout}
                    testID={testID}
                />
            )),
        [render]
    );
}
