import {
    InputControl,
    DateTimePickerInput,
    Spacer,
    StyleFunction,
    useThemedStyle,
    useForm,
} from "@venuepos/react-common";
import { dateToDateTime } from "lib";
import { useTranslation } from "locales";
import React, { View } from "react-native";
import { schemaSAFTExportForm, defaultSAFTExportForm } from "./validation";

export function DateForm({
    onDateChange,
    formValues,
}: {
    onDateChange: (date: Date, dateField: string) => void;
    formValues: typeof defaultSAFTExportForm;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const [{ errors: formErrors }] = useForm(
        schemaSAFTExportForm,
        defaultSAFTExportForm
    );

    return (
        <View style={styles.row}>
            <InputControl error={formErrors.from}>
                <DateTimePickerInput
                    dateTimeType="date"
                    label={t("common.from", "From")}
                    value={dateToDateTime(formValues.from)}
                    onChangeDate={d => onDateChange(d, "from")}
                />
            </InputControl>
            <Spacer space={2} />
            <InputControl error={formErrors.to}>
                <DateTimePickerInput
                    dateTimeType="date"
                    label={t("common.to_including", "To (including)")}
                    value={dateToDateTime(formValues.to)}
                    onChangeDate={d => onDateChange(d, "to")}
                />
            </InputControl>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    row: { ...theme.styles.row },
});
