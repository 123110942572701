import { AdminContainer } from "../container";
import React, { useCallback } from "react";
import { RootStackScreenProps } from "../../navigation";
import {
    Button,
    PageTitle,
    StyleFunction,
    Surface,
    Text,
    useMe,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { Linking, View } from "react-native";
import { useTranslation } from "locales";
import { FlatList } from "react-native-gesture-handler";
import { useSumUpClient } from "./use-sumup-client";
import { useHandleMutationError } from "../../hooks";
import { useEntityConfigSaveMutation } from "graphql-sdk";

type ScreenProps = RootStackScreenProps<"SUMUP_AUTHORIZATION">;

export function SumUpAuthorizationScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { getAccessToken } = useSumUpClient();
    const { handleMutationError } = useHandleMutationError();
    const [saveEntityConfig] = useEntityConfigSaveMutation();
    const merchantConfig = useMerchantConfig();
    const me = useMe();

    if (route.params?.code) {
        getAccessToken(route.params.code).then((refreshToken: string) => {
            if (
                refreshToken !== undefined &&
                refreshToken !== "" &&
                merchantConfig.sumupRefreshToken !== refreshToken
            ) {
                handleMutationError(
                    async () =>
                        await saveEntityConfig({
                            variables: {
                                entityId: me.merchant!.id,
                                partialData: JSON.stringify({
                                    sumupRefreshToken: refreshToken,
                                }),
                                disabledData: [],
                            },
                        }),
                    t("backoffice.settings.sumup.updated", "SumUp configured"),
                    () => {
                        // Navigate to the same page to get rid of the query parameter
                        navigate("SUMUP_AUTHORIZATION");
                    }
                );
            } else {
                // Navigate to the same page to get rid of the query parameter
                navigate("SUMUP_AUTHORIZATION");
            }
        });
    }

    const onOpenSumUp = useCallback(() => {
        Linking.openURL(
            "https://api.sumup.com/authorize?response_type=code&client_id=" +
                merchantConfig.sumupClientId +
                "&redirect_uri=" +
                merchantConfig.sumupRedirectUrl +
                "&scope=transactions.history%20user.app-settings%20user.profile_readonly%20payments"
        );
    }, [merchantConfig.sumupClientId, merchantConfig.sumupRedirectUrl]);

    return (
        <AdminContainer>
            <View style={styles.container}>
                <PageTitle>
                    {t("backoffice.sumup.headline", "SumUp setup")}
                </PageTitle>
            </View>

            <View style={styles.box}>
                <Surface>
                    <Text>
                        {t(
                            "backoffice.sumup.authorization_description",
                            "Pressing the button will open the SumUp website where you must allow Venue POS to do the following:"
                        )}
                    </Text>

                    <FlatList
                        data={[
                            {
                                key: t(
                                    "backoffice.sumup.authorization_option_payments",
                                    "Make payments by creating and processing checkouts."
                                ),
                            },
                            {
                                key: t(
                                    "backoffice.sumup.authorization_option_transactions_history",
                                    "View your transactions and transaction history."
                                ),
                            },
                            {
                                key: t(
                                    "backoffice.sumup.authorization_option_user_app_settings",
                                    "View and manage your SumUp mobile application settings."
                                ),
                            },
                            {
                                key: t(
                                    "backoffice.sumup.authorization_option_user_profile_readonly",
                                    "View your merchant profile information"
                                ),
                            },
                        ]}
                        renderItem={({ item }) => {
                            return (
                                <View style={styles.listItem}>
                                    <Text>{`\u2022 ${item.key}`}</Text>
                                </View>
                            );
                        }}
                        style={styles.list}
                    />

                    <Button onPress={onOpenSumUp}>
                        {t("backoffice.sumup.open_sumup", "Open SumUp")}
                    </Button>
                </Surface>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    list: { marginVertical: 10 },
    listItem: { marginLeft: 10, marginBottom: 10 },
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale,
        flexWrap: "wrap",
    },
    box: {
        maxWidth: theme.dimensions.maxFormWidth,
        backgroundColor: theme.colors.white,
    },
});
