import {
    Alert,
    Cell,
    DataTable,
    Headline,
    Loading,
    PageTitle,
    Row,
    Spacer,
    StyleFunction,
    Surface,
    Table,
    Text,
    Title,
    TooltipInfoIcon,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQSoftpayAcquirer,
    GQSoftpayMiaMerchantQuery,
    useSoftpayMiaMerchantQuery,
} from "graphql-sdk";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { styleFunc as sharedStyleFunc } from "./styles";

type ScreenProps = RootStackScreenProps<"SOFTPAY_MIA_MERCHANT">;

export function SoftpayMiaMerchantScreen({
    route,
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const merchantId = decodeURI(route.params.merchantId);
    const acquirer = decodeURI(route.params.acquirer);

    const styles = useThemedStyle(styleFunc);
    // const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(sharedStyleFunc);
    const [t] = useTranslation();

    const { data, loading, error } = useSoftpayMiaMerchantQuery({
        variables: {
            acquirer: acquirer as GQSoftpayAcquirer,
            merchantId: merchantId,
        },
        fetchPolicy: "no-cache",
    });

    const handleClick = useCallback(
        (storeId: string) => {
            navigate("SOFTPAY_MIA_STORE", {
                merchantId,
                storeId,
                acquirer,
            });
        },
        [acquirer, merchantId, navigate]
    );

    const renderSoftpayMiaMerchantRow = useCallback(
        (
            item: GQSoftpayMiaMerchantQuery["softpayMiaMerchant"]["stores"][0],
            rowIndex
        ) => {
            if (!item) {
                return;
            }

            return (
                <DataTable.Row
                    onPress={() => handleClick(item.storeId)}
                    style={rowIndex % 2 === 0 ? sharedStyles.oddRow : undefined}
                    key={`store_${rowIndex}`}
                >
                    <DataTable.Cell>{item.name}</DataTable.Cell>
                    <DataTable.Cell>{item.storeId}</DataTable.Cell>
                    <DataTable.Cell>{item.addressLine1}</DataTable.Cell>
                    <DataTable.Cell>{item.addressLine2}</DataTable.Cell>
                    <DataTable.Cell>{item.zipCode}</DataTable.Cell>
                    <DataTable.Cell>{item.city}</DataTable.Cell>
                    <DataTable.Cell>{item.phone}</DataTable.Cell>
                    <DataTable.Cell>{item.businessRegNo}</DataTable.Cell>
                    <DataTable.Cell>{item.state}</DataTable.Cell>
                </DataTable.Row>
            );
        },
        [handleClick, sharedStyles.oddRow]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t(
                    "backoffice.softpay_mia_merchant_list.softpay_mia_merchant",
                    "Softpay MIA merchant"
                )}
            </PageTitle>
            <Surface>
                {loading ? (
                    <Loading />
                ) : data?.softpayMiaMerchant === undefined ? (
                    t(
                        "backoffice.softpay_mia_merchant.no_data",
                        "No data returned"
                    )
                ) : (
                    <Table>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t("backoffice.common.name", "Name")}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.merchantName}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_merchant.merchant_id",
                                    "Merchant ID"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.merchantId}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_merchant.merchant_reference",
                                    "Merchant reference"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.merchantReference}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_merchant.aid_group",
                                            "Aid List Group Name"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Supported Aid List Group Name" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.aidGroup}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_merchant.business_reg_no",
                                    "Business Register Number"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.businessRegNo}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_merchant.country_code",
                                            "Country code"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Merchant's country code in ISO 3166 (Alpha2 Code) format" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.countryCode}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_merchant.primary_contact_email",
                                    "Primary contact email"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.primaryContactEmail}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_merchant.primary_contact_phone",
                                    "Primary contact phone"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.primaryContactPhone}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_merchant.state",
                                    "State"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaMerchant.state}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_merchant.local_privileges",
                                            "Local privileges"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="The privileges guarding operations in the Softpay SDK" />
                                </>
                            </Cell>
                            <Cell
                                style={[
                                    styles.privileges,
                                    sharedStyles.dataCell,
                                ]}
                            >
                                <Text style={styles.privilegeText}>
                                    {data.softpayMiaMerchant.localPrivileges
                                        .sort()
                                        .map(
                                            (item, rowIndex) =>
                                                `${item}${
                                                    rowIndex <
                                                    data.softpayMiaMerchant
                                                        .remotePrivileges
                                                        .length -
                                                        1
                                                        ? ", "
                                                        : ""
                                                }`
                                        )}
                                </Text>
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_merchant.remote_privileges",
                                            "Remote privileges"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="The privileges guarding operations in the App Switch SDK" />
                                </>
                            </Cell>
                            <Cell
                                style={[
                                    styles.privileges,
                                    sharedStyles.dataCell,
                                ]}
                            >
                                <Text style={styles.privilegeText}>
                                    {data.softpayMiaMerchant.remotePrivileges
                                        .sort()
                                        .map(
                                            (item, rowIndex) =>
                                                `${item}${
                                                    rowIndex <
                                                    data.softpayMiaMerchant
                                                        .remotePrivileges
                                                        .length -
                                                        1
                                                        ? ", "
                                                        : ""
                                                }`
                                        )}
                                </Text>
                            </Cell>
                        </Row>
                    </Table>
                )}
            </Surface>
            <Spacer space={2} />
            <Surface>
                <Headline size="h2" style={styles.headline}>
                    {t(
                        "backoffice.softpay_mia_merchant.stores",
                        "Stores for merchant"
                    )}
                </Headline>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title>
                            {t("backoffice.common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_store.store_id",
                                "Store ID"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_store.address_line_1",
                                "Address"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_store.address_line_2",
                                "Address 2"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_store.zip_code",
                                "Zip code"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.softpay_mia_store.city", "City")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.softpay_mia_store.phone", "Phone")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.softpay_mia_store.business_reg_no",
                                "Business Register Number"
                            )}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.softpay_mia_store.state", "State")}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : data?.softpayMiaMerchant.stores.length === 0 ? (
                        <DataTable.Row>
                            <DataTable.Cell>
                                {t(
                                    "backoffice.softpay_mia_store.no_stores",
                                    "No stores for merchant."
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                    ) : (
                        data?.softpayMiaMerchant.stores.map(
                            renderSoftpayMiaMerchantRow
                        )
                    )}
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    privileges: {
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        marginVertical: theme.spacingScale / 2,
    },
    privilegeText: {
        ...theme.styles.text,
        fontSize: 14,
    },
    headline: {
        color: theme.colors.textDark,
    },
});
