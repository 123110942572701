import {
    HeaderBackButton,
    HeaderBackButtonProps,
} from "@react-navigation/elements";
import React, { useCallback, useMemo } from "react";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import {
    useThemedStyle,
    useDimensions,
    PERMANENT_DRAWER_WIDTH,
    IconButton,
    StyleFunction,
    Icon,
    useTheme,
} from "@venuepos/react-common";
import { View } from "react-native";

export function LeftHeader({
    buttonProps,
}: {
    buttonProps: HeaderBackButtonProps;
}): JSX.Element {
    const { dispatch } = useNavigation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const { width } = useDimensions();

    const backImage = useCallback(
        () => <Icon name="arrow-left" color={theme.colors.black} />,
        [theme.colors.black]
    );

    const menuButton = useMemo(() => {
        if (width > PERMANENT_DRAWER_WIDTH) {
            return null;
        }

        return (
            <IconButton
                name="menu"
                color={theme.colors.black}
                onPress={() => dispatch(DrawerActions.toggleDrawer())}
            />
        );
    }, [dispatch, theme.colors.black, width]);

    return (
        <View style={styles.leftHeader}>
            {menuButton}
            {buttonProps.canGoBack ? (
                <HeaderBackButton {...buttonProps} backImage={backImage} />
            ) : null}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    leftHeader: {
        flexDirection: "row",
        marginLeft: theme.spacingScale,
    },
});
