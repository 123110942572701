import "react-datepicker/dist/react-datepicker.min.css";

import {
    da as daLocale,
    enGB as enGBLocale,
    enUS as enUSLocale,
} from "date-fns/locale";
import { dateTimeToDate } from "lib";
import React, { useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "locales";
import {
    StyleProp,
    StyleSheet,
    TextInput,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";

import { StyleFunction, useThemedStyle } from "../../theme";
import { InputLabel } from "../input-label";
import { Portal } from "../portal";

import type { Types } from "./common";
registerLocale("da", daLocale);
registerLocale("da-DK", daLocale);
registerLocale("en-GB", enGBLocale);
registerLocale("en-US", enUSLocale);

// TODO It would be great to one day have this component allow date/time ranges. The DatePicker allows it. We have to adjust the interface for our DateTimePickerInput component to handle this. Take a look at https://reactdatepicker.com/#example-date-range

export function DateTimePickerInput<T extends keyof Types>(props: {
    dateTimeType?: T;
    label?: string;
    disabled?: boolean;
    defaultValue?: Types[T];
    value?: Types[T];
    maxDate?: Date;
    onChange?: (date: Types[T] | undefined) => void;
    onChangeDate?: (date: Date) => void; // Sometimes it's nice to get the actual Date object
    style?: StyleProp<ViewStyle | TextStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const [, { language }] = useTranslation();

    // const
    const date =
        (props.value && dateTimeToDate(props.value)) ||
        (props.defaultValue && dateTimeToDate(props.defaultValue));

    const style = StyleSheet.flatten([
        styles.container,
        props.disabled ? styles.disabled : undefined,
    ]);

    const handleChange = useCallback(
        (d: Date | null) => {
            if (!d) {
                return;
            }

            if (props.onChange) {
                const dvalue = decodeEventValue(
                    props.dateTimeType || "dateTime",
                    d
                );
                props.onChange(dvalue as any);
            }

            if (props.onChangeDate) {
                props.onChangeDate(d);
            }
        },
        [props]
    );

    let dateFormat = "p"; // time only
    if (props.dateTimeType === "dateTime") {
        dateFormat = "Pp";
    }
    if (props.dateTimeType === "date") {
        dateFormat = "P";
    }

    const popperContainer = useCallback(
        ({ children }) => <Portal>{children}</Portal>,
        []
    );

    return (
        <View style={[props.style]}>
            {props.dateTimeType === "time" ? (
                <DatePicker
                    timeCaption={props.label}
                    locale={language}
                    value={date as any}
                    selected={date}
                    onChange={handleChange}
                    dateFormat={dateFormat}
                    popperContainer={popperContainer} // Renders Datepicker in the upper scope of DOM
                    customInput={
                        <TextInput
                            testID={props.testID}
                            accessibilityRole="search"
                            style={style}
                        />
                    }
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    timeIntervals={15}
                    popperPlacement="auto"
                />
            ) : (
                <>
                    {props.label && <InputLabel>{props.label}</InputLabel>}
                    <DatePicker
                        title={props.label}
                        locale={language}
                        showTimeInput={
                            props.dateTimeType === "dateTime" || false
                        }
                        value={date as any}
                        selected={date}
                        onChange={handleChange}
                        dateFormat={dateFormat}
                        popperContainer={popperContainer} // Renders Datepicker in the upper scope of DOM
                        customInput={
                            <TextInput
                                testID={props.testID}
                                accessibilityRole="search"
                                style={style}
                            />
                        }
                        maxDate={props.maxDate}
                    />
                </>
            )}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        ...theme.styles.input,
        minHeight: 40,
        backgroundColor: theme.colors.white,
        paddingLeft: 6,
        paddingRight: 6,
        borderStyle: "solid",
        borderRadius: theme.borderRadiusSmall,
        width: "100%",
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme.colors.secondary,
        marginBottom: theme.spacingScale,
    },
    disabled: {
        backgroundColor: theme.colors.grey50,
        borderColor: theme.colors.grey500,
        color: theme.colors.grey800,
    },
});

function decodeEventValue<T extends keyof Types>(type: T, date: Date) {
    if (!date) {
        throw new Error("Expecting a date object");
    }
    switch (type) {
        case "time":
            return {
                hour: date.getHours(),
                minute: date.getMinutes(),
            } as Types["time"];

        case "dateTime":
            return {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
                hour: date.getHours(),
                minute: date.getMinutes(),
            } as Types["dateTime"];

        case "date":
            return {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            } as Types["date"];

        default:
            return undefined;
    }
}
