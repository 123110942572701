import { StackScreenProps } from "@react-navigation/stack";
import {
    Alert,
    Button,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useTheme,
} from "@venuepos/react-common";
import {
    usePaymentConfigurationDeleteMutation,
    usePaymentConfigurationsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { StyleSheet, View } from "react-native";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackParamList } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "PAYMENT_CONFIGURATIONS"
>;

export function PaymentConfigurationListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        [
            "merchant.payment_configuration.write",
            "merchant.payment_configuration.delete",
        ],
        "OR"
    );

    const [t] = useTranslation();
    const theme = useTheme();
    const { render } = useModal();
    const [paymentConfigurationDelete] =
        usePaymentConfigurationDeleteMutation();
    const { handleMutationError } = useHandleMutationError();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();

    const { data, loading, error, refetch } = usePaymentConfigurationsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.payment_configurations.delete",
                            "Delete payment configuration?"
                        )}
                        bodyText={t(
                            "backoffice.payment_configurations.delete_explain",
                            "If you choose to delete this payment configuration, the cash registers that use this configuration, will stop working. Make sure that no cash register uses this configuration before you continue.\n\nAre you sure, you want to delete this?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            await handleMutationError(
                async () =>
                    await paymentConfigurationDelete({
                        variables: {
                            id: id,
                        },
                    }),
                t(
                    "backoffice.payment_configuration.deleted.success",
                    "Payment configuration deleted"
                ),
                async () => {
                    await refetch();
                }
            );
        },
        [handleMutationError, paymentConfigurationDelete, refetch, render, t]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("PAYMENT_CONFIGURATION_EDIT", {
                id,
            });
        },
        [navigate]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer testID="paymentConfigurationListScreen">
            <View style={styles.container}>
                <Button
                    style={styles.button}
                    onPress={() => {
                        navigate("PAYMENT_CONFIGURATION_CREATE");
                    }}
                    testID="paymentConfiguration:create"
                >
                    {t(
                        "backoffice.payment_configurations.create",
                        "Create new"
                    )}
                </Button>
            </View>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {!loading && data ? (
                        data.paymentConfigurations.data.map((item, index) => (
                            <DataTable.Row
                                onPress={() => handleEdit(item.id)}
                                key={`payment_configuration_${index}`}
                                testID={"paymentConfiguration:" + item.name}
                            >
                                <DataTable.Cell>{item.name}</DataTable.Cell>
                                <DataTable.Cell numeric>
                                    <IconButton
                                        color={theme.colors.secondary}
                                        name="edit"
                                        onPress={() => handleEdit(item.id)}
                                        testID={
                                            "paymentConfiguration:edit:" +
                                            item.name
                                        }
                                    />
                                    <IconButton
                                        color={theme.colors.secondary}
                                        name="delete"
                                        onPress={() => handleDelete(item.id)}
                                        testID={
                                            "paymentConfiguration:delete:" +
                                            item.name
                                        }
                                    />
                                </DataTable.Cell>
                            </DataTable.Row>
                        ))
                    ) : (
                        <Loading />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={
                            data?.paymentConfigurations.pagination.pages
                        }
                        itemCount={
                            data?.paymentConfigurations.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        marginBottom: 20,
        flexWrap: "wrap",
    },
    button: { width: 200, alignSelf: "flex-end" },
    sortIcon: {
        justifyContent: "flex-end",
    },
});
