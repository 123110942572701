import React from "react";
import { useTranslation } from "locales";
import { useVatFormQuery } from "graphql-sdk";
import {
    Surface,
    InputControl,
    TextInput,
    Button,
    NumberInput,
    RequiredText,
} from "@venuepos/react-common";
import type { Form as FormComponent } from "@venuepos/react-common";
import type { IVatInput } from "lib";

export function Form(props: {
    form: FormComponent<IVatInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data } = useVatFormQuery();

    if (!values || !data) {
        return null;
    }

    return (
        <Surface>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name || ""}
                    onChangeText={text => setValue("name", text)}
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.percentage}>
                <NumberInput
                    label={t("backoffice.vat_form.vat", "VAT percentage")}
                    placeholder={t(
                        "backoffice.vat_form.enter_vat",
                        "Enter VAT percentage"
                    )}
                    defaultValue={values.percentage}
                    decimals={2}
                    min={0}
                    max={100}
                    unit="%"
                    onChange={value => setValue("percentage", Number(value))}
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </Surface>
    );
}
