import React, { useCallback, useState } from "react";
import {
    assignOrderCondition,
    ConditionAssignment,
    Conditions,
    OrderConditions,
} from "lib";
import { useTranslation } from "locales";
import type { GQDiscountFormQuery } from "graphql-sdk";
import { produce } from "immer";
import {
    CheckBox,
    InputControl,
    Loading,
    NumberInput,
    RemovableRow,
} from "@venuepos/react-common";
import { v4 as uuidv4 } from "uuid";
import { SubFormBoxInactive } from "./sub-form-box";
import { GroupConditionInput } from "./group-condition-input";
import { GroupFormBox } from "./group-form-box";
import { isEqual } from "lodash";

export function GroupConditionsForm({
    conditions,
    onConditionsChange,
    formData,
}: {
    conditions: Conditions;
    onConditionsChange: (conditions: Conditions) => void;
    formData: GQDiscountFormQuery;
}) {
    const [keyPrefix, setKeyPrefix] = useState(uuidv4());
    const [t] = useTranslation();

    const handleConditionChange = useCallback(
        (groupKey: number, args: OrderConditions["hasGroupQuantity"]) => {
            onConditionsChange(
                produce(conditions, draft => {
                    draft[groupKey].args = args;
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const handleValueChange = useCallback(
        (key: number, value: boolean) => {
            onConditionsChange(
                produce(conditions, draft => {
                    (
                        draft[key].args as OrderConditions["hasGroupQuantity"]
                    ).ofSameId = value;
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const handleRequiredQuantityChange = useCallback(
        (key: number, value: number) => {
            onConditionsChange(
                produce(conditions, draft => {
                    (
                        draft[key].args as OrderConditions["hasGroupQuantity"]
                    ).requiredQuantity = value;
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const addCondition = useCallback(() => {
        onConditionsChange(
            produce(conditions, draft => {
                // Find the default product. It must not be in any other conditions.
                const defaultProduct = formData.products.data.find(
                    productItr => {
                        return (
                            conditions.find(itr =>
                                (
                                    itr.args as OrderConditions["hasGroupQuantity"]
                                ).productIds.find(
                                    itr2 => itr2 === productItr.id
                                )
                            ) === undefined
                        );
                    }
                );

                if (defaultProduct !== undefined) {
                    draft.push(
                        assignOrderCondition("hasGroupQuantity", {
                            productIds: [defaultProduct.id],
                            requiredQuantity: 1,
                            ofSameId: false,
                        })
                    );
                }
            })
        );
    }, [conditions, formData.products.data, onConditionsChange]);

    const addProductToCondition = useCallback(
        (condition: ConditionAssignment) => {
            onConditionsChange(
                produce(conditions, draft => {
                    // Find the default product. It must not be in any other conditions.
                    const defaultProduct = formData.products.data.find(
                        productItr => {
                            return (
                                conditions.find(itr =>
                                    (
                                        itr.args as OrderConditions["hasGroupQuantity"]
                                    ).productIds.find(
                                        itr2 => itr2 === productItr.id
                                    )
                                ) === undefined
                            );
                        }
                    );

                    if (defaultProduct !== undefined) {
                        const pList = draft.find(itr =>
                            isEqual(
                                (
                                    itr.args as OrderConditions["hasGroupQuantity"]
                                ).productIds,
                                (
                                    condition.args as OrderConditions["hasGroupQuantity"]
                                ).productIds
                            )
                        );
                        (
                            pList?.args as OrderConditions["hasGroupQuantity"]
                        ).productIds.push(defaultProduct.id);
                    }
                })
            );
        },
        [conditions, formData.products.data, onConditionsChange]
    );

    const removeCondition = useCallback(
        (conditionKey: number) => {
            setKeyPrefix(uuidv4());
            onConditionsChange(
                produce(conditions, draft => {
                    draft.splice(conditionKey, 1);
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const removeProductCondition = useCallback(
        (conditionKey: number, productKey: number) => {
            setKeyPrefix(uuidv4());
            onConditionsChange(
                produce(conditions, draft => {
                    (
                        draft[conditionKey]
                            .args as OrderConditions["hasGroupQuantity"]
                    ).productIds.splice(productKey, 1);
                })
            );
            if (
                (
                    conditions[conditionKey]
                        .args as OrderConditions["hasGroupQuantity"]
                ).productIds.length === 1
            ) {
                removeCondition(conditionKey);
            }
        },
        [conditions, onConditionsChange, removeCondition]
    );

    if (!formData) {
        return <Loading />;
    }

    const groupConditions = conditions.filter(
        itr => itr.condition === "hasGroupQuantity"
    );

    if (groupConditions.length === 0) {
        return (
            <SubFormBoxInactive
                text={t(
                    "backoffice.discount_form.add_group_condition",
                    "Add group condition"
                )}
                onPress={() => {
                    addCondition();
                }}
            />
        );
    }

    const hasUnusedConditions =
        // First check groups
        !!formData.products.data.find(
            productItr =>
                !groupConditions.find(
                    conditionItr =>
                        conditionItr.condition === "hasGroupQuantity" &&
                        (
                            conditionItr.args as OrderConditions["hasGroupQuantity"]
                        ).productIds.find(itr => itr === productItr.id)
                )
        );

    return (
        <>
            {groupConditions.map((condition, key) => {
                return (
                    <GroupFormBox
                        headline={t(
                            "backoffice.discount_form.group_conditions",
                            "Group conditions"
                        )}
                        onAddCondition={addProductToCondition}
                        hasUnusedConditions={hasUnusedConditions}
                        key={key}
                        condition={condition}
                    >
                        <InputControl>
                            <NumberInput
                                label={t(
                                    "backoffice.discount_form.quantity",
                                    "Quantity"
                                )}
                                placeholder={t(
                                    "backoffice.discount_form.enter_quantity",
                                    "Enter quantity"
                                )}
                                defaultValue={
                                    (
                                        condition.args as OrderConditions["hasGroupQuantity"]
                                    ).requiredQuantity
                                }
                                onChange={value =>
                                    handleRequiredQuantityChange(
                                        key,
                                        value || 1
                                    )
                                }
                                min={1}
                                decimals={0}
                                required={true}
                            />
                        </InputControl>

                        <InputControl
                            description={t(
                                "backoffice.discount_form.of_same_id_description",
                                "Discount is only applied if all the products is of the same product."
                            )}
                        >
                            <CheckBox
                                label={t(
                                    "backoffice.discount_form.of_same_id",
                                    "Same product"
                                )}
                                value={
                                    (
                                        condition.args as OrderConditions["hasGroupQuantity"]
                                    ).ofSameId
                                }
                                onValueChange={value =>
                                    handleValueChange(key, value)
                                }
                            />
                        </InputControl>

                        {(
                            condition.args as OrderConditions["hasGroupQuantity"]
                        ).productIds.map((productId, productKey) => {
                            return (
                                <RemovableRow
                                    key={key + productKey + keyPrefix}
                                    onRemove={() => {
                                        removeProductCondition(key, productKey);
                                    }}
                                >
                                    <GroupConditionInput
                                        condition={
                                            condition.args as OrderConditions["hasGroupQuantity"]
                                        }
                                        productId={productId}
                                        conditions={conditions}
                                        onConditionChange={args =>
                                            handleConditionChange(key, args)
                                        }
                                        formData={formData}
                                    />
                                </RemovableRow>
                            );
                        })}
                    </GroupFormBox>
                );
            })}
            <SubFormBoxInactive
                text={t(
                    "backoffice.discount_form.add_group_condition",
                    "Add group condition"
                )}
                onPress={() => {
                    addCondition();
                }}
            />
        </>
    );
}
