import React, { useMemo } from "react";
import type { ReactNode } from "react";
import { getAppConfig } from "../../config";
import { ApolloProvider } from "@apollo/client";
import { createClient } from "./client";
import { useAuthSession, AuthTokenRefresher } from "../../hooks";
import { useDeviceProfile } from "../device-profile";
import { getUserAgentSync } from "react-native-device-info";

export function ProvideGraphQL({ children }: { children: ReactNode }) {
    const config = getAppConfig();
    const [, setAuthSession] = useAuthSession(["authToken"]);

    const deviceConfig = useDeviceProfile();

    const client = useMemo(
        () =>
            createClient({
                baseURL: config.backendBaseURL,
                onInvalidAuthToken: () => {
                    setAuthSession("authToken", undefined);
                    setAuthSession("me", undefined);
                },
                trace: deviceConfig?.serialNumber || getUserAgentSync(),
            }),
        [config.backendBaseURL, deviceConfig?.serialNumber, setAuthSession]
    );

    return (
        <ApolloProvider client={client}>
            <>
                <AuthTokenRefresher />
                {children}
            </>
        </ApolloProvider>
    );
}
