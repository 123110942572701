import {
    Alert,
    Cell,
    Loading,
    PageTitle,
    Row,
    StyleFunction,
    Surface,
    Table,
    Text,
    Title,
    TooltipInfoIcon,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQSoftpayAcquirer, useSoftpayMiaTerminalQuery } from "graphql-sdk";
import { currencyCodeToIso, formatDateTime } from "lib";
import { AvailableLocale } from "locales";
import React from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { styleFunc as sharedStyleFunc } from "./styles";

type ScreenProps = RootStackScreenProps<"SOFTPAY_MIA_TERMINAL">;

export function SoftpayMiaTerminalScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const merchantId = decodeURI(route.params.merchantId);
    const storeId = decodeURI(route.params.storeId);
    const terminalId = decodeURI(route.params.terminalId);
    const acquirer = decodeURI(route.params.acquirer);

    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(sharedStyleFunc);
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);

    const { data, loading, error } = useSoftpayMiaTerminalQuery({
        variables: {
            acquirer: acquirer as GQSoftpayAcquirer,
            merchantId: merchantId,
            storeId: storeId,
            terminalId: terminalId,
        },
        fetchPolicy: "no-cache",
    });

    // @Note to be used with the Release button and callback further down.
    // const [releaseTerminalMutation] = useReleaseTerminalMutation();
    // const { handleMutationError } = useHandleMutationError();

    let optionalRowCounter = 0;
    const optionalDataAvailable = (dataSet?: any) => {
        if (!dataSet) {
            return false;
        } else {
            optionalRowCounter++;
            return true;
        }
    };

    // @NOTE This callback is needed when the Release function in MIA works properly. It is used with the Release button futher down.
    // const releaseTerminal = useCallback(async () => {
    //     if (
    //         !(await confirm(
    //             t(
    //                 "backoffice.softpay_mia_merchant_list.release_terminal_header",
    //                 "Release terminal?"
    //             ),
    //             t(
    //                 "backoffice.softpay_mia_merchant_list.release_terminal_explain",
    //                 "If releasing a terminal the POS device/Softpay app using the terminal will no longer be able to receive payments.\nThis cannot be undone.\n\nAre you sure, you want to release this terminal?"
    //             )
    //         ))
    //     ) {
    //         return;
    //     }

    //     await handleMutationError(
    //         async () =>
    //             await releaseTerminalMutation({
    //                 variables: {
    //                     acquirer: acquirer as GQSoftpayAcquirer,
    //                     merchantId: merchantId,
    //                     storeId: storeId,
    //                     terminalId: terminalId,
    //                 },
    //             }),
    //         t(
    //             "backoffice.softpay_mia_merchant_list.terminal_released",
    //             "Terminal released"
    //         ),
    //         async () => {
    //             await refetch();
    //         }
    //     );
    // }, [
    //     acquirer,
    //     confirm,
    //     handleMutationError,
    //     merchantId,
    //     refetch,
    //     releaseTerminalMutation,
    //     storeId,
    //     t,
    //     terminalId,
    // ]);

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={styles.container}>
                <PageTitle>
                    {t(
                        "backoffice.softpay_mia_merchant_list.softpay_mia_terminal",
                        "Softpay MIA terminal"
                    )}
                </PageTitle>
                {/* The "Release" button is disabled until we know that MIA handles Release correctly. Maybe it is ready for v1.50.0? */}
                {/* <View style={styles.actionButtonContainer}>
                    <Button onPress={releaseTerminal}>
                        {t(
                            "backoffice.softpay_mia_terminal.release",
                            "Release"
                        )}
                    </Button>
                </View> */}
            </View>

            <Surface>
                {loading ? (
                    <Loading />
                ) : data?.softpayMiaTerminal === undefined ? (
                    t(
                        "backoffice.softpay_mia_terminal.no_data",
                        "No data returned"
                    )
                ) : (
                    <Table>
                        <Row style={sharedStyles.oddRow}>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.app_id",
                                            "App ID"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="The value of the App Id as shown in the app" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.appId}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_terminal.terminal_id",
                                    "Terminal ID"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.terminalId}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_terminal.online_terminal_id",
                                    "Online terminal ID (TID)"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.onlineTerminalId}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_terminal.online_merchant_id",
                                    "Online merchant ID (MID)"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.onlineMerchantId}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.merchant_category_code",
                                            "Merchant category code"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Merchant Category Code (mcc)" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.merchantCategoryCode}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.default_currency_code",
                                            "Default currency code"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Default Transaction Currency Code in ISO 4217 numeric format" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {`${
                                    data.softpayMiaTerminal.defaultCurrencyCode
                                } (${currencyCodeToIso(
                                    data.softpayMiaTerminal.defaultCurrencyCode
                                )})`}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.kernel_data_id",
                                            "Kernel data ID"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Kernel Data Id that inserted in kernel data endpoints" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.kernelDataId}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.public_key_set_id",
                                            "Public key set ID"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Public Key Set Id that inserted in public keyset endpoints" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.publicKeySetId}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_terminal.state",
                                    "State"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.state}
                            </Cell>
                        </Row>
                        <Row>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_terminal.profile_name",
                                    "Profile Name"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.profileName}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.installed",
                                            "Installed"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Defines a terminal is installed at an app or not" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.installed
                                    ? t("common.yes", "Yes")
                                    : t("common.no", "No")}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.taken_at",
                                            "Taken at"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Terminal last install datetime at the app" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.takenAt &&
                                    formatDateTime(
                                        data.softpayMiaTerminal.takenAt,
                                        locale as AvailableLocale
                                    )}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.last_online",
                                            "Last online"
                                        )}
                                    </Text>
                                    <TooltipInfoIcon title="Terminal last online datetime at the app" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.lastOnline &&
                                    formatDateTime(
                                        data.softpayMiaTerminal.lastOnline,
                                        locale as AvailableLocale
                                    )}
                            </Cell>
                        </Row>
                        <Row>
                            <Cell style={sharedStyles.titleCell}>
                                <>
                                    <Text
                                        style={sharedStyles.titleText}
                                        numberOfLines={1}
                                    >
                                        {t(
                                            "backoffice.softpay_mia_terminal.released_at",
                                            "Released at"
                                        )}
                                    </Text>

                                    <TooltipInfoIcon title="Terminal last release datetime" />
                                </>
                            </Cell>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.releasedAt &&
                                    formatDateTime(
                                        data.softpayMiaTerminal.releasedAt,
                                        locale as AvailableLocale
                                    )}
                            </Cell>
                        </Row>
                        <Row style={sharedStyles.oddRow}>
                            <Title style={sharedStyles.titleCell}>
                                {t(
                                    "backoffice.softpay_mia_terminal.softpay_terminal_id",
                                    "Softpay terminal ID"
                                )}
                            </Title>
                            <Cell style={sharedStyles.dataCell}>
                                {data.softpayMiaTerminal.softpayTerminalId}
                            </Cell>
                        </Row>
                        {optionalDataAvailable(
                            data?.softpayMiaTerminal.processorData?.cardSchema
                        ) ? (
                            <Row
                                style={
                                    optionalRowCounter % 2 === 0
                                        ? sharedStyles.oddRow
                                        : undefined
                                }
                            >
                                <Title style={sharedStyles.titleCell}>
                                    {t(
                                        "backoffice.softpay_mia_terminal.processor_data.card_schema",
                                        "Processor data - Card schema"
                                    )}
                                </Title>
                                <Cell style={sharedStyles.dataCell}>
                                    {
                                        data?.softpayMiaTerminal.processorData
                                            ?.cardSchema
                                    }
                                </Cell>
                            </Row>
                        ) : null}
                        {optionalDataAvailable(
                            data?.softpayMiaTerminal.processorData
                                ?.processorName
                        ) ? (
                            <Row
                                style={
                                    optionalRowCounter % 2 === 0
                                        ? sharedStyles.oddRow
                                        : undefined
                                }
                            >
                                <Title style={sharedStyles.titleCell}>
                                    {t(
                                        "backoffice.softpay_mia_terminal.processor_data.processor_name",
                                        "Processor data - Processor name"
                                    )}
                                </Title>
                                <Cell style={sharedStyles.dataCell}>
                                    {
                                        data?.softpayMiaTerminal.processorData
                                            ?.processorName
                                    }
                                </Cell>
                            </Row>
                        ) : null}
                        {optionalDataAvailable(
                            data?.softpayMiaTerminal.processorData
                                ?.processorTerminalId
                        ) ? (
                            <Row
                                style={
                                    optionalRowCounter % 2 === 0
                                        ? sharedStyles.oddRow
                                        : undefined
                                }
                            >
                                <Title style={sharedStyles.titleCell}>
                                    {t(
                                        "backoffice.softpay_mia_terminal.processor_data.processor_terminal_id",
                                        "Processor data - Processor terminal ID"
                                    )}
                                </Title>
                                <Cell style={sharedStyles.dataCell}>
                                    {
                                        data?.softpayMiaTerminal.processorData
                                            ?.processorTerminalId
                                    }
                                </Cell>
                            </Row>
                        ) : null}
                        {optionalDataAvailable(
                            data?.softpayMiaTerminal.processorData
                                ?.processorMerchantId
                        ) ? (
                            <Row
                                style={
                                    optionalRowCounter % 2 === 0
                                        ? sharedStyles.oddRow
                                        : undefined
                                }
                            >
                                <Title style={sharedStyles.titleCell}>
                                    {t(
                                        "backoffice.softpay_mia_terminal.processor_data.processor_merchant_id",
                                        "Processor data - Processor merchant ID"
                                    )}
                                </Title>
                                <Cell style={sharedStyles.dataCell}>
                                    {
                                        data?.softpayMiaTerminal.processorData
                                            ?.processorMerchantId
                                    }
                                </Cell>
                            </Row>
                        ) : null}
                        {optionalDataAvailable(
                            data?.softpayMiaTerminal.additionalData &&
                                data?.softpayMiaTerminal.additionalData
                                    ?.length > 0
                        ) ? (
                            <Row
                                style={
                                    optionalRowCounter % 2 === 0
                                        ? sharedStyles.oddRow
                                        : undefined
                                }
                            >
                                <Title style={sharedStyles.titleCell}>
                                    {t(
                                        "backoffice.softpay_mia_store.additional_data",
                                        "Additional data"
                                    )}
                                </Title>
                                <Cell style={sharedStyles.dataCell}>
                                    <Table>
                                        {data.softpayMiaTerminal.additionalData?.map(
                                            (item, idx) => (
                                                <Row
                                                    style={
                                                        idx % 2 === 1
                                                            ? sharedStyles.oddRow
                                                            : undefined
                                                    }
                                                >
                                                    <Title
                                                        style={
                                                            sharedStyles.titleCell
                                                        }
                                                    >{`${item.key}:`}</Title>
                                                    <Cell
                                                        style={
                                                            sharedStyles.dataCell
                                                        }
                                                    >
                                                        <Text
                                                            style={
                                                                sharedStyles.dataText
                                                            }
                                                        >
                                                            {item.value}
                                                        </Text>
                                                    </Cell>
                                                </Row>
                                            )
                                        )}
                                    </Table>
                                </Cell>
                            </Row>
                        ) : null}
                    </Table>
                )}
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
});
