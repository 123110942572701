import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "locales";
import type { Form, RelatedValidationFields } from "./types";
import {
    initResolvers,
    validateField,
    validationResolve,
    ValidationErrors,
    ValidationRule,
    ValidationSchema,
    ValidationRules,
} from "lib";
import _ from "lodash";
import { produce } from "immer";
export type { Form } from "./types";
export type { ValidationSchema } from "lib";

// The `values` and the `setValue` method will only work when a non-null set of default values has been set
export function useForm<FormValues>(
    schemaArg: ValidationSchema<FormValues>,
    defaultValuesArg: FormValues | null
): Form<FormValues> {
    const [values, setValues] = useState<FormValues | null>(defaultValuesArg);
    const [defaultValues, setDefaultValues] = useState<FormValues | null>(
        defaultValuesArg
    );

    if (
        defaultValuesArg !== null &&
        !_.isEqual(defaultValuesArg, defaultValues)
    ) {
        setValues(defaultValuesArg);
        setDefaultValues(defaultValuesArg);
    }

    const [errors, setErrors] = useState<ValidationErrors<FormValues>>({});
    const [schema, setSchema] =
        useState<ValidationSchema<FormValues>>(schemaArg);
    const [relatedValidationFields, setRelatedValidationFields] = useState<
        Partial<RelatedValidationFields<FormValues>>
    >({});
    const [t] = useTranslation();

    useEffect(() => {
        if (schema) {
            const relatedFields = {} as RelatedValidationFields<FormValues>;

            for (const field in schema) {
                relatedFields[field] = [];
                const fieldRules = schema[
                    field as keyof FormValues
                ] as ValidationRules<FormValues>;
                for (const rule in fieldRules) {
                    const fieldRule = fieldRules[
                        rule as keyof ValidationRules<FormValues>
                    ] as any;
                    if (typeof fieldRule === "object") {
                        if (typeof fieldRule.field === "string") {
                            const relatedFieldName =
                                fieldRule.field as keyof FormValues;
                            if (
                                !Array.isArray(relatedFields[relatedFieldName])
                            ) {
                                relatedFields[relatedFieldName] = [];
                            }
                            relatedFields[relatedFieldName].push(field);
                        }
                    }
                }
            }

            setRelatedValidationFields(
                relatedFields as Partial<RelatedValidationFields<FormValues>>
            );
        }
    }, [schema]);

    initResolvers();

    const validateFieldCallback = useCallback(
        (field: keyof FormValues, value: any, valuesArg: FormValues) => {
            const fieldRules = schema[field] as {
                [key: string]: ValidationRule;
            };
            const err = validateField<FormValues>(
                value,
                valuesArg,
                fieldRules,
                t
            );
            if (err) {
                setErrors(prevState => {
                    const n = { ...prevState };
                    n[field] = err;
                    return n;
                });
            } else {
                setErrors(prevState => {
                    const n = { ...prevState };
                    if (n[field]) {
                        delete n[field];
                    }
                    return n;
                });
            }
        },
        [schema, t]
    );

    const internalSetValue = useCallback(
        (
            valuesArg: FormValues,
            field: keyof FormValues,
            value: any
        ): FormValues => {
            validateFieldCallback(field, value, valuesArg);
            const newValues = produce(prevState => {
                if (prevState) {
                    const n = { ...prevState };
                    n[field] = value;
                    return n;
                } else {
                    console.error(
                        "Don't call setValue(...) or setValues(...) before defaultValues has been given"
                    );
                    return null;
                }
            })(valuesArg);

            if (relatedValidationFields && relatedValidationFields[field]) {
                const relatedFields = relatedValidationFields[field];
                if (relatedFields) {
                    for (let i = 0; i < relatedFields.length; i++) {
                        validateFieldCallback(
                            relatedFields[i],
                            newValues[relatedFields[i]],
                            newValues
                        );
                    }
                }
            }

            return newValues;
        },
        [relatedValidationFields, validateFieldCallback]
    );

    const setValue = useCallback(
        (field: keyof FormValues, value: any) => {
            if (values === null) {
                throw new Error(
                    "Don't call setValue(...) before defaultValues has been given"
                );
            }

            setValues(internalSetValue(values, field, value));
        },
        [internalSetValue, values]
    );

    const setValuesCallback = useCallback(
        (valuesArg: Partial<{ [K in keyof FormValues]: FormValues[K] }>) => {
            if (values === null) {
                throw new Error(
                    "Don't call setValues(...) before defaultValues has been given"
                );
            }

            let newValues: FormValues = values;
            for (const i in valuesArg) {
                newValues = internalSetValue(newValues, i, valuesArg[i]);
            }
            setValues(newValues);
        },
        [internalSetValue, values]
    );

    const setDefaultValuesCallback = useCallback(
        (val: FormValues) => {
            if (!_.isEqual(val, defaultValues)) {
                setValues(val);
                setDefaultValues(val);
            }
        },
        [defaultValues]
    );

    const setSchemaCallback = useCallback(
        (newSchema: ValidationSchema<FormValues>) => {
            setSchema(newSchema);
        },
        []
    );

    const handleSubmit = useCallback(
        (callback: () => void) => async () => {
            const err = validationResolve(values, schema, t);
            setErrors(err || {});
            if (!err) {
                callback();
            }
        },
        [schema, t, values]
    );

    const reset = useCallback(() => {
        setValues(defaultValues);
    }, [defaultValues]);

    const actions = useMemo(() => {
        return {
            setValue,
            setValues: setValuesCallback,
            setDefaultValues: setDefaultValuesCallback,
            setSchema: setSchemaCallback,
            handleSubmit,
            reset,
        };
    }, [
        handleSubmit,
        reset,
        setDefaultValuesCallback,
        setSchemaCallback,
        setValue,
        setValuesCallback,
    ]);

    return [{ values, errors }, actions];
}
