import {
    StyleFunction,
    useThemedStyle,
    useAuth,
    Surface,
    Alert,
    InputControl,
    TextInput,
    Picker,
    InputLabel,
    Button,
    Accordion,
    Text,
    CheckBox,
    RequiredText,
} from "@venuepos/react-common";
import React, { useCallback, useMemo } from "react";
import type { Form } from "@venuepos/react-common";
import { useTranslation } from "locales";
import {
    defaultRolePermissions,
    IRole,
    IRolePermissionInput,
    IRoleSave,
    permissionScope,
    RolePermissionValue,
} from "lib";
import { View } from "react-native";
import { produce } from "immer";
import type { RolePermissions } from "lib/src/user/role-permissions";
import type { GQRoleEditFormQuery } from "graphql-sdk";

const PERMISSIONS_WITHOUT_SECTIONS = "";

type Sections = { [key: string]: Partial<RolePermissions> };

export function RoleForm<FormValues extends IRoleSave>(props: {
    form: Form<FormValues>;
    merchant: Exclude<
        GQRoleEditFormQuery["role"],
        null | undefined
    >["merchant"];
    onSubmit: () => void;
    submitButton: string;
    permissionsValues: IRolePermissionInput[];
    loading: boolean;
    roleType: IRole["type"];
    readOnly: boolean;
}) {
    const auth = useAuth();
    const isReadOnly = auth.may(["admin.role"]) ? false : props.readOnly;

    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const styles = useThemedStyle(styleFunc);

    const permissionsSections = useMemo(() => {
        const sections: Sections = {};

        Object.keys(defaultRolePermissions).map(key => {
            const sectionKey = key.substring(
                0,
                key.indexOf(".", key.indexOf(".") + 1)
            );

            if (
                permissionScope(key as keyof RolePermissions) !== props.roleType
            ) {
                return;
            }

            if (!sections[sectionKey]) {
                sections[sectionKey] = {};
            }

            sections[sectionKey][key as keyof RolePermissions] =
                defaultRolePermissions[key as keyof RolePermissions];
        });

        return sections;
    }, [props.roleType]);

    const togglePermission = useCallback(
        (key: string, valueBool: boolean) => {
            if (!values) {
                return;
            }

            const value: RolePermissionValue = valueBool
                ? "enabled"
                : "disabled";
            setValue(
                "permissions",
                produce(permissionInputs => {
                    const inputIndex = getPermissionIndex(
                        key,
                        permissionInputs
                    );
                    if (inputIndex === null) {
                        permissionInputs.push({
                            key,
                            value,
                        });
                    } else {
                        // Because we only can toggle on/off right now, if the permission key is present
                        // in the form values, we should just remove it from there again, as we only
                        // should put the permission change in there if it's actually changed from the
                        // value given in the prop permissions
                        permissionInputs.splice(inputIndex, 1);
                    }
                })(values.permissions)
            );
        },
        [setValue, values]
    );

    if (!values) {
        return null;
    }

    return (
        <Surface>
            {props.readOnly && props.roleType !== "ADMIN" && (
                <Alert type="warning" style={styles.alert}>
                    {auth.may("admin.role")
                        ? t(
                              "backoffice.role_form.read_only_admin",
                              "This role is READ ONLY and can only by edited by an admin user."
                          )
                        : t(
                              "backoffice.role_form.read_only",
                              "This role is READ ONLY and therefore can't be modified."
                          )}
                </Alert>
            )}

            {props.roleType === "ADMIN" && (
                <Alert type="info" style={styles.alert}>
                    {t(
                        "backoffice.role_form.this_is_an_admin_role",
                        "This is an admin role."
                    )}
                </Alert>
            )}

            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.role_form.enter_role",
                        "Enter name of role"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    disabled={isReadOnly}
                    required={true}
                />
            </InputControl>

            {auth.may(["admin.role"]) && props.merchant && (
                <InputControl error={errors.name}>
                    <Picker
                        label={t("backoffice.role_form.merchant", "Merchant")}
                        disabled={true}
                    >
                        <Picker.Item label={props.merchant.name} />
                    </Picker>
                </InputControl>
            )}

            {!props.merchant}

            <InputLabel>
                {t("backoffice.role_form.permissions", "Permissions")}
            </InputLabel>

            <View style={styles.permissionsContainer}>
                <Permissions
                    sections={permissionsSections}
                    sectionKey={PERMISSIONS_WITHOUT_SECTIONS}
                    values={values}
                    permissionsValues={props.permissionsValues}
                    readOnly={isReadOnly}
                    togglePermission={togglePermission}
                />
                {Object.keys(permissionsSections)
                    .filter(key => key !== "")
                    .map(sectionKey => (
                        <PermissionSection
                            key={sectionKey}
                            sections={permissionsSections}
                            sectionKey={sectionKey}
                            values={values}
                            permissionsValues={props.permissionsValues}
                            readOnly={isReadOnly}
                            togglePermission={togglePermission}
                        />
                    ))}
            </View>

            <Button
                onPress={handleSubmit(props.onSubmit)}
                loading={props.loading}
                disabled={isReadOnly}
            >
                {props.submitButton}
            </Button>

            <RequiredText />
        </Surface>
    );
}

function PermissionSection<FormValues extends IRoleSave>({
    sectionKey,
    sections,
    values,
    permissionsValues,
    readOnly,
    togglePermission,
}: {
    sectionKey: string;
    sections: Sections;
    values: FormValues;
    permissionsValues: IRolePermissionInput[];
    readOnly: boolean;
    togglePermission: (key: string, valueBool: boolean) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <Accordion
            title={
                <View style={styles.section}>
                    <Text style={styles.sectionText}>
                        {t(`role_permission.${sectionKey}.headline`)}
                    </Text>
                </View>
            }
            showContents={false}
        >
            <View style={styles.sectionContent}>
                <Permissions
                    sectionKey={sectionKey}
                    sections={sections}
                    values={values}
                    permissionsValues={permissionsValues}
                    readOnly={readOnly}
                    togglePermission={togglePermission}
                />
            </View>
        </Accordion>
    );
}

function Permissions<FormValues extends IRoleSave>({
    sectionKey,
    sections,
    values,
    permissionsValues,
    readOnly,
    togglePermission,
}: {
    sectionKey: string;
    sections: Sections;
    values: FormValues;
    permissionsValues: IRolePermissionInput[];
    readOnly: boolean;
    togglePermission: (key: string, valueBool: boolean) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <>
            {Object.keys(sections[sectionKey]).map(key => {
                let permissionEnabled = false;

                // First, we check if permission is present in values
                const valuesIndex = getPermissionIndex(key, values.permissions);

                if (valuesIndex !== null) {
                    permissionEnabled =
                        values.permissions[valuesIndex].value === "enabled";
                } else {
                    // If not, we will use the value from the permission value given as a prop
                    const propIndex = getPermissionIndex(
                        key,
                        permissionsValues
                    );

                    if (propIndex !== null) {
                        permissionEnabled =
                            permissionsValues[propIndex].value === "enabled";
                    }
                }

                return (
                    <View key={key} style={styles.permissionRow}>
                        <View style={styles.permissionLabel}>
                            <Text>
                                {sectionKey !== PERMISSIONS_WITHOUT_SECTIONS &&
                                    "- "}
                                {`${t(`role_permission.${key}`)}`}
                            </Text>
                        </View>
                        <View style={styles.checkboxContainer}>
                            <CheckBox
                                style={styles.checkbox}
                                value={permissionEnabled}
                                disabled={readOnly}
                                onValueChange={value =>
                                    togglePermission(key, value)
                                }
                            />
                        </View>
                    </View>
                );
            })}
        </>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        permissionsContainer: {
            marginVertical: theme.spacingScale * 2,
        },
        permissionRow: {
            flexDirection: "row",
            marginBottom: theme.spacingScale,
            borderBottomColor: theme.colors.grey100,
            paddingBottom: theme.spacingScale,
            borderBottomWidth: 1,
        },
        permissionLabel: {
            flex: 1,
        },
        checkboxContainer: {
            paddingLeft: theme.spacingScale * 2,
        },
        section: {
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
        },
        sectionText: {
            textDecorationLine: "underline",
        },
        sectionContent: {
            marginBottom: theme.spacingScale,
        },
        checkbox: {
            alignItems: "flex-end",
            marginBottom: 0,
        },
        alert: {
            marginBottom: 20,
        },
    };
};

function getPermissionIndex(key: string, input: IRolePermissionInput[]) {
    for (let i = 0; i < input.length; i++) {
        if (input[i].key === key) {
            return i;
        }
    }

    return null;
}
