import React, { ReactFragment } from "react";
import {
    Button,
    Headline,
    Icon,
    Picker,
    StyleFunction,
    Surface,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { TouchableOpacity, View } from "react-native";
import type { ConditionLogicOperator } from "lib";
import { useTranslation } from "locales";

export function SubFormBox({
    children,
    headline,
    onAddCondition,
    hasUnusedConditions,
    conditionsLogicOperator,
    onConditionsLogicOperatorChange,
    hideLogicOperatorPicker,
}: {
    headline: string;
    children: ReactFragment[];
    onAddCondition: () => void;
    hasUnusedConditions: boolean;
    conditionsLogicOperator: ConditionLogicOperator;
    onConditionsLogicOperatorChange: (
        logicOperator: ConditionLogicOperator
    ) => void;
    hideLogicOperatorPicker?: boolean;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    return (
        <Surface style={styles.surface}>
            <Headline size="h5">{headline}</Headline>
            {children}
            <View style={styles.actions}>
                {!hideLogicOperatorPicker && (
                    <Picker
                        onValueChange={value => {
                            onConditionsLogicOperatorChange(
                                value as ConditionLogicOperator
                            );
                        }}
                        selectedValue={conditionsLogicOperator}
                        style={styles.logicPicker}
                    >
                        <Picker.Item
                            value="AND"
                            key={"AND"}
                            label={t(
                                "backoffice.discount_form.all",
                                "All (AND)"
                            )}
                        />
                        <Picker.Item
                            value="OR"
                            key={"OR"}
                            label={t(
                                "backoffice.discount_form.any",
                                "Any (OR)"
                            )}
                        />
                    </Picker>
                )}
                <Button
                    onPress={onAddCondition}
                    variant="text"
                    size="small"
                    iconName="plus"
                    disabled={!hasUnusedConditions}
                >
                    {t(
                        "backoffice.discount_form.new_order_condition",
                        "Add condition"
                    )}
                </Button>
            </View>
        </Surface>
    );
}

export function SubFormBoxInactive({
    text,
    icon = "plus",
    onPress,
}: {
    text: string;
    icon?: string;
    onPress: () => void;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.inactiveSurface}>
                <Icon
                    name={icon}
                    color={styles.inactiveIcon.color}
                    size="small"
                />
                <Text style={styles.inactiveText}>{text}</Text>
            </View>
        </TouchableOpacity>
    );
}

const styleFunc: StyleFunction = theme => ({
    surface: {
        marginBottom: theme.spacingScale * 2,
    },
    actions: {
        marginTop: theme.spacingScale,
        flexDirection: "row",
        alignItems: "center",
    },
    addConditionButtonIcon: {
        color: theme.colors.textDark,
    },
    logicPicker: {
        width: "auto",
        marginRight: theme.spacingScale * 2,
        marginBottom: 0,
        paddingVertical: 4,
        minHeight: 32,
        borderColor: theme.colors.grey250,
    },
    inactiveSurface: {
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
        borderColor: theme.colors.grey100,
        borderRadius: theme.borderRadius,
        borderStyle: "dashed",
        borderWidth: 2,
        backgroundColor: "transparent",
        padding: theme.spacingScale * 2,
    },
    inactiveIcon: {
        color: theme.colors.grey500,
    },
    inactiveText: {
        ...theme.fonts.regular,
        marginLeft: theme.spacingScale,
        color: theme.colors.grey500,
        fontSize: 16,
        fontWeight: "bold",
    },
});
