import type { ReportData } from "lib";
import type { AvailableLocale } from "locales";
import React from "react";
import { useTranslation } from "locales";
import { StyleFunction, useThemedStyle } from "../../theme";
import { DataTable } from "../data-table";
import { formatAsNumeric, renderValue } from "./functions";
import { Headers } from "./headers";
import { Sums } from "./sums";

export function ReportTable({
    report,
    locale,
}: {
    report: ReportData;
    locale: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    if (!report.headers) {
        return null;
    }

    let sums: (number | undefined)[] = report.headers.map(() => undefined);
    // Sum the numeric columns
    report.rows.forEach(row =>
        row.forEach((cell, cIdx) => {
            if (
                formatAsNumeric(report.headers[cIdx].formatAs) &&
                report.headers[cIdx].noSummation !== true
            ) {
                if (sums[cIdx] === undefined) {
                    sums[cIdx] =
                        typeof cell === "string" ? parseFloat(cell) : cell;
                } else {
                    sums[cIdx] =
                        (sums[cIdx] as number) +
                        (typeof cell === "string" ? parseFloat(cell) : cell);
                }
            }
        })
    );

    return (
        <DataTable>
            <Headers data={report.headers} />
            {report.rows.length > 0 ? (
                report.rows.map((r, rid) => (
                    <DataTable.Row
                        key={`r-${rid}`}
                        style={rid % 2 === 1 ? styles.oddRow : undefined}
                    >
                        {r.map((cell, cid) => (
                            <DataTable.Cell
                                numeric={formatAsNumeric(
                                    report.headers[cid].formatAs
                                )}
                                key={`r-${rid}-${cid}`}
                            >
                                {renderValue(
                                    cell,
                                    report.headers[cid].formatAs,
                                    locale as AvailableLocale
                                )}
                            </DataTable.Cell>
                        ))}
                    </DataTable.Row>
                ))
            ) : (
                <DataTable.Row key="no-data">
                    <DataTable.Cell>
                        {t(
                            "backoffice.report.no_data",
                            "No data for the given period."
                        )}
                    </DataTable.Cell>
                </DataTable.Row>
            )}
            <Sums
                data={sums}
                formatAs={report.headers.map(header => header.formatAs)}
                locale={locale as AvailableLocale}
                style={report.rows.length % 2 === 1 ? styles.oddRow : undefined}
            />
        </DataTable>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
