import React, { useCallback } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import {
    StyleData,
    useThemedStyle,
    Button,
    StyleFunction,
} from "@venuepos/react-common";
import { ProductLayoutButtonType } from "lib";

/**
 * Show the list of possible button types and let the user choose one of them
 */
export function ButtonTypeSelector({
    onButtonTypeSelect,
}: {
    onButtonTypeSelect: (buttonType: ProductLayoutButtonType) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const handleFunctionTypeSelect = useCallback(() => {
        onButtonTypeSelect("FUNCTION");
    }, [onButtonTypeSelect]);

    const handleProductTypeSelect = useCallback(() => {
        onButtonTypeSelect("PRODUCT");
    }, [onButtonTypeSelect]);

    return (
        <View testID="layout:add">
            <View style={styles.buttons}>
                <Button
                    type="primary"
                    onPress={handleProductTypeSelect}
                    style={styles.button}
                    testID="layout:addProduct"
                >
                    {t("layout.add_product_button", "Add product button")}
                </Button>
                <Button
                    onPress={handleFunctionTypeSelect}
                    style={styles.button}
                    testID="layout:addFunctionButton"
                >
                    {t("layout.add_function_button", "Add function button")}
                </Button>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => {
    let styling: StyleData = {
        buttons: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
        },
        button: {
            marginHorizontal: theme.spacingScale,
        },
    };

    return styling;
};
