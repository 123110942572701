import { useAuth, useForm } from "@venuepos/react-common";
import { GQTagGroupInput, useTagGroupCreateMutation } from "graphql-sdk";
import { defaultTagGroup, schemaTagGroup } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { TagScreen } from "../../tag-screen";
import { Form } from "../form";

type ScreenProps = RootStackScreenProps<"TAG_GROUP_CREATE">;

export function TagGroupCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();
    const [tagGroupCreate] = useTagGroupCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<GQTagGroupInput>(schemaTagGroup, defaultTagGroup);
    const [{ values }] = form;

    const redirect = useCallback(() => {
        // If the page was loaded with a referrer, then return to that referrer.
        // The referrer could be e.g. CASH_REGISTER_EDIT with a specific id, but could also be to the general MERCHANT_SETTINGS,
        // so the id could be missing from the referrer argument
        if (
            !route.params ||
            !route.params.referrer ||
            !route.params.referrer.route
        ) {
            return;
        }

        if (
            route.params.referrer.route === "TAG_EDIT" &&
            route.params.referrer.id
        ) {
            navigate("TAG_EDIT", { id: route.params.referrer.id });
        }
        if (route.params.referrer.route === "TAG_CREATE") {
            navigate("TAG_CREATE");
        }
    }, [route.params, navigate]);

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await tagGroupCreate({
                    variables: {
                        tagGroup: {
                            name: values.name,
                        },
                    },
                }),
            t("common.created", "Created"),
            redirect
        );
    }, [values, handleMutationError, t, redirect, tagGroupCreate]);

    return (
        <TagScreen>
            <Form
                form={form}
                onSubmit={create}
                submitButton={["common.create", "Create"]}
            />
        </TagScreen>
    );
}
