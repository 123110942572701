import React, { ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Text } from "../text";
import { useTheme, StyleFunction, useThemedStyle } from "../../theme";
import type { ValidationError, ValidationErrorMessage } from "lib";

export function InputControl(props: {
    children: ReactNode;
    description?: string | ReactNode;
    style?: StyleProp<ViewStyle>;
    error?: ValidationError | string;
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    let message;
    if (props.error) {
        message =
            typeof props.error === "string"
                ? props.error
                : (props.error as ValidationErrorMessage).message;
    }
    return (
        <View style={[styles.container, props.style]} testID="input-wrapper">
            {props.children}
            {props.error && (
                <Text
                    style={[
                        styles.error,
                        props.description
                            ? styles.errorWithDescription
                            : undefined,
                    ]}
                    testID={"error:" + message}
                    accessibilityRole="alert"
                >
                    {message}
                </Text>
            )}
            {props.description &&
                (typeof props.description === "string" ? (
                    <Text style={theme.styles.inputDescription}>
                        {props.description}
                    </Text>
                ) : (
                    props.description
                ))}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    error: {
        ...theme.fonts.regular,
        ...theme.styles.inputDescription,
        color: theme.colors.error,
    },
    errorWithDescription: {
        marginBottom: theme.spacingScale / 2,
    },
    container: {
        minHeight: "auto",
        flex: 1,
        marginBottom: theme.spacingScale * 2,
    },
});
