import {
    Alert,
    Button,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useConfirm,
    useMe,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useMerchantDeleteMutation, useMerchantsQuery } from "graphql-sdk";
import { formatDateTime, SearchDefinition } from "lib";
import { AvailableLocale } from "locales";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { useLoginAsRole } from "./login-as-role-modal";
import { useSearchDefinition } from "../../hooks";

type ScreenProps = RootStackScreenProps<"MERCHANTS">;

export function Merchants({ navigation, route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        [
            "admin.merchant.write",
            "admin.merchant.delete",
            "admin.merchant.login_as_role",
        ],
        "OR"
    );

    const { navigate, reset } = navigation;
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const loginAsRole = useLoginAsRole();
    const [merchantDelete] = useMerchantDeleteMutation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "name", initialSortDirection: "ASC" });
    const { handleMutationError } = useHandleMutationError();
    const confirm = useConfirm();
    const me = useMe();

    const defaultMerchantSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultMerchantSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { data, loading, error, refetch } = useMerchantsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            if (id !== me.merchant?.id) {
                if (
                    !(await confirm(
                        t("backoffice.merchants.delete", "Delete merchant?"),
                        t(
                            "backoffice.merchants.delete_explain",
                            "If you choose to delete this merchant, the users cannot log in anymore, the cash registers stop working, etc.\n\nAre you sure, you want to delete this merchant?"
                        )
                    ))
                ) {
                    return;
                }

                await handleMutationError(
                    async () => {
                        await merchantDelete({
                            variables: {
                                id: id,
                            },
                        });
                    },
                    t("common.deleted", "Deleted"),
                    async () => {
                        await refetch();
                    }
                );
            }
        },
        [confirm, handleMutationError, me.merchant, merchantDelete, refetch, t]
    );

    const handleEdit = useCallback(
        (id: string) => {
            if (!auth.may("admin.merchant.write")) {
                return;
            }

            navigate("MERCHANT_EDIT", {
                id,
            });
        },
        [auth, navigate]
    );

    const handleLoginAsRole = useCallback(
        async merchantId => {
            const logInAsRole = await loginAsRole({
                merchantId,
                navigation,
            });

            if (!logInAsRole) {
                // User did not select a role. Back off.
                return;
            }

            // User selected a role? Then, navigate to Dashboard to avoid issues with reloading pages with a different role
            navigate("DASHBOARD");

            // Select the role.
            await auth.selectUserRole(logInAsRole, merchantId);

            // And reset the stack to avoid issues with going back in stack history.
            reset({ routes: [{ name: "DASHBOARD" }] });
        },
        [auth, loginAsRole, navigate, navigation, reset]
    );

    const renderMerchantRow = useCallback(
        (item, index) => (
            <DataTable.Row
                key={index.toString()}
                onPress={() => handleLoginAsRole(item.id)}
            >
                <DataTable.Cell>{item.name}</DataTable.Cell>
                <DataTable.Cell numeric>
                    {formatDateTime(item.createdAt, locale as AvailableLocale)}
                </DataTable.Cell>
                <DataTable.Cell numeric>
                    {auth.may("admin.merchant.login_as_role") && (
                        <IconButton
                            color={styles.icon.color}
                            name="signIn"
                            onPress={() => handleLoginAsRole(item.id)}
                        />
                    )}
                    {auth.may("admin.merchant.write") && (
                        <IconButton
                            color={styles.icon.color}
                            name="edit"
                            onPress={() => handleEdit(item.id)}
                        />
                    )}
                    {auth.may("admin.merchant.delete") && (
                        <IconButton
                            color={styles.icon.color}
                            name="delete"
                            disabled={me.merchant?.id === item.id}
                            onPress={() => handleDelete(item.id)}
                        />
                    )}
                </DataTable.Cell>
            </DataTable.Row>
        ),
        [
            locale,
            auth,
            styles.icon.color,
            me.merchant?.id,
            handleLoginAsRole,
            handleEdit,
            handleDelete,
        ]
    );

    if (!me) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {auth.may("admin.merchant.write") && (
                    <Button
                        onPress={() => {
                            navigate("MERCHANT_CREATE");
                        }}
                    >
                        {t(
                            "backoffice.merchant_list.create_merchant",
                            "Create merchant"
                        )}
                    </Button>
                )}
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={styles.sortIcon.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {(loading && <Loading />) ||
                        data!.merchants.data.map(renderMerchantRow)}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.merchants.pagination.pages}
                        itemCount={data?.merchants.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    sortIcon: {
        justifyContent: "flex-end",
        color: theme.colors.black,
    },
    icon: {
        color: theme.colors.secondary,
    },
});
