import {
    Button,
    ConfirmModal,
    IconButton,
    StyleFunction,
    useMerchantConfig,
    useModal,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { useTranslation } from "locales";
import { Text, View } from "react-native";

import { SectionTabHeader } from "../section-tab-header";
import { GridEditor, GRID_MARGIN } from "./grid-editor";
import { SendToSectionModal } from "./modal/send-to-section";
import {
    ProductLayoutAction,
    productLayoutReducer,
} from "./product-layout-operation-reducer";

import type {
    ButtonPosition,
    GridDimensions,
    ILayoutProduct,
    IProductGroup,
    LayoutButton,
    LayoutButtonCreate,
    LayoutButtonMetaData,
    FunctionButton,
    ProductButton,
    LayoutSection,
    ProductSection,
} from "lib";
import { getButtonPositionsForSection } from "../functions";
import { LayoutAction, MoveActionOptions } from "../layout-operation-reducer";
import { useAddButtonModal } from "./modal/add-button";
const GRID_HEIGHT = 640;

export function LayoutEditor({
    onSubmit,
    layoutData,
    productData,
    productGroupData,
    dimensions,
}: {
    onSubmit: (sections: ProductSection[]) => Promise<void>;
    layoutData: ProductSection[];
    productData: ILayoutProduct[];
    productGroupData: Pick<IProductGroup, "id" | "color">[];
    dimensions: GridDimensions;
}) {
    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const toast = useToast();
    const renderAddButtonModal = useAddButtonModal();
    const { render } = useModal();

    const [state, dispatch] = useReducer(productLayoutReducer, {
        sections: layoutData,
        knownProducts: productData,
        currentSectionIndex: 0,
        dimensions,
    });

    const [gridWidth, setGridWidth] = useState<number>(1000);

    const editorWidth = useMemo(
        () => ({
            width: gridWidth - GRID_MARGIN,
        }),
        [gridWidth]
    );

    const handleOnLayout = useCallback(e => {
        setGridWidth(e.nativeEvent.layout.width);
    }, []);

    /**
     * Deletes the section with the given index.
     * Also, makes sure that there is always at least one section in the collection.
     */
    const handleDeleteSection = useCallback(
        async (sectionId: string) => {
            if (!sectionId) {
                console.error(
                    "There is no id. What would you have me do, huh?"
                );
                return;
            }

            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.layout.delete_section_header",
                            "Delete section?"
                        )}
                        bodyText={t(
                            "backoffice.layout.delete_section_body",
                            "Are you sure you want delete this section?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            dispatch({
                type: LayoutAction.DELETE_SECTION,
                sectionId,
                translationFunction: t,
            });
        },
        [render, t]
    );

    const handleDeleteButton = useCallback(
        async (buttonId: LayoutButton["id"]) => {
            if (!buttonId) {
                return;
            }

            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.layout.delete_button_header",
                            "Delete button?"
                        )}
                        bodyText={t(
                            "backoffice.layout.delete_button_body",
                            "Are you sure you want delete this button?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            dispatch({
                type: ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                buttonId,
            });
        },
        [render, t]
    );

    const handleMoveButtonToSection = useCallback(
        async (buttonId: LayoutButton["id"]) => {
            if (!buttonId) {
                return;
            }

            const sections = state.sections.filter(
                (sectionItr: LayoutSection) =>
                    sectionItr.id !==
                        state.sections[state.currentSectionIndex].id && // skip the current section
                    getButtonPositionsForSection(
                        sectionItr.buttons,
                        state.dimensions
                    ).filter(positionItr => positionItr === "").length > 0 // skip this section, if the section is full
            );

            let initialSection: LayoutSection["id"];
            if (sections.length && sections.length > 0) {
                initialSection = sections[0].id;
            }

            const sendToResult: LayoutSection["id"] | "NEW" | undefined =
                await render(onClose => (
                    <SendToSectionModal
                        onClose={onClose}
                        initialSection={initialSection || "NEW"}
                        sections={sections}
                    />
                ));

            if (!sendToResult) {
                return;
            }

            dispatch({
                type: ProductLayoutAction.MOVE_BUTTON_TO_SECTION,
                buttonId,
                toSection: sendToResult,
                translationFunction: t,
            });
        },
        [render, state.currentSectionIndex, state.dimensions, state.sections, t]
    );

    const handleShowButtonSettings = useCallback(
        async (
            buttonData: FunctionButton | ProductButton | LayoutButtonCreate
        ) => {
            const editButtonResult = await renderAddButtonModal({
                button: buttonData,
                addedProducts: state.knownProducts.map(itr => itr.id),
                product: state.knownProducts.find(
                    productItr =>
                        productItr.id ===
                        (buttonData as ProductButton).productId
                ),
            });

            if (!editButtonResult || !editButtonResult.buttonType) {
                return;
            }

            // There is an array of products, that should be added to the section. Do it, and then back off.
            if (
                editButtonResult.buttonType === "PRODUCT" &&
                editButtonResult.formValues === null
            ) {
                dispatch({
                    type: ProductLayoutAction.ADD_PRODUCTS_TO_SECTION,
                    products: editButtonResult.productData,
                    startingPosition: { x: buttonData.x, y: buttonData.y },
                    translationFunction: t,
                });

                toast.success(
                    t(
                        "backoffice.layout.product_added_success",
                        "Added {{ count }} products to the layout",
                        { count: editButtonResult.productData.length }
                    )
                );

                return;
            }

            // Merge the existing button data and the values from the modal and handle the result:
            let newButton = {
                ...buttonData,
                ...(editButtonResult.formValues as ProductButton),
            };

            if (!newButton.id) {
                dispatch({
                    type: ProductLayoutAction.ADD_BUTTON_TO_SECTION, // if the button has no id, then add it to the current section ...
                    newButton: newButton,
                    productData:
                        editButtonResult.buttonType === "PRODUCT" // Include the product data if this button is a product button
                            ? editButtonResult.productData
                            : undefined,
                });

                return;
            }

            dispatch({
                type: ProductLayoutAction.UPDATE_BUTTON_IN_SECTION,
                updatedButton: newButton,
                productData:
                    editButtonResult.buttonType === "PRODUCT" // Include the product data if this button is a product button
                        ? editButtonResult.productData
                        : undefined,
            });
        },
        [renderAddButtonModal, state.knownProducts, t, toast]
    );

    const handleShowButtonById = useCallback(
        async (
            buttonId:
                | (FunctionButton | ProductButton | LayoutButtonCreate)["id"]
        ) => {
            const foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === buttonId);
            if (foundButtonIndex === -1) {
                console.error(
                    "Invalid input for handleShowButtonModal. Requested button",
                    buttonId,
                    "not found. Back off."
                );
                return;
            }

            return handleShowButtonSettings(
                state.sections[state.currentSectionIndex].buttons[
                    foundButtonIndex
                ]
            );
        },
        [handleShowButtonSettings, state.currentSectionIndex, state.sections]
    );

    const handleAddButton = useCallback(
        async (
            buttonPosition: ButtonPosition,
            buttonMetaData: LayoutButtonMetaData
        ) => {
            let newLayoutButton: LayoutButtonCreate = {
                id: null,
                x: buttonPosition.x,
                y: buttonPosition.y,
                height: 1,
                width: 1,
                color: "",
                label: "",
                buttonType: null,
                metaData: {
                    maxWidthValue: buttonMetaData.maxWidthValue || 1,
                    maxHeightValue: buttonMetaData.maxHeightValue || 1,
                },
            };

            await handleShowButtonSettings(newLayoutButton);
        },
        [handleShowButtonSettings]
    );

    const handleOnSubmit = useCallback(async () => {
        await onSubmit(state.sections);
    }, [onSubmit, state.sections]);

    const handleDropButton = (
        position: ButtonPosition,
        buttonId: LayoutButton["id"]
    ) => {
        dispatch({
            type: ProductLayoutAction.MOVE_BUTTON_IN_SECTION,
            position,
            buttonId,
        });
    };

    const handleRenameSection = (
        sectionId: LayoutSection["id"],
        newLabel: LayoutSection["label"]
    ) => {
        dispatch({
            type: LayoutAction.RENAME_SECTION,
            sectionId,
            newLabel,
        });
    };

    const handleAddNewSection = () => {
        dispatch({
            type: LayoutAction.ADD_SECTION,
            translationFunction: t,
        });
    };

    const handleSelectSection = (sectionId: LayoutSection["id"]) => {
        dispatch({ type: LayoutAction.SELECT_SECTION, sectionId });
    };

    const handleMoveSection = (
        sectionId: LayoutSection["id"],
        direction: MoveActionOptions
    ) => {
        dispatch({ type: LayoutAction.MOVE_SECTION, sectionId, direction });
    };

    const handleToggleRenaming = (sectionId: LayoutSection["id"]) => {
        dispatch({ type: LayoutAction.TOGGLE_RENAME_SECTION, sectionId });
    };

    return (
        <View testID="layout:editScreen">
            <View style={styles.sectionTabContainer}>
                <View style={styles.sectionTabs}>
                    {state.sections.map((section, sectionIndex) => (
                        <SectionTabHeader
                            key={`section_${section.id}`}
                            sectionId={section.id}
                            label={section.label}
                            onPress={handleSelectSection}
                            onDelete={handleDeleteSection}
                            onChange={handleRenameSection}
                            activeTab={
                                sectionIndex === state.currentSectionIndex
                            }
                            allowMoveBefore={sectionIndex !== 0}
                            allowMoveAfter={
                                sectionIndex !== state.sections.length - 1
                            }
                            onMoveSection={handleMoveSection}
                            onToggleRenaming={handleToggleRenaming}
                            renaming={!!section?.renaming}
                            testID={`layout:section:${sectionIndex}`}
                        />
                    ))}
                </View>
                <IconButton
                    name="plus-circle"
                    size="large"
                    color={styles.icon.color}
                    onPress={handleAddNewSection}
                    testID="layout:addSection"
                />
            </View>
            <View key="LayoutSections" onLayout={handleOnLayout}>
                {state.sections
                    // Only show the currently selected section
                    .filter(
                        (_, sectionIndex) =>
                            sectionIndex === state.currentSectionIndex
                    )
                    .map(section => (
                        <View
                            style={editorWidth}
                            key={section.id}
                            testID={`layout:grid:${state.currentSectionIndex}`}
                        >
                            <GridEditor
                                dimensions={state.dimensions}
                                width={gridWidth}
                                height={GRID_HEIGHT}
                                sectionId={section.id}
                                buttons={section.buttons.map(buttonItr => {
                                    let buttonProduct:
                                        | ILayoutProduct
                                        | undefined;

                                    if (buttonItr.buttonType === "PRODUCT") {
                                        buttonProduct =
                                            state.knownProducts.find(
                                                productItr =>
                                                    productItr.id ===
                                                    (buttonItr as ProductButton)
                                                        .productId
                                            );
                                        if (!buttonProduct) {
                                            return buttonItr;
                                        }

                                        const buttonMetaData = {
                                            groupColor:
                                                buttonProduct.group?.color,
                                            buttonText:
                                                buttonProduct.buttonText ||
                                                buttonProduct.name,
                                        };
                                        buttonItr.metaData = {
                                            ...buttonItr?.metaData,
                                            ...buttonMetaData,
                                        };

                                        buttonItr.amount = buttonProduct.amount;
                                    }

                                    if (
                                        buttonItr.buttonType === "FUNCTION" &&
                                        (buttonItr.function === "CUSTOM_ITEM" ||
                                            buttonItr.function ===
                                                "VENUE_ACCESS_TICKETS")
                                    ) {
                                        const foundGroup =
                                            productGroupData.find(
                                                groupItr =>
                                                    groupItr.id ===
                                                    buttonItr.productGroupId
                                            );

                                        if (!foundGroup) {
                                            return buttonItr;
                                        }

                                        const buttonMetaData = {
                                            groupColor: foundGroup.color,
                                        };

                                        buttonItr.metaData = {
                                            ...buttonItr?.metaData,
                                            ...buttonMetaData,
                                        };
                                    }
                                    return buttonItr;
                                })}
                                currency={merchantConfig.currency}
                                onMoveButtonToSection={
                                    handleMoveButtonToSection
                                }
                                onAddButton={handleAddButton}
                                onShowButtonSettings={handleShowButtonById}
                                onDeleteButton={handleDeleteButton}
                                onDropButton={handleDropButton}
                                testID={`layout:grid:${section.label}`}
                            />
                        </View>
                    ))}
            </View>
            <Button
                onPress={handleOnSubmit}
                style={styles.saveButton}
                testID="layout:saveLayout"
            >
                <Text>{t("common.save", "Save")}</Text>
            </Button>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    saveButton: {
        marginTop: theme.spacingScale * 2,
    },
    icon: {
        color: theme.colors.grey250,
    },

    sectionTabs: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
    },
    sectionTabContainer: {
        flex: 1,
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
    },
});
