export interface IDeviceProfile {
    id: string;
    osName: "ios" | "android" | "web";
    osVersion: string | number;
    appVersion: string;
    appBuild: string;
    serialNumber?: string;
    hardwareModel: string;
    hardwareProducer: string;
    macAddress?: string;
    language: string; // Returns language code of the best available language. e.g "da" or "en".
    capabilities: {
        camera?: boolean;
        cameraFront?: boolean;
        cameraRear?: boolean;
        cashdrawer?: boolean;
    };
    printer: {
        feedPost?: number;
        charWidth?: number;
        columns?: 32 | 38;
    };
    isUsingAutoTime?: boolean;
    isUsingAutoTimeZone?: boolean;
    timeZone?: string;
}

// react-native-device-info has the annoying feature that if something isn't available;
// they say "unknown" instead of undefined
export function unknownToUndefined(text: string): string | undefined {
    if (text === "unknown") {
        return undefined;
    }
    return text;
}

/**
 * Sorry, I disabled this for now we probably want to control this through the admin interface -Andri
 * Defaults to danish
 */
export function getLanguage() {
    return "da";
}
