import React from "react";
import { Link } from "@react-navigation/native";
import {
    DataTable,
    Headline,
    Loading,
    Text,
    useMe,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQDashboard } from "graphql-sdk";
import { formatAmount } from "lib";
import { AvailableLocale } from "locales";
import { useTranslation } from "locales";
import { useAdminSession } from "../../../session";
import { dashboardStyleFunc } from "../styles";
import { ScrollView } from "react-native-gesture-handler";

export function DepartmentSalesTop10({ data }: { data?: GQDashboard }) {
    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const me = useMe();

    return (
        <>
            <Headline size="h5">
                {`${t(
                    "dashboard.widgets.department_sales_title",
                    "Department Sales - Top 10"
                )} *`}
            </Headline>
            {data ? (
                <ScrollView>
                    <DataTable>
                        <DataTable.Header>
                            <DataTable.Title>
                                {t("common.department", "Department")}
                            </DataTable.Title>
                            <DataTable.Title numeric>
                                {t(
                                    "dashboard.number_of_transactions",
                                    "Number of transactions"
                                )}
                            </DataTable.Title>
                            <DataTable.Title numeric>
                                {t(
                                    "dashboard.avg_transaction",
                                    "Avg. transaction"
                                )}
                            </DataTable.Title>
                            <DataTable.Title numeric>
                                {t("dashboard.turnover", "Turnover")}
                            </DataTable.Title>
                        </DataTable.Header>
                        {data.departments.length === 0 ? (
                            <DataTable.Row style={sharedStyles.darkRow}>
                                <DataTable.Cell>
                                    {t(
                                        "dashboard.no_data",
                                        "No data for the selected date."
                                    )}
                                </DataTable.Cell>
                            </DataTable.Row>
                        ) : (
                            data.departments.map((row, index) => (
                                <DataTable.Row
                                    key={`department-${row.name}`}
                                    style={
                                        index % 2 === 0
                                            ? sharedStyles.darkRow
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell>
                                        {!row.id ? (
                                            me.merchant?.name
                                        ) : (
                                            <Link
                                                to={{
                                                    screen: "DEPARTMENT_EDIT",
                                                    params: { id: row.id },
                                                }}
                                                style={sharedStyles.linkText}
                                                numberOfLines={1}
                                            >
                                                {row.name}
                                            </Link>
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {row.invoices}
                                    </DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {formatAmount(
                                            row.avgTransaction,
                                            merchantConfig.currency,
                                            {
                                                locale: locale as AvailableLocale,
                                            }
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {formatAmount(
                                            row.turnoverAmount,
                                            merchantConfig.currency,
                                            {
                                                locale: locale as AvailableLocale,
                                            }
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            ))
                        )}
                    </DataTable>
                </ScrollView>
            ) : (
                <Loading message={t("common.loading", "Loading")} />
            )}
            <Text style={sharedStyles.noteText}>
                {`* ${t(
                    "dashboard.turnover_disclaimer",
                    "Turnover is excluding giftcard usage"
                )}`}
            </Text>
        </>
    );
}
