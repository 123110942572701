import {
    Alert,
    DataTable,
    Icon,
    IconButton,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useMerchantConfig,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useShiftsQuery } from "graphql-sdk";
import { SearchDefinition, formatDateTime, today } from "lib";
import { formatAmount } from "lib/src/currencies/currencies";
import React, { View } from "react-native";
import { useTranslation } from "locales";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";

import type { AvailableLocale } from "locales";
import { useSearchDefinition } from "../../hooks";
type ScreenProps = RootStackScreenProps<"SHIFTS">;

export function ShiftListScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.shift");

    const { currency } = useMerchantConfig();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const { createSearchDefinition } = useSearchDefinition();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "opened_at",
        initialSortDirection: "DESC",
    });

    const defaultShiftSearch: SearchDefinition = {
        amount: createSearchDefinition("amount"),
        openedAt: {
            name: t("searching.opened_between", "Opened between"),
            type: "date",
            values: { from: today(new Date()), to: today(new Date()) },
            enabled: false,
            description: t(
                "searching.opened_between_description",
                "Click here to select two dates and times to search between"
            ),
        },
        closedAt: {
            name: t("searching.closed_between", "Closed between"),
            type: "date",
            values: { from: today(new Date()), to: today(new Date()) },
            enabled: false,
        },
        cashRegisterName: {
            name: t("searching.cash_register_name", "Cash register name"),
            type: "string",
            value: "",
            enabled: false,
        },
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultShiftSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const { data, loading, error } = useShiftsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    const [{ locale }] = useAdminSession(["locale"]);

    function handleShowShift(id: string) {
        navigate("SHIFT", {
            id,
        });
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "department_name" && sortDirection
                            }
                            onPress={() => onSortChange("department_name")}
                        >
                            {t(
                                "backoffice.shifts.department_name",
                                "Department"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cash_register_name" && sortDirection
                            }
                            onPress={() => onSortChange("cash_register_name")}
                        >
                            {t(
                                "backoffice.shifts.cash_register_name",
                                "Cash register"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "opened_at" && sortDirection
                            }
                            onPress={() => onSortChange("opened_at")}
                        >
                            {t("backoffice.shifts.opened_at", "Opened")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "closed_at" && sortDirection
                            }
                            onPress={() => onSortChange("closed_at")}
                        >
                            {t("backoffice.shifts.closed_at", "Closed")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "opened_by_username" && sortDirection
                            }
                            onPress={() => onSortChange("opened_by_username")}
                        >
                            {t(
                                "backoffice.shifts.opened_by_username",
                                "Opened by"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "closed_by_username" && sortDirection
                            }
                            onPress={() => onSortChange("closed_by_username")}
                        >
                            {t(
                                "backoffice.shifts.closed_by_username",
                                "Closed by"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={sortBy === "amount" && sortDirection}
                            onPress={() => onSortChange("amount")}
                        >
                            {t("shift.amount", "Amount")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.sortIcon}>
                            <Icon name="sort" color={styles.sortIcon.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.shifts.data.map((item, rowIndex) => (
                            <DataTable.Row
                                key={item?.id}
                                onPress={() => item && handleShowShift(item.id)}
                                style={
                                    rowIndex % 2 === 1
                                        ? styles.oddRow
                                        : undefined
                                }
                            >
                                <DataTable.Cell>
                                    {item && item.cashRegister.department?.name}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {item && item.cashRegister.name}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    <Text
                                        ellipsizeMode="middle"
                                        numberOfLines={1}
                                    >
                                        {item &&
                                            formatDateTime(
                                                item.openedAt,
                                                locale as AvailableLocale
                                            )}
                                    </Text>
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {item &&
                                        item.closedAt &&
                                        formatDateTime(
                                            item.closedAt,
                                            locale as AvailableLocale
                                        )}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {item?.openedByUser.username}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {item?.closedByUser?.username}
                                </DataTable.Cell>
                                <DataTable.Cell numeric>
                                    {item &&
                                        formatAmount(item.amount, currency, {
                                            locale: locale as AvailableLocale,
                                        })}
                                </DataTable.Cell>
                                <DataTable.Cell numeric style={styles.sortIcon}>
                                    <IconButton
                                        color={styles.detailsIcon.color}
                                        name="shift"
                                        onPress={() =>
                                            item && handleShowShift(item.id)
                                        }
                                    />
                                </DataTable.Cell>
                            </DataTable.Row>
                        ))
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.shifts.pagination.pages}
                        itemCount={data?.shifts.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "flex-end",
    },
    sortIcon: {
        flexBasis: theme.spacingScale * 6,
        flexShrink: 0,
        flexGrow: 0,
        color: theme.colors.black,
    },

    detailsIcon: {
        color: theme.colors.secondary,
    },

    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
