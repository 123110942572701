import type { StyleFunction } from "@venuepos/react-common";

export const dashboardStyleFunc: StyleFunction = theme => ({
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    topRow: {
        height: 100,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacingScale * 2,
        paddingLeft: theme.spacingScale * 2,
        paddingRight: theme.spacingScale * 2,
    },
    totalRow: {
        flexDirection: "row",
    },
    totalValue: {
        fontSize: 24,
    },
    dateLabel: {
        marginRight: theme.spacingScale,
    },
    widget: {
        flex: 1,
        elevation: 1,
        margin: theme.spacingScale,
    },
    calendarArrow: {
        marginTop: 28,
    },
    noteText: {
        marginTop: theme.spacingScale,
        fontSize: 14,
    },
    darkRow: {
        backgroundColor: theme.colors.grey50,
    },
    tableHeaders: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    linkText: {
        ...theme.fonts.regular,
        fontSize: 18,
        color: theme.colors.secondary,
        textDecorationLine: "underline",
    },

    widerColumn: {
        flex: 2,
    },
    widgetContent: { width: "100%", height: "100%" },
});
