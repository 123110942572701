import React from "react";
import {
    Headline,
    Loading,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQDashboard } from "graphql-sdk";
import { useTranslation } from "locales";
import { dashboardStyleFunc } from "../styles";

export function InvoiceTotal({ data }: { data?: GQDashboard }) {
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    return (
        <>
            <Headline style={sharedStyles.totalHeadline} size="h5">
                {t(
                    "dashboard.widgets.number_of_transactions",
                    "Number of transactions"
                )}
            </Headline>
            {data ? (
                <Text style={sharedStyles.totalValue}>
                    {data.totals.invoices}
                </Text>
            ) : (
                <Loading message={t("common.loading", "Loading")} />
            )}
        </>
    );
}
