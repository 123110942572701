import { useApolloClient } from "@apollo/client";
import { createStackNavigator } from "@react-navigation/stack";
import {
    StyleFunction,
    useAuth,
    useMe,
    useRoutePropsFromRouteConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { lighten } from "color2k";
import React, { useCallback, useMemo } from "react";

import { RootStackParamList } from ".";
import { routeConfig } from "../screens/navigator-routes";
import { LeftHeader } from "./left-header";
import { RightHeader } from "./right-header";

const Stack = createStackNavigator<RootStackParamList>();

const envName: string = globalThis.location.hostname.includes("venuepos.net")
    ? globalThis.location.hostname
    : "development";

export function StackScreen() {
    const me = useMe();
    const auth = useAuth();
    const client = useApolloClient();
    const screens = useRoutePropsFromRouteConfig(routeConfig);
    const styles = useThemedStyle(styleFunc);

    const headerLeft = useCallback(
        props => <LeftHeader buttonProps={props} />,
        []
    );

    const headerRight = useCallback(
        () => <RightHeader loggedInUser={me} client={client} auth={auth} />,
        [auth, client, me]
    );

    // Color the topbar differently on development and test
    let headerStyle = styles.header;
    if (envName === "development") {
        headerStyle = styles.developmentHeader;
    } else if (envName === "test.env.venuepos.net") {
        headerStyle = styles.testHeader;
    }

    const screenOpts = useMemo(
        () => ({
            headerLeft,
            headerRight,
            headerStyle,
        }),
        [headerLeft, headerRight, headerStyle]
    );

    return (
        <Stack.Navigator screenOptions={screenOpts}>
            {screens.map(screen => (
                <Stack.Screen key={screen.name} {...screen} />
            ))}
        </Stack.Navigator>
    );
}

const styleFunc: StyleFunction = theme => ({
    header: { backgroundColor: theme.colors.background },
    developmentHeader: { backgroundColor: theme.colors.success },
    testHeader: { backgroundColor: lighten(theme.colors.success, 0.1) },
});
