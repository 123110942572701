import React, { useCallback, useState } from "react";
import {
    assignOrderCondition,
    ConditionLogicOperator,
    Conditions,
    OrderConditions,
} from "lib";
import { useTranslation } from "locales";
import type { GQDiscountFormQuery } from "graphql-sdk";
import { produce } from "immer";
import { Loading, RemovableRow } from "@venuepos/react-common";
import { ProductConditionInput } from "./product-condition-input";
import { v4 as uuidv4 } from "uuid";
import { SubFormBox, SubFormBoxInactive } from "./sub-form-box";

export function ProductConditionsForm({
    conditionsLogicOperator,
    onConditionsLogicOperatorChange,
    conditions,
    onConditionsChange,
    formData,
}: {
    conditionsLogicOperator: ConditionLogicOperator;
    onConditionsLogicOperatorChange: (
        logicOperator: ConditionLogicOperator
    ) => void;
    conditions: Conditions;
    onConditionsChange: (conditions: Conditions) => void;
    formData: GQDiscountFormQuery;
}) {
    const [keyPrefix, setKeyPrefix] = useState(uuidv4());
    const [t] = useTranslation();

    const handleConditionChange = useCallback(
        (key: number, args: OrderConditions["hasProductQuantity"]) => {
            onConditionsChange(
                produce(conditions, draft => {
                    draft[key].args = args;
                    return draft;
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const addCondition = useCallback(() => {
        onConditionsChange(
            produce(conditions, draft => {
                const defaultProduct = formData.products.data.find(
                    productItr => {
                        return (
                            conditions.find(
                                itr =>
                                    (
                                        itr.args as OrderConditions["hasProductQuantity"]
                                    ).productId === productItr.id
                            ) === undefined
                        );
                    }
                );

                if (defaultProduct !== undefined) {
                    draft.push(
                        assignOrderCondition("hasProductQuantity", {
                            productId: defaultProduct.id,
                            requiredQuantity: 1,
                        })
                    );
                }
            })
        );
    }, [conditions, formData, onConditionsChange]);

    const removeCondition = useCallback(
        (conditionKey: number) => {
            setKeyPrefix(uuidv4());
            onConditionsChange(
                produce(conditions, draft => {
                    draft.splice(conditionKey, 1);
                })
            );
        },
        [conditions, onConditionsChange]
    );

    if (!formData || !formData) {
        return <Loading />;
    }

    // Remove conditions not related to products
    let productConditions = conditions.filter(
        itr => itr.condition === "hasProductQuantity"
    );

    // Remove discount products that is not in the list of all products (the product may have been deleted)
    productConditions = productConditions.filter(
        itr =>
            formData.products.data.find(
                it =>
                    it.id ===
                    (itr.args as OrderConditions["hasProductQuantity"])
                        .productId
            ) !== undefined
    );

    if (productConditions.length === 0) {
        return (
            <SubFormBoxInactive
                text={t(
                    "backoffice.discount_form.add_product_condition",
                    "Add product condition"
                )}
                onPress={() => {
                    addCondition();
                }}
            />
        );
    }

    const hasUnusedConditions =
        // First check groups
        !!formData.products.data.find(
            productItr =>
                !productConditions.find(
                    conditionItr =>
                        conditionItr.condition === "hasProductQuantity" &&
                        (
                            conditionItr.args as OrderConditions["hasProductQuantity"]
                        ).productId === productItr.id
                )
        );

    return (
        <SubFormBox
            headline={t(
                "backoffice.discount_form.product_conditions",
                "Product conditions"
            )}
            onAddCondition={addCondition}
            hasUnusedConditions={hasUnusedConditions}
            conditionsLogicOperator={conditionsLogicOperator}
            onConditionsLogicOperatorChange={onConditionsLogicOperatorChange}
            hideLogicOperatorPicker={true}
        >
            {productConditions.map((condition, key) => (
                <RemovableRow
                    key={key + keyPrefix}
                    onRemove={() => {
                        removeCondition(key);
                    }}
                >
                    <ProductConditionInput
                        condition={
                            condition.args as OrderConditions["hasProductQuantity"]
                        }
                        conditions={conditions}
                        onConditionChange={args =>
                            handleConditionChange(key, args)
                        }
                        formData={formData}
                    />
                </RemovableRow>
            ))}
        </SubFormBox>
    );
}
