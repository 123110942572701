import {
    ApolloClient,
    ApolloLink,
    FetchResult,
    HttpLink,
    InMemoryCache,
    Observable,
    Operation,
    ServerParseError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { ERR_EVENT_STORE_DISPATCH_FAILED, ERR_INVALID_AUTH_TOKEN } from "lib";
import { GraphQLState } from "./graphql-state";
import { getMainDefinition } from "@apollo/client/utilities";
import {
    getMacAddressSync,
    getUserAgentSync,
    getVersion,
} from "react-native-device-info";
import type { GraphQLError } from "graphql";
import { captureError, getAuthToken } from "../../hooks";
import { Platform } from "react-native";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient as createClientWs } from "graphql-ws";
import { getI18n } from "react-i18next";

export interface IGraphQLClientOptions {
    baseURL: string;
    onInvalidAuthToken: () => void;
    trace?: string;
}

const appVersion =
    Platform.OS === "web" ? "unknown" : getVersion() || "0.0.1-dev";

const macAddress = getMacAddressSync();
const userAgent = getUserAgentSync();

export function createClient(options: IGraphQLClientOptions) {
    const cache = new InMemoryCache();

    const uri = `${options.baseURL}/api/graphql`;
    const wsUri = uri.replace("http", "ws");

    const httpLink = new HttpLink({
        uri,
    });

    const wsLink = new GraphQLWsLink(
        createClientWs({
            url: wsUri,
            lazy: true, // Wait with creating a web socket connection until the first subscription has been made
            shouldRetry: (_errOrCloseEvent: unknown) => true,
            connectionParams: async () => {
                const token = await getAuthToken();
                return {
                    accessToken: token?.access,
                    macAddress: macAddress,
                    userAgent: userAgent,
                };
            },
        })
    );

    if (__DEV__) {
        console.log(
            `New client: ${options.baseURL}`,
            "If you're seeing this too many times, that means that we're creating a new GQL client every time. We shouldn't do that."
        );
    }

    const connectionObserverLink = new ApolloLink((operation, forward) => {
        const observables = forward(operation);

        return new Observable<FetchResult>(observer => {
            const subscription = observables.subscribe({
                next: (result: FetchResult) => {
                    observer.next(result);
                    GraphQLState.dispatch({ connected: true });
                },
                error(err: any) {
                    observer.error(err);
                },
                complete: observer.complete.bind(observer),
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        });
    });

    const contextLink = setContext(request => {
        return new Promise(async resolve => {
            if (
                (request.operationName &&
                    request.operationName !== "deviceConfig" &&
                    request.operationName.substring(0, 6) === "device") ||
                // Authentication is needed for the `authSelectUserRole` and `authUpdateLogin` endpoint
                (request.operationName &&
                    request.operationName !== "authSelectUserRole" &&
                    request.operationName !== "authUpdateLogin" &&
                    request.operationName.substring(0, 4) === "auth")
            ) {
                resolve({});
                return;
            }

            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset();
            const i18next = getI18n();

            const token = await getAuthToken();
            if (token) {
                resolve({
                    headers: {
                        authorization: `Bearer ${token.access}`,
                        "x-app-version": appVersion,
                        "x-timezone-offset": timezoneOffset,
                        "x-language": i18next.language,
                        "x-trace": "trace " + options.trace,
                    },
                });
            } else {
                resolve({
                    headers: {
                        "x-app-version": appVersion,
                        "x-timezone-offset": timezoneOffset,
                        "x-language": i18next.language,
                        "x-trace": "trace " + options.trace,
                    },
                });
            }
        });
    });

    const errorLink = onError(
        ({ graphQLErrors, networkError, operation, forward }) => {
            if (networkError) {
                if (networkError.name === "ServerParseError") {
                    const serverError = networkError as ServerParseError;
                    console.error(
                        `[Server ${serverError.statusCode}]: ${serverError.bodyText}`
                    );
                }
                console.debug(
                    `[Network: ${networkError.name} ${networkError.message}]`
                );
                console.debug(`[OPERATION]`, JSON.stringify(operation));
                console.debug(`[Response errors]`, graphQLErrors);
                if (
                    networkError.message.includes("Failed to fetch") ||
                    networkError.message.includes("Network request failed")
                ) {
                    GraphQLState.dispatch({ connected: false });
                }
            }

            if (graphQLErrors) {
                const isInvalidAuthTokenError = graphQLErrors.find(
                    e => e.extensions?.code === ERR_INVALID_AUTH_TOKEN
                );

                if (isInvalidAuthTokenError) {
                    // Handle invalid auth token errors
                    options.onInvalidAuthToken();
                } else {
                    // Only capture errors that's not an invalid auth token error
                    captureGraphQLErrors(graphQLErrors, operation);
                }
            }

            forward(operation);
        }
    );

    return new ApolloClient({
        connectToDevTools: __DEV__,
        defaultOptions: {
            mutate: {
                fetchPolicy: "no-cache",
            },
            query: {
                fetchPolicy: "no-cache",
            },
        },
        link: connectionObserverLink.concat(
            contextLink.concat(
                errorLink.split(
                    ({ query }) => {
                        const def = getMainDefinition(query);
                        return (
                            def.kind === "OperationDefinition" &&
                            def.operation === "subscription"
                        );
                    },
                    wsLink,
                    httpLink
                )
            )
        ),
        cache,
    });
}

const ignoreCodes = [ERR_INVALID_AUTH_TOKEN, ERR_EVENT_STORE_DISPATCH_FAILED];

function captureGraphQLErrors(
    errors: readonly GraphQLError[],
    operation: Operation
) {
    for (let i = 0; i < errors.length; i++) {
        const error = errors[i];

        // Ignore specified codes
        if (
            typeof error.extensions?.code !== "string" ||
            ignoreCodes.includes(error.extensions?.code)
        ) {
            continue;
        }

        // Send message to Sentry
        captureError(
            new Error(
                `Backend request failed (${
                    error.extensions?.code || "NO_CODE"
                }): ${error.message}`
            ),
            scope => {
                scope.setExtra("graphqlError", error);
                scope.setExtra("operation", operation);
                return scope;
            }
        );
    }
}
