import { useAuth, useForm, useThemedStyle } from "@venuepos/react-common";
import { GQCustomerInput, useCustomerCreateMutation } from "graphql-sdk";
import { defaultCustomer, schemaCustomer } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { CustomerForm } from "../customer-form";
import { CustomerScreen } from "../customer-screen";

import type { StyleFunction } from "@venuepos/react-common";
type ScreenProps = RootStackScreenProps<"CUSTOMER_CREATE">;

export function CustomerCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const [t] = useTranslation();
    const [customerCreate] = useCustomerCreateMutation();
    const form = useForm<GQCustomerInput>(schemaCustomer, defaultCustomer);
    const [{ values }, { reset }] = form;
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await customerCreate({
                    variables: {
                        customer: {
                            name: values.name,
                            address: values.address,
                            zip: values.zip,
                            city: values.city,
                            phoneNumber: values.phoneNumber,
                            email: values.email,
                            cvrNumber: values.cvrNumber,
                            contactName: values.contactName,
                            comment: values.comment,
                            groupId: values.groupId || null,
                        },
                    },
                }),
            t("backoffice.customer.created", "Customer group created"),
            () => {
                reset();
                navigate("ACCOUNT_PAYMENTS", { refetch: true });
            }
        );
    }, [values, handleMutationError, t, customerCreate, reset, navigate]);

    return (
        <CustomerScreen>
            <View style={styles.formContainer}>
                <View style={styles.formBox} testID="customerAccountCreate">
                    <CustomerForm
                        id={undefined}
                        form={form}
                        onSubmit={create}
                        submitButton={["common.create", "Create"]}
                    />
                </View>
            </View>
        </CustomerScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        formContainer: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        formBox: {
            maxWidth: theme.dimensions.maxFormWidth,
            backgroundColor: theme.colors.white,
        },
    };
};
