import {
    contrastToBlack,
    getButtonColor,
    StyleFunction,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    ButtonPosition,
    Currency,
    GridDimensions,
    LayoutButton,
    LayoutButtonCreate,
    LayoutButtonMetaData,
    FunctionButton,
    ProductButton,
    LayoutSection,
} from "lib";
import React, { useCallback, useRef } from "react";
import {
    Animated,
    FlexStyle,
    StyleProp,
    StyleSheet,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";

import {
    createDndContext,
    Draggable as DraggableType,
} from "../../../components/drag-and-drop";
import { getMaxDimensionsForCoordinate } from "../functions";
import { GridEditorDroppable } from "./grid-editor-droppable";
import { LayoutButtonComponent } from "./layout-button";

export const GRID_MARGIN = 5;

// The opacity of the draggable element
const transparentDraggableColor = 0.625;
const opaqueDraggableColor = 1;

export function GridEditor({
    dimensions,
    width,
    height,
    buttons,
    currency,
    onMoveButtonToSection,
    onDeleteButton,
    onShowButtonSettings,
    onAddButton,
    onDropButton,
    editorStyle,
    testID,
}: {
    dimensions: GridDimensions;
    width: number;
    height: number;
    sectionId: LayoutSection["id"];
    buttons: (FunctionButton | ProductButton)[];
    currency: Currency;
    onMoveButtonToSection: (buttonId: LayoutButton["id"]) => void;
    onDeleteButton: (buttonId: LayoutButton["id"]) => Promise<void>;
    onShowButtonSettings: (
        buttonId: (FunctionButton | ProductButton | LayoutButtonCreate)["id"]
    ) => Promise<void>;
    onAddButton: (
        buttonPosition: ButtonPosition,
        buttonMetaData: LayoutButtonMetaData
    ) => Promise<void>;
    onDropButton: (
        position: ButtonPosition,
        buttonId: LayoutButton["id"]
    ) => void;
    editorStyle?: StyleProp<ViewStyle>;
    testID?: string;
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const { Provider, Droppable, Draggable } = createDndContext();
    const DragAndDropProvider = Provider;

    const draggableOpacity = useRef(1);
    const handleDragStart = () => {
        draggableOpacity.current = transparentDraggableColor;
    };

    const handleDragEnd = () => {
        draggableOpacity.current = opaqueDraggableColor;
    };

    const widthMultiplier = width / dimensions.columns;
    const heightMultiplier = height / dimensions.rows;

    const droppableHolders = useCallback(() => {
        const droppableHolder = (
            position: ButtonPosition,
            cellData: LayoutButtonMetaData,
            cellStyle: StyleProp<ViewStyle>
        ) => (
            <View
                style={[styles.positionAbsolute, styles.gridCell, cellStyle]}
                key={`droppable_cell_${position.x}_${position.y}`}
            >
                <Droppable
                    onDrop={(droppedElement: DraggableType) =>
                        onDropButton(position, droppedElement.payload.id)
                    }
                >
                    {({ active, viewProps }) => (
                        <Animated.View
                            {...viewProps}
                            style={[
                                styles.flexContainer,
                                styles.droppableStyle,
                                active ? styles.activeDroppable : null,
                                viewProps.style,
                            ]}
                        >
                            <GridEditorDroppable
                                onPress={async () =>
                                    await onAddButton(position, cellData)
                                }
                                testID={`layout:${position.x}:${position.y}`}
                            />
                        </Animated.View>
                    )}
                </Droppable>
            </View>
        );

        let elements = [];
        for (let _y = 0; _y < dimensions.rows; _y++) {
            for (let _x = 0; _x < dimensions.columns; _x++) {
                let cellWidth: number = widthMultiplier - GRID_MARGIN;
                let cellHeight: number = heightMultiplier - GRID_MARGIN;

                let cellStyle: StyleProp<ViewStyle> = {
                    left: _x * widthMultiplier,
                    top: _y * heightMultiplier,
                    height: cellHeight,
                    width: cellWidth,
                };

                const position = {
                    x: _x,
                    y: _y,
                };
                const maxDimensions = getMaxDimensionsForCoordinate(
                    position,
                    dimensions,
                    buttons
                );

                const cellData: LayoutButtonMetaData = {
                    maxWidthValue: maxDimensions.width,
                    maxHeightValue: maxDimensions.height,
                };

                elements.push(droppableHolder(position, cellData, cellStyle));
            }
        }

        return elements;
    }, [
        styles.positionAbsolute,
        styles.gridCell,
        styles.flexContainer,
        styles.droppableStyle,
        styles.activeDroppable,
        Droppable,
        onDropButton,
        onAddButton,
        dimensions,
        widthMultiplier,
        heightMultiplier,
        buttons,
    ]);

    return (
        <View
            style={[
                StyleSheet.absoluteFill,
                styles.gridContainerParent,
                editorStyle,
            ]}
            testID={testID}
        >
            <View style={styles.flexContainer}>
                <DragAndDropProvider>
                    {droppableHolders()}
                    {buttons.map((button, buttonIndex) => {
                        if (!button.id) {
                            return null;
                        }

                        if (
                            !(
                                button.metaData?.maxWidthValue ||
                                button.metaData?.maxHeightValue
                            )
                        ) {
                            const { width: maxWidth, height: maxHeight } =
                                getMaxDimensionsForCoordinate(
                                    {
                                        x: button.x,
                                        y: button.y,
                                    },
                                    dimensions,
                                    buttons
                                );

                            button.metaData = {
                                ...button.metaData,
                                maxWidthValue: maxWidth,
                                maxHeightValue: maxHeight,
                            };
                        }

                        const visualWidth = button.width * widthMultiplier;
                        const visualHeight = button.height * heightMultiplier;

                        const buttonDimensionStyle: StyleProp<
                            Pick<FlexStyle, "width" | "height">
                        > = {
                            height: visualHeight - 2 * GRID_MARGIN,
                            width: visualWidth - 2 * GRID_MARGIN,
                        };
                        const buttonPositionStyle: StyleProp<ViewStyle> = {
                            left: button.x * widthMultiplier + GRID_MARGIN / 2,
                            top: button.y * heightMultiplier + GRID_MARGIN / 2,
                        };

                        const styleProps: StyleProp<ViewStyle> = [
                            buttonDimensionStyle,
                        ];

                        const buttonColor = getButtonColor(
                            button.color,
                            button.metaData.groupColor
                        );

                        styleProps.push({
                            backgroundColor: buttonColor,
                        });

                        const textStyle: StyleProp<TextStyle> = {
                            color: contrastToBlack(buttonColor)
                                ? theme.colors.textLight
                                : theme.colors.textDark,
                        };
                        return (
                            <View
                                key={`draggable_container_${button.id}`}
                                testID={`layout:draggable:${buttonIndex}`}
                                style={StyleSheet.flatten([
                                    styles.positionAbsolute,
                                    buttonDimensionStyle,
                                    buttonPositionStyle,
                                ])}
                            >
                                <Draggable
                                    customId={`draggable_${button.id}`}
                                    payload={button}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                >
                                    {({ viewProps }) => {
                                        const styleObj = [
                                            {
                                                opacity:
                                                    draggableOpacity.current,
                                            },
                                            viewProps.style,
                                        ];

                                        return (
                                            <Animated.View
                                                {...viewProps}
                                                style={styleObj}
                                            >
                                                <LayoutButtonComponent
                                                    currency={currency}
                                                    button={button}
                                                    textStyle={textStyle}
                                                    buttonStyle={styleProps}
                                                    onPress={async () =>
                                                        await onShowButtonSettings(
                                                            button.id
                                                        )
                                                    }
                                                    onDelete={async () =>
                                                        await onDeleteButton(
                                                            button.id
                                                        )
                                                    }
                                                    onSendTo={() =>
                                                        onMoveButtonToSection(
                                                            button.id
                                                        )
                                                    }
                                                    testID={`layout:button:label:${
                                                        button.label ||
                                                        button.metaData
                                                            ?.buttonText
                                                    }`}
                                                />
                                            </Animated.View>
                                        );
                                    }}
                                </Draggable>
                            </View>
                        );
                    })}
                </DragAndDropProvider>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    flexContainer: {
        flex: 1,
    },
    positionAbsolute: {
        position: "absolute",
    },
    gridContainerParent: {
        position: "relative",
        backgroundColor: theme.colors.white,
        height: 640,
    },
    gridCell: {
        padding: GRID_MARGIN / 2,
    },
    droppableStyle: {
        borderColor: "transparent",
        borderStyle: "dashed",
        borderWidth: 2,
    },
    activeDroppable: {
        borderColor: theme.colors.success,
        borderStyle: "dashed",
        borderWidth: 2,
        borderRadius: theme.borderRadiusTiny,
    },
});
