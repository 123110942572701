import React, { ReactNode } from "react";
import { StyleProp, ViewStyle, StyleSheet, Animated } from "react-native";
import { shadow } from "../../theme";
import { StyleFunction, useThemedStyle } from "../../theme";

export function Surface(props: {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
}) {
    const { style, ...rest } = props;
    const flattenedStyles = StyleSheet.flatten(style) || {};
    const { elevation = 1 }: ViewStyle = flattenedStyles;
    const styles = useThemedStyle(styleFunc);

    return (
        <Animated.View
            {...rest}
            style={[
                styles.container,
                elevation > 0 ? shadow(elevation) : null,
                style,
            ]}
        />
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        backgroundColor: theme.colors.white,
        borderRadius: theme.borderRadiusSmall,
        paddingHorizontal: theme.spacingScale * 3,
        paddingVertical: theme.spacingScale * 2,
    },
});
