import { SearchDef, today } from "lib";
import { useCallback } from "react";
import { useTranslation } from "locales";

export function useSearchDefinition() {
    const [t] = useTranslation();

    const createSearchDefinition = useCallback(
        (
            name:
                | "amount"
                | "name"
                | "dateInterval"
                | "user"
                | "department"
                | "string"
                | "createdAt",
            options?: { name?: string }
        ): SearchDef => {
            switch (name) {
                case "amount":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.amount", "Amount"),
                        type: "amount",
                        value: 0,
                        enabled: false,
                    };
                case "name":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.name", "Name"),
                        type: "string",
                        value: "",
                        enabled: false,
                    };
                case "createdAt":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.created_between", "Created between"),
                        type: "date",
                        values: {
                            from: today(new Date()),
                            to: today(new Date()),
                        },
                        enabled: false,
                    };

                case "user":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.user", "User"),
                        type: "string",
                        value: "",
                        enabled: false,
                    };
                case "department":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.department", "Department"),
                        type: "string",
                        value: "",
                        enabled: false,
                    };
                case "string":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.string", "Text"),
                        type: "string",
                        value: "",
                        enabled: false,
                    };
                case "dateInterval":
                    return {
                        name: options?.name
                            ? options.name
                            : t("searching.between", "Between"),
                        type: "date",
                        values: {
                            from: today(new Date()),
                            to: today(new Date()),
                        },
                        enabled: false,
                    };
            }
        },
        [t]
    );

    return { createSearchDefinition };
}
