import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useTranslation } from "locales";
import {
    Button,
    CheckBox,
    DataTable,
    Loading,
    SearchInput,
    StyleFunction,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQTagScreenFieldsFragment, useTagsQuery } from "graphql-sdk";
import { useMultiSelect } from "../../../hooks/use-multi-select";
import { TagItem } from "./tag-item";
import type {
    AssignTagsModalOutput,
    AssignTagsModalProps,
} from "./assign-tags-modal";

export function TagsViewTab({
    enabledTags,
    onClose,
}: {
    enabledTags: AssignTagsModalProps["enabledTags"];
    onClose: (tags?: AssignTagsModalOutput) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialPageSize: 10,
    });

    const {
        selectedItems: selectedTags,
        setSelectedItems: setSelectedTags,
        handleMultiSelect,
        allItemsSelected: allRowsSelected,
        setAllItemsSelected: setAllRowsSelected,
    } = useMultiSelect<GQTagScreenFieldsFragment["id"]>();

    const [search, setSearch] = useState<string>("");

    const { data, loading } = useTagsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
            tagIds: enabledTags,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!data?.tags.data || !enabledTags) {
            return;
        }
        let tags = [];
        for (let i = 0; i < data.tags.data.length; i++) {
            tags.push(data?.tags.data[i].id);
        }
        setSelectedTags(tags);
    }, [data, setSelectedTags, setAllRowsSelected, enabledTags]);

    const renderTagRow = useCallback(
        (item: GQTagScreenFieldsFragment) => (
            <TagItem
                key={item.id}
                item={item}
                selectedTag={selectedTags.includes(item.id)}
                onMultiSelectChange={handleMultiSelect}
            />
        ),
        [selectedTags, handleMultiSelect]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <View>
            <SearchInput onChange={handleSearchTextChange} />
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title style={styles.iconColumn}>
                        <CheckBox
                            value={allRowsSelected}
                            onValueChange={value => {
                                const allItemIds = data!.tags.data.map(
                                    p => p.id
                                );
                                handleMultiSelect(value, allItemIds);
                                setAllRowsSelected(value);
                            }}
                        />
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={sortBy === "name" && sortDirection}
                        onPress={() => onSortChange("name")}
                    >
                        {t("common.name", "Name")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={sortBy === "groupName" && sortDirection}
                        onPress={() => onSortChange("groupName")}
                    >
                        {t("common.group", "Group")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={sortBy === "tagType" && sortDirection}
                        onPress={() => onSortChange("tagType")}
                    >
                        {t("common.type", "Type")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={sortBy === "identifier" && sortDirection}
                        onPress={() => onSortChange("identifier")}
                    >
                        {t("backoffice.account.tag", "Tag")}
                    </DataTable.Title>
                </DataTable.Header>
                {(loading && <Loading />) || data!.tags.data.map(renderTagRow)}
                <DataTable.Pagination
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    onSizeChange={onPageSizeChange}
                    page={page}
                    numberOfPages={data?.tags.pagination.pages}
                    itemCount={data?.tags.pagination.resultCount}
                    disablePageSizeSelector={true}
                />
            </DataTable>
            <View style={styles.buttonContainer}>
                <Button
                    style={styles.button}
                    onPress={() => {
                        onClose(selectedTags);
                    }}
                >
                    {t("common.save", "Save")}
                </Button>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    typeIcon: {
        color: theme.colors.primary,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
});
