import {
    LoadingScreen,
    Spacer,
    StyleFunction,
    useAuth,
    useForm,
    useMe,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import { useRoleEditFormLazyQuery, useRoleSaveMutation } from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { schemaRole } from "./forms";
import { RoleDeleteForm } from "./role-delete-form";
import { RoleForm } from "./role-form";
import { RoleScreen } from "./role-screen";

import type { IRolePermissionInput, IRoleSave } from "lib";
type ScreenProps = RootStackScreenProps<"ROLE_EDIT">;

export function RoleEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.role", "admin.role"], "OR");

    const styles = useThemedStyle(styleFunc);
    const me = useMe();
    const [t] = useTranslation();
    const toast = useToast();
    const [saveRole, { loading: savingLoading }] = useRoleSaveMutation();
    const [getRole, role] = useRoleEditFormLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IRoleSave>(schemaRole, null);
    const [{ values }, { setDefaultValues, reset: resetForm }] = form;
    const roleId = route.params.id;

    useEffect(() => {
        if (!roleId) {
            navigate("ROLES");
        } else {
            getRole({ variables: { id: roleId } });
        }
    }, [getRole, navigate, roleId]);

    useEffect(() => {
        if (role.data && role.data.role) {
            setDefaultValues({
                name: role.data.role.name,
                type: role.data.role.type,
                shared: role.data.role.shared,
                permissions: [],
            });
        }
    }, [role.data, setDefaultValues]);

    const save = useCallback(async () => {
        if (!role.data || !role.data.role || !values) {
            return;
        }

        await saveRole({
            variables: {
                id: role.data.role.id,
                role: {
                    name: values.name,
                    type: role.data.role.type,
                    shared: false,
                    permissions: values.permissions,
                },
            },
        });

        await role.refetch();
        resetForm();

        toast.success(t("backoffice.role_form.saved", "Role saved"));

        if (role.data.role.id === me.role?.id) {
            console.log("Should resign");
            await auth.resignIn();
        }
    }, [auth, me.role, resetForm, role, saveRole, t, toast, values]);

    if (!roleId || !role.data || !role.data.role) {
        return <LoadingScreen style="light" />;
    }

    return (
        <RoleScreen>
            <View style={styles.box}>
                <View style={styles.container}>
                    <RoleForm
                        form={form}
                        merchant={role.data.role.merchant}
                        onSubmit={save}
                        submitButton={t("common.save", "Save")}
                        permissionsValues={
                            role.data.role.permissions as IRolePermissionInput[]
                        }
                        loading={role.loading || savingLoading}
                        roleType={role.data.role.type}
                        readOnly={role.data.role.shared}
                    />
                </View>
            </View>
            {(!role.data.role.shared || auth.may("admin.role")) && (
                <>
                    <Spacer space={5} />
                    <View style={styles.box}>
                        <View style={styles.container}>
                            <RoleDeleteForm
                                name={role.data.role.name}
                                role={role.data.role}
                            />
                        </View>
                    </View>
                </>
            )}
        </RoleScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    box: {
        width: "100%",
        backgroundColor: theme.colors.white,
    },
    container: {
        width: "100%",
        maxWidth: 600,
        alignSelf: "flex-start",
    },
});
