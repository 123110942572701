import { useAuth, useForm, useThemedStyle } from "@venuepos/react-common";
import { useVatCreateMutation } from "graphql-sdk";
import { defaultVat, IVatInput, schemaVat } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { AdminContainer } from "../..";
import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { Form } from "../form";

import type { StyleFunction } from "@venuepos/react-common";
type ScreenProps = RootStackScreenProps<"VAT_CREATE">;

export function VatCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.vat");

    const [t] = useTranslation();
    const [vatCreate] = useVatCreateMutation();
    const form = useForm<IVatInput>(schemaVat, defaultVat);
    const [{ values }] = form;
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await vatCreate({
                    variables: {
                        vat: {
                            name: values.name,
                            percentage: values.percentage,
                        },
                    },
                }),
            t("backoffice.vat.created", "Vat created"),
            () => {
                if (!route.params || !route.params.referrer) {
                    navigate("VATS", { refetch: true });
                    return;
                }

                if (route.params.referrer.route === "MERCHANT_SETTINGS") {
                    navigate("MERCHANT_SETTINGS");
                    return;
                }

                if (
                    route.params.referrer.route === "PRODUCT_EDIT" &&
                    route.params.referrer.id
                ) {
                    navigate("PRODUCT_EDIT", {
                        id: route.params.referrer?.id,
                    });
                }
            }
        );
    }, [values, handleMutationError, t, vatCreate, route, navigate]);

    return (
        <AdminContainer>
            <View style={styles.formContainer}>
                <View style={styles.formBox}>
                    <Form
                        form={form}
                        onSubmit={create}
                        submitButton={["common.create", "Create"]}
                    />
                </View>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        formContainer: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        formBox: {
            maxWidth: theme.dimensions.maxFormWidth,
            backgroundColor: theme.colors.white,
        },
    };
};
