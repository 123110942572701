import { ApolloError } from "@apollo/client";
import { StackScreenProps } from "@react-navigation/stack";
import {
    Alert,
    Headline,
    Loading,
    PrinterWrapper,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCardTransactionQuery, useCardTransactionQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React from "react-native";

import { RootStackParamList } from "../../../navigation";
import { AdminContainer } from "../../container";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "CARD_TRANSACTION_VIEW"
>;

export function CardTransactionScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.invoice");

    const transactionId = route.params.id;
    const { loading, error, data } = useCardTransactionQuery({
        variables: {
            id: transactionId,
        },
        fetchPolicy: "no-cache",
    });

    return (
        <AdminContainer>
            <Transaction loading={loading} error={error} data={data} />
        </AdminContainer>
    );
}

function Transaction({
    loading,
    error,
    data,
}: {
    loading: boolean;
    error?: ApolloError;
    data?: GQCardTransactionQuery;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    if (!data?.cardTransaction.receipt) {
        return (
            <Alert type="warning">
                {t(
                    "backoffice.card_transaction.missing",
                    "The card transaction receipt is missing."
                )}
            </Alert>
        );
    }

    return (
        <Surface>
            <Headline size="h4" style={styles.headline}>
                {t("common.card_transaction", "Card transaction")}
            </Headline>

            <PrinterWrapper>
                <Surface style={styles.receiptWrapper}>
                    <Text style={styles.receipt}>
                        {data?.cardTransaction.receipt}
                    </Text>
                </Surface>
            </PrinterWrapper>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    headline: {
        marginBottom: 0,
    },
    receipt: {
        ...theme.fonts.monospace,
        fontSize: 14,
    },
    receiptWrapper: {
        padding: theme.spacingScale * 2,
        borderWidth: 1,
        borderColor: theme.colors.grey250,
        borderRadius: 0,

        maxWidth: 400,
        elevation: 3,
    },
});
