import {
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { useDepartmentCreateMutation } from "graphql-sdk";
import { defaultDepartment, IDepartmentInput, schemaDepartment } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { DepartmentForm } from "../department-form";
import { DepartmentScreen } from "../department-screen";

type ScreenProps = RootStackScreenProps<"DEPARTMENT_EDIT">;

export function DepartmentCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.department.write");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [departmentCreate] = useDepartmentCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<IDepartmentInput>(schemaDepartment, defaultDepartment);
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                departmentCreate({
                    variables: {
                        department: {
                            name: values.name,
                            externalId: values.externalId,
                        },
                    },
                }),
            t("backoffice.department.created", "Department created"),
            () => {
                reset();
                navigate("DEPARTMENTS", { refetch: true });
            }
        );
    }, [values, handleMutationError, t, departmentCreate, reset, navigate]);

    return (
        <DepartmentScreen>
            <Surface>
                <View style={styles.box}>
                    <View style={styles.container}>
                        <DepartmentForm
                            form={form}
                            onSubmit={create}
                            submitButton={["common.create", "Create"]}
                        />
                    </View>
                </View>
            </Surface>
        </DepartmentScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
