import type {
    ImageStyle,
    ScaledSize,
    TextStyle,
    ViewStyle,
} from "react-native";
import { useContext, useMemo } from "react";
import { useDimensions } from "../hooks";
import { ITheme } from "./types";
import { ThemeContext } from "./";

// Allows us to do performant, but themed styles with optional dimensions
// Usage: useThemedStyle(styleFunc)
// Note: Please put the styleFunc in the bottom of the component file, like you would with other styles.

export type ThemedStyleProps<T> = {
    [P in keyof T]: ImageStyle & TextStyle & ViewStyle;
};

export function useThemedStyle<T>(
    styleFunc: (theme: ITheme, dimensions: ScaledSize) => T
): ThemedStyleProps<T> {
    const theme = useTheme();
    const dimensions = useDimensions();

    return useMemo(
        () => styleFunc(theme, dimensions) as unknown as ThemedStyleProps<T>,
        [styleFunc, theme, dimensions]
    );
}

export function useTheme(): ITheme {
    const ctx = useContext(ThemeContext);

    if (ctx === undefined) {
        throw new Error(
            "Theme Context is undefined. Remember to wrap your app in <ProvideThemeContext />"
        );
    }

    return ctx;
}
