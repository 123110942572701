import {
    Button,
    Headline,
    IconButton,
    StyleFunction,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { useCallback } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { TableButtonForm } from "./table-button-form";

import type { LayoutTableButton } from "lib";
export type EditButtonModalOutput = {
    action: "EDIT" | "DELETE";
    formValues?: LayoutTableButton;
    buttonId?: string;
};

export type EditButtonModalProps = {
    button: LayoutTableButton;
    onClose: (value?: EditButtonModalOutput) => void;
};

export function EditButtonModal({ button, onClose }: EditButtonModalProps) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    const handleDeletePress = useCallback(
        buttonData => onClose({ action: "DELETE", buttonId: buttonData.id }),
        [onClose]
    );

    return (
        <View style={styles.container}>
            <Headline>
                {t("backoffice.layout.edit_button", "Edit button")}
            </Headline>
            <TableButtonForm
                onSubmit={formValues => onClose({ action: "EDIT", formValues })}
                values={button}
            >
                <Button
                    size="small"
                    type="secondary"
                    iconName="delete"
                    onPress={() => handleDeletePress(button)}
                    style={styles.deleteButton}
                >
                    {t("common.delete", "Delete")}
                </Button>
            </TableButtonForm>
            <IconButton
                style={styles.closeIcon}
                onPress={() => onClose()}
                color={styles.container.backgroundColor}
                name="close"
            />
        </View>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        position: "relative",
        padding: theme.spacingScale * 2,
        backgroundColor: theme.colors.white,
        height: dimensions.height - 100,
        minWidth:
            dimensions.width <= theme.breakpoint.portraitWidth ? "100%" : 800,
        maxWidth:
            dimensions.width <= theme.breakpoint.portraitWidth
                ? dimensions.width - 20
                : dimensions.width - 100,
    },
    closeIcon: {
        position: "absolute",
        backgroundColor: theme.colors.primary,
        borderRadius: dimensions.height <= 768 ? 0 : 17,
        top: 0,
        right: 0,
        marginRight: dimensions.height <= 768 ? 0 : -17,
        marginTop: dimensions.height <= 768 ? 0 : -17,
        elevation: 1,
    },
    deleteButton: {
        marginTop: theme.spacingScale,
        width: 150,
        marginRight: 100,
    },
});

export function useEditButtonModal() {
    const { render } = useModal<EditButtonModalOutput | undefined>();
    return async (props: Omit<EditButtonModalProps, "onClose">) => {
        return render(onClose => (
            <EditButtonModal {...props} onClose={onClose} />
        ));
    };
}
