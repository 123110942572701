import {
    Alert,
    Button,
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useGiftCardsQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { RootStackScreenProps } from "../../../navigation";
import { useAdminSession } from "../../../session";
import { AdminContainer } from "../../container";
import { AccountRow } from "./account-row";

import type { AvailableLocale } from "locales";
import { SearchDefinition } from "lib";
import { useAccessToken, useSearchDefinition } from "../../../hooks";
import { ImportGiftcardsModal } from "./import-modal";
type ScreenProps = RootStackScreenProps<"ACCOUNTS">;

export function AccountsListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const theme = useTheme();
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const formRef = useRef<HTMLFormElement>(null);
    const accessToken = useAccessToken();
    const { render } = useModal();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);

    const defaultAccountSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        amount: createSearchDefinition("amount"),
        status: {
            name: t("searching.status", "Status"),
            type: "type",
            value: "",
            values: [
                { name: t("searching.open", "Open"), value: "OPEN" },
                { name: t("searching.closed", "Closed"), value: "CLOSED" },
            ],
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultAccountSearch, {
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    // GraphQL
    const { data, loading, error, refetch } = useGiftCardsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleView = useCallback(
        (id: string) => {
            navigate("ACCOUNT_VIEW", {
                id,
            });
        },
        [navigate]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("ACCOUNT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    function handleDownloadPress() {
        if (!formRef.current) {
            // DOM Form not ready
            return;
        }

        formRef.current.submit();
    }

    const handleImportPress = useCallback(async () => {
        await render(onClose => <ImportGiftcardsModal onClose={onClose} />);
    }, [render]);

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                <Button
                    testID="importGiftcards"
                    style={styles.button}
                    variant="invert"
                    onPress={handleImportPress}
                >
                    {t("common.import", "Import")}
                </Button>
                <Spacer space={1} />
                <Button
                    testID="exportGiftcards"
                    style={styles.button}
                    variant="invert"
                    onPress={handleDownloadPress}
                >
                    {t("common.export", "Eksport")}
                </Button>
                <Spacer space={1} />
                <Button
                    testID="createGiftcard"
                    style={styles.button}
                    type="secondary"
                    onPress={() => {
                        navigate("ACCOUNT_CREATE", {
                            type: "GIFT_CARD",
                        });
                    }}
                >
                    {t("common.create", "Create")}
                </Button>
                <Spacer space={1} />
                {searchIndicator}
            </View>
            <Spacer space={1} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "status" && sortDirection}
                            onPress={() => onSortChange("status")}
                        >
                            {t("common.status", "Status")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={sortBy === "amount" && sortDirection}
                            onPress={() => onSortChange("amount")}
                        >
                            {t("common.balance", "Balance")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data?.giftCards.data.map(item => (
                            <AccountRow
                                key={item.id}
                                item={item}
                                onView={() => handleView(item.id)}
                                onEdit={() => handleEdit(item.id)}
                                locale={locale as AvailableLocale}
                                currency={currency}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.giftCards.pagination.pages}
                        itemCount={data?.giftCards.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
            <form
                target="_blank"
                ref={formRef}
                action="/api/export/giftcard"
                method="post"
            >
                <input type="hidden" name="language" value={locale} />
                <input
                    type="hidden"
                    name="token"
                    id="token"
                    value={accessToken}
                />
            </form>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = () => ({
    container: {
        justifyContent: "flex-end",
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
    },
});
