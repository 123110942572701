import { Button, Headline, Text, useThemedStyle } from "@venuepos/react-common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useAccessToken } from "../../hooks/use-access-token";
import { ValidationErrorsList } from "./validation-errors-list";

import type { StyleFunction } from "@venuepos/react-common";
import type { ValidationErrorData } from "lib/src/validation/types";
export function ImportTagsModal({ onClose }: { onClose: (val: any) => any }) {
    const accessToken = useAccessToken();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [successful, setSuccessful] = useState<boolean>(false);
    const [importState, setImportState] = useState<string | undefined>();
    const [validationErrors, setValidationErrors] = useState<
        { errors: ValidationErrorData[]; rowIndex: number }[]
    >([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = useCallback(async () => {
        const fileName = inputRef.current?.files && inputRef.current!.files[0];
        if (!fileName || !accessToken) {
            return;
        }

        const formData = new FormData();
        formData.append("tag-import", fileName);
        await fetch("/api/import/tag", {
            method: "POST",
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        })
            .then(async response => {
                const jsonResponse = await response.json();
                if (jsonResponse.error) {
                    setImportState(t(jsonResponse.error));
                    if (jsonResponse.validationErrors) {
                        setValidationErrors(jsonResponse.validationErrors);
                    } else {
                        setValidationErrors([]);
                    }
                    return;
                }

                setImportState(
                    t("import.successful", {
                        itemsAdded: jsonResponse.tagsAdded,
                        itemGroupsAdded: jsonResponse.tagGroupsAdded,
                        itemsUpdated: jsonResponse.tagsUpdated,
                        itemsHandled: jsonResponse.tagsHandled,
                    })
                );
                setSuccessful(true);
            })
            .catch(error => {
                console.debug(error);
            });
    }, [accessToken, t]);

    useEffect(() => {
        if (inputRef.current === null) {
            return;
        }
        const input = inputRef.current;
        input.click();
        input.addEventListener("change", handleFileChange);
        return () => {
            input.removeEventListener("change", handleFileChange);
        };
    }, [inputRef, handleFileChange]);

    return (
        <View style={styles.container}>
            <Headline>{t("common.import", "Import")}</Headline>

            {!successful && (
                <input
                    name="file"
                    ref={inputRef}
                    type="file"
                    style={styles.fileInput as any}
                />
            )}

            {importState && (
                <Text style={successful ? styles.successful : styles.error}>
                    {importState}
                </Text>
            )}

            <ValidationErrorsList errors={validationErrors} />

            <Button
                style={styles.closeButton}
                onPress={() => {
                    onClose(null);
                }}
            >
                {t("common.close", "Close")}
            </Button>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        backgroundColor: theme.colors.white,
        padding: theme.spacingScale * 2,
        alignSelf: "center",
        width: "100%",
        maxWidth: 500,
    },
    fileInput: {
        marginBottom: theme.spacingScale * 2,
    },
    closeButton: {
        marginTop: theme.spacingScale * 2,
    },
    error: {
        color: theme.colors.error,
    },
    successful: {
        color: theme.colors.success,
    },
});
